
import { useRecoilValue } from "recoil";
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector";
import { getQuery } from "../../shared/function";

/* eslint-disable @typescript-eslint/no-unused-vars */
const NearBy = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { nearby } = data

  if(nearby.length < 1) return <></>

  return (
    <article>
      <h2>주변 설명</h2>
      <ul className="icon_holder">
        {
          nearby
            .map((i , index) => {
              return (
                <li key={index}>
                  <img src={i.icon_path} alt={`${i.name} 아이콘`} />
                  {i.name}
                </li>
              )
          })
        }
      </ul>
    </article>
  )
}

export default NearBy