import { useEffect, useRef } from 'react'

interface Props {
  panoId: any
  position: any
  map: any
  setPanoId: React.Dispatch<React.SetStateAction<any>>
  setPosition: React.Dispatch<React.SetStateAction<any>>
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const RoadView = (props: Props) => {
  const { panoId, position, map, setPanoId, setPosition } = props

  const ref = useRef<HTMLDivElement | null>(null)

  const roadViewCall = () => {
    const rv = new window.kakao.maps.Roadview(ref.current)

    const rvClient = new window.kakao.maps.RoadviewClient()

    rvClient.getNearestPanoId(position, 50, function (panoId: any) {
      map.relayout() //지도를 감싸고 있는 영역이 변경됨에 따라, 지도를 재배열합니다
      rv.setPanoId(panoId, position) //panoId를 통한 로드뷰 실행
      rv.relayout() //로드뷰를 감싸고 있는 영역이 변경됨에 따라, 로드뷰를 재배열합니다
    })
  }

  const onClickClose = () => {
    setPanoId(null)
    setPosition(null)
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (panoId && position) {
      roadViewCall()
    }
    // eslint-disable-next-line
  }, [panoId, position])

  if (!panoId || !position) {
    return <></>
  }

  return (
    <div className='layer-map-dim' onClick={onClickClose}>
      <div onClick={stopPropagation}>
        <button onClick={onClickClose}>
          <div />
          <div />
        </button>
        <div id='roadview' ref={ref} />
      </div>
    </div>
  )
}

export default RoadView
