import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import ImageSlidePopup from '../popup/image-slide-popup'


/* eslint-disable @typescript-eslint/no-unused-vars */
const Pyeong = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const [checked, setChecked] = useState("")

  const { pyeong, detail } = data

  const [popupImgIdx, setPopupImgIdx] = useState<number | undefined>(undefined)

  const [clickPopupImgIdx, setClickPopupImgIdx] = useState<number | undefined>(
    undefined
  )

  const onChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idx = parseInt(e.target.id.split('_')[1])
    setPopupImgIdx(idx)
    setChecked(e.target.id)
  }

  const onClickImage = () => {
    if (popupImgIdx) {
      setClickPopupImgIdx(popupImgIdx)
    } else {
      setClickPopupImgIdx(0)
    }
  }

  useEffect(() => {
    if (pyeong?.length) {
      setChecked(`${pyeong[0].title}_0`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pyeong])

  if (!pyeong) return <></>

  if (
    pyeong.length === 0 || !["WH", "FT", "KC"].includes(detail.type)
  ) return <></>

  const selected_pyeong = pyeong.filter((_, i) => {

    const id = `${_.title}_${i}`

    return id === checked
  })

  return (
    <article className='pyeong_wrapper'>
      <h2>평형정보</h2>
      <ul className='type_info'>
        {pyeong.map((i, index) => {
          const id = `${i.title}_${index}`

          return (
            <li>
              <input
                type='radio'
                id={id}
                name={id}
                checked={checked === id}
                onChange={onChangeChecked}
              />
              <label htmlFor={id}>{i.title}</label>
            </li>
          )
        })}
      </ul>
      {selected_pyeong.length === 1 && (
        <div
          className='img_area'
          style={{ width: 'unset' }}
          onClick={onClickImage}
        >
          <img src={selected_pyeong[0].path} style={{ width: '100%' }} alt='' />
        </div>
      )}
      {clickPopupImgIdx !== undefined && (
        <ImageSlidePopup
          onClickClose={() => setClickPopupImgIdx(undefined)}
          imgIdx={clickPopupImgIdx}
          name='bunyang'
        />
      )}
    </article>
  )
}

export default Pyeong