import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import new_alarm_atom from '../atom/new-alarm'
import LoginResponseModel from '../service/account/model/login-response-model'
import API from '../service/api'
import accountManager from '../shared/account-manager'
import { PATH } from '../shared/constant'
import { apiCallback, blankFn } from '../shared/function'

function useGetDefault() {
  const history = useHistory()

  const location = useLocation()

  const setNewAlarm = useSetRecoilState(new_alarm_atom)

  const getDefault = () => {
    const account_idx = accountManager.getIdx()

    if (account_idx === null) return

    API.account
      .getDefault(account_idx)
      .then((res) => {
        apiCallback(res, {
          success: getDefaultSuccessCallback,
          fail: blankFn,
        })
      })
      .catch(getDefaultFailCallback)
  }

  const getDefaultSuccessCallback = (res: LoginResponseModel) => {
    accountManager.login(res.info)
    setNewAlarm(res.info.alarm_not_read_cnt)
  }

  const getDefaultFailCallback = (err: AxiosError) => {
    try {
      if (err?.response?.data?.msg) {
        if (err?.response?.data?.msg === 'DELETE') {
          accountManager.logout()

          history.replace(PATH.LOGIN)
        }
      }
    } catch {}
  }

  useEffect(() => {
    getDefault()
    // eslint-disable-next-line
  }, [location.pathname])
}

export default useGetDefault
