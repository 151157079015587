import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import AreaPop from "./area-pop"
import CompleteAfterPop from "./complete-after-pop"
import DealTypePop from "./deal-type-area"
import PricePop from "./price-pop"

/* eslint-disable @typescript-eslint/no-unused-vars */
const PcFilterArea = () => {

  const media = useRecoilValue(media_atom)

  if ( media !== "P" ) return <></>

  return (
    <>
      <PricePop />
      <AreaPop />
      <DealTypePop />
      <CompleteAfterPop />
    </>
  )
}

export default PcFilterArea
