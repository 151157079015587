import { useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import media_atom from '../../atom/media-atom'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { bigNumber, getQuery } from '../../shared/function'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Middle = () => {
  const media = useRecoilValue(media_atom)

  const { area_type } = useRecoilValue(bunyang_detail_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data

  const area_type_text = area_type === 'PY' ? '평' : '㎡'

  const total_area = detail.total_tot_area ? detail.total_tot_area : detail.total_plat_area

  let unit_price = 0

  if(total_area > 0){

    const py = total_area / 3.3058
  
    const sq = total_area
  
    unit_price = detail.price_main / (area_type === 'PY' ? py : sq)
  } 
  
  // const unit_price = (detail.price_main / detail.total_tot_area) / (area_type === 'PY' ? 3.3058 : 1)

  return (
    <>
      <div>
        <h2 className='c-price fw600'>{detail.price_text}</h2>
        <p>
          ({bigNumber(unit_price)}원 /{area_type_text})
        </p>
      </div>
      {media === 'P' && (
        <div className='item_info'>
          <span>
            최종업데이트 :{' '}
            <strong className='fw500'>{detail.complete_ymd}</strong>
          </span>
        </div>
      )}
    </>
  )
}

export default Middle
