import Gnb from './gnb'
import Footer from './footer'

import '../../style/components/layout/main.scss'
import TabBar from './tab-bar'
import { PATH } from '../../shared/constant'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
type Maintype = {
  children: JSX.Element
  id?: string
  className?: string
  is_main?: boolean
  vis_footer?: boolean
}
export default function Main(props: Maintype) {
  const media = useRecoilValue(media_atom)
  const Location = window.location.pathname
  const accessList = ['/e1','/e2','/e3','/e4','/e5','/e6','/e7','/e8','/e9','/e10']
  return (
    <>
      {media === 'M' ? <></> : <Gnb is_main={props.is_main} />}
      <main id={props.id} className={props.className}>
        {props.children}
      </main>
      {media === 'M' && (Location === PATH.MAIN || accessList.indexOf(Location) !== -1) ? (
        <Footer vis_footer={props.vis_footer} />
      ) : media === 'P' ? (
        <Footer vis_footer={props.vis_footer} />
      ) : media === 'T' ? (
        <Footer vis_footer={props.vis_footer} />
      ) : (
        <></>
      )}
      {(media === 'M' && (Location === PATH.MAIN || accessList.indexOf(Location) !== -1)) ||
      Location === '/map' ||
      Location === '/sub-division' ||
      Location === '/register' ||
      Location === '/mypage' ||
      Location === '/sub-division/guide-detail' ||
      Location === '/sub-division/info-detail' ||
      Location === '/sub-division/theme-detail' ||
      Location === '/sub-division/news-detail' ||
      Location === '/notice' ? (
        <TabBar />
      ) : (
        <></>
      )}
    </>
  )
}
