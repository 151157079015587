import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const LoanSkeleton = () => {

  const media = useRecoilValue(media_atom)

  return (
    <div className='detail_bottom_wrap loan_info'>
      <article>
        <Skeleton height={25} width={61.13} radius={4} marginBottom={16} />
        <Skeleton width={'100%'} height={media === "P" ? 169.59 : 364.19} radius={4} />
      </article>
    </div>
  )
}

export default LoanSkeleton