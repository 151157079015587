import { atom } from 'recoil'
interface MapFilterOptionsAtomModel {
  price_pop: boolean
  area_pop: boolean
  deal_type_pop: boolean
  complete_after_pop: boolean
  filter_all_pop: boolean
}

const map_filter_options_atom = atom<MapFilterOptionsAtomModel>({
  key: 'map_filter_options_atom',
  default: {
    price_pop: false,
    area_pop: false,
    deal_type_pop: false,
    complete_after_pop: false,
    filter_all_pop: false,
  },
})

export default map_filter_options_atom
