import AreaTypeToggle from './area-type-toggle'
import CertifyButton from './certify-button'
import Controller from './controller'
import DealTypeToggle from './deal-type-toggle'
import FilterAll from './filter-all'
import FullMap from './full-map'
import FullMapFallback from './full-map-fallback'
import MapSideBar from './map-side-bar'
import MapTopBar from './map-top-bar'
import PcFilterArea from './pc-filter-area'
import SearchArea from './search-area'
import PcTypeArea from './pc-type-area'

const Map = {
  FullMap,
  MapSideBar,
  MapTopBar,
  FullMapFallback,
  SearchArea,
  DealTypeToggle,
  PcTypeArea,
  PcFilterArea,
  FilterAll,
  CertifyButton,
  Controller,
  AreaTypeToggle,
}

export default Map
