import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { comma, getQuery, onChangeToggleTypeUnit, onChangeToggleTypeValue, tableData } from "../../shared/function"

import item1 from '../../static/img/icons/info-item13.svg'
import item2 from '../../static/img/icons/info-item1.svg'
import item3 from '../../static/img/icons/info-item7.svg'
import item4 from '../../static/img/icons/info-item6.svg'
import item5 from '../../static/img/icons/info-item15.svg'
import item6 from '../../static/img/icons/info-item1.svg'
import item7 from '../../static/img/icons/info-item12.svg'
import item8 from '../../static/img/icons/info-item13.svg'
import bunyang_detail_atom from "../../atom/bunyang-detail"

interface upjongDataModel {
  code: string
  name?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Table = () => {
  
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { area_type } = useRecoilValue(bunyang_detail_atom)

  const toggle_type = area_type === 'PY' ? 'left' : 'right'

  const { detail } = data

  let unit_price = 0

  if(detail.area > 0){

    const py = detail.area / 3.3058
  
    unit_price = detail.price_main / py
    
  } 


  let table_data = [
    {
      icon : item1 ,
      name : '진행현황' ,
      text : <><strong>{tableData(detail.bunyang_status)}</strong></>
    },{
      icon : item2 ,
      name : '면적' ,
      text :  <><strong>{onChangeToggleTypeValue(toggle_type, detail.area) || '0'}</strong>{onChangeToggleTypeUnit(toggle_type)}</>
    },{
      icon : item3 ,
      name : '건폐율 / 용적률' ,
      text :  <>
                <strong>{detail.bc_rat ? Number(detail.bc_rat).toFixed(0) : '0'}</strong>%&nbsp;/&nbsp;
                <strong>{detail.vl_rat ? Number(detail.vl_rat).toFixed(0) : '0'}</strong>%
              </>
    }
  ]

  if (detail.price_per_area_yn === 'Y' || detail.price_per_area_yn === 'N') {
    table_data[3] = {
      icon: item4,
      name: '평당 분양가',
      //text : <><strong>{comma(unit_price.toFixed(0))}</strong>원</>,
      text: (
        <>
          <strong>{detail.price_bunyang_txt}</strong>
        </>
      ),
    }
  }

  if(detail.type === "KC"){

    table_data[1] = {
      icon : item2 ,
      name : '면적' ,
      text :  <div>
                <p className="item_data">토지 : <strong>{onChangeToggleTypeValue(toggle_type, detail.area) || '0'}</strong>{onChangeToggleTypeUnit(toggle_type)}</p>
                <p className="item_data">건물 : <strong>{onChangeToggleTypeValue(toggle_type, detail.bld_area) || '0'}</strong>{onChangeToggleTypeUnit(toggle_type)}</p>
              </div>
    }
    table_data[2] = {
      icon : item5 ,
      name : '형태' ,
      text : <>{tableData(detail.shape_type)}</>
    }
  }

  if(detail.type === "WH"){ //창고

    table_data[1] = {
      icon : item2 ,
      name : '면적' ,
      text :  <div>
                <p className="item_data">토지 : <strong>{onChangeToggleTypeValue(toggle_type, detail.area) || '0'}</strong>{onChangeToggleTypeUnit(toggle_type)}</p>
                <p className="item_data">건물 : <strong>{onChangeToggleTypeValue(toggle_type, detail.bld_area) || '0'}</strong>{onChangeToggleTypeUnit(toggle_type)}</p>
              </div>
    }
  }

  //23.08.30 #176. 업종정보 영역 삭제
  // let upjong_data: upjongDataModel[] = []

  // if(detail.type === 'IC' && lot){

  //   upjong_data = lot.reduce((arr:upjongDataModel[] ,curr: LotModel)=>{
  //     const { upjong_code, upjong_name } = curr
  //     if(
  //       (upjong_code === '' && (!upjong_name || upjong_name === '')) ||
  //       (arr.findIndex(uj=> uj.code === upjong_code && uj.name === upjong_name) > -1)
  //     ){
  //       return arr
  //     } else return [...arr, {code: upjong_code, name: upjong_name ?? '' }]

  //   },[])
  // }

  return (
    <>
    <ul className='detail_info_items'>
      {
        table_data.map((i , index) => {
          return (
            <li key={index}>
              <div className='item_title'>
                <img src={i.icon} alt='' />
                {i.name}
              </div>
              <div className='item_desc'>
                {i.text}
              </div>
            </li>
          )
        })
      }
    </ul>
    {/* 23.08.30 #176. 업종정보 영역 삭제 */}
    {/* {upjong_data.length > 0 &&
      <div className="upjong_wrapper">
        <h2>업종</h2>
        <div className="upjong_data">
          {upjong_data.map((j) => {
            const codeRegExp = /(?<code>[A-Z][0-9]{2})/g
            const upjongName = j.name?.replace(codeRegExp,'<strong>$<code></strong>') ?? ''

            return (
              <p>
                <i className='color_block' style={{backgroundColor: `${businessCodeInnerColor(j.code)}`, border: `1px solid ${businessCodeLineColor(j.code)}`}}></i>
                {j.code === 'X' || j.code === '' ? '' : <strong className="upjong_code">{j.code}</strong>}
                <span dangerouslySetInnerHTML={{ __html: upjongName }}></span>
              </p>
            )
          })}
        </div>
      </div>
    } */}
    </>
  )
}

export default Table