import { useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery, onChangeDateYmdType, onChangeToggleTypeUnit, onChangeToggleTypeValue, tableData } from '../../shared/function'
import item1 from '../../static/img/icons/info-item1.svg'
import item2 from '../../static/img/icons/info-item2.svg'
import item3 from '../../static/img/icons/info-item4.svg'
import item4 from '../../static/img/icons/info-item9.svg'
import { useEffect } from 'react'

interface Props {
  연면적: number
  신축년도: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Bottom = ({ 연면적, 신축년도 }: Props) => {
  
  const { area_type } = useRecoilValue(bunyang_detail_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail, location } = data

  const toggle_type = area_type === 'PY' ? 'left' : 'right'

  return (
    <ul className='detail_info_items'>
      <li>
        <div className='item_title'>
          <img src={item1} alt='' />총 토지 면적
        </div>
        <div className='item_desc'>
          <strong>
            {onChangeToggleTypeValue(toggle_type, detail.total_plat_area) ||
              '0'}
          </strong>
          {onChangeToggleTypeUnit(toggle_type)}
        </div>
      </li>
      {연면적 ? (
        <li>
          <div className='item_title'>
            <img src={item2} alt='' />총 연 면적
          </div>
          <div className='item_desc'>
            <strong>
              {/* {onChangeToggleTypeValue(toggle_type, detail.total_tot_area) || '0'} */}
              {onChangeToggleTypeValue(toggle_type, 연면적) || '0'}
            </strong>
            {onChangeToggleTypeUnit(toggle_type)}
          </div>
        </li>
      ) : (
        <></>
      )}
      {신축년도 ? (
        <li>
          <div className='item_title'>
            <img src={item3} alt='' />
            신축연도
          </div>
          <div className='item_desc'>
            {location.length > 1 ? (
              <div>
                {location.map((loc) => (
                  <p className='item_data'>{tableData(loc.regist_ymd)}</p>
                ))}
              </div>
            ) : (
              <>
                {/* {tableData(location[0].regist_ymd)} */}
                {onChangeDateYmdType(신축년도)}
                <strong></strong>
              </>
            )}
          </div>
        </li>
      ) : (
        <></>
      )}
      <li>
        <div className='item_title'>
          <img src={item4} alt='' />
          거래시점
        </div>
        <div className='item_desc'>
          {onChangeDateYmdType(detail.complete_ymd)}
        </div>
      </li>
    </ul>
  )
}

export default Bottom