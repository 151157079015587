import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"

import nodata_icon from '../../static/img/icons/map-side-nodata.svg'

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapSideNodata = () => {

  const media = useRecoilValue(media_atom)

  return (
    <div id="map-side-nodata">
      <img src={nodata_icon} alt="노데이터 아이콘" />
      <p>
        검색된 매물이 없습니다.
      </p>
    </div>
  )
}

export default MapSideNodata