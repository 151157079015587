import { Suspense } from "react"
import Middle from "./middle"
import MiddleSkeleton from "./middle-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const MiddleWrapper = () => {
  return (
    <Suspense fallback={<MiddleSkeleton />}>
      <Middle />
    </Suspense>
  )
}

export default MiddleWrapper