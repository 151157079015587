import React, { useEffect, useState } from 'react'
import API from '../../service/api'
import GetSaleDetailReportResponseModel, {
  GetSaleDetailReportResponseListModel,
} from '../../service/estate/model/get-sale-detail-report-response-model'
import { apiCallback, blankFn, click, getQuery } from '../../shared/function'
import '../../style/components/popup/declare-popup.scss'
import MainButton from '../button/main-button'
import Popup from './popup'
type PopupTypes = {
  title?: string
  sandan?: boolean
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
}
export default function DeclarePopup(props: PopupTypes) {
  const onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }
  const [reason, setReason] = useState<string>('') //신고 사유
  const [metadata_type_idx, setMetaDataTypeIdx] = useState<number>(0) //신고 유형 idx
  const estate_idx = props.sandan ? 0 : parseInt(getQuery().idx) // 매물 고유 번호
  const sandan_id = props.sandan ? getQuery().id : undefined // 산업단지 고유 번호
  const [report_list, setReportList] = useState<
    Array<GetSaleDetailReportResponseListModel>
  >([]) //매물 상세 신고 유형

  const getSaleDetailReport = () => {
    API.estate
      .getSaleDetailReportMetaData()
      .then((res) => {
        apiCallback(res, {
          success: getSaleDetailReportSuccessCallback,
          fail: blankFn,
        })
      })
      .catch(blankFn)
  }

  const getSaleDetailReportSuccessCallback = (
    res: GetSaleDetailReportResponseModel
  ) => {
    setReportList(res.report_result)
  }

  const onClickSubmit = async () => {
    if (!is_disabled) {
      const estateNo = isNaN(estate_idx) ? 0 : estate_idx

      await API.estate
        .saleDetailDeclare({
          estate_idx: estateNo,
          sandan_id,
          metadata_type_idx,
          reason,
        })
        .then((res) => {
          apiCallback(res, {
            success: SaleDetailDeclareSuccessCallback,
            fail: () => {
              alert('신고요청이 거절되었습니다.')
            },
          })
        })
        .catch((e) => {
          alert('신고요청이 거절되었습니다.')
        })
    }
  }

  const SaleDetailDeclareSuccessCallback = () => {
    click('.pop_close')
    alert('신고 완료 되었습니다.')
  }

  useEffect(() => {
    getSaleDetailReport()
    // eslint-disable-next-line
  }, [])

  if (report_list.length === 0) return <></>

  const is_disabled = reason.length === 0 || metadata_type_idx === 0

  return (
    <Popup title='신고하기' onClickClose={(e) => onClickClose(e)}>
      <div className='declare_popup'>
        <ul>
          <li>
            <h3>1.신고하시는 유형이 무엇인가요?</h3>
            <ul className='radio'>
              {report_list.map((item) => {
                return (
                  <li key={item.idx}>
                    <input
                      type='radio'
                      name={item.name}
                      id={`_type${item.idx}`}
                      checked={metadata_type_idx === item.idx}
                      onChange={() => setMetaDataTypeIdx(item.idx)}
                    />
                    <label htmlFor={`_type${item.idx}`}></label>
                    <label htmlFor={`_type${item.idx}`}>{item.name}</label>
                  </li>
                )
              })}
            </ul>
          </li>
          <li>
            <h3 className='q'>2.신고하시는 구체적 사유가 무엇인가요?</h3>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.currentTarget.value)}
              cols={30}
              rows={10}
              maxLength={500}
              placeholder='최대 500자 입력가능합니다.'
            ></textarea>
          </li>
        </ul>
        <div className='btn_wrap'>
          <MainButton
            title='신고하기'
            types={is_disabled ? 'inactive' : 'priority'}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Popup>
  )
}
