import MainButton from '../button/main-button'

import tel from '../../static/img/icons/tel_icon.svg'
import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { copyInClipBoard, getQuery } from '../../shared/function'
import EstateMap from '../detail/estate-map'

interface Props {
  name?: string
}


/* eslint-disable @typescript-eslint/no-unused-vars */
const Right = ({ name }: Props) => {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { broker, detail } = data

  const onClickHomepage = () => {
    window.open(broker.homepage)
  }

  return (
    <div className='right'>
      <div className='r_card bottom_card'>
        <h3>{detail.title}</h3>
        {broker.homepage && (
          <MainButton title='홈페이지' types='more' onClick={onClickHomepage} />
        )}
        {broker.tel && (
          <MainButton
            title={broker.tel}
            types='blue'
            front_icon={
              <>
                <img src={tel} alt='전화' />
              </>
            }
            onClick={() => copyInClipBoard(broker.tel)}
          />
        )}
      </div>

      <div className='rel'>
        <EstateMap lat={detail.lat} lng={detail.lng} id='__by_map__' name={name} />
      </div>
    </div>
  )
}

export default Right
