import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import DetailMobileTable from "./detail-mobile-table"
import DetailPcTable from "./detail-pc-table"

interface Props {
  type: 'explain' | 'explain2' | 'explain3'
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Detail = ({type}: Props) => {

  const media = useRecoilValue(media_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data

  let detailType: 'explain' | 'explain2'= 'explain'

  if(type === 'explain3') {
    detailType = 'explain'
  }else{
    detailType = type
  }

  if(!detail[detailType]) return <></>

  return (
    <article className="more_info">
      {/* 23.06 상세페이지 개선안_ 분양페이지 중단 상세정보영역 삭제 */}
      {/* <h2>상세정보</h2>
      {media === "P" ? (
        <DetailPcTable />
      ) : (
        <DetailMobileTable />
      )} */}
      <div className="desc_text ws-bs" style={{ marginTop : media === "P" ? 40 : 25 }} dangerouslySetInnerHTML={{ __html : detail[detailType] }} />
    </article>
  )
}

export default Detail