import AreaTable from './area-table'
import AreaTypeToggle from './area-type-toggle'
import BuildingTable from './building-table'
import DescMiddle from './desc-middle'
import DescTop from './desc-top'
import FloorTable from './floor-table'
import HistoryTable from './history-table'
import News from './news'
import Top from './top'
import TrendChart from './trend-chart'

const PNU = {
  Top,
  AreaTypeToggle,
  DescTop,
  DescMiddle,
  News,
  HistoryTable,
  TrendChart,
  FloorTable,
  AreaTable,
  BuildingTable,
}

export default PNU
