import React from "react"
import { blankFn, getLocationBetween0And100 } from "../../shared/function"
import "../../style/components/input/filter-range.scss"

export interface DevFilterRangeOnChangeEvent {
  min : number
  max : number
}

export type DevFilterRangeOnChangeEventHandler = (e : DevFilterRangeOnChangeEvent) => void

interface Props {
  title: string
  min : number
  max : number
  min_text: string
  max_text: string
  min_value : number
  max_value : number
  onChange : DevFilterRangeOnChangeEventHandler
  formatter : (e : number) => string
}

const DevFilterRange = (props: Props) => {

  const { max_value , min_value } = props

  const onChangeRangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value < max_value - 5) {
      props.onChange({
        max : max_value ,
        min : +e.target.value
      })
    }
  }
  const onChangeRangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value - 5 > min_value) {
      props.onChange({
        min : min_value ,
        max : +e.target.value
      })
    }
  }

  const barRenderer = () => {
    const left = getLocationBetween0And100( { min : props.min , max : props.max , value : min_value } )
    const width = getLocationBetween0And100( { min : props.min , max : props.max , value : max_value } )

    return (
      <div className="slide_track">
        <div className="track_wrap">
          <div
            className="value_track"
            style={{ left: `${left}%`, right: `${100 - width}%` , width : 'unset' }}
          ></div>
        </div>
      </div>
    )
  }

  return (
    <div className="filter_range">
      <h3>{props.title}</h3>
      <div className="filter_range_value">
        <input type="text" placeholder={`${props.min_text}`} onChange={blankFn} value={props.formatter(props.min_value)} />
        <span>~</span>
        <input type="text" placeholder={`${props.max_text}`} onChange={blankFn} value={props.formatter(props.max_value)} />
      </div>
      <div className="filter_range_input">
        {barRenderer()}
        <input
          type="range"
          min={props.min}
          max={props.max}
          value={min_value}
          step={1}
          onChange={onChangeRangeMin}
        />
        <input
          type="range"
          min={props.min}
          max={props.max}
          value={max_value}
          step={1}
          onChange={onChangeRangeMax}
        />
      </div>
      <div className="min_max">
        <span className="min">{props.min_text}</span>
        <span className="max">{props.max_text}</span>
      </div>
    </div>
  )
}

export default DevFilterRange