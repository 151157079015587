import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_filter_options_atom from '../../atom/map-filter-options'
import { bigNumber } from '../../shared/function'
import MainButton from '../button/main-button'
import DevFilterRange, {
  DevFilterRangeOnChangeEventHandler,
} from '../input/dev-filter-range'

/* eslint-disable @typescript-eslint/no-unused-vars */
const PricePop = () => {
  const [filter, setFilter] = useRecoilState(map_filter_options_atom)
  const { price_pop } = filter
  // const [ filter , setFilter ] = useState(false)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const [price_once_max, setPriceOnceMax] = useState(map_filter.price_once_max)
  const [price_once_min, setPriceOnceMin] = useState(map_filter.price_once_min)
  const [price_total_max, setPriceTotalMax] = useState(
    map_filter.price_total_max
  )
  const [price_total_min, setPriceTotalMin] = useState(
    map_filter.price_total_min
  )

  const [area_type, setAreaType] = useState(map_filter.area_type)

  const onChangeTotal: DevFilterRangeOnChangeEventHandler = (e) => {
    setPriceTotalMax(e.max)
    setPriceTotalMin(e.min)
  }

  const onChangeOnce: DevFilterRangeOnChangeEventHandler = (e) => {
    setPriceOnceMax(e.max)
    setPriceOnceMin(e.min)
  }

  const onClickFilter = () => {
    setFilter({
      price_pop: price_pop === true ? false : true,
      area_pop: false,
      deal_type_pop: false,
      complete_after_pop: false,
      filter_all_pop: false,
    })
  }

  const onClickApply = () => {
    setMapFilter({
      ...map_filter,
      price_total_max,
      price_total_min,
      price_once_max,
      price_once_min,
      area_type,
      price_area_type: area_type,
    })
    setFilter({
      ...filter,
      price_pop: false,
    })
  }

  const onChangeAreaType = () => {
    setAreaType(area_type === 'PY' ? 'SQ' : 'PY')
  }

  const onClickReset = () => {
    setPriceTotalMax(100)
    setPriceTotalMin(0)
    setPriceOnceMax(100)
    setPriceOnceMin(0)
    setAreaType('PY')
  }

  useEffect(() => {
    setPriceTotalMax(map_filter.price_total_max)
    setPriceTotalMin(map_filter.price_total_min)
    setPriceOnceMax(map_filter.price_once_max)
    setPriceOnceMin(map_filter.price_once_min)
    setAreaType(map_filter.area_type)
  }, [price_pop, map_filter])

  const area_type_kor = area_type === 'PY' ? '평' : '㎡'

  return (
    <div className={`filter ${price_pop ? 'on' : ''}`}>
      <div className='filter_btn' onClick={() => onClickFilter()}>
        <p>가격대</p>
        <span className='arrow'></span>
      </div>
      {price_pop && (
        <div className='filter_detail_wrap'>
          <section>
            <article>
              <button className='btn_area' onClick={onChangeAreaType}>
                {area_type_kor}
              </button>
              <DevFilterRange
                title='거래 총액'
                min_text='0'
                max_text='999억+'
                min={0}
                max={999}
                min_value={price_total_min}
                max_value={price_total_max}
                formatter={(e) => e + '억'}
                onChange={onChangeTotal}
              />
            </article>
            <article>
              <DevFilterRange
                title='거래 단가'
                min_text='0'
                max_text='1억+'
                min={0}
                max={100}
                min_value={price_once_min}
                max_value={price_once_max}
                formatter={(e) => bigNumber(e * 1_000_000)}
                onChange={onChangeOnce}
              />
            </article>
          </section>
          <div className='btn_wrap'>
            <button
              type='button'
              className='reset_btn'
              onClick={onClickReset}
            />
            <MainButton
              title='필터 적용'
              size='small'
              types='priority'
              onClick={onClickApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PricePop
