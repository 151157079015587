import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PNU from '../../components/@pnu'
import Main from '../../components/layout/main'
import MobileGNB from '../../components/layout/mobile-gnb'
import OnlyMobile from '../../components/layout/only-mobile'
import OnlyPc from '../../components/layout/only-pc'
import useComponentDidMount from '../../hooks/use-component-did-mount'
import API from '../../service/api'
import { PATH } from '../../shared/constant'
import {
  apiCallback,
  blankFn,
  farmLandTypeCheck,
  getQuery,
  networkErrorAlert,
} from '../../shared/function'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'

import '../../style/pages/trade-price-detail.scss'
import { AreaType } from '../../atom/map-filter'
import LoanInfo from '../../components/detail/loan-info'
import ScrollDetailTab from '../../components/tab/scroll_detail_tab'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import ErrorBoundary from '../../components/layout/error-boundary'
import ErrorBoundaryFallbackKick from '../../components/layout/error-boundary-fallback-kick'
import Loading from '../loading'

function _() {
  const { push } = useHistory()

  const [j, setJ] = useState('')

  const media = useRecoilValue(media_atom)

  const [data, setData] = useState<GetDetailResponseModel | null>(null)

  const [area_type, setAreaType] = useState<AreaType>('PY')

  const [officialPrice, setOfficialPrice] = useState<number>(0)
  const [totalTotArea, setTotalTotArea] = useState<number>(0)
  const [jimok, setJimok] = useState<string>('')

  function guard() {
    //23.08.23 #89.한글파라미터부분 인코딩 된 형태로 저장
    let j = window.atob(getQuery().j)
    j = decodeURIComponent(j)

    if (j) setJ(j)
    else kick('비정상적인 접근입니다.')
  }

  function kick(msg: string) {
    alert(msg)
    push(PATH.MAIN)
  }

  function getDetail() {
    API.estate
      .getDetailForJibun(j)
      .then((res) => {
        apiCallback(res, {
          success: getDetailSuccessCallback,
          fail: blankFn,
        })
      })
      .catch((err) => {
        networkErrorAlert(err)
        push(PATH.MAIN)
      })
  }

  function getDetailSuccessCallback(res: GetDetailResponseModel) {
    setData(res)
  }

  useComponentDidMount(() => {
    guard()
  })

  useEffect(() => {
    if (j) {
      getDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [j])

  const [scrollY, setScrollY] = useState(0)

  const handleGnb = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleGnb)
    }

    watch()

    return () => {
      window.removeEventListener('scroll', handleGnb)
    }
  })

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallbackKick
          message='조회할 수 없는 정보입니다.'
          path={PATH.MAIN}
        />
      }
    >
      {data ? (
        <>
          <MobileGNB />
          <Main className='trade_price_detail detail_bottom_wrap'>
            <div className='container'>
              <OnlyMobile>
                <PNU.Top lat={data.detail.lat} lng={data.detail.lng} />
              </OnlyMobile>

              <div className='inner first'>
                <OnlyPc>
                  <PNU.Top lat={data.detail.lat} lng={data.detail.lng} />
                </OnlyPc>
                <div className='detail_desc'>
                  <div className='desc_text'>
                    <PNU.DescTop
                      detail={data.detail}
                      area_type={area_type}
                      setAreaType={setAreaType}
                    />
                    {/* 230915 고객사 요청으로 인해 주석처리 */}
                    {/* <PNU.DescMiddle
                      detail={data.detail}
                      area_type={area_type}
                      officialPrice={officialPrice}
                      totalTotArea={totalTotArea}
                    /> */}
                  </div>
                </div>
              </div>
              <ScrollDetailTab
                className={
                  media
                    ? scrollY >= 600
                      ? 'fixed'
                      : ''
                    : scrollY >= 1024
                    ? 'fixed'
                    : ''
                }
                data={[
                  { name: '토지 정보' },
                  // { name: '매물 시세' },
                  { name: '부동산 뉴스' },
                ]}
              />
              <div className='inner'>
                <div id='location--0' style={{ height: 0, width: '1px' }} />
                <div className='trade_price_info'>
                  <PNU.HistoryTable
                    history={data.history}
                    area_type={area_type}
                  />
                  {/* 토지정보 */}
                  <PNU.AreaTable
                    // detail={data.detail}
                    area_type={area_type}
                    jibun={j}
                    setOfficialPrice={setOfficialPrice}
                    setTotalTotArea={setTotalTotArea}
                    setJimok={setJimok}
                  />
                  {/* 건물정보 */}
                  <PNU.BuildingTable
                    // detail={data.detail}
                    area_type={area_type}
                    jibun={j}
                    type={'PNU'}
                  />
                  <PNU.FloorTable floor={data.floor} area_type={area_type} />
                  <PNU.TrendChart data={data} />
                </div>
                {/* #198. 일반 지번상세페이지에서 "대출정보", "취득세정보" 삭제 */}
                {/* <div id='location--1' style={{ height: 0, width: '1px' }} /> */}
                {/* 23.05.17 일반필지의 경우 매물 가격을 공시시가 x 면적으로 산정 */}
                {/* <LoanInfo price={data.detail.official_price * data.detail.total_plat_area} is_farmland={farmLandTypeCheck(data.detail.jimok)} /> */}
                <div id='location--1' style={{ height: 0, width: '1px' }} />
                <PNU.News detail={data.detail} />
              </div>
            </div>
          </Main>
        </>
      ) : (
        <Loading />
      )}
    </ErrorBoundary>
  )
}

export default _
