import { useRecoilValue, useRecoilState } from 'recoil'
import { AreaType } from '../../atom/map-filter'
import media_atom from '../../atom/media-atom'
import { GetDetailResponseDataModel } from '../../service/estate/model/get-detail-response-model'
import Colgroup from '../layout/colgroup'
import {
  onChangeDateYmdType,
  onChangeToggleTypeUnit,
  onChangeToggleTypeValue,
  tableData,
} from '../../shared/function'
import API from '../../service/api'
import { useEffect, useState } from 'react'
import Skeleton from '../skeleton'
import get_building_data_selector from '../../selector/get-building-data-selector'
import building_data_atom from '../../atom/building-data'

interface Props {
  // detail: GetDetailResponseDataModel
  area_type: AreaType
  jibun: string
  type: string
  pnu?: string
  set연면적?: React.Dispatch<React.SetStateAction<number>>
  set신축년도?: React.Dispatch<React.SetStateAction<string>>
}

interface bulidStatusModel {
  kind: string
  bld_nm: string
  bld_strct: string
  bld_roof: string
  floor: string
  purps: string
  tot_area: string
  floor_on: string
  floor_on_num: number
  floor_under: string
  floor_under_num: number
}

interface floorModel {
  floor_gb: string //구분
  floor_no: string //층별
  strc: string //구조
  purps: string //용도
  area: number //면적
}

interface pnuBuildingDataDataListModel {
  address: string
  atch_bld_area: number
  atch_bld_cnt: number
  bc_rat: number
  bld_cnt: number
  bld_nm: string
  bld_status: bulidStatusModel[]
  building_area: number
  dong_nm: string
  emgen_elvt_cnt: string
  etc_roof: string
  etc_strct: string
  floor: floorModel[]
  grnd_flr_cnt: string
  indr_auto: string
  indr_mech: string
  jibun: string
  jijigugb: string
  main_atch_gb_cd_nm: string
  main_purps: string
  main_strct: string
  oudr_auto: string
  oudr_mech: string
  plat_area: number
  plat_plc: string
  pms_day: string
  recap_yn: string
  regstr_kind: string
  ride_elvt_cnt: string
  roof_cd_nm: string
  stcns_day: string
  strct_cd_nm: string
  tot_area: number
  tot_ho_cnt: number
  ugrnd_flr_cnt: string
  use_apr_day: string
  vl_rat: number
  vl_rat_estm_tot_area: number
  wcl_capa: number
  wcl_mode: string
  atch_bld: string
  viol_bld_yn: string
  spcmt: string
}

function BuildingTable({ area_type, jibun, type, pnu, set신축년도, set연면적 }: Props) {
  const media = useRecoilValue(media_atom)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toggle_type = area_type === 'PY' ? 'left' : 'right'  

  const [isExist, setIsExist] = useState<0 | 1>(0);
  const [data, setData] = useState<
    Array<pnuBuildingDataDataListModel> | undefined
  >(undefined)
  const [buildingDetails, setBuildingDetails] = useState<
    pnuBuildingDataDataListModel | undefined
    >(undefined)
  const [buildingDetailsIdx, setBuildingDetailsIdx] = useState<
    number | undefined
  >(undefined)  
    

  function getArea(area: number) {
    return (area / (area_type === 'PY' ? 3.3058 : 1)).toFixed(2)
  }

  const onClickBld = (bldIdx: number) => {
    if (data && data.length > 0) {
      setBuildingDetails(data[bldIdx])  
    }
    setBuildingDetailsIdx(bldIdx)
  }  

  function getBuildingData() {
    API.estate
      .getPnuBuildingData(jibun, type, pnu)
      .then((res) => {
        setData(res.data.data.list)
        setIsExist(res.data.data.is_exist)
        setIsLoading(true)
        if (set연면적 && res.data.data.list.length > 0) {
          set연면적(res.data.data.list[0].tot_area)
        }
        if (set신축년도 && res.data.data.list.length > 0) {
          set신축년도(res.data.data.list[0].open_y)
        }        
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (jibun) {
      getBuildingData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jibun])

  return (
    <>
      {isLoading ? (
        isExist ? (
          <article className='building_info'>
            <h2>
              건물정보
              <p className='building_info_desc'>
                아래의 목록에서 건축물을 선택하시면 상세정보를 열람하실 수
                있습니다.
              </p>
            </h2>
            {media === 'M' && 'T' ? (
              <>
                <div className='building_info_table'>
                  <div>
                    <table className='building_list'>
                      <Colgroup count={4} />
                      <thead>
                        <tr>
                          <th>대장종류</th>
                          <th>건축물명칭</th>
                          <th>동명칭 및 번호</th>
                          <th>연면적</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length ? (
                          data.map(
                            (
                              bld: pnuBuildingDataDataListModel,
                              bld_index: number
                            ) => {
                              return (
                                <tr
                                  key={`building_${bld.bld_nm}_${bld_index}`}
                                  onClick={() => onClickBld(bld_index)}
                                  className={`${
                                    buildingDetailsIdx === bld_index
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <td>{tableData(bld.regstr_kind)}</td>
                                  <td>{tableData(bld.bld_nm)}</td>
                                  <td>{tableData(bld.dong_nm)}</td>
                                  <td>
                                    {bld.tot_area
                                      ? `${onChangeToggleTypeValue(
                                          toggle_type,
                                          Number(bld.tot_area)
                                        )}${onChangeToggleTypeUnit(
                                          toggle_type
                                        )}`
                                      : '-'}
                                  </td>
                                </tr>
                              )
                            }
                          )
                        ) : (
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* 건축물정보 */}
                {buildingDetails ? (
                  <>
                    <div>
                      {buildingDetails.recap_yn === 'Y' ? (
                        <h3>총괄표제부</h3>
                      ) : (
                        <h3>건축물정보</h3>
                      )}
                      <div className='dl_wrap'>
                        <dl>
                          <dt>대지위치</dt>
                          <dd>{tableData(buildingDetails.address)}</dd>
                        </dl>
                        <dl>
                          <dt>지번</dt>
                          <dd>{tableData(buildingDetails.jibun)}</dd>
                        </dl>
                        <dl>
                          <dt>대지면적</dt>
                          <dd>
                            {buildingDetails.plat_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.plat_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>연면적</dt>
                          <dd>
                            {buildingDetails.tot_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.tot_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>명칭 및 번호</dt>
                          <dd>{tableData(buildingDetails.bld_nm)}</dd>
                        </dl>
                        <dl>
                          <dt>건축면적</dt>
                          <dd>
                            {buildingDetails.building_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.building_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>용적률 산정용 연면적</dt>
                          <dd>
                            {buildingDetails.vl_rat_estm_tot_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.vl_rat_estm_tot_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>건축물수</dt>
                          <dd>{tableData(buildingDetails.bld_cnt)}</dd>
                        </dl>
                        <dl>
                          <dt>건폐율</dt>
                          <dd>
                            {buildingDetails.bc_rat
                              ? `${Number(buildingDetails.bc_rat).toFixed(1)}%`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>용적률</dt>
                          <dd>
                            {buildingDetails.vl_rat
                              ? `${Number(buildingDetails.vl_rat).toFixed(1)}%`
                              : '-'}
                          </dd>
                        </dl>
                        <dl>
                          <dt>총호수</dt>
                          <dd>{tableData(buildingDetails.tot_ho_cnt)}</dd>
                        </dl>
                        <dl>
                          <dt>주용도</dt>
                          <dd>{tableData(buildingDetails.main_purps)}</dd>
                        </dl>
                        <dl>
                          <dt>주구조</dt>
                          <dd>{tableData(buildingDetails.main_strct)}</dd>
                        </dl>
                        <dl>
                          <dt>부속건축물</dt>
                          <dd>{tableData(buildingDetails.atch_bld)}</dd>
                        </dl>
                        <dl>
                          <dt>허가일자</dt>
                          <dd>
                            {onChangeDateYmdType(buildingDetails.pms_day)}
                          </dd>
                        </dl>
                        <dl>
                          <dt>착공일자</dt>
                          <dd>
                            {onChangeDateYmdType(buildingDetails.stcns_day)}
                          </dd>
                        </dl>
                        <dl>
                          <dt>사용승인일자</dt>
                          <dd>
                            {onChangeDateYmdType(buildingDetails.use_apr_day)}
                          </dd>
                        </dl>
                        <dl>
                          <dt>위반건축물 여부</dt>
                          <dd>{tableData(buildingDetails.viol_bld_yn)}</dd>
                        </dl>
                        <dl>
                          <dt>특이사항</dt>
                          <dd>{tableData(buildingDetails.spcmt)}</dd>
                        </dl>
                      </div>
                    </div>
                    {buildingDetails.recap_yn === 'Y' ? (
                      <div>
                        <h3>건축물현황</h3>
                        {buildingDetails.bld_status?.length ? (
                          buildingDetails.bld_status.map((bs, index) => {
                            let floorName = bs.floor_under + ' / ' + bs.floor_on
                            return (
                              <div
                                key={`building_details_${bs.bld_nm}_${index}`}
                                className='dl_wrap floor_info'
                              >
                                <dl>
                                  <dt>구분</dt>
                                  <dd>{tableData(bs.kind)}</dd>
                                </dl>
                                <dl>
                                  <dt>건축물명칭</dt>
                                  <dd>{tableData(bs.bld_nm)}</dd>
                                </dl>
                                <dl>
                                  <dt>건축물구조</dt>
                                  <dd>{tableData(bs.bld_strct)}</dd>
                                </dl>
                                <dl>
                                  <dt>건축물지붕</dt>
                                  <dd>{tableData(bs.bld_roof)}</dd>
                                </dl>
                                <dl>
                                  <dt>층수</dt>
                                  <dd>{tableData(floorName)}</dd>
                                </dl>
                                <dl>
                                  <dt>용도</dt>
                                  <dd>{tableData(bs.purps)}</dd>
                                </dl>
                                <dl>
                                  <dt>연면적</dt>
                                  <dd>
                                    {bs.tot_area
                                      ? `${onChangeToggleTypeValue(
                                          toggle_type,
                                          Number(bs.tot_area)
                                        )}${onChangeToggleTypeUnit(
                                          toggle_type
                                        )}`
                                      : '-'}
                                  </dd>
                                </dl>
                              </div>
                            )
                          })
                        ) : (
                          <div className='dl_wrap floor_info'>
                            <dl>
                              <dt>구분</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>건축물명칭</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>건축물구조</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>건축물지붕</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>층수</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>용도</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>연면적</dt>
                              <dd>-</dd>
                            </dl>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <h3>층별현황</h3>
                        {buildingDetails.floor?.length ? (
                          buildingDetails.floor.map((f, index) => {
                            return (
                              <div
                                key={`floor_info_${index}`}
                                className='dl_wrap floor_info'
                              >
                                <dl>
                                  <dt>구분</dt>
                                  <dd>{tableData(f.floor_gb)}</dd>
                                </dl>
                                <dl>
                                  <dt>층별</dt>
                                  <dd>{tableData(f.floor_no)}</dd>
                                </dl>
                                <dl>
                                  <dt>구조</dt>
                                  <dd>{tableData(f.strc)}</dd>
                                </dl>
                                <dl>
                                  <dt>용도</dt>
                                  <dd>{tableData(f.purps)}</dd>
                                </dl>
                                <dl>
                                  <dt>면적</dt>
                                  <dd>
                                    {f.area
                                      ? `${onChangeToggleTypeValue(
                                          toggle_type,
                                          Number(f.area)
                                        )}${onChangeToggleTypeUnit(
                                          toggle_type
                                        )}`
                                      : '-'}
                                  </dd>
                                </dl>
                              </div>
                            )
                          })
                        ) : (
                          <div className='dl_wrap floor_info'>
                            <dl>
                              <dt>구분</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>층별</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>구조</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>용도</dt>
                              <dd>-</dd>
                            </dl>
                            <dl>
                              <dt>면적</dt>
                              <dd>-</dd>
                            </dl>
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      <h3>주차장</h3>
                      <div className='dl_wrap'>
                        <dl>
                          <dt>옥내기계식</dt>
                          <dd>{tableData(buildingDetails.indr_mech)}</dd>
                        </dl>
                        <dl>
                          <dt>옥내자주식</dt>
                          <dd>{tableData(buildingDetails.oudr_mech)}</dd>
                        </dl>
                        <dl>
                          <dt>옥외기계식</dt>
                          <dd>{tableData(buildingDetails.indr_auto)}</dd>
                        </dl>
                        <dl>
                          <dt>옥외자주식</dt>
                          <dd>{tableData(buildingDetails.oudr_auto)}</dd>
                        </dl>
                      </div>
                    </div>
                    {buildingDetails.recap_yn === 'Y' ? (
                      <div>
                        <h3>오수정화시설</h3>
                        <div className='dl_wrap'>
                          <dl>
                            <dt>형식</dt>
                            <dd>{tableData(buildingDetails.wcl_mode)}</dd>
                          </dl>
                          <dl>
                            <dt>용량</dt>
                            <dd>{tableData(buildingDetails.wcl_capa)}</dd>
                          </dl>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3>승강기</h3>
                        <div className='dl_wrap'>
                          <dl>
                            <dt>승용</dt>
                            <dd>{buildingDetails.ride_elvt_cnt ?? 0}대</dd>
                          </dl>
                          <dl>
                            <dt>비상용</dt>
                            <dd>{buildingDetails.emgen_elvt_cnt ?? 0}대</dd>
                          </dl>
                        </div>
                      </div>
                    )}
                    <p className='building_info_desc warning'>
                      * 본 건축물정보는 증명서로서의 효력이 없습니다.
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className='building_info_table'>
                  <div>
                    <table className='building_list'>
                      <Colgroup count={6} />
                      <thead>
                        <tr>
                          <th>대장종류</th>
                          <th>대지위치</th>
                          <th>건축물명칭</th>
                          <th>동명칭 및 번호</th>
                          <th>주용도</th>
                          <th>연면적</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length ? (
                          data.map(
                            (
                              bld: pnuBuildingDataDataListModel,
                              bld_index: number
                            ) => {
                              return (
                                <tr
                                  key={`building_${bld.bld_nm}_${bld_index}`}
                                  onClick={() => onClickBld(bld_index)}
                                  className={`${
                                    buildingDetailsIdx === bld_index
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <td>{tableData(bld.regstr_kind)}</td>
                                  <td>{tableData(bld.address)}</td>
                                  <td>{tableData(bld.bld_nm)}</td>
                                  <td>{tableData(bld.dong_nm)}</td>
                                  <td>{tableData(bld.main_purps)}</td>
                                  <td>
                                    {bld.tot_area
                                      ? `${onChangeToggleTypeValue(
                                          toggle_type,
                                          Number(bld.tot_area)
                                        )}${onChangeToggleTypeUnit(
                                          toggle_type
                                        )}`
                                      : '-'}
                                  </td>
                                </tr>
                              )
                            }
                          )
                        ) : (
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {buildingDetails ? (
                  <>
                    <div>
                      {buildingDetails.recap_yn === 'Y' ? (
                        <h3>총괄표제부</h3>
                      ) : (
                        <h3>건축물정보</h3>
                      )}
                      <table className='vertical_table'>
                        <Colgroup count={6} />
                        <tr>
                          <th>대지위치</th>
                          <td colSpan={3}>
                            {tableData(buildingDetails.address)}
                          </td>
                          <th>지번</th>
                          <td>{tableData(buildingDetails.jibun)}</td>
                        </tr>
                        <tr>
                          <th>대지면적</th>
                          <td>
                            {buildingDetails.plat_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.plat_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </td>
                          <th>연면적</th>
                          <td>
                            {buildingDetails.tot_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.tot_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </td>
                          <th>명칭 및 번호</th>
                          <td>{tableData(buildingDetails.bld_nm)}</td>
                        </tr>
                        <tr>
                          <th>건축면적</th>
                          <td>
                            {buildingDetails.building_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.building_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </td>
                          <th>용적률 산정용 연면적</th>
                          <td>
                            {buildingDetails.vl_rat_estm_tot_area
                              ? `${onChangeToggleTypeValue(
                                  toggle_type,
                                  Number(buildingDetails.vl_rat_estm_tot_area)
                                )}${onChangeToggleTypeUnit(toggle_type)}`
                              : '-'}
                          </td>
                          <th>건축물수</th>
                          <td>{tableData(buildingDetails.bld_cnt)}</td>
                        </tr>
                        <tr>
                          <th>건폐율</th>
                          <td>
                            {buildingDetails.bc_rat
                              ? `${Number(buildingDetails.bc_rat).toFixed(1)}%`
                              : '-'}
                          </td>
                          <th>용적률</th>
                          <td>
                            {buildingDetails.vl_rat
                              ? `${Number(buildingDetails.vl_rat).toFixed(1)}%`
                              : '-'}
                          </td>
                          <th>총호수</th>
                          <td>{tableData(buildingDetails.tot_ho_cnt)}</td>
                        </tr>
                        <tr>
                          <th>주용도</th>
                          <td>{tableData(buildingDetails.main_purps)}</td>
                          <th>주구조</th>
                          <td>{tableData(buildingDetails.main_strct)}</td>
                          <th>부속건축물</th>
                          <td>{tableData(buildingDetails.atch_bld)}</td>
                        </tr>
                        <tr>
                          <th>허가일자</th>
                          <td>
                            {onChangeDateYmdType(buildingDetails.pms_day)}
                          </td>
                          <th>착공일자</th>
                          <td>
                            {onChangeDateYmdType(buildingDetails.stcns_day)}
                          </td>
                          <th>사용승인일자</th>
                          <td>
                            {onChangeDateYmdType(buildingDetails.use_apr_day)}
                          </td>
                        </tr>
                        <tr>
                          <th>위반건축물 여부</th>
                          <td>{tableData(buildingDetails.viol_bld_yn)}</td>
                          <th>특이사항</th>
                          <td colSpan={3}>
                            {tableData(buildingDetails.spcmt)}
                          </td>
                        </tr>
                      </table>
                    </div>
      
                    {buildingDetails.recap_yn === 'Y' ? (
                      <div>
                        <h3>건축물현황</h3>
                        <table>
                          <Colgroup count={7} />
                          <thead>
                            <tr>
                              <th>구분</th>
                              <th>건축물명칭</th>
                              <th>건축물구조</th>
                              <th>건축물지붕</th>
                              <th>층수</th>
                              <th>용도</th>
                              <th>연면적</th>
                            </tr>
                          </thead>
                          <tbody>
                            {buildingDetails.bld_status?.length ? (
                              buildingDetails.bld_status.map((bs, index) => {
                                let floorName =
                                  bs.floor_under + ' / ' + bs.floor_on
                                return (
                                  <tr
                                    key={`building_details_${bs.bld_nm}_${index}`}
                                  >
                                    <td>{tableData(bs.kind)}</td>
                                    <td>{tableData(bs.bld_nm)}</td>
                                    <td>{tableData(bs.bld_strct)}</td>
                                    <td>{tableData(bs.bld_roof)}</td>
                                    <td>{tableData(floorName)}</td>
                                    <td>{tableData(bs.purps)}</td>
                                    <td>
                                      {bs.tot_area
                                        ? `${onChangeToggleTypeValue(
                                            toggle_type,
                                            Number(bs.tot_area)
                                          )}${onChangeToggleTypeUnit(
                                            toggle_type
                                          )}`
                                        : '-'}
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <h3>층별현황</h3>
                        <table>
                          <Colgroup count={5} />
                          <thead>
                            <tr>
                              <th>구분</th>
                              <th>층별</th>
                              <th>구조</th>
                              <th>용도</th>
                              <th>면적</th>
                            </tr>
                          </thead>
                          <tbody>
                            {buildingDetails.floor?.length ? (
                              buildingDetails.floor.map((f, index) => {
                                return (
                                  <tr key={`floor_info_${index}`}>
                                    <td>{tableData(f.floor_gb)}</td>
                                    <td>{tableData(f.floor_no)}</td>
                                    <td>{tableData(f.strc)}</td>
                                    <td>{tableData(f.purps)}</td>
                                    <td>
                                      {f.area
                                        ? `${onChangeToggleTypeValue(
                                            toggle_type,
                                            Number(f.area)
                                          )}${onChangeToggleTypeUnit(
                                            toggle_type
                                          )}`
                                        : '-'}
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div>
                      <h3>주차장</h3>
                      <table>
                        <Colgroup count={4} />
                        <thead>
                          <tr>
                            <th>옥내기계식</th>
                            <th>옥내자주식</th>
                            <th>옥외기계식</th>
                            <th>옥외자주식</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{tableData(buildingDetails.indr_mech)}</td>
                            <td>{tableData(buildingDetails.oudr_mech)}</td>
                            <td>{tableData(buildingDetails.indr_auto)}</td>
                            <td>{tableData(buildingDetails.oudr_auto)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {buildingDetails.recap_yn === 'Y' ? (
                      <div>
                        <h3>오수정화시설</h3>
                        <table>
                          <Colgroup count={2} />
                          <thead>
                            <tr>
                              <th>형식</th>
                              <th>용량</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{tableData(buildingDetails.wcl_mode)}</td>
                              <td>{tableData(buildingDetails.wcl_capa)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <h3>승강기</h3>
                        <table>
                          <Colgroup count={2} />
                          <thead>
                            <tr>
                              <th>승용</th>
                              <th>비상용</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {tableData(buildingDetails.ride_elvt_cnt)}대
                              </td>
                              <td>
                                {tableData(buildingDetails.emgen_elvt_cnt)}대
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    <p className='building_info_desc warning'>
                      * 본 건축물정보는 증명서로서의 효력이 없습니다.
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </article>
        ) : (
          <></>
        )
      ) : (
        <>
          <article>
            <Skeleton width={95.16} height={25} marginBottom={16} radius={4} />
            <Skeleton width={'100%'} height={150} />
          </article>
        </>
      )}
    </>
  )
}

export default BuildingTable
