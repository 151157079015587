import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const NearbySkeleton = () => {

  const media = useRecoilValue(media_atom)

  const icon_size = media === "M" ? 64 : 74

  return (
    <article>
      <Skeleton width={65} height={25} marginBottom={16} radius={4} />
      <ul className="icon_holder">
        <li className="f-c f-a-c">
          <Skeleton width={icon_size} height={icon_size} radius='50%' marginBottom={4} />
          <Skeleton width={50} height={19} radius={4} />
        </li>
        <li className="f-c f-a-c">
          <Skeleton width={icon_size} height={icon_size} radius='50%' marginBottom={4} />
          <Skeleton width={55} height={19} radius={4} />
        </li>
        <li className="f-c f-a-c">
          <Skeleton width={icon_size} height={icon_size} radius='50%' marginBottom={4} />
          <Skeleton width={45} height={19} radius={4} />
        </li>
      </ul>
    </article>
  )
}

export default NearbySkeleton