import Skeleton from '../skeleton'

/* eslint-disable @typescript-eslint/no-unused-vars */
const PyeongSkeleton = () => {
  return (
    <article>
      <Skeleton width={65} height={25} marginBottom={16} radius={4} />
      <ul className='type_info' style={{ marginBottom: 20 }}>
        <li>
          <Skeleton width={70} height={36} radius={18} marginRight={8} />
        </li>
        <li>
          <Skeleton width={70} height={36} radius={18} marginRight={8} />
        </li>
        <li>
          <Skeleton width={70} height={36} radius={18} marginRight={8} />
        </li>
        <li>
          <Skeleton width={70} height={36} radius={18} />
        </li>
      </ul>
      <Skeleton width={'unset'} height={500} className='mt-20px mw-800px' />
    </article>
  )
}

export default PyeongSkeleton
