import DetailWrapper from "./detail-wrapper"
import MapWrapper from "./map-center-wrapper"
import SandanMobileGnbWrapper from './sandan-mobile-gnb-wrapper'
import LotWrapper from './lot-wrapper'
import TableWrapper from "./table-wrapper"
import TopWrapper from "./top-wrapper"
import NewsWrapper from "./news-wrapper"
import PriceInfoWrapper from "./price-info"

const Sandan = {
  LotWrapper,
  DetailWrapper,
  SandanMobileGnbWrapper,
  TopWrapper,
  TableWrapper,
  MapWrapper,
  NewsWrapper,
  PriceInfoWrapper,
}

export default Sandan