import account from './account/account'
import board from './board/board'
import search from './search/search'
import kakao from './kakao/kakao'
import estate from './estate/estate'
import banner from './banner/banner'
import bunyang from './bunyang/bunyang'
import sandan from './sandan/sandan'
import mypage from './mypage/mypage'
import naver from './naver/naver'
import theme from './theme/theme'
import main from './main/main'

const API = {
  account,
  board,
  search,
  kakao,
  estate,
  banner,
  bunyang,
  sandan,
  mypage,
  naver,
  theme,
  main
}

export default API
