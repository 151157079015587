import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import media_atom from '../../atom/media-atom'
import site_map_types from '../../atom/site-map-types'

import { ElasticType } from '../../shared/types'

/* eslint-disable @typescript-eslint/no-unused-vars */
const PcTypeArea = () => {
  const media = useRecoilValue(media_atom)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const [site_map_type, setSiteMapType] = useRecoilState(site_map_types)

  const { deal_kind } = map_filter

  const { type } = map_filter

  const checkType = (e: ElasticType) => {
    const type_Y_count = Object.values(type).filter(
      (item) => item === 'Y'
    ).length

    if (type_Y_count === 1 && type[e] === 'Y') {
      return alert('최소한 하나의 유형은 선택해야합니다.')
    } else {
      setMapFilter({
        ...map_filter,
        type: {
          ...type,
          [e]: type[e] === 'Y' ? 'N' : 'Y',
        },
      })
    }
  }

  const onChangeType = (e: ElasticType) => {
    checkType(e)
  }

  useEffect(() => {
    if (site_map_type !== '') {
      onChangeType(site_map_type)
    }
    setSiteMapType('')
    // eslint-disable-next-line
  }, [site_map_type])

  if (media === 'M') return <></>

  return (
    <div className='types_item'>
      <ul>
        <li className={`industry ${deal_kind.tr === 'Y' ? 'h' : ''}`}>
          <input
            type='checkbox'
            id='_industry'
            name='types'
            checked={type.IC === 'Y'}
            onChange={() => onChangeType('IC')}
          />
          <label htmlFor='_industry'>산업단지</label>
        </li>
        <li className={`logistics ${deal_kind.tr === 'Y' ? 'h' : ''}`}>
          <input
            type='checkbox'
            id='_logistics'
            name='types'
            checked={type.LC === 'Y'}
            onChange={() => onChangeType('LC')}
          />
          <label htmlFor='_logistics'>물류단지</label>
        </li>
        <li className='factory'>
          <input
            type='checkbox'
            id='_factory'
            name='types'
            checked={type.FT === 'Y'}
            onChange={() => onChangeType('FT')}
          />
          <label htmlFor='_factory'>공장</label>
        </li>
        <li className='store'>
          <input
            type='checkbox'
            id='_store'
            name='types'
            checked={type.WH === 'Y'}
            onChange={() => onChangeType('WH')}
          />
          <label htmlFor='_store'>창고</label>
        </li>
        <li className='ground'>
          <input
            type='checkbox'
            id='_ground'
            name='types'
            checked={type.LD === 'Y'}
            onChange={() => onChangeType('LD')}
          />
          <label htmlFor='_ground'>토지</label>
        </li>
        <li className='center'>
          <input
            type='checkbox'
            id='_center'
            name='types'
            checked={type.KC === 'Y'}
            onChange={() => onChangeType('KC')}
          />
          <label htmlFor='_center'>지식산업센터</label>
        </li>
      </ul>
    </div>
  )
}

export default PcTypeArea
