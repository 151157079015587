import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const DetailSkeleton = () => {

  const media = useRecoilValue(media_atom)

  return (
    <article className="more_info">
      <Skeleton width={61} height={25} marginBottom={16} radius={4} />
      <Skeleton width={'100%'} height={media === "P" ? 160 : 434} marginBottom={media === "P" ? 40 : 25} />
      <div>
        <Skeleton width={'70%'} height={20} marginBottom={8} radius={4} />
        <Skeleton width={'80%'} height={20} marginBottom={8} radius={4} />
        <Skeleton width={'65%'} height={20} marginBottom={8} radius={4} />
        <Skeleton width={'30%'} height={20} marginBottom={8} radius={4} />
        <Skeleton width={'50%'} height={20} marginBottom={8} radius={4} />
        <Skeleton width={'75%'} height={20} marginBottom={8} radius={4} />
      </div>
    </article>
  )
}

export default DetailSkeleton