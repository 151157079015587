import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import media_atom from "../../atom/media-atom"
import { getQuery } from "../../shared/function"
import API from "../../service/api"
import AddressToCoordResponseModel from "../../service/kakao/model/address-to-coord-response-model"
import search_atom from "../../atom/search-atom"
import { useHistory } from "react-router-dom"
import { PATH } from "../../shared/constant"
import map_filter_atom from "../../atom/map-filter"
import get_sandan_detail_selector from "../../selector/get-sandan-detail-selector"
import MobileTop from "./mobile-top"
import PcTop from "./pc-top"

/* eslint-disable @typescript-eslint/no-unused-vars */
const Top = () => {

  const media = useRecoilValue(media_atom)
  const history = useHistory()

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const setSearch = useSetRecoilState(search_atom)
  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)
  
  const onClickMapBtn = (e: React.MouseEvent<HTMLButtonElement>, address: string) => {
    e.preventDefault()
    API.kakao
      .addressToCoord(address)
      .then((res) => {
        addressToCoordSuccessCallback(res.data, address)
      })
      .catch(addressToCoordFailCallback)
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel, address: string) {
    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]
      setMapFilter({
        ...map_filter,
        deal_kind: {
          ...map_filter.deal_kind,
          tr: 'N',
          sub_division: 'Y',
        },
       })
      setSearch(address)
      history.push({
        pathname : `${PATH.MAP}`,
        hash : `LatLng=${y}^${x}`,
        state : 'address-from-detail-page'
      })
    } else {
      throw new Error()
    }
  }

  function addressToCoordFailCallback() {
    history.push({
      pathname : `${PATH.MAP}`,
      hash : `LatLng=${data.lat}^${data.lng}`,
      state : 'address-from-detail-page'
    })
  }

  return (
    <div>
      {media === 'M' ?(
        <div className="top">
          <div className='desc_address'>
            <span>{data.address}</span>
            <button className='map_button' onClick={(e) => onClickMapBtn(e, data.address)}>지도보기</button>
          </div>
          <div className="bunyang_title">{data.name ?? data.api_name}</div>
          
          <MobileTop />
        </div>
      ) : (
        <>
        <div className="bunyang_title">{data.name ?? data.api_name}</div>
        <div className="top">
          <div className='desc_address'>
            <span>{data.address}</span>
            <button className='map_button' onClick={(e) => onClickMapBtn(e, data.address)}>지도보기</button>
          </div>
          
          <PcTop />
        </div>
        </>
      )}
    </div>
  )
}

export default Top