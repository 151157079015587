import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_filter_is_filtered_seletor from '../../selector/map-filter-is-filtered-seletor'
import { PATH } from '../../shared/constant'
import { recordEnc } from '../../shared/passport'

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapRecorder = () => {
  const history = useHistory()

  const map_filter = useRecoilValue(map_filter_atom)

  const is_filtered = useRecoilValue(map_filter_is_filtered_seletor)

  const recording = () => {
    const encRecord = recordEnc(map_filter)

    history.replace(`${PATH.MAP}?${encRecord}`)
  }

  const erase = () => {
    history.replace(PATH.MAP)
  }

  useEffect(() => {
    if (is_filtered) recording()
    else erase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_filter])

  return <></>
}

export default MapRecorder
