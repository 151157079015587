import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import MainButton from '../button/main-button'

import tel from '../../static/img/icons/tel_icon.svg'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery, onClickTel } from '../../shared/function'


/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileBottomCard = () => {
  const media = useRecoilValue(media_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { broker, detail } = data

  const onClickHomepage = () => {
    window.open(broker.homepage)
  }

  if (media === 'P' || media === 'T') return <></>

  return (
    <div className='mobile_register_card'>
      <div className='r_card bottom_card'>
        <h3>{detail.title}</h3>
        {broker.homepage && (
          <div>
            <MainButton title='홈페이지' types='more' onClick={onClickHomepage} />
          </div>
        )}
        {broker.tel && (
          <div>
            <MainButton
              title={broker.tel}
              types='blue'
              onClick={() => onClickTel(parseInt(getQuery().idx), broker.tel)}
              front_icon={<img src={tel} alt='전화' />}
            />
          </div>
        )}
        <div className='reg_bottom'>
          {broker.homepage && (
            <MainButton
              title='홈페이지'
              types='more'
              fixed_secondly='fixed_top'
              onClick={onClickHomepage}
            />
          )}
          {broker.tel && (
            <MainButton
              title={broker.tel}
              types='blue'
              onClick={() => onClickTel(parseInt(getQuery().idx), broker.tel)}
              front_icon={<img src={tel} alt='전화' />}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileBottomCard
