import { useState } from "react"
import LayerMap from "../layout/layer-map"

interface Props {
  lat : number
  lng : number
  bottom_right?: boolean
  currentTypeId_ref: React.MutableRefObject<any>
  name?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LocationIcon = (props : Props) => {
  
  const [is_show , setIsShow] = useState(false)

  const onClickLocation = () => {
    setIsShow(true)
  }

  return (
    <>
      <div className={`location __dev__location__ ${props.bottom_right ? 'br' : ''}`}>
        <button onClick={onClickLocation} />
      </div>
      {
        is_show &&
        <LayerMap lat={props.lat} lng={props.lng} onClickClose={setIsShow} currentTypeId_ref={props.currentTypeId_ref} name={props.name ?? ''} />
      }
    </>
  )
}

export default LocationIcon