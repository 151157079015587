import alg from '../../static/img/icons/arrow-left-gray.svg'
import adg from '../../static/img/icons/arrow-down-gray.svg'
import '../../style/components/map/map-side-bar.scss'
import { Suspense, useState } from 'react'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import MapSideBarList from './map-side-bar-list'
import LoadingFallback from '../loading/loading-fallback'
import map_filter_atom from '../../atom/map-filter'

interface Props {
  map_loaded: boolean
  filtered: boolean
  fold: boolean
  onChangeFold: (isfold: boolean, isMobile: boolean) => void
}

export default function MapSideBar(props: Props) {
  const { fold, onChangeFold } = props

  const media = useRecoilValue(media_atom)

  const map_filter = useRecoilValue(map_filter_atom)

  const { sw_lat } = map_filter

  return (
    <div className={`map_side_bar ${fold ? 'hide' : ''}`}>
      {media === 'M' && 'T' ? (
        <>
          <div className='side_bar_head'>
            <h3 className='fs-r fw500'>매물 리스트</h3>
            <button
              className={` ${fold ? 'hide' : ''}`}
              onClick={() => onChangeFold(!fold, true)}
            >
              <img src={adg} alt='' />
            </button>
          </div>
        </>
      ) : (
        <button
          type='button'
          className={`fold_btn ${fold ? 'hide' : ''}`}
          onClick={() => onChangeFold(!fold, false)}
        >
          <img src={alg} alt='' />
        </button>
      )}
      {sw_lat !== 0 && props.map_loaded && props.filtered && (
        <Suspense fallback={media === 'P' ? <LoadingFallback /> : <></>}>
          <MapSideBarList fold={fold} />
        </Suspense>
      )}
    </div>
  )
}
