import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import LeftBookMarker from './left-book-marker'
import LeftCategoryIcon from './left-category-icon'
import LeftImageSlider from './left-image-slider'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Left = () => {

  return (
    <div className='left rel'>
      <LeftCategoryIcon />
      <LeftBookMarker />
      <LeftImageSlider />
    </div>
  )
}

export default Left