import Main from '../../components/layout/main'

import DeclarePopup from '../../components/popup/declare-popup'
import RegisterPopup from '../../components/popup/register-popup'
import SharePopup from '../../components/popup/share-popup'
import Bunyang from '../../components/bunyang'

import '../../style/pages/sale-detail/sale-detail-type.scss'
import '../../style/components/detail/type4.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useEffect } from 'react'
import ErrorBoundary from '../../components/layout/error-boundary'
import ErrorBoundaryFallbackKick from '../../components/layout/error-boundary-fallback-kick'
import { PATH } from '../../shared/constant'
import useScrollControl from '../../hooks/use-scroll-control'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'
import ES from '../../components/detail'
import Sandan from '../../components/@sandan'
import sandan_detail_atom from '../../atom/sandan-detail'
import get_sandan_detail_selector from '../../selector/get-sandan-detail-selector'

const SaleDetailTypeSandan = () => {
  /**
   * States
   */
  const [sandan_detail, setSandanDetail] = useRecoilState(sandan_detail_atom)

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))
  
  const { popup } = sandan_detail

  const setPopup = (e: number) => {
    setSandanDetail({
        ...sandan_detail,
      popup: e,
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useScrollControl()

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallbackKick
          message='조회할 수 없는 정보입니다.'
          path={PATH.MAIN}
        />
      }
    >
      <Sandan.SandanMobileGnbWrapper />
      <Main id='sale_detail'>
        <div className='container'>
          <div className='inner for_type2 pb-zero'>
            <div className='detail_card'>
                <Sandan.MapWrapper />
            </div>
            <div className='detail_desc'>
              <div className='desc_text'>
                <Sandan.TopWrapper />
                <Sandan.TableWrapper />
              </div>
            </div>
            <div id='type' className='detail_bottom_wrap type4'>
              <Sandan.DetailWrapper/>
              {/* <Sandan.LotWrapper/> */}
              <Sandan.PriceInfoWrapper/>
              <Sandan.NewsWrapper/>
            </div>
          </div>
        </div>
      </Main>
      {popup === 1 && <DeclarePopup sandan onClickClose={() => setPopup(0)} />}
      {popup === 3 && <SharePopup onClickClose={() => setPopup(0)} />}
    </ErrorBoundary>
  )
}

export default SaleDetailTypeSandan
