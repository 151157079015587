import Skeleton from "../../skeleton"


/* eslint-disable @typescript-eslint/no-unused-vars */
const RtChartSkeleton = () => {
  return (
    <article>
      <Skeleton width={100} height={25} marginBottom={16} radius={4} />
      <Skeleton width={'100%'} height={294} radius={4} />
    </article>
  )
}

export default RtChartSkeleton