import React from 'react'
import { useRecoilValue } from 'recoil'
import Map from '.'
import media_atom from '../../atom/media-atom'

import '../../style/components/map/map-top-bar.scss'

interface Props {
  onClickClose: React.MouseEventHandler<HTMLDivElement>
}

export default function MapTopBar(props: Props) {
  const media = useRecoilValue(media_atom)

  const onClickClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }

  return (
    <div className='map_top_bar'>
      <div className='items_wrap'>
        <div className='search_item'>
          {media !== 'M' && <Map.SearchArea />}

          <Map.DealTypeToggle />
        </div>
        <Map.PcTypeArea />

        <div className='filters_item'>
          <Map.PcFilterArea />

          {media === 'M' && <Map.CertifyButton />}
          <Map.FilterAll onClickClose={onClickClose} />
        </div>
      </div>

      {media !== 'M' && <Map.CertifyButton />}
    </div>
  )
}
