import { Suspense } from "react"
import Detail from "./detail"
import DetailSkeleton from "./detail-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const DetailWrapper = () => {
  return (
    <Suspense fallback={<DetailSkeleton />}>
      <Detail/>
    </Suspense>
  )
}

export default DetailWrapper