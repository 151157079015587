import React, { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import {
  businessCodeInnerColor,
  businessCodeLineColor,
  getQuery,
  isFloat,
} from '../../shared/function'

interface Props {
  map_ref: React.MutableRefObject<any>
  selected_lot: LotModel | null
  setSelectedLot: React.Dispatch<React.SetStateAction<LotModel | null>>
}

const util = {
  getPolygon: (path: Array<any>, index: number, type: string, color?: string) => {

    const line_color = businessCodeLineColor(type, color)

    const fill_color = businessCodeInnerColor(type, color)

    if (index === 0) {
      return new window.kakao.maps.Polygon({
        path,
        strokeWeight: 2,
        strokeColor: '#1A519F',
      })
    } else {
      return new window.kakao.maps.Polygon({
        path,
        strokeWeight: 1,
        strokeColor: line_color,
        fillColor: fill_color,
        fillOpacity: 0.4,
        strokeOpacity: 1,
      })
    }
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LotMap = (props: Props) => {
  const { map_ref } = props

  const bunyang_detail = useRecoilValue(bunyang_detail_atom)

  const { area_type } = bunyang_detail

  const last_select = useRef<number>(0)

  const polygon_overlays = useRef<Array<any>>([])

  const custom_overlays = useRef<Array<any>>([])

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail, lot } = data

  const mapDraw = () => {
    const container = document.getElementById('lot-map')

    const options = {
      center: new window.window.kakao.maps.LatLng(detail.lat, detail.lng),
      level: 5,
    }

    const map = new window.kakao.maps.Map(container, options)

    map.setMinLevel(1)
    map.setMaxLevel(12)

    map_ref.current = map

    // 4레벨 이하만 보여주도록 설정
    window.kakao.maps.event.addListener(
      map_ref.current,
      'zoom_changed',
      onResize
    )
  }

  function mapResizeEventRemove() {
    window.kakao.maps.event.removeListener(
      map_ref.current,
      'zoom_changed',
      onResize
    )
  }

  const onClickPolygon = (item: LotModel) => {
    if (new Date().valueOf() - last_select.current < 1000) return

    let index = 0

    for (const _lot of lot) {
      if (index !== 0) {
        if (
          _lot.title === item.title &&
          _lot.building_area === item.building_area
        ) {
          polygon_overlays.current[index].setOptions({
            strokeWeight: 1.5,
            strokeColor: '#333333',
            strokeStyle: 'longdash',
            fillOpacity: 1,
            zIndex: 3,
          })
        } else {
          polygon_overlays.current[index].setOptions({
            strokeWeight: 1,
            strokeColor: businessCodeLineColor(_lot.upjong_code, _lot.upjong_color_inner),
            strokeStyle: 'solid',
            fillOpacity: 0.4,
            zIndex: 1,
          })
        }
      }

      index += 1
    }

    last_select.current = new Date().valueOf()

    props.setSelectedLot(item)
  }

  const customOverlayDraw = (item: LotModel) => {
    const land_position = getCentroid(item.lot)

    const land_content = document.createElement('div')
    land_content.className = 'c-overlay'

    const land_title = document.createElement('div')
    land_title.className = 'text strong'
    const land_title_text = document.createTextNode(item.title)
    land_title.appendChild(land_title_text)

    const land_plat_area = document.createElement('div')
    land_plat_area.className = 'text'

    let area_size = area_type === 'SQ' ? item.plat_area : item.plat_area / 3.3058

    if (isFloat(area_size)) {
      area_size = Number(area_size.toFixed(1))
    }

    const suffix = area_type === 'PY' ? '평' : '㎡'

    const land_plat_area_text = document.createTextNode(`${area_size}${suffix}`)
    land_plat_area.appendChild(land_plat_area_text)

    land_content.appendChild(land_title)
    land_content.appendChild(land_plat_area)
    land_content.onclick = function () {
      onClickPolygon(item)
    }

    return new window.kakao.maps.CustomOverlay({
      position: new window.kakao.maps.LatLng(...land_position),
      content: land_content,
    })
  }

  const polygonDraw = () => {
    let index = 0

    console.log(lot)

    for (const item of lot) {
      const path = item.lot.map((i) => new window.kakao.maps.LatLng(...i))

      const polygon = util.getPolygon(path, index, item.upjong_code, item.upjong_color_inner)

      polygon.setMap(map_ref.current)

      polygon_overlays.current.push(polygon)

      window.kakao.maps.event.addListener(polygon, 'mousedown', function () {
        onClickPolygon(item)
      })

      const land_info = customOverlayDraw(item)

      index !== 0 && land_info.setMap(map_ref.current)

      custom_overlays.current.push(land_info)

      index += 1
    }

    //지도 초기 level 6으로 수정 => 초기 진입 시 overlay text 미노출
    document.querySelectorAll('.c-overlay').forEach((element) => {
      element.style.display = 'none'
    })

  }

  function onResize() {
    const level = map_ref.current.getLevel()

    document.querySelectorAll('.c-overlay').forEach((element) => {
      element.style.display = level > 4 ? 'none' : 'block'
    })
  }

  // 다각형의 무게 중심 좌표 구하기
  function getCentroid(points: number[][]): number[] {
    let area = 0
    let cx = 0
    let cy = 0

    for (let i = 0; i < points.length; i++) {
      let j = (i + 1) % points.length

      let pt1 = points[i]
      let pt2 = points[j]

      let x1 = pt1[0]
      let x2 = pt2[0]
      let y1 = pt1[1]
      let y2 = pt2[1]

      area += x1 * y2
      area -= y1 * x2

      cx += (x1 + x2) * (x1 * y2 - x2 * y1)
      cy += (y1 + y2) * (x1 * y2 - x2 * y1)
    }

    area /= 2
    area = Math.abs(area)

    cx = cx / (6.0 * area)
    cy = cy / (6.0 * area)

    return [Math.abs(cx), Math.abs(cy)]
  }

  const polygonRemove = () => {
    for (const overlay of polygon_overlays.current) {
      overlay.setMap(null)
    }
  }

  const customOverlayRemove = () => {
    for (const overlay of custom_overlays.current) {
      overlay.setMap(null)
    }
  }

  useEffect(() => {
    mapDraw()

    return () => {
      mapResizeEventRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    polygonDraw()

    return () => {
      polygonRemove()
      customOverlayRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area_type])

  return (
    <>
      <div id='lot-map' style={{ width: '100%', height: 500 }} />
      {props.selected_lot &&
        <div className='lot-map-detil'>
          <ul>
            <li>
              <span>필지명 : </span>{props.selected_lot.title}
            </li>
            <li>
              <span>면적 : </span>{props.selected_lot.plat_area.toLocaleString()}㎡
            </li>
            <li>
              <span>업종 : </span>
              {/* 230427 업종 코드 없을 경우('X') upjong_name 노출  */}
              {/* <i className='color_block' style={{backgroundColor: `${businessCodeInnerColor(props.selected_lot.upjong_code )}`, border: `1px solid ${businessCodeLineColor(props.selected_lot.upjong_code)}`}}></i> */}
              {props.selected_lot.upjong_code == 'X' ? props.selected_lot.upjong_name : props.selected_lot.upjong_code}
            </li>
          </ul>
        </div>
      }
    </>
  )
}

export default LotMap
