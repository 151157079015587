import { Suspense } from "react"
import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"
import Bottom from "./bottom"

interface Props {
  연면적: number
  신축년도: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const BottomWrapper = ({ 연면적, 신축년도 }: Props) => {
  const media = useRecoilValue(media_atom)

  return (
    <Suspense
      fallback={
        <Skeleton
          width='100%'
          height={media === 'P' ? 110 : 144}
          marginBottom={media === 'P' ? 40 : 20}
          marginTop={media === 'P' ? 40 : 20}
        />
      }
    >
      <Bottom 연면적={연면적} 신축년도={신축년도} />
    </Suspense>
  )
}

export default BottomWrapper