import { useEffect } from 'react'

const useComponentDidMount = (fn: Function) => {
  useEffect(() => {
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useComponentDidMount
