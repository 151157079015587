import { NavLink } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import accountManager from '../../shared/account-manager'
import { PATH } from '../../shared/constant'
import '../../style/components/layout/tab-bar.scss'
export default function TabBar() {
  const activeTabStyle = {
    color: '#007EFF',
  }

  const media = useRecoilValue(media_atom)

  return (
    <>
      {media === 'M' && (
        <div className='tab_bar_wrap'>
          <ul>
            <li>
              <NavLink
                to={PATH.MAIN}
                activeStyle={activeTabStyle}
                activeClassName='on'
                exact
              >
                <span className='tab1'></span>홈
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/map'
                activeStyle={activeTabStyle}
                activeClassName='on'
              >
                <span className='tab2'></span>
                지도
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/sub-division'
                activeStyle={activeTabStyle}
                activeClassName='on'
              >
                <span className='tab3'></span>
                분양
              </NavLink>
            </li>
            <li>
              <NavLink
                to={accountManager.isLogin() ? PATH.ESTATE : PATH.LOGIN}
                activeStyle={
                  accountManager.isLogin() ? activeTabStyle : undefined
                }
                activeClassName='on'
              >
                <span className='tab4'></span>
                매물등록
              </NavLink>
            </li>
            <li>
              <NavLink
                to={accountManager.isGuest() ? PATH.LOGIN : PATH.MYPAGE}
                activeStyle={activeTabStyle}
                activeClassName='on'
              >
                <span className='tab5'></span>
                {accountManager.isGuest() ? '로그인' : '마이페이지'}
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}
