import close from "../../static/img/icons/close-white.svg";
import "../../style/components/popup/popup.scss";
type PopupTypes = {
  title?: string;
  children: JSX.Element;
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Popup(props: PopupTypes) {
  const onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e);
    }
  };
  return (
    <div id="popup">
      <div className="dim"></div>
      <div className="pop_content">
        <button type="button" className="pop_close" onClick={onClickClose}>
          <img src={close} alt="close" />
        </button>
        <div className="pop_title">
          <h3>{props.title}</h3>
        </div>
        {props.children}
      </div>
    </div>
  );
}
