import { Suspense } from "react"
import MobileGNB from "../layout/mobile-gnb"
import BunyangMobileGnb from "./bunyang-mobile-gnb"

/* eslint-disable @typescript-eslint/no-unused-vars */
const BunyangMobileGnbWrapper = () => {
  return (
    <Suspense fallback={ <MobileGNB title="" /> }>
      <BunyangMobileGnb />
    </Suspense>
  )
}

export default BunyangMobileGnbWrapper