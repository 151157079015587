import { useEffect, useState } from 'react'
import { AreaType } from '../../atom/map-filter'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import { bigNumber } from '../../shared/function'

interface Props {
  area_type: AreaType
  detail: GetDetailResponseModel['detail']
  officialPrice: number
  totalTotArea: number
}

function DescMiddle({ area_type, detail, officialPrice, totalTotArea }: Props) {
  const area_type_text = area_type === 'PY' ? '평' : '㎡'

  let total_area = totalTotArea ? totalTotArea : totalTotArea

  let unit_price = 0

  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (total_area > 0) {
    const py = total_area / 3.3058

    const sq = total_area

    unit_price = officialPrice / (area_type === 'PY' ? py : sq)
  }
  
  useEffect(() => {
    if (officialPrice) {
      let py = total_area / 3.3058

      let sq = total_area      
      setIsLoading(true)
      unit_price = officialPrice / (area_type === 'PY' ? py : sq)
    }
  }, [officialPrice])

  return (
    <>
      {isLoading ?
      <div className='middle'>
        <div>
          <h2 className='c-price fw600'>{bigNumber(officialPrice)}원</h2>
          <p>
            ({bigNumber(unit_price)}원 /{area_type_text})
          </p>
        </div>
      </div> : <></>
    }
  </>
  )
}

export default DescMiddle
