import { Suspense } from 'react'
import Left from './left'

/* eslint-disable @typescript-eslint/no-unused-vars */
const LeftWrapper = () => {
  return (
    <Suspense
      fallback={
        <div className='left skeleton' style={{ aspectRatio: '778 / 465' }} />
      }
    >
      <Left />
    </Suspense>
  )
}

export default LeftWrapper
