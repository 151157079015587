import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import '../../style/components/slide/similarity-info.scss'
import { useHistory } from 'react-router-dom'
import { GetDetailSimilarResponseListModel } from '../../service/estate/model/get-detail-response-model'
import {
  getEstateClassName,
  getEstateDetailPath,
  getQuery,
  onError,
} from '../../shared/function'
import { Fragment, useRef } from 'react'

interface Props {
  similar_list?: Array<GetDetailSimilarResponseListModel>
}

export default function SimilarityInfo(props: Props) {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

  const history = useHistory()

  const swiper_ref = useRef<any>(null)
  const idx = parseInt(getQuery().idx)
  const similar_list = props.similar_list?.filter((item) => item.idx !== idx)

  if (similar_list === undefined || similar_list.length === 0) return <></>

  return (
    <div className='similarity_info_wrap'>
      <div className='info_head'>
        <h3 className='nanumB'>
          공짱 추천 유사 매물<strong className='nanumEB'></strong>
        </h3>
      </div>
      <div className='info_body'>
        <Swiper
          onInit={(e) => {
            swiper_ref.current = e
          }}
          scrollbar={{ draggable: true }}
          className='swiper_recent_list'
          breakpoints={{
            0: {
              spaceBetween: 14,
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1024: {
              spaceBetween: 20,
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
        >
          {similar_list.map((item) => {
            return (
              <SwiperSlide key={item.idx}>
                <div
                  className='rec_item unify_item'
                  onClick={() => {
                    history.push(
                      `/sale-detail-type${getEstateDetailPath(
                        item.deal_kind_text,
                        item.idx
                      )}`
                    )
                    window.scrollTo({
                      top: 0,
                    })
                  }}
                >
                  <div className='img_holder'>
                    <img
                      src={item.thumbnail_path}
                      alt='매물 이미지'
                      onError={(e) => onError(e, '3x', true)}
                    />
                  </div>
                  <div className='rec-desc'>
                    <span className={getEstateClassName(item.type_text)}>
                      {item.type_text}
                    </span>
                    <h3 className='address'>{item.address_text}</h3>
                    <strong>
                      {item.deal_kind_text}
                      &nbsp;
                      {item.deal_kind_text === '매매' && item.price_sale_text}
                      {item.rental_type_text === '전세' &&
                        item.price_deposit_text}
                      {item.rental_type_text === '월세' &&
                        item.price_deposit_text + '/' + item.price_monthly_text}
                    </strong>
                    <ul>
                      <li className='ellipsis2'>
                        {item.tags_text.split(' ').map((tag) => {
                          return (
                            <Fragment
                              key={item.tags_text.split(' ').indexOf(tag)}
                            >
                              {tag}&nbsp;
                            </Fragment>
                          )
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
          {/* <SwiperSlide>
            <div className='rec_item'>
              <div className='img_holder'>
                <img
                  src=''
                  alt='매물 이미지'
                  onError={(e) => onError(e, '3x', true)}
                />
              </div>
              <div className='rec-desc'>
                <span className='cat factory'>공장</span>
                <h3 className='address'>
                  부산광역시 동래구 온천동 100-13번지 및 금빛빌딩 길어지면
                  말줄임 닷닷닷닷닷닷닷닷닷닷닷닷
                </h3>
                <strong>매매 1억 5000</strong>
                <ul>
                  <li>#단독마당</li>
                  <li>#트레일러진입</li>
                  <li>#대형물류</li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='rec_item'>
              <div className='img_holder'>
                <img
                  src=''
                  alt='매물 이미지'
                  onError={(e) => onError(e, '3x', true)}
                />
              </div>
              <div className='rec-desc'>
                <span className='cat store'>창고</span>
                <h3 className='address'>
                  부산광역시 동래구 온천동 100-13번지 및 금빛빌딩 길어지면
                  말줄임 닷닷닷닷닷닷닷닷닷닷닷닷
                </h3>
                <strong>매매 1억 5000</strong>
                <ul>
                  <li>#단독마당</li>
                  <li>#트레일러진입</li>
                  <li>#대형물류</li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='rec_item'>
              <div className='img_holder'>
                <img
                  src=''
                  alt='매물 이미지'
                  onError={(e) => onError(e, '3x', true)}
                />
              </div>
              <div className='rec-desc'>
                <span className='cat ground'>토지</span>
                <h3 className='address'>
                  부산광역시 동래구 온천동 100-13번지 및 금빛빌딩 길어지면
                  말줄임 닷닷닷닷닷닷닷닷닷닷닷닷
                </h3>
                <strong>매매 1억 5000</strong>
                <ul>
                  <li>#단독마당</li>
                  <li>#트레일러진입</li>
                  <li>#대형물류</li>
                </ul>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  )
}
