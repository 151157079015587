import { GetDetailSimilarResponseListModel } from '../../service/estate/model/get-detail-response-model'
import '../../style/components/detail/real-estate-info.scss'
import MainButton from '../button/main-button'
import SimilarityInfo from '../slide/similarity-info'
import MainComponent from '../../components/main/index'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import estate_detail_news_start_point_atom from '../../atom/estate-detail-news-start-point'
import estate_detail_news_atom from '../../atom/estate-detail-news'
import estate_detail_news_btn_click_atom from '../../atom/estate-detail-news-btn-click'
import estate_detail_news_type_atom from '../../atom/estate-detail-news-type'

interface Props {
  similar_list?: Array<GetDetailSimilarResponseListModel>
  lat: number
  lng: number
}

export default function RealEstateInfo(props: Props) {
  const similar_list = props.similar_list

  const [news_type, setNewsType] = useRecoilState(estate_detail_news_type_atom)
  const setNewsStartPointAtom = useSetRecoilState(
    estate_detail_news_start_point_atom
  )

  const setBtnClick = useSetRecoilState(estate_detail_news_btn_click_atom)

  const news_atom = useRecoilValue(estate_detail_news_atom)

  // 최대 12개까지만 더보기 가능
  const onClickBtn = () => {
    if(news_type === 1){
      setNewsType(0)
    }else{
      setNewsStartPointAtom((prevNum) => prevNum + 4)
    }
    
    setBtnClick(true)
  }

  return (
    <div className='detail_bottom_wrap real_estate_info'>
      <article>
        <MainComponent.NewsWrapper lat={props.lat} lng={props.lng} detail />
        {news_atom.list.length < 12 && (
          <div className='btn_wrap'>
            <MainButton
              title='더보기'
              size='xsmall'
              types='more'
              onClick={onClickBtn}
            />
          </div>
        )}
      </article>
      <article>
        {similar_list && similar_list.length > 0 && (
          <SimilarityInfo similar_list={similar_list} />
        )}
      </article>
    </div>
  )
}
