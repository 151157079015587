import axios, { CancelTokenSource } from 'axios'
import { selectorFamily } from 'recoil'
import API from '../service/api'
import {
  GetDetailBrokerResponseDataModel,
  GetDetailFloorResponseListModel,
  GetDetailHistoryResponseListModel,
  GetDetailLocationResponseListModel,
  GetDetailResponseDataModel,
  GetDetailSimilarResponseListModel,
  GetDetailTrendResponseDataModel,
  TemporaryDesignModel,
} from '../service/estate/model/get-detail-response-model'
import { YNType } from '../shared/types'
import { useHistory } from 'react-router-dom'
import { PATH } from '../shared/constant'

import estateData from '../pages/sale-detail/estate.json'



type PolygonType = [number, number]

interface GetSandanDetailResponseModel {
  emd_cd: string
  id: string
  type: string
  name: string
  api_name: string
  address: string
  sido: string
  sigungu: string
  set_area: number|null
  set_date: string|null
  business_schedule: number|null
  corp: string|null
  organize: string|null
  design_status: string|null
  route_status: string|null
  route_rate: number|null
  accept_date: string|null
  work_start_date: string|null
  work_end_date: string|null
  avg_route_price: number|null
  business_type: string|null
  lat: number
  lng: number
  polygon: Array<PolygonType>
  attract_type: Array<SandanAttractType>
  work_type: Array<SandanWorkType>
  purpose: Array<SandanPurposeType>
}

type SandanAttractType = {
  sandan_id: string
  work_type_id: string
  name: string
}

type SandanWorkType = {
  id: string
  name: string
}

type SandanPurposeType = {
  sandan_id: string
  purpose: string
}

let cancel_token: CancelTokenSource | null = null

const get_sandan_detail_selector = selectorFamily<
  GetSandanDetailResponseModel,
    string
>({
  key: '@sandan/get_sandan_detail_selector',
  get: (id: string) => async () => {
    const { replace } = useHistory()

    if(id === '' || id == null) {
      alert('조회할 수 없는 산업단지 입니다.')
      replace(PATH.MAIN)
      return
    }
    if (cancel_token) cancel_token.cancel()

    cancel_token = axios.CancelToken.source()
    const response = await API.sandan.getDetail(
      id
    )
    .catch(() => {
      alert('조회요청이 거절된 산업단지 입니다.')
      //replace(PATH.MAIN)
      return
    })

    const data = response?.data


    return data
      },
})

export default get_sandan_detail_selector
