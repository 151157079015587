import axios, { AxiosError, CancelTokenSource } from 'axios'
import { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_side_bar_list_atom from '../../atom/map-side-bar-list'
import media_atom from '../../atom/media-atom'

import get_map_data_selector, {
  EstateModel,
} from '../../selector/get-map-data-selector'
import API from '../../service/api'
import ApiBaseResponseModel from '../../service/model/api-base-response-model'

import { SIGHT } from '../../shared/constant'
import { apiCallback } from '../../shared/function'
import { ElasticTextType, MapStatusType, YNType } from '../../shared/types'

interface Props {
  map_ref: React.MutableRefObject<any>
  setFold: React.Dispatch<React.SetStateAction<boolean>>
  removeOverlay: () => void
}

interface BrokerOverlayGeneratorProps {
  profile_path: string | null
  store_name: string
  idx: number
}

interface EstateOverlayGeneratorProps {
  class_name: string
  type_text: string
  price_text: string
  title: string
  idx: number
  type: ElasticTextType
  status: MapStatusType
  deal_kind_text: EstateModel['deal_kind_text']
  count: number
  realdeal_yn: YNType
  same_address_realdeal_y: Array<number>
  same_address_realdeal_n: Array<number>

  setFold: React.Dispatch<React.SetStateAction<boolean>>
  page: React.MutableRefObject<number>
  is_last: React.MutableRefObject<boolean>
  loading_ref: React.MutableRefObject<boolean>
  cancel_token: React.MutableRefObject<null | CancelTokenSource>
  media: 'M' | 'T' | 'P'
  setZoomableMap: (zoomable: boolean) => void
  removeOverlay: () => void
}

interface GroupingEstateModel extends EstateModel {
  count: number
  same_address_realdeal_y: Array<number>
  same_address_realdeal_n: Array<number>
}

interface DataModel extends ApiBaseResponseModel {
  list: Array<EstateModel>
  is_last: boolean
}

const util = {
  brokerOverlayGenerator: (props: BrokerOverlayGeneratorProps) => {
    const { idx, profile_path, store_name } = props

    const content = profile_path
      ? `<img class="wd-100per" src="${profile_path}" alt="${store_name} 중개인 프로필 이미지" />`
      : '중개'

    return `<a href="${'/profile-detail?i=' + idx}">
              <div class="agent of-h animate__animated animate__heartBeat animate__delay-10ms">
                ${content}
              </div>
            </a>`
  },
  estateOverlayGenerator: (props: EstateOverlayGeneratorProps) => {
    const {
      class_name,
      price_text,
      title,
      type_text,
      idx,
      status,
      deal_kind_text,
      count,
      realdeal_yn,
      same_address_realdeal_y,
      same_address_realdeal_n,

      setFold,
      page,
      is_last,
      loading_ref,
      cancel_token,
      media,
      setZoomableMap,
    } = props


    const dealContent = (item: EstateModel, isMobile: boolean) => {
      if (isMobile) item.thumbnail_path = null

      return `
      <li onclick="window._em(this)" data-key="${item.idx}" data-status="${
        item.status
      }" data-dkt="${item.deal_kind_text}" data-rd="${item.realdeal_yn}" >
        <div class="deal">
          ${
            item.thumbnail_path
              ? `
            <div class="img_holder">
              <img src="${item.thumbnail_path}" alt="매물이미지">
              <div class="tag ${
                item.status === 'COMPLETE'
                  ? 'bg-complate'
                  : item.type_text === '공장'
                  ? 'bg-factory'
                  : item.type_text === '창고'
                  ? 'bg-store'
                  : item.type_text === '토지'
                  ? 'bg-ground'
                  : item.type_text === '지식' ||
                    item.type_text === '지식산업센터'
                  ? 'bg-center'
                  : item.type_text === '산업' || item.type_text === '산업단지'
                  ? 'bg-industry'
                  : 'bg-logistics'
              }">${item.type_text}</div>
          </div>`
              : ''
          }
          <div class="list_desc ${item.thumbnail_path ? '' : 'noThumbnail'}">
            <div class="desc_top">
              ${
                item.thumbnail_path
                  ? ''
                  : `<div class="tag ${
                      item.status === 'COMPLETE'
                        ? 'bg-complate'
                        : item.type_text === '공장'
                        ? 'bg-factory'
                        : item.type_text === '창고'
                        ? 'bg-store'
                        : item.type_text === '토지'
                        ? 'bg-ground'
                        : item.type_text === '지식'
                        ? 'bg-center'
                        : ''
                    }" >
              ${item.type_text}
              </div>`
              }
             <h3 class="title ellipsis">${item.address_text}</h3>
            </div>
            <div class='desc_middle'>
              <div>
                ${
                  item.price_per_area_yn === 'Y'
                    ? `<p class='c-price fs-s fw500'>${item.price_text}</p>`
                    : ''
                }
                <p class='c-ink2 fs-s fw500'>${item.total_plat_area_text}</p>
              </div>
              ${
                item.status === 'COMPLETE' && item.realdeal_yn === 'Y'
                  ? `<div class='date c-ink2 fw500'>${item.complete_ymd}</div>`
                  : ''
              }
            </div>
            <p class="desc_bottom c-hash fs-xs ellipsis">${item.tags_text}</p>
          </div>
        </div>
      </li>`
    }

    //[이벤트] 같은 주소 매매가 있는 마커 클릭 시
    const onClickOverlapDeal = (e: any) => {
      //같은 주소 매매 리스트 노출 되어 있을 경우 삭제
      removeOverlay()
      page.current = 1
      getData()
      setZoomableMap(false)
    }

    //[이벤트] 페이지 이동
    const _em = () => {
      window.location.replace(
        `${window.location.pathname}${
          window.location.search
        }#key=${idx}&status=${status}&dkt=${deal_kind_text}&t=${new Date().valueOf()}&rd=${realdeal_yn}`
      )
    }

    const onScroll = (e: any) => {
      e.stopPropagation()
      const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget
      if (
        scrollHeight - scrollTop - offsetHeight < 20 &&
        !loading_ref.current &&
        !is_last.current
      ) {
        page.current += 1
        getData()
      }
    }

    const getData = () => {
      if (cancel_token.current) cancel_token.current.cancel()

      cancel_token.current = axios.CancelToken.source()

      loading_ref.current = true

      API.estate
        .getSameAddress(
          page.current,
          cancel_token.current.token,
          same_address_realdeal_y,
          same_address_realdeal_n
        )
        .then((res) => {
          apiCallback(res, {
            success: onSameAddressListSuccessCallback,
            fail: () => {
              throw new Error()
            },
          })
        })
        .catch(getDataFailCallback)
        .finally(getDataFinallyCallback)
    }

    const onSameAddressListSuccessCallback = (res: any) => {
      is_last.current = res.is_last
      const isMobile = media === 'M'

      if (isMobile) {
        setFold(true)
      }

      if (!document.querySelector('.overlay_wrap')) {
        if (res.list.length < 1) {
          removeOverlay()
          return
        }

        let sameAddressItem = document.createElement('div')
        sameAddressItem.className = 'overlay_wrap'
        sameAddressItem.onscroll = onScroll
        sameAddressItem.ontouchstart = onScroll

        sameAddressItem.innerHTML = `
                    <div class="overlay_content">
                      <ul class="overlay_items">
                        ${res.list
                          .map((deal: any) => dealContent(deal, isMobile))
                          .join('')}
                      </ul>
                    </div>
                  `
        map_marker.append(sameAddressItem)

        //동일 매물 리스트 최상단 위치
        if (map_marker.parentElement) {
          map_marker.parentElement.style.zIndex = 5
        }
      } else {
        const addItem = res.list
          .map((deal: any) => dealContent(deal, isMobile))
          .join('')
        const items = document.querySelector('.overlay_items')

        const parser = new DOMParser()
        const doc = parser.parseFromString(addItem, 'text/html')
        const decEl = doc.querySelector('li')

        if (decEl) items?.append(decEl)
      }
    }

    function getDataFailCallback(err: Error | AxiosError) {
      if (!axios.isCancel(err)) {
        is_last.current = true
      }
      removeOverlay()
    }

    function getDataFinallyCallback() {
      loading_ref.current = false
      cancel_token.current = null
    }

    function removeOverlay() {
      props.removeOverlay()
      setZoomableMap(true)
    }

    const isIC =
      type_text === '산업' ||
      type_text === '산업단지' ||
      props.deal_kind_text === '분양'

    let titleArr = []
    titleArr.push(title?.slice(0, 4))
    title?.length > 8
      ? titleArr.push(`${title?.slice(4, 8)}..`)
      : titleArr.push(title?.slice(4, 8))

    let map_marker = document.createElement('div')
    map_marker.className = `map_marker ${class_name}`
    map_marker.onclick = count < 2 ? _em : onClickOverlapDeal

    const priceText = price_text ?? ""
    const text = isIC ? titleArr.filter((e) => e !== '').join('<br/>') : priceText.toString().split(' ').join('<br/>')

    map_marker.innerHTML = `
          <span class="cat ${class_name}">${type_text}</span>
          ${count > 1 ? `<div class="same-count">${count}</div>` : ''}
          <div class="outside">
            <div class="inside scd5 ta-c ${isIC ? 'small' : ''} ${!isIC && priceText.length > 4 ? 'small' : ''}">
              ${text}
            </div>
          </div>`
          
    return map_marker
  },
  sandanOverlayGenerator: (name: string, type: string, id: string) => {
    return `<div class="sandan" onClick="javascript:window.location.href='/sale-detail-type-sandan?id=${id}'">
              <span>${type}</span>
              ${type !== '' ? '<div></div>' : ''}
              ${name}
            </div>`
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapMarkers = (props: Props) => {
  const { setFold, removeOverlay } = props
  const map = props.map_ref.current

  const map_filter = useRecoilValue(map_filter_atom)

  const map_data = useRecoilValue(get_map_data_selector)

  const { broker, estate, sandan } = map_data

  const { sub_division } = map_filter.deal_kind

  const broker_overlays = useRef<Array<any>>([])

  const estate_overlays = useRef<Array<any>>([])

  const polygon_overlays = useRef<Array<any>>([])

  const sandan_overlays = useRef<Array<any>>([])

  const page = useRef<number>(1)

  const is_last = useRef<boolean>(false)

  const loading_ref = useRef<boolean>(false)

  const cancel_token = useRef<null | CancelTokenSource>(null)

  const media = useRecoilValue(media_atom)

  const setZoomableMap = (zoomable: boolean) => {
    map.setZoomable(zoomable)
  }

  const brokerDrawing = () => {
    try {
      const level = map.getLevel()

      if (SIGHT.ICONS.min <= level && SIGHT.ICONS.max >= level) {
        for (const item of broker) {
          const position = new window.kakao.maps.LatLng(item.lat, item.lng)

          const overlay = new window.kakao.maps.CustomOverlay({
            position,
            content: util.brokerOverlayGenerator({
              profile_path: item.profile_path,
              store_name: item.store_name,
              idx: item.idx,
            }),
            zIndex: 3,
          })

          overlay.setMap(map)

          broker_overlays.current.push(overlay)
        }
      }
    } catch {}
  }

  const estateDrawing = () => {
    try {
      const level = map.getLevel()

      const max = sub_division === 'Y' ? SIGHT.CITIES.max : SIGHT.ICONS.max

      if (SIGHT.ICONS.min <= level && max >= level) {
        const grouping_estate: Array<GroupingEstateModel> = estate.reduce(
          (acc: Array<GroupingEstateModel>, current: EstateModel) => {
            if (
              acc.findIndex(
                (i) => i.lat === current.lat && i.lng === current.lng
              ) !== -1
            ) {
              return acc.map((i) => {
                if (i.lat === current.lat && i.lng === current.lng) {
                  let same_address_realdeal_y = [...i.same_address_realdeal_y]
                  let same_address_realdeal_n = [...i.same_address_realdeal_n]

                  if (current.realdeal_yn === 'Y') {
                    same_address_realdeal_y.push(current.idx)
                  } else {
                    same_address_realdeal_n.push(current.idx)
                  }

                  return {
                    ...i,
                    count: (i.count += 1),
                    same_address_realdeal_y,
                    same_address_realdeal_n,
                  }
                } else {
                  return i
                }
              })
            } else {
              let same_address_realdeal_y = []
              let same_address_realdeal_n = []

              if (current.realdeal_yn === 'Y') {
                same_address_realdeal_y.push(current.idx)
              } else {
                same_address_realdeal_n.push(current.idx)
              }

              return acc.concat({
                ...current,
                count: 1,
                same_address_realdeal_y,
                same_address_realdeal_n,
              })
            }
          },
          []
        )

        for (const item of grouping_estate) {
          const position = new window.kakao.maps.LatLng(item.lat, item.lng)
          //매물 & 분양 매물 이 실거래가 보다 위에 위치, 동일 지번 매물이 여러개일 경우 위에 위치
          let zIndex = item.status === 'COMPLETE' ? 1 : 3
          zIndex = 1 < item.count ? zIndex + 1 : zIndex

          // (FT: 공장, WH: 창고, LD: 토지, KC: 지식산업센터, IC: 산업단지, LC: 물류단지)
          const class_name =
            item.status === 'COMPLETE' && item.realdeal_yn === 'N'
              ? `complete ${item.type}`
              : item.status === 'COMPLETE' && item.realdeal_yn === 'Y'
              ? `realdeal ${item.type}`
              : item.type === 'FT'
              ? 'factory'
              : item.type === 'WH'
              ? 'store'
              : item.type === 'LD'
              ? 'ground'
              : item.type === 'IC'
              ? 'industry'
              : item.type === 'KC'
              ? 'center'
              : 'logistics'

          const overlay = new window.kakao.maps.CustomOverlay({
            position,
            content: util.estateOverlayGenerator({
              class_name,
              price_text:
                item.price_per_area_yn === 'Y'
                  ? item.marker_price_sub
                    ? `${item.marker_price_main}<br/>${item.marker_price_sub}`
                    : item.marker_price_main
                  : '-',
              title: item.title || '-',
              type_text: item.type_text,
              idx: item.idx,
              type: item.type_text,
              status: item.status,
              deal_kind_text: item.deal_kind_text,
              count: item.count,
              realdeal_yn: item.realdeal_yn,
              same_address_realdeal_y: item.same_address_realdeal_y,
              same_address_realdeal_n: item.same_address_realdeal_n,

              setFold,
              page: page,
              is_last: is_last,
              loading_ref: loading_ref,
              cancel_token: cancel_token,
              media,
              setZoomableMap,
              removeOverlay,
            }),
            zIndex,
          })

          overlay.setMap(map)

          estate_overlays.current.push(overlay)
        }
      }
    } catch {}
  }

  const polygonDrawing = () => {
    try {
      const level = map.getLevel()

      if (SIGHT.ICONS.min <= level && SIGHT.GU.max >= level) {
        if (map_filter.sandan_yn !== 'N') {
          // sandan 배열에 중복id로 인해 맵에 여러번 마커 되어 색이 진해지는 현상으로 중복 제거함
          const uniqueSandan = Array.from(
            new Set(sandan.map((obj) => obj.id))
          ).map((id) => {
            return sandan.find((obj) => obj.id === id)!
          })

          for (const item of uniqueSandan.filter(
            (i) => i.polygon !== undefined && i.polygon.length !== 0
          )) {
            const path = item.polygon.map(
              (i) => new window.kakao.maps.LatLng(...i)
            )

            const polygon = new window.kakao.maps.Polygon({
              path,
              strokeWeight: 2,
              strokeColor: '#748398',
              fillColor: '#748398',
              fillOpacity: 0.3,
            })

            polygon.setZIndex(item.api_yn === 'N' ? 2 : 1)

            polygon.setMap(map)

            polygon_overlays.current.push(polygon)

            // 하위 필지 렌더링
            for (const inner_item of item.lot) {
              const path = inner_item.polygon.map(
                (i) => new window.kakao.maps.LatLng(...i)
              )

              const polygon = new window.kakao.maps.Polygon({
                path,
                strokeWeight: 2,
                strokeColor: '#748398',
                fillColor: '#748398',
                fillOpacity: 0.3,
              })

              polygon.setZIndex(3)

              polygon.setMap(map)

              polygon_overlays.current.push(polygon)
            }

            const position = new window.kakao.maps.LatLng(item.lat, item.lng)

            const overlay = new window.kakao.maps.CustomOverlay({
              position,
              content: util.sandanOverlayGenerator(
                item.name,
                item.type,
                item.id
              ),
            })

            overlay.setMap(map)

            sandan_overlays.current.push(overlay)
          }
        }
      }
    } catch {}
  }

  const brokerRemove = () => {
    for (const overlay of broker_overlays.current) {
      overlay.setMap(null)
    }
  }

  const estateRemove = () => {
    for (const overlay of estate_overlays.current) {
      overlay.setMap(null)
    }
  }

  const polygonRemove = () => {
    for (const overlay of polygon_overlays.current) {
      overlay.setMap(null)
    }
    for (const overlay of sandan_overlays.current) {
      overlay.setMap(null)
    }
  }

  useEffect(() => {
    if (map) {
      brokerDrawing()
      estateDrawing()
    }

    return () => {
      brokerRemove()
      estateRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_data, map])

  useEffect(() => {
    if (map) polygonDrawing()

    return () => {
      polygonRemove()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_data, map, map_filter.sandan_yn])

  return <></>
}

export default MapMarkers
