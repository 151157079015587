import Axios,{ AxiosRequestConfig, CancelToken } from "axios"
import { GET, JSON_HEADER, SANDAN_URL } from "../../shared/api-constant"
import accountManager from "../../shared/account-manager"
import { getQuery } from "../../shared/function"

const sandan = {
  getDetail: (id: string) => {
    const config: AxiosRequestConfig = {
      url: `${SANDAN_URL}/get_detail.php`,
      method: GET,
      params: {
        id
      },
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getRealDeal: (id: string) => {
    const config: AxiosRequestConfig = {
      url: `${SANDAN_URL}/get_real_deal.php`,
      method: GET,
      params: {
        id,
      },
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  }
}

export default sandan