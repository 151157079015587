import axios, { AxiosRequestConfig } from "axios"
import { GET, JSON_HEADER, MAIN_URL } from "../../shared/api-constant"

const main = {
  getBunyangMainThemeList : () => {
    const config : AxiosRequestConfig = {
      url : `${MAIN_URL}/main_theme_list.php`,
      method : GET ,
      headers : {
        ...JSON_HEADER
      }
    }

    return axios(config)
  },

  getMainLastBunyang : () => {
    const config : AxiosRequestConfig = {
      url : `${MAIN_URL}/main_last_bunyang.php`,
      method : GET ,
      headers : {
        ...JSON_HEADER
      }
    }

    return axios(config)
  },
}

export default main