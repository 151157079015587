import { selectorFamily } from 'recoil'
import estate_detail_news_start_point_atom from '../atom/estate-detail-news-start-point'
import API from '../service/api'
import estate_detail_news_type_atom from '../atom/estate-detail-news-type'

export interface NaverNewsModel {
  description: string
  link: string
  originallink: string
  pub_ymd: string
  title: string
}

const get_detail_page_news_selector = selectorFamily<
  Array<NaverNewsModel>,
  string
>({
  key: '@naver/get-detail-page-news',
  get:
    (query: string) =>
    async ({ get }) => {
      const type = get(estate_detail_news_type_atom)
      const start_point = get(estate_detail_news_start_point_atom)
      const response = await API.naver.getNews({
        type,
        query,
        display: 4,
        start: start_point,
      })

      return response.data.list
    },
})

export default get_detail_page_news_selector
