import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'
import LeftCategoryIcon from '../bunyang/left-category-icon'
import EstateMap from '../detail/estate-map'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Top = () => {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const is_complete = getQuery().rd === "N"

  const { detail } = data

  return (
    <div className='detail_card'>
      {
        is_complete &&
        <div className="complete-label scd5">
          거래 완료
        </div>
      }
      <EstateMap
        id='__rt_map__'
        className='rt_map'
        lat={detail.lat}
        lng={detail.lng}
        location_bottom_right
      />
      <LeftCategoryIcon />
    </div>
  )
}

export default Top
