import { atom } from 'recoil'
import { AreaType } from './map-filter'

interface Model {
  area_type: AreaType
  popup: number
}

const bunyang_detail_atom = atom<Model>({
  key: '@bunyang/detail-atom',
  default: {
    area_type: 'PY',
    popup: 0,
  },
})

export default bunyang_detail_atom
