import { useEffect } from "react"

const useComponentWillUnmount = (fn : Function) => {
  
  useEffect(() => {
    return () => {
      fn()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

}

export default useComponentWillUnmount