import { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import logo from '../../static/img/logo.svg'
import logoW from '../../static/img/logo-w.svg'
import back from '../../static/img/icons/back.svg'
import glass from '../../static/img/icons/m-glass.svg'

import '../../style/components/layout/mobile-gnb.scss'

import { PATH } from '../../shared/constant'
import Map from '../map'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'

//import newLogo from '../../static/img/logo-color.png'
import newLogo from '../../static/img/logo-v2.svg'

interface Props {
  logo?: boolean
  search?: boolean
  title?: string
  is_main?: boolean
  non_border?: boolean
  back?: JSX.Element
}

declare const window: any

export default function MobileGNB(props: Props) {
  const history = useHistory()

  const location = useLocation()

  const media = useRecoilValue(media_atom)

  const [scrollY, setScrollY] = useState(0)
  // const [search_result, setSearchResult] = useState(props.title)

  const onClickBack = () => {
    if (history.length === 1) {
      if (window.opener) {
        window.self.close()
      } else {
        history.push(PATH.MAIN)
      }
    } else {
      history.go(-1)
    }
  }

  const handleGnb = () => {
    setScrollY(window.scrollY)
  }

  const onClickRegisterButton = () => {
    history.push(PATH.REGISTER + '?s=1')
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleGnb)
    }
    watch()
    return () => {
      window.removeEventListener('scroll', handleGnb)
    }
  })

  return (
    <>
      {media === 'M' && props.is_main && (
        <>
          <header
            id='mobile_main_gnb'
            className={`${props.non_border ? 'non_border' : ''}`}
          >
            <img src={newLogo} alt='' />
          </header>
          {/*
          // 원본
        <header
          id='mobile_gnb'
          className={`is_main ${scrollY > 56 ? 'active' : ''} ${
            props.non_border ? 'non_border' : ''
          } `}
        >
          <div className='col front'>
            {props.logo ? (
              <Link to={PATH.MAIN}>
                {props.is_main && scrollY > 56 ? (
                  <img className='logo' src={logo} alt='로고' />
                ) : (
                  <img className='logo' src={logoW} alt='로고' />
                )}
              </Link>
            ) : (
              <button type='button' onClick={() => onClickBack()}>
                <img className='back' src={back} alt='뒤로가기' />
              </button>
            )}
          </div>
          <div className='col middle'>
            {props.search ? (
              <p className='ellipsis ta-c'>
                <img className='glass' src={glass} alt='' />
                {props.title}
              </p>
            ) : (
              <p className='ellipsis ta-c'>{props.title}</p>
            )}
          </div>
          <div className='col back'>{props.back}</div>
        </header>
        */}
        </>
      )}

      {media === 'M' && !props.is_main && (
        <header
          id='mobile_gnb'
          className={`${props.non_border ? 'non_border' : ''}`}
          style={props.search ? { justifyContent: 'space-between' } : {}}
        >
          <div className='col front'>
            {props.logo ? (
              <>
                <Link to={PATH.MAIN}>
                  <img className='logo' src={logo} alt='로고' />
                </Link>
                {location.pathname === PATH.ESTATE && (
                  <button
                    onClick={onClickRegisterButton}
                    className='register-button'
                  >
                    매물 등록
                  </button>
                )}
              </>
            ) : (
              <button type='button' onClick={() => onClickBack()}>
                <img className='back' src={back} alt='뒤로가기' />
              </button>
            )}
          </div>
          {props.search ? (
            <Map.SearchArea />
          ) : location.pathname === '/register' ||
            location.pathname === '/mypage' ? (
            <></>
          ) : (
            <div className='col middle'>
              <p className='ellipsis ta-c'>{props.title}</p>
            </div>
          )}
          {props.search ? <></> : <div className='col back'>{props.back}</div>}
        </header>
      )}
    </>
  )
}
