import { Suspense } from "react"
import Loan from "./loan"
import LoanSkeleton from "./loan-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const LoanWrapper = () => {
  return (
    <Suspense fallback={<LoanSkeleton />}>
      <Loan />
    </Suspense>
  )
}

export default LoanWrapper