import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import PNU from '.'
import map_filter_atom, { AreaType } from '../../atom/map-filter'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import media_atom from '../../atom/media-atom'
import API from '../../service/api'
import AddressToCoordResponseModel from '../../service/kakao/model/address-to-coord-response-model'
import search_atom from '../../atom/search-atom'
import { useHistory } from 'react-router-dom'
import { PATH } from '../../shared/constant'

interface Props {
  area_type: AreaType
  setAreaType: React.Dispatch<React.SetStateAction<AreaType>>
  detail: GetDetailResponseModel['detail']
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const DescTop = ({ detail, area_type, setAreaType }: Props) => {
  const media = useRecoilValue(media_atom)
  const history = useHistory()

  const setSearch = useSetRecoilState(search_atom)
  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

    const onClickMapBtn = (e: React.MouseEvent<HTMLButtonElement>, address: string) => {
    e.preventDefault()
    API.kakao
      .addressToCoord(address)
      .then((res) => {
        addressToCoordSuccessCallback(res.data, address)
      })
      .catch(addressToCoordFailCallback)
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel, address: string) {
    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]
      setMapFilter({
        ...map_filter,
        complete_after: '3Y',
        realdeal_yn: 'Y',
        deal_kind: {
          ...map_filter.deal_kind,
          tr: 'Y',
          sub_division: 'N',
        },
      })
      setSearch(address)
      history.push({
        pathname : `${PATH.MAP}`,
        hash : `LatLng=${y}^${x}`,
        state : 'address-from-detail-page'
      })
    } else {
      throw new Error()
    }
  }

  function addressToCoordFailCallback() {}

  return (
    <div className='top'>
      <div className='desc_address'>
        <span>{detail.address_text}</span>
        <button className='map_button' onClick={(e) => onClickMapBtn(e, detail.address_text)}>지도보기</button>
      </div>
      <div>
        <div className='top_btn'>
          <PNU.AreaTypeToggle area_type={area_type} setAreaType={setAreaType} />
        </div>
        {media === 'P' && <p className='toggle_desc pd_top'>* 평전환시 소수점 첫째자리에서 반올림한 값입니다.</p>}
      </div>
      {media !== 'P' && <p className='toggle_desc pd_bottom left'>* 평전환시 소수점 첫째자리에서 반올림한 값입니다.</p>}
    </div>
  )
}

export default DescTop
