import { useLayoutEffect } from 'react'
import Main from '../../components/layout/main'
import BunyangMobileGnbWrapper from '../../components/bunyang/bunyang-mobile-gnb-wrapper'
import ErrorBoundary from '../../components/layout/error-boundary'
// import ErrorBoundaryFallbackKick from '../../components/layout/error-boundary-fallback-kick'
import LotWrapper from "../../components/bunyang/lot-wrapper"
// import { PATH } from '../../shared/constant'
import { useSetRecoilState } from 'recoil'
import detail_type3d_atom from '../../atom/detail-type3d'
import '../../style/components/detail/type3d.scss'
import Nodata from '../../components/layout/nodata'
import MobileGNB from '../../components/layout/mobile-gnb'


const SaleDetailType3D = () => {
  const setState = useSetRecoilState(detail_type3d_atom)

  useLayoutEffect(() => {
    setState(true)

    return () => {
      setState(false)
    }
  }, [])

  return (
    <>
      <ErrorBoundary
        fallback={
          <>
            <MobileGNB title='페이지를 찾을 수 없습니다.' />
            <Main id='sale_detail'>
              <div className='container'>
                <div className='inner for_type2 pb-zero'>
                  <Nodata writing='페이지를 찾을 수 없습니다.' />
                </div>
              </div>
            </Main>
          </>
        }
      >
        <BunyangMobileGnbWrapper />
        <Main id='sale_detail'>
          <div className='container'>
            <div className='inner for_type2 pb-zero'>
              <div id='type' className='detail_bottom_wrap type3d'>
                <LotWrapper />
              </div>
            </div>
          </div>
        </Main>
      </ErrorBoundary>
    </>
  )
}

export default SaleDetailType3D