import { useRecoilValue, useSetRecoilState } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'
import AreaTypeToggle from './area-type-toggle'

declare const window:any

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileTop = () => {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data

  const setBunyangDetail = useSetRecoilState(bunyang_detail_atom)

  const url = window.location.href

  const onClickDeclare = () => {
    setBunyangDetail((_) => ({
      ..._,
      popup: 1,
    }))
  }

  const onClickShare = () => {
    if (typeof window.AndroidScript != 'undefined' && window.AndroidScript?.sharingText) {
      window.AndroidScript.sharingText(url)
      return
    }

    try{
      window.webkit.messageHandlers.sharing.postMessage(url)
      return
    }catch{}

    setBunyangDetail((_) => ({
      ..._,
      popup: 3,
    }))
  }

  return (
    <>
      <div className='mobile_top_btn'>
        <div>
          {detail.complete_ymd ? (
            <p>최종업데이트 : <strong className='fw500'>{detail.complete_ymd}</strong></p>
          ) : (
            <p>등록일자 : <strong className='fw500'>{detail.regist_ymd}</strong></p>
          )}
          {detail.deal_kind === 'BY' && <p>분양정보번호 : <strong className='fw500'>{detail.code}</strong></p>}
        </div>
        <div className='top_btn'>
          {/* {
            data.detail.price_per_area_yn === 'Y' &&
            <AreaTypeToggle />
          } */}
            <AreaTypeToggle />
          <button type='button' className='declare' onClick={onClickDeclare} />
          <button type='button' className='share' onClick={onClickShare} />
        </div>
      </div>
      {/* {data.detail.price_per_area_yn === 'Y' && <p className='toggle_desc pd_bottom'>* 평전환시 소수점 첫째자리에서 반올림한 값입니다.</p>} */}
      <p className='toggle_desc pd_bottom'>* 평전환시 소수점 첫째자리에서 반올림한 값입니다.</p>
    </>
  )
}

export default MobileTop
