import Main from '../../components/layout/main'

import DeclarePopup from '../../components/popup/declare-popup'
import RegisterPopup from '../../components/popup/register-popup'
import SharePopup from '../../components/popup/share-popup'
import Bunyang from '../../components/bunyang'

import '../../style/pages/sale-detail/sale-detail-type.scss'
import '../../style/components/detail/type2.scss'
import '../../style/components/detail/type3.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import { useEffect } from 'react'
import ErrorBoundary from '../../components/layout/error-boundary'
import ErrorBoundaryFallbackKick from '../../components/layout/error-boundary-fallback-kick'
import { PATH } from '../../shared/constant'
import useScrollControl from '../../hooks/use-scroll-control'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'
import ES from '../../components/detail'

const SaleDetailTypeBY = () => {
  /**
   * States
   */
  const [bunyang_detail, setBunyangDetail] = useRecoilState(bunyang_detail_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { popup } = bunyang_detail
  const { type_text } = data.detail


  const setPopup = (e: number) => {
    setBunyangDetail({
      ...bunyang_detail,
      popup: e,
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useScrollControl()

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallbackKick
          message='조회할 수 없는 정보입니다.'
          path={PATH.MAIN}
        />
      }
    >
      <Bunyang.BunyangMobileGnbWrapper />
      <Main id='sale_detail'>
        <div className='container'>
          <div className='inner for_type2 pb-zero'>
            <div className='detail_card'>
              <Bunyang.LeftWrapper />
              <Bunyang.RightWrapper name='bunyang' />
            </div>
            <div className='detail_desc'>
              <div className='desc_text'>
                <Bunyang.TopWrapper />
                {/* <Bunyang.MiddleWrapper /> */}
                <Bunyang.BottomWrapper />
                <Bunyang.NoticeWrapper />
                <Bunyang.TableWrapper />
              </div>
              <Bunyang.MobileBottomCardWrapper />
            </div>
            <div id='type' className='detail_bottom_wrap type2'>
              <Bunyang.NearbyWrapper />
              <Bunyang.PyeongWrapper />
              <Bunyang.DetailWrapper type='explain2' />
              <Bunyang.LotWrapper />
              <Bunyang.DetailWrapper type='explain' />
              <Bunyang.PriceInfoWrapper />
              <Bunyang.NewsWrapper />
            </div>
          </div>
        </div>
      </Main>
      {popup === 1 && <DeclarePopup onClickClose={() => setPopup(0)} />}
      {popup === 2 && <RegisterPopup onClickClose={() => setPopup(0)} />}
      {popup === 3 && <SharePopup onClickClose={() => setPopup(0)} />}
    </ErrorBoundary>
  )
}

export default SaleDetailTypeBY
