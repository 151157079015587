import { Suspense } from "react"
import Right from "./right"
import RightSkeleton from "./right-skeleton"
interface Props {
  name?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const RightWrapper = ({ name }: Props) => {
  return (
    <Suspense fallback={<RightSkeleton />}>
      <Right name={name} />
    </Suspense>
  )
}

export default RightWrapper