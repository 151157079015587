import { useEffect } from 'react'

const useGlobalFunction = () => {
  // DEBUGGER
  useEffect(() => {
    // window.message = console.log
    window.message = () => {}

    window.ALLOWED_IMAGE_EXTENSION = ['gif', 'jpeg', 'jpg', 'png']
  }, [])
}

export default useGlobalFunction
