import React from "react"
import Popup from "./popup"

import kakao from "../../static/img/icons/sns-kakao.svg"
import facebook from "../../static/img/icons/sns-facebook.svg"
import blog from "../../static/img/icons/sns-blog.svg"

import "../../style/components/popup/share-popup.scss"

interface Props {
  title?: string
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
}
const SharePopup = (props: Props) => {

  const onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }

  const onClickCopy = () => {
    const element = document.createElement('textarea');
    element.value = url;  
    document.body.appendChild(element);

    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
  }

  const onClickShareToKakao = () => {
    window.Kakao.Link.sendScrap({
      requestUrl : url,
    })
  }

  const onClickShareToFacebook = () => {
    window.open(
      `http://www.facebook.com/sharer.php?u=${url}`
    )
  }

  const onClickShareToNaverBlog = () => {
    window.open(
      `http://blog.naver.com/openapi/share?url=${url}`
    )
  }

  const url = window.location.href

  return (
    <Popup title="공유하기" onClickClose={onClickClose}>
      <div className="share_popup">
        <div className="sns">
          <button type="button" onClick={onClickShareToKakao}>
            <img src={kakao} alt="카카오톡" />
          </button>
          <button type="button" onClick={onClickShareToFacebook}>
            <img src={facebook} alt="페이스북" />
          </button>
          <button type="button" onClick={onClickShareToNaverBlog}>
            <img src={blog} alt="블로그" />
          </button>
        </div>
        <div className="url">
          <span className="ellipsis">{url}</span>
          <button type="button" onClick={onClickCopy}>URL 복사</button>
        </div>
      </div>
    </Popup>
  )
}

export default SharePopup