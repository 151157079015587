import React from 'react'

interface Props {
  is_loading: boolean
  top?: number
  bottom?: number
  left?: number
  color?: string
  size?: number
  width?: number
  className?: string
}

const LoadingMini = (props: Props) => {
  const { is_loading, top, bottom, color, size, width } = props
  return (
    <div
      style={{
        marginTop: top,
        marginBottom: bottom,
        borderTopColor: color ?? '#fff',
        borderWidth: !is_loading ? 0 : width ?? 2,
        width: is_loading ? size ?? 15 : 0,
        height: is_loading ? size ?? 15 : 0,
      }}
      className={`${is_loading ? 's' : ''} loading${props.className ? ` ${props.className}` : ''}`}
    ></div>
  )
}

export default React.memo(LoadingMini)
