import { Suspense } from "react"
import Table from "./table"
import TableSkeleton from "./table-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const TableWrapper = () => {
  return (
    <Suspense fallback={<TableSkeleton />}>
      <Table />
    </Suspense>
  )
}

export default TableWrapper