import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { farmLandTypeCheck, getQuery } from '../../shared/function'
import LoanInfo from '../detail/loan-info'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Loan = () => {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  //23.05.17 임대일 경우 대출정보 제공란을 삭제
  if(data.detail.deal_kind === 'RT') return <></>

  return <LoanInfo price={data.detail.price_main} is_farmland={farmLandTypeCheck(data.detail.jimok)} />
}

export default Loan
