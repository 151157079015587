import Axios, { AxiosRequestConfig, CancelToken } from 'axios'
import accountManager from '../../shared/account-manager'
import {
  ACCOUNT_URL,
  FORM_HEADER,
  GET,
  JSON_HEADER,
  POST,
} from '../../shared/api-constant'
import { formGenerator } from '../../shared/function'
import { YNType } from '../../shared/types'
import FindAccountRequestModel from './model/find-account-request-model'
import JoinRequestModel from './model/join-request-model'
import LoginRequestModel from './model/login-request-model'
import ModifyAccountRequestModel from './model/modify-account-request-model'
import PasswordResetRequestModel from './model/password-reset-request-model'
import VerifyPhoneRequestModel from './model/verify-phone-request-model'

export type ProfileDetailType = `${YNType}${YNType}${YNType}${YNType}`

const account = {
  /**
   * 이메일 중복 검사
   */
  emailCheck: (email: string) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/dup_email.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator({
        email,
      }),
    }

    return Axios(config)
  },

  /**
   * 휴대폰번호 인증
   */
  verifyPhone: (request: VerifyPhoneRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/verify_phone.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  /**
   * 회원가입
   */
  join: (request: JoinRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/join.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  /**
   * 로그인
   */
  login: (request: LoginRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/login.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  /**
   * 계정 찾기
   */
  findAccount: (request: FindAccountRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/find_account.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  /**
   * 비밀번호 초기화
   */
  passwordReset: (request: PasswordResetRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/reset_password.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  /**
   * 휴면계정 해제하기
   */
  resurrect: (id: string) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/resurrect.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator({
        id,
      }),
    }

    return Axios(config)
  },

  mypage: (idx: string) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/mypage.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator({
        idx,
      }),
    }

    return Axios(config)
  },

  modifyAccount: (request: ModifyAccountRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/modify.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  exitAccount: (idx: string) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/exit.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator({
        idx,
      }),
    }

    return Axios(config)
  },

  getBrokerProfileDetail: (
    account_idx: number,
    current_page: number,
    type: ProfileDetailType,
    cancel_token?: CancelToken
  ) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/profile_detail.php?account_idx=${account_idx}&current_page=${current_page}&type=${type}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
    }
    return Axios(config)
  },

  getDefault: (account_idx: string | null) => {
    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/get_default.php?account_idx=${account_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  checkPassword: (pw: string) => {
    const account_idx = accountManager.getIdx()

    const config: AxiosRequestConfig = {
      url: `${ACCOUNT_URL}/check_password.php?`,
      method: POST,
      headers: {
        ...JSON_HEADER,
      },
      data: formGenerator({
        account_idx,
        pw,
      }),
    }
    return Axios(config)
  },
}

export default account
