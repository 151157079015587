import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import API from '../../service/api'
import accountManager from '../../shared/account-manager'
import {
  apiCallback,
  errorAlert,
  errorGenerator,
  getLoginRedirectUrl,
  getQuery,
  loginRead,
} from '../../shared/function'

/* eslint-disable @typescript-eslint/no-unused-vars */
const LeftBookMarker = () => {
  const idx = getQuery().idx

  const data = useRecoilValue(get_bunyang_detail_selector(idx))

  const { push } = useHistory()

  const { fave_yn } = data.personal

  const [fave, setFave] = useState<boolean>(fave_yn === 'Y')

  const onChangeFave = () => {
    if (accountManager.isGuest()) {

      loginRead(() => {
        push(
          getLoginRedirectUrl()
        )
      })
      return
    }

    API.estate
      .setBookMark({
        estate_idx: parseInt(idx),
      })
      .then((res) => {
        apiCallback(res, {
          success: onChangeFaveSuccessCallback,
          fail: onChangeFaveFailCallback,
        })
      })
      .catch(onChangeFaveFailCallback)
  }

  const onChangeFaveSuccessCallback = () => {
    setFave(!fave)
  }

  const onChangeFaveFailCallback = () => {
    errorAlert(errorGenerator('보관하기 중 오류가 발생했습니다.'))
  }

  return (
    <div className='bookmark'>
      <input
        type='checkbox'
        id='_bookmark1'
        name='_bookmark'
        checked={fave}
        onChange={onChangeFave}
      />
      <label htmlFor='_bookmark1' />
    </div>
  )
}

export default LeftBookMarker
