import { Suspense } from "react"
import Bottom from "./bottom"
import BottomSkeleton from "./bottom-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const BottomWrapper = () => {
  return (
    <Suspense fallback={<BottomSkeleton />}>
      <Bottom />
    </Suspense>
  )
}

export default BottomWrapper