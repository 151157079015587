import React, { useMemo } from 'react'
import { AreaType } from '../../atom/map-filter'
import { encNumber, random } from '../../shared/function'
import { useRecoilState } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'

// interface Props {
//   area_type: AreaType
//   setAreaType: React.Dispatch<React.SetStateAction<AreaType>>
// }

/* eslint-disable @typescript-eslint/no-unused-vars */
const AreaTypeToggle = () => {
  // const { area_type, setAreaType } = props
  // const { area_type } = props
  const [bunyang_detail, setBunyangDetail] = useRecoilState(bunyang_detail_atom)  
  const { area_type } = bunyang_detail

  const onChangeDealKind = () => {
    // setAreaType(area_type === 'PY' ? 'SQ' : 'PY')
    setBunyangDetail({
      ...bunyang_detail,
      area_type: area_type === 'PY' ? 'SQ' : 'PY',
    })    
  }

  const name = useMemo(() => {
    return encNumber(random(10, 9999).toString())
  }, [])

  const id = useMemo(() => {
    return random(10000, 1000000)
  }, [])

  const left_id = encNumber((id + 501).toString())

  const right_id = encNumber((id + 701).toString())

  return (
    <div className={`unit_toggle filter_pop2`}>
      <input
        className='_left'
        type='checkbox'
        id={left_id}
        name={name}
        onChange={onChangeDealKind}
        checked={area_type === 'PY'}
      />
      <label htmlFor={left_id}>평</label>
      <input
        className='_right'
        type='checkbox'
        id={right_id}
        name={name}
        onChange={onChangeDealKind}
        checked={area_type === 'SQ'}
      />
      <label htmlFor={right_id}>㎡</label>
      <div className='handle'>{area_type === 'PY' ? '평' : '㎡'}</div>
    </div>
  )
}

export default AreaTypeToggle
