import Axios, { AxiosRequestConfig, CancelToken } from 'axios'
import accountManager from '../../shared/account-manager'
import {
  FORM_HEADER,
  GET,
  JSON_HEADER,
  MYPAGE_URL,
  POST,
} from '../../shared/api-constant'
import { formGenerator, objectToHeaderParameter } from '../../shared/function'
import AskWriteRequestModel from './model/ask-write-request-model'
import GetFavoriteListRequestModel from './model/get-favorite-list-request-model'
import GetLatestListRequestModel from './model/get-latest-list-request-model'

const mypage = {
  getAskList: (current_page: number) => {
    const account_idx = accountManager.getIdx()

    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/ask_list.php?current_page=${current_page}&account_idx=${account_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getAskDetail: (current_detail_idx: number) => {
    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/ask_detail.php?idx=${current_detail_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  askWrite: (request: AskWriteRequestModel) => {
    const account_idx = accountManager.getIdx()!.toString()

    const fd = formGenerator(request)

    fd.append('account_idx', account_idx)

    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/ask_write.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: fd,
    }

    return Axios(config)
  },

  getFavoriteList: (
    request: GetFavoriteListRequestModel,
    cancel_token: CancelToken
  ) => {
    const account_idx = accountManager.getIdx()

    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/fave.php?account_idx=${account_idx}&${objectToHeaderParameter(
        request
      )}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getLatestList: (
    request: GetLatestListRequestModel,
    cancel_token: CancelToken
  ) => {
    const account_idx = accountManager.getIdx()

    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/latest.php?account_idx=${account_idx}&${objectToHeaderParameter(
        request
      )}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getAlarmList: (current_page: number) => {
    const account_idx = accountManager.getIdx()
    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/alarm.php?current_page=${current_page}&account_idx=${account_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  AlarmRead: (idx: number) => {
    const fd = formGenerator({})
    fd.append('idx', idx.toString())

    const config: AxiosRequestConfig = {
      url: `${MYPAGE_URL}/alarm_read.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: fd,
    }
    return Axios(config)
  },
}

export default mypage
