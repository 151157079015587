import Axios, { AxiosRequestConfig } from 'axios'
import { BOARD_URL, GET, JSON_HEADER } from '../../shared/api-constant'
import { objectToHeaderParameter } from '../../shared/function'
import { BoardCategoryType, TermsType } from '../../shared/types'
import GetContentsRequestModel from './model/get-contents-request-model'

const board = {
  getTerms: (type: TermsType) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/terms.php?type=${type}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getCategorys: (type: BoardCategoryType) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/category_list.php?type=${type}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getContents: (request: GetContentsRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/contents_list.php?${objectToHeaderParameter(request)}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getNoticeList: (current_page: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/notice_list.php?current_page=${current_page}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getNoticeDetail: (detail_idx: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/notice_detail.php?idx=${detail_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getEventList: (current_page: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/event_list.php?current_page=${current_page}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getEventDetail: (detail_idx: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/event_detail.php?idx=${detail_idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getGuideList: (page_size: number, current_page: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/guide_list.php?page_size=${page_size}&current_page=${current_page}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getCheckNormalList: () => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/check_normal_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getRankingNewsList: () => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/ranking_news_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getNewsDetailContent: (idx: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/news_data.php?idx=${idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getGuideDetail: (idx: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/guide_detail.php?idx=${idx}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getNormalList: (category_idx: number, current_page: number) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/normal.php?category_idx=${category_idx}&current_page=${current_page}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getNormalDetail: (idx: number, type: string) => {
    const config: AxiosRequestConfig = {
      url: `${BOARD_URL}/normal_detail.php?idx=${idx}&type=${type}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },
}

export default board
