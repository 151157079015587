import '../../style/components/tab/detail-tab.scss'
import { useEffect, useState } from 'react'
import { qs } from '../../shared/function'

type ScrollDetailTabTypes = {
  data?: { name: string }[]
  className?: string
}
function ScrollDetailTab(props: ScrollDetailTabTypes) {
  const [activeIndex, setActiveIndex] = useState(0)

  // 각 메뉴 영역 별 ,시작되는 위치를 담은 배열
  // const [tab_position, setTabPosition] = useState<Array<number>>([])

  const onClickTab = (num: number) => {
    const building = document.getElementById('building_infos')
    let target = document.getElementById(`location--${num}`)

    let scrollHeight = target?.offsetTop ?? 0
    if (building && num === 2 && building.innerHTML.indexOf('건물정보') >= 0) {
      scrollHeight = building?.offsetTop ?? 0
      if (scrollHeight > 0) {
        scrollHeight -= 50
      }
    }

    window.scrollTo({ left: 0, top: scrollHeight - 140, behavior: 'smooth' })
  }

  const onScroll = () => {
    const s = window.scrollY // 현재 스크롤 위치
  
    const building = document.getElementById('building_infos')
    let dataNoBuilding  = props.data ?? []
    if (props.data && building && props.data[2].name !== '건물정보') { 
      dataNoBuilding.splice(2, 0, {name: '건물정보'})
    }
    let els = (dataNoBuilding ?? []).map((_, index) => {
      if (index === 2 && building) {
        return document.querySelector('article#building_infos') ?? null
      } else { 
        return qs(`#location--${index}`)
      }
    })
    // let buildingHeight = building?.offsetTop ?? 0 
    let index = els.length - 1
    
    for (const el of els.reverse()) {
      if (el) {
        const current_top = el.getBoundingClientRect().top + s - 191

        if (current_top < s) {
          setActiveIndex(index)
          return
        }
      }
      index -= 1
    }
  }

  useEffect(() => {
    // onScroll()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line
  }, [])

  // const getPosition = () => {
  //   props.data &&
  //     props.data.map((item, index) => {
  //       const target = document.getElementById(`location--${index}`)
  //       const scrollHeight = target?.offsetTop ?? 0
  //       setTabPosition((item) => [...item, scrollHeight - 140])
  //     })
  // }

  // useEffect(() => {
  //   getPosition()
  // }, [])

  // useEffect(() => {
  //   tab_position.forEach((item, index) => {
  //     if (window.scrollY >= item) {
  //       setActiveIndex(index)
  //     }
  //   })
  // }, [window.scrollY])

  return (
    <div className={`detail_tab ${props.className}`}>
      <ul>
        <>
          {props.data &&
            props.data.map((item, index) => {
              return (
                <li
                  key={item.name}
                  onClick={(e) => onClickTab(index)}
                  className={activeIndex === index ? 'on' : ''}
                >
                  {item.name}
                </li>
              )
            })}
        </>
      </ul>
    </div>
  )
}

export default ScrollDetailTab
