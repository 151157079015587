import { NaverNewsModel } from '../../selector/get-news-selector'
import { encNumber } from '../../shared/function'

const util = {
  bTagReplace: (e: string) => {
    return e.replace(/<\/b>/gi, '').replace(/<b>/gi, '')
  },
}

interface Props extends NaverNewsModel {
  index: number
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const NewsCard = (props: Props) => {
  const onClickItem = () => {
    window.open(props.originallink)
  }

  return (
    <li
      onClick={onClickItem}
      id={`nnews_${encNumber((props.index + 500) * 2021 + '')}`}
    >
      <div className='right_desc'>
        <h3
          className='fs-l fw500 c-main'
          dangerouslySetInnerHTML={{ __html: util.bTagReplace(props.title) }}
        />
        <p
          className='card_content fs-s'
          dangerouslySetInnerHTML={{
            __html: util.bTagReplace(props.description),
          }}
        />
      </div>
      <p className='card_date fs-s c-date'>{props.pub_ymd}</p>
    </li>
  )
}

export default NewsCard
