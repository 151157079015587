import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'

interface Props {
  children: React.ReactNode
}

function OnlyPc({ children }: Props) {
  const media = useRecoilValue(media_atom)

  if (media !== 'P') return <></>

  return <>{children}</>
}

export default OnlyPc
