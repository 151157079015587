import { useRef, useState, Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import { getQuery, lotReverse } from '../../shared/function'
import get_sandan_detail_selector from '../../selector/get-sandan-detail-selector'
import sandan_map_atom from '../../atom/sandan-map'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Lot = () => {
  const map_ref = useRef<any>(null)

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

	// lt_c_damyoj : 단지시설용지
	// lt_c_damyod : 단지용도지역
	// lt_c_damyuch : 단지유치업종
  const [type ,setType] = useState<'lt_c_damyoj'|'lt_c_damyod'|'lt_c_damyuch'>('lt_c_damyoj');
  const sandanBounds = useRecoilValue(sandan_map_atom)

  if(sandanBounds === undefined) return <></>

  return (
    <article>
      <div className='f-r f-a-c f-j-sb top new_top'>
        <h2>필지 정보</h2>
        <div className='rel lot-guide-wrap'>
          <button onClick={() => setType('lt_c_damyoj')} className='mini_gray_line_btn'>
            용도지역
          </button>
          <button onClick={() => setType('lt_c_damyod')} className='mini_gray_line_btn'>
            시설용지
          </button>
          <button onClick={() => setType('lt_c_damyuch')} className='mini_gray_line_btn'>
            유치업종
          </button>
        </div>
      </div>
      <div className='img_area rel' style={{ width: '100%', maxWidth: 'unset' }}>
        <img src={`http://saenalyun.cafe24.com/backend/app/api/sandan/get_image.php?type=${type}&ymin=${sandanBounds.ymin}&xmin=${sandanBounds.xmin}&ymax=${sandanBounds.ymax}&xmax=${sandanBounds.xmax}`} alt="필지정보"/>
      </div>
      {/* <div className='img_area rel' style={{ width: '100%', maxWidth: 'unset' }}>
        <img src="http://saenalyun.cafe24.com/backend/app/api/sandan/get_image.php" alt="시설용지"/>
      </div>
      <div className='img_area rel' style={{ width: '100%', maxWidth: 'unset' }}>
        <img src="http://saenalyun.cafe24.com/backend/app/api/sandan/get_image.php" alt="유치업종"/>
      </div> */}
    </article>
  )
}

export default Lot
