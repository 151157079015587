import { ElasticTypeExtension } from './types'

export const PATH = {
  LOGIN: '/login',
  JOIN: '/join',
  FIND: '/join/find',
  MAIN: '/',
  MAP: '/map',
  MYPAGE: '/mypage',
  REGISTER: '/register/register_detail',
  ESTATE: '/register',
  THEME_BUNYANG: '/sub-division/theme-detail',
  SEARCH: '/search',
}

export const INQUIRY_METADATA_TYPES = [
  {
    name: '앱개선',
    value: '28',
  },
  {
    name: '매물 등록',
    value: '29',
  },
  {
    name: '광고 등록',
    value: '30',
  },
  {
    name: '공짱맨 문의',
    value: '127',
  },
  {
    name: '기타',
    value: '31',
  },
]

export const SIGHT = {
  SIDO: {
    max: 12,
    min: 11,
  },
  CITIES: {
    max: 10,
    min: 7,
  },
  GU: {
    max: 8,
    min: 7,
  },
  ICONS: {
    max: 6,
    min: 1,
  },
}

interface EstateTypesModel {
  name: string
  type: ElasticTypeExtension
  value: number
}

export const ESTATE_TYPES: Array<EstateTypesModel> = [
  { name: '전체', type: '0', value: 0 },
  { name: '공장', type: 'FT', value: 1 },
  { name: '창고', type: 'WH', value: 2 },
  { name: '토지', type: 'LD', value: 3 },
  { name: '지식산업센터', type: 'KC', value: 4 },
  { name: '산업단지', type: 'IC', value: 5 },
  { name: '물류단지', type: 'LC', value: 6 },
]

export const ESTATE_STATUS = [
  { name: '전체', value: '' },
  { name: '승인대기', value: 'R' },
  { name: '작성중', value: 'W' },
  { name: '광고중', value: 'I' },
  { name: '승인반려', value: 'T' },
  { name: '거래완료', value: 'C' },
]

export const ESTATE_IMAGE_SIZE = 5

export const ESTATE_IMAGE_SIZE_GUIDE_TEXT = `사진은 최대 ${ESTATE_IMAGE_SIZE}MB까지 등록 가능합니다.`
