import { Suspense } from "react"
import Top from "./top"
import PcTopSkeleton from "./pc-top-skeleton"
import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import MobileTopSkeleton from "./mobile-top-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const TopWrapper = () => {

  const media = useRecoilValue(media_atom)

  return (
    <Suspense fallback={media === "P" || media === "T" ? <PcTopSkeleton /> : <MobileTopSkeleton /> }>
      <Top />
    </Suspense>
  )
}

export default TopWrapper