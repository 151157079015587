import BottomWrapper from "./bottom-wrapper"
import BunyangMobileGnbWrapper from "./bunyang-mobile-gnb-wrapper"
import DetailWrapper from "./detail-wrapper"
import LeftWrapper from "./left-wrapper"
import LotWrapper from "./lot-wrapper"
import MiddleWrapper from "./middle-wrapper"
import MobileBottomCardWrapper from "./mobile-bottom-card-wrapper"
import NearbyWrapper from "./nearby-wrapper"
import NoticeWrapper from "./notice-wrapper"
import PyeongWrapper from "./pyeong-wrapper"
import RightWrapper from "./right-wrapper"
import TableWrapper from "./table-wrapper"
import TopWrapper from "./top-wrapper"
import NewsWrapper from "../rt/news-wrapper"
import PriceInfoWrapper from "../detail/price-info"

const Bunyang = {
  LeftWrapper ,
  RightWrapper ,
  MobileBottomCardWrapper,
  TopWrapper,
  MiddleWrapper,
  BottomWrapper,
  TableWrapper,
  NoticeWrapper,
  NearbyWrapper,
  PyeongWrapper,
  LotWrapper,
  DetailWrapper,
  BunyangMobileGnbWrapper,
  PriceInfoWrapper,
  NewsWrapper,
}

export default Bunyang