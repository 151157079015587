import React from 'react'

import image from '../../static/img/icons/map-side-nodata.svg'

interface Props {
  writing: string
}

const Nodata = (props: Props) => {
  return React.createElement(
    'div',
    { className: 'by-nodata' },
    React.createElement('img', { src: image, alt: '노데이터 아이콘' }),
    props.writing
  )
}

export default Nodata
