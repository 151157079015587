interface Props {
  map_ref : React.MutableRefObject<any>
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LotControll = (props : Props) => {

  const { map_ref } = props

  const onClickButton = (target : number) => {
    if ( map_ref.current ) {
      const level = map_ref.current.getLevel()

      map_ref.current.setLevel(level + target)
    }
  }

  return (
    <div className="lot-controll">
      <button onClick={() => onClickButton(-1)}>
        <div></div>
        <div></div>
      </button>

      <span></span>
      
      <button onClick={() => onClickButton(+1)}>
        <div></div>
      </button>
    </div>
  )
}

export default LotControll