import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import check from '../../static/img/icons/terms-check.svg'

/* eslint-disable @typescript-eslint/no-unused-vars */
const CertifyButton = () => {

  const [ map_filter , setMapFilter ] = useRecoilState(map_filter_atom)

  const { certify_yn } = map_filter

  const onChangeCertifyYn = () => {
    setMapFilter({
      ...map_filter ,
      certify_yn : certify_yn === "Y" ? "N" : "Y"
    })
  }

  return (
    <div className='liability_wrap'>
      <div className='liability_check'>
        <input 
          type='checkbox'
          id='see_check'
          name='see_check'
          checked={certify_yn === "Y"}
          onChange={onChangeCertifyYn}
        />
        <label htmlFor='see_check'>
          <img src={check} alt='공짱 책임 매물 체크 아이콘' />
        </label>
      </div>
      <label className='h3' htmlFor='see_check'>공짱 책임 매물</label>
    </div>
  )
}

export default CertifyButton