import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery, lotReverse } from '../../shared/function'
import View3D from '../detail/view-3d'

/* eslint-disable @typescript-eslint/no-unused-vars */
const View3dArea = () => {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { lot } = data

  if (lot.length === 0) return <></>

  const _lot = lotReverse(lot)

  return (
    <article>
      <h2>3D 가설계</h2>
      <View3D vol2 lot={_lot} is_IC={data.detail.type === "IC"}/>
    </article>
  )
}

export default View3dArea
