import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import search_atom from '../../atom/search-atom'
import search_focus_atom from '../../atom/search-focus-atom'

import API from '../../service/api'
import AddressToCoordResponseModel from '../../service/kakao/model/address-to-coord-response-model'
import { GetSigungudongResponseListModel } from '../../service/search/model/get-sigungudong-response-model'
import { PATH } from '../../shared/constant'

function SearchListItemForJR(props: GetSigungudongResponseListModel) {
  const { search } = useLocation()

  const { replace } = useHistory()

  const setSearch = useSetRecoilState(search_atom)
  const setFocused = useSetRecoilState(search_focus_atom)

  function onClick(content: string) {
    const address = content.replace("<b class='on'>", '').replace('</b>', '')

    API.kakao
      .addressToCoord(address)
      .then((res) => {
        addressToCoordSuccessCallback(res.data, address)
      })
      .catch(addressToCoordFailCallback)
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel, address: string) {

    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]

      setSearch(address)
      setFocused(false)
      replace(`${PATH.MAP}${search}#LatLng=${y}^${x}`)
    } else {
      let geocoder = new window.kakao.maps.services.Geocoder();

      let tempAddressArr = address.split(' ')
      let reSearchAddress = tempAddressArr.length > 2 ? (tempAddressArr[0]  + ' ' + tempAddressArr[1] + ' ' + tempAddressArr[2])  : ''

      if(reSearchAddress){
        // 주소로 좌표를 검색합니다
        geocoder.addressSearch(reSearchAddress, function(result: any, status: any) {
            // 정상적으로 검색이 완료됐으면 
            if (status === 'OK') {
                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                setSearch(address)
                setFocused(false)
                replace(`${PATH.MAP}${search}#LatLng=${result[0].y}^${result[0].x}`)
                return;
            }else{
              alert('일치하는 주소가 없습니다.\n주소를 다시 확인해주세요')
            }
        });    
      }else{
        alert('일치하는 주소가 없습니다.\n주소를 다시 확인해주세요')
      }
     

      throw new Error()
    }
  }

  function addressToCoordFailCallback() { }

  return React.createElement(
    'li',
    { className: 'f-c' },
    props.road.contents ?
      React.createElement(
        'div',
        {},
        React.createElement('span', {}, '도로명'),
        React.createElement('p', {
          dangerouslySetInnerHTML: { __html: props.road.contents },
          onClick: () => onClick(props.road.contents),
        })
      )
      : undefined
    ,
    React.createElement(
      'div',
      {},
      React.createElement('span', {}, '지번'),
      React.createElement('p', {
        dangerouslySetInnerHTML: { __html: props.jibun.contents },
        onClick: () => onClick(props.jibun.contents),
      })
    )
  )
}

export default SearchListItemForJR
