import React, {useEffect, useState} from 'react'
import '../../style/components/popup/register-popup.scss'
import Popup from './popup'
import { comma } from '../../shared/function'

type monthlyPaymentModel = {
  principalRepayment : number // 납입원금
  interest: number //대출이자
  payment: number //월상환금
  balance: number //대출잔금
}

type PopupTypes = {
  title?: string
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
  loan_price: number,
  repayment_period: number,
  lowest_rate: number
}
export default function LoanMonthlyPaymentPopup(props: PopupTypes) {

  const {loan_price, repayment_period, lowest_rate} = props

  const onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }

  const [monthlyPayment, setMonthlyPayment] = useState<Array<monthlyPaymentModel>>([])

  useEffect(()=>{
    getMonthlyPayment()
  },[])

  const getMonthlyPayment = () => {
    const A = loan_price
    const b = (lowest_rate * 0.01) / 12
    const n = repayment_period * 12

    if(isNaN(A)) {
      setMonthlyPayment([])
      return 0
    }
    
    const principal =(A * 10000) / n //원금
    let balance  = A * 10000 //잔액

    let _monthlyPayment = []

    for (let i = 0; i < n; i++) {
      const _balance = balance
      balance = balance - principal

      _monthlyPayment.push({
        principalRepayment : principal,
        interest: _balance * b,
        payment: principal + _balance * b,
        balance: balance
      })
    }
    setMonthlyPayment(_monthlyPayment)
  }

  return (
    <Popup title='월별 상환내역' onClickClose={(e) => onClickClose(e)}>
      <div className='monthly_payment_popup'>
        <table>
          <thead>
            <tr>
              <th>회차</th>
              <th>납입원금</th>
              <th>대출이자</th>
              <th>월상환금</th>
              <th>대출잔금</th>
            </tr>
          </thead>
          <tbody>
            {monthlyPayment.map((data, index)=>
              <tr>
                <td>{index + 1}회</td>
                <td>{comma(Math.round(data.principalRepayment))}</td>
                <td>{comma(Math.round(data.interest))}</td>
                <td className='payment'>{comma(Math.round(data.payment))}</td>
                <td>{comma(Math.round(data.balance))}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}
