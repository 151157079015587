import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import Skeleton from '../skeleton'

/* eslint-disable @typescript-eslint/no-unused-vars */
const BottomSkeleton = () => {
  const media = useRecoilValue(media_atom)

  return (
    <div className='bottom'>
      <ul className='hashtag'>
        <li>
          <Skeleton width={32} height={'100%'} radius={2} />
        </li>
        <li>
          <Skeleton width={71} height={'100%'} radius={2} />
        </li>
        <li>
          <Skeleton width={40} height={'100%'} radius={2} />
        </li>
        <li>
          <Skeleton width={50} height={'100%'} radius={2} />
        </li>
        <li>
          <Skeleton width={34} height={'100%'} radius={2} />
        </li>
      </ul>
      {media === 'P' ||
        (media === 'T' && (
          <div className='item_info'>
            <Skeleton width={150} height={28} radius={2} marginRight={17} />
            <Skeleton width={126} height={28} radius={2} />
          </div>
        ))}
    </div>
  )
}

export default BottomSkeleton
