import { Suspense } from "react"
import Detail from "./detail"
import DetailSkeleton from "./detail-skeleton"

interface Props {
  type: 'explain' | 'explain2' | 'explain3'
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const DetailWrapper = ({type}: Props) => {
  return (
    <Suspense fallback={<DetailSkeleton />}>
      {/* #179. 분양상세페이지 수정 (상세설명2 추가) */}
      <Detail type={type}/>
    </Suspense>
  )
}

export default DetailWrapper