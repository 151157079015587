import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import API from '../../service/api'
import AddressToCoordResponseModel from '../../service/kakao/model/address-to-coord-response-model'
import { encNumber, getGeoLocation, getQuery, getQueryValues } from '../../shared/function'
import ErrorBoundary from '../layout/error-boundary'
import RoadView from './roadview'
import MapControll from './map-controll'
import MapLoading from './map-loading'
import MapMarkers from './map-markers'
import useComponentWillUnmount from '../../hooks/use-component-will-unmount'
import MapRecorder from './map-recorder'
import MapLocationCluster from './map-location-cluster'
import MapCluster from './map-cluster'
import MapClickControll from './map-click-controll'
import media_atom from '../../atom/media-atom'
import CurrentPosition from './current-position'

declare global {
  interface Window {
    kakao: any
  }
}

interface Props {
  map_ref: React.MutableRefObject<any>
  currentTypeId_ref: React.MutableRefObject<any>
  map_loaded: boolean
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>
  map_toggle: boolean
  setMapToggle: React.Dispatch<React.SetStateAction<boolean>>
  filtered: boolean
  setFold: React.Dispatch<React.SetStateAction<boolean>>
  removeOverlay: () => void
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const FullMap = (props: Props) => {
  const media = useRecoilValue(media_atom)

  const { map_loaded, setMapLoaded, map_toggle, setFold, removeOverlay } = props

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const { sw_lat, level, lat, lng } = map_filter

  const map_ref = useRef<any>()
  const options_ref = useRef<any>()
  const currentTypeId_ref = useRef<any>()
  const error_boundary = useRef<ErrorBoundary | null>(null)

  const [pano_id, setPanoId] = useState<any>(null)
  const [position, setPosition] = useState<any>(null)

  const _rvMarker = useRef<any>(null)

  // 현재 디바이스의 위치 구하기
  const getCenter = () => {
    getGeoLocation({
      success: (pos) => {
        mapDraw(pos.coords.latitude, pos.coords.longitude)
      },
      fail: () => {
        mapDraw(37.51004261128597, 127.04384291197633)
      },
    })
  }

  // Query Parameter로 전달된 한글 주소 -> lat , lng로 변환
  // 실패 시 window 객체의 geolocation 사용
  const getLocationFromKor = () => {
    API.kakao
      .addressToCoord(getQuery().search_kor)
      .then((res) => {
        const data = res.data as AddressToCoordResponseModel

        if (data.meta.total_count > 0) {
          mapDraw(
            parseFloat(data.documents[0].y),
            parseFloat(data.documents[0].x),
            true
          )
        } else {
          throw new Error()
        }
      })
      .catch(getCenter)
  }

  // Query Parameter로 전달된 record 내 lat , lng로 위치 찾기
  const getLocationFromRecord = () => {
    try {
      const query = getQueryValues()

      const lat = Number(query[10])
      const lng = Number(query[12])

      if (!lat || !lng) throw new Error()
      mapDraw(lat, lng, true)
    } catch {
      getCenter()
    }
  }

  // 지도 그리기
  const mapDraw = (lat: number, lng: number, search?: boolean) => {
    const container = document.getElementById('map_api')

    let customLevel: number | undefined = Number(getQuery().level ?? '')
    const showPosition = getQuery().sp === '1' ? true : false
    let showLat: number | undefined = Number(getQuery().splat)
    let showLng: number | undefined = Number(getQuery().splng)

    showLat = !isNaN(showLat) ? showLat : undefined
    showLng = !isNaN(showLng) ? showLng : undefined

    if (customLevel > 12) customLevel = 12
    if (isNaN(customLevel)) customLevel = undefined

    const options = {
      center: new window.window.kakao.maps.LatLng(lat, lng),
      level: customLevel ? customLevel : level,
    }

    const map = new window.kakao.maps.Map(container, options)

    const position = media === 'M' ? window.kakao.maps.CopyrightPosition.BOTTOMLEFT : window.kakao.maps.CopyrightPosition.BOTTOMRIGHT

    map.setCopyrightPosition(position, true);

    let currentTypeId

    map_ref.current = map
    options_ref.current = options
    currentTypeId_ref.current = currentTypeId

    props.map_ref.current = map_ref.current
    props.currentTypeId_ref.current = currentTypeId_ref.current
    map.setMinLevel(1)
    map.setMaxLevel(12)

    if (showPosition) {
      setMapFilter((_) => ({
        ..._,
        realdeal_yn: 'Y',
        search: search ? { lat, lng } : undefined,
      }))
      setTimeout(() => {
        if (showLat || showLng) {
          saleLocationTwinkling(showLat ?? lat, showLng ?? lng, undefined, true)
          saleLocationTwinkling(lat, lng, "black", true)
        } else {
          saleLocationTwinkling(showLat ?? lat, showLng ?? lng, undefined, true)
        }
      }, 1000)
    } else {
      setMapFilter((_) => ({
        ..._,
        search: search ? { lat, lng } : undefined,
      }))
    }

    updateLevelAndPosition()

    window.kakao.maps.event.addListener(map, 'zoom_changed', mapEvent)

    window.kakao.maps.event.addListener(map, 'tilesloaded', mapEvent)

    window.kakao.maps.event.addListener(map, 'dragstart', removeOverlayWrap)

    window.kakao.maps.event.addListener(map, 'mousedown', (e: any) => {
      const overlayDom = document.querySelector('.overlay_wrap')
      if (overlayDom) {
        document.querySelector('body')?.addEventListener('click', (e: any) => {
          if (!e.target || e.target.className !== 'overlay_wrap') {
            removeOverlay()
          }
        })
      }
    })

    setMapLoaded(true)
  }

  const mapEvent = () => {
    updateLevelAndPosition()
  }

  const removeOverlayWrap = () => {
    map_ref.current.setZoomable(true)
    //같은 주소 매매 리스트 노출 되어 있을 경우 삭제
    removeOverlay()
  }

  // 지도 움직일 때 마다 위치 및 지도 레벨 변경
  const updateLevelAndPosition = () => {
    if (map_ref.current) {

      setTimeout(() => {
        const map = map_ref.current

        const level = map.getLevel()
        const center = map.getCenter()

        const lat = center.getLat()
        const lng = center.getLng()

        const bounds = map.getBounds()
        const ne = Object.values(
          bounds.getNorthEast() as { [key: string]: number }
        ).sort((a, b) => (a > b ? 1 : -1))

        const sw = Object.values(
          bounds.getSouthWest() as { [key: string]: number }
        ).sort((a, b) => (a > b ? 1 : -1))

        const [ne_lat, ne_lng] = ne
        const [sw_lat, sw_lng] = sw

        setMapFilter((_) => ({
          ..._,
          level,
          lat,
          lng,
          ne_lat,
          ne_lng,
          sw_lat,
          sw_lng,
        }))

        error_boundary.current?.reset()
      }, 300)
    }
  }

  //로드뷰 toggle함수 (마커 움직일 때 마다 작동)
  const roadViewCall = (position: any) => {
    const rvClient = new window.kakao.maps.RoadviewClient() //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

    setPosition(position)

    //전달받은 좌표(position)에 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄웁니다
    rvClient.getNearestPanoId(position, 50, function (panoId: any) {
      setPanoId(panoId)
    })
  }

  const onDragEnd = () => {
    var position = _rvMarker.current.getPosition() //현재 마커가 놓인 자리의 좌표
    roadViewCall(position) //로드뷰를 토글합니다
  }

  const onClick = useCallback((mouseEvent: any) => {
    // 현재 클릭한 부분의 좌표를 리턴
    var position = mouseEvent.latLng
    _rvMarker.current.setPosition(position)
    roadViewCall(position) //로드뷰를 토글합니다
  }, [])

  const saleLocationTwinkling = (lat: number, lng: number, className?: string, isfixed?: boolean) => {
    const position = new window.kakao.maps.LatLng(lat, lng)
    const circle = new window.kakao.maps.CustomOverlay({
      position,
      content: `<div class="sale-location-circle ${className}" />`,
      zIndex: 10,
    })

    circle.setMap(map_ref.current)

    setTimeout(() => {
      circle.setMap(null)

      if (isfixed) {
        const circle = new window.kakao.maps.CustomOverlay({
          position,
          content: `<div class="sale-location-circle ${className} fixed" />`,
          zIndex: 10,
        })

        circle.setMap(map_ref.current)
      }
    }, 3000)
  }

  const markerRendering = () => {
    const map = map_ref.current

    // 마커 이미지를 생성합니다.
    var markImage = new window.kakao.maps.MarkerImage(
      'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
      new window.kakao.maps.Size(26, 46),
      {
        // 스프라이트 이미지를 사용합니다.
        // 스프라이트 이미지 전체의 크기를 지정하고
        spriteSize: new window.kakao.maps.Size(1666, 168),
        // 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
        // background-position으로 지정하는 값이며 부호는 반대입니다.
        spriteOrigin: new window.kakao.maps.Point(705, 114),
        offset: new window.kakao.maps.Point(13, 46),
      }
    )

    _rvMarker.current = new window.kakao.maps.Marker({
      image: markImage,
      position: map.getCenter(),
      draggable: true,
      map: map,
    })

    //마커에 dragend 이벤트를 할당합니다
    window.kakao.maps.event.addListener(_rvMarker.current, 'dragend', onDragEnd)

    //지도에 클릭 이벤트를 할당합니다
    window.kakao.maps.event.addListener(map, 'click', onClick)
  }

  const markerRemove = () => {
    if (_rvMarker.current && map_ref.current) {
      const map = map_ref.current

      //마커에 dragend 이벤트를 제거합니다
      window.kakao.maps.event.removeListener(
        _rvMarker.current,
        'dragend',
        onDragEnd
      )

      //지도에 클릭 이벤트를 제거합니다
      window.kakao.maps.event.removeListener(map, 'click', onClick)

      _rvMarker.current.setMap(null)
      _rvMarker.current = null
    }
  }

  const mapEventRemove = () => {
    if (map_ref.current) {
      window.kakao.maps.event.removeListener(
        map_ref.current,
        'dragend',
        'dragstart',
        mapEvent,
        removeOverlayWrap
      )
    }
  }

  const brokerIdxInit = () => {
    setMapFilter((_) => ({
      ..._,
      broker_idx: 0,
    }))
  }

  useEffect(() => {
    if (getQuery()[encNumber("0")]) getLocationFromRecord()
    else if (getQuery().search_kor) getLocationFromKor()
    else if (lat && lng) mapDraw(lat, lng)
    else getCenter()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (map_toggle) {
      markerRendering()
    } else {
      markerRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_toggle])

  useComponentWillUnmount(mapEventRemove)
  useComponentWillUnmount(brokerIdxInit)

  const rendering_preparation = sw_lat !== 0 && map_loaded && props.filtered

  return (
    <div id='map_container'>
      <CurrentPosition />
      <div id='map_api' className={media === 'P' ? 'pc_map' : ''} />
      {rendering_preparation && (
        <ErrorBoundary ref={error_boundary} fallback={<MapLoading />}>
          <Suspense fallback={<MapLoading />}>
            <MapMarkers map_ref={map_ref} setFold={setFold} removeOverlay={removeOverlay} />
            <MapCluster map_ref={map_ref} />
            <MapLocationCluster map_ref={map_ref} />
            <MapClickControll map_ref={map_ref} />
          </Suspense>
        </ErrorBoundary>
      )}
      <MapControll map_ref={map_ref} />
      <RoadView
        panoId={pano_id}
        position={position}
        map={map_ref.current}
        setPanoId={setPanoId}
        setPosition={setPosition}
      />
      {props.filtered && <MapRecorder />}
    </div>
  )
}

export default FullMap
