import axios, { CancelTokenSource } from 'axios'
import { useEffect, useRef, useState } from 'react'
import API from '../../service/api'
import GetSigungudongResponseModel, {
  GetSigungudongDataModel,
  GetSigungudongResponseListModel,
} from '../../service/search/model/get-sigungudong-response-model'
import { apiCallback, blankFn } from '../../shared/function'
import SearchResultItem from './search-result-item'
import SearchResultItemForJr from './search-list-item-for-jr'

interface Props {
  search: string
}

const SearchResultArea = (props: Props) => {
  const { search } = props

  const cancel_token = useRef<CancelTokenSource | null>(null)

  const [address, setAddress] = useState<
    Array<GetSigungudongResponseListModel>
  >([])

  const [sigungudong, setSigungudong] = useState<
    Array<GetSigungudongDataModel>
  >([])

  const getSigungudong = async () => {
    if (cancel_token.current) API.search.cancelSigungudong(cancel_token.current)

    cancel_token.current = axios.CancelToken.source()

    API.search
      .getSigungudong(search, cancel_token.current.token)
      .then((res) => {
        apiCallback(res, {
          success: getSigungudongSuccessCallback,
          fail: blankFn,
        })
      })
      .catch(blankFn)
  }

  const getSigungudongSuccessCallback = (data: GetSigungudongResponseModel) => {
    const { sigungudong, address } = data.list

    setAddress(
      address.map((item) => ({
        jibun: {
          ...item.jibun,
          contents: item.jibun.contents.replace(
            search.trim(),
            `<b class='on'>${search.trim()}</b>`
          ),
        },
        road: {
          ...item.road,
          contents: item.road.contents.replace(
            search.trim(),
            `<b class='on'>${search.trim()}</b>`
          ),
        },
      }))
    )
    setSigungudong(
      sigungudong.map((item) => ({
        ...item,
        contents: item.contents.replace(
          search.trim(),
          `<b class='on'>${search.trim()}</b>`
        ),
      }))
    )
  }

  useEffect(() => {
    if (search.length >= 2) getSigungudong()
    else {
      setAddress([])
      setSigungudong([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div className='search-result-wrapper'>
      {search.length < 2 && <p>찾으시는 매물의 위치를 검색해주세요.</p>}
      {search.length >= 2 && address.length + sigungudong.length === 0 && (
        <p>검색결과가 없습니다.</p>
      )}
      {address.length + sigungudong.length > 0 && (
        <ul>
          {sigungudong.map((i, index) => {
            return <SearchResultItem key={index} contents={i.contents} />
          })}
          {address.map((i, index) => {
            return (
              <SearchResultItemForJr
                key={index}
                jibun={i.jibun}
                road={i.road}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default SearchResultArea
