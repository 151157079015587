import { useRecoilValue } from 'recoil'
import { AreaType } from '../../atom/map-filter'
import media_atom from '../../atom/media-atom'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import { tableData, officialPriceByToggleType, onChangeDateYmType, onChangeToggleTypeUnit, onChangeToggleTypeValue } from '../../shared/function'
import Colgroup from '../layout/colgroup'
import API from '../../service/api'
import { useEffect, useState } from 'react'
import Skeleton from '../skeleton'

interface Props {
  // detail: GetDetailResponseModel['detail']
  area_type: AreaType
  jibun: string
  setOfficialPrice: React.Dispatch<React.SetStateAction<number>>
  setTotalTotArea: React.Dispatch<React.SetStateAction<number>>
  setJimok: React.Dispatch<React.SetStateAction<string>>
}

interface pnuAreaDataDataModel {
  jijigugb: string
  jimok: string
  jise: string
  official_price: number
  road: string
  shape: string
  stdr_month: string
  stdr_year: string
  total_plat_area: number
  use_situation: string
  use_plan: string
}

function AreaTable({
  // detail,
  area_type,
  jibun,
  setOfficialPrice,
  setTotalTotArea,
  setJimok
}: Props) {
  const media = useRecoilValue(media_atom)

  const toggle_type = area_type === 'PY' ? 'left' : 'right'

  const [data, setData] = useState<pnuAreaDataDataModel>({
    jijigugb: '',
    jimok: '',
    jise: '',
    official_price: 0,
    road: '',
    shape: '',
    stdr_month: '',
    stdr_year: '',
    total_plat_area: 0,
    use_situation: '',
    use_plan: '',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  function getAreaData() {
    API.estate
      .getPnuAreaData(jibun)
      .then((res) => {
        setData(res.data.data)
        setOfficialPrice(res.data.data.official_price)
        setTotalTotArea(res.data.data.total_plat_area)
        setJimok(res.data.data.jimok)
        setIsLoading(true)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (jibun) {
      getAreaData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jibun])

  /**
   * total_plat_area 총 토지 면적
   * jimok
   * use_situation
   * jijigugb
   * road
   * shape
   * jise
   * stdr_year
   * stdr_month
   * official_price
   * use_plan
   * shape
   */

  return (
    <>
      {isLoading ? (
        <article>
          <h2>토지정보</h2>
          {media === 'M' && 'T' ? (
            <>
              <div className='dl_wrap'>
                <dl>
                  <dt>총 토지 면적</dt>
                  <dd>
                    {/* {data?.total_plat_area} */}
                    {onChangeToggleTypeValue(
                      toggle_type,
                      data?.total_plat_area
                    ) || '0'}
                    {onChangeToggleTypeUnit(toggle_type)}
                  </dd>
                </dl>
                <dl>
                  <dt>지목</dt>
                  <dd>{tableData(data.jimok)}</dd>
                </dl>
                <dl>
                  <dt>이용상황</dt>
                  <dd>{tableData(data.use_situation)}</dd>
                </dl>
                <dl>
                  <dt>용도지역·지구</dt>
                  <dd>{tableData(data.jijigugb)}</dd>
                </dl>
                <dl>
                  <dt>도로조건</dt>
                  <dd>{tableData(data.road)}</dd>
                </dl>
                <dl>
                  <dt>형상</dt>
                  <dd>{tableData(data.shape)}</dd>
                </dl>
                <dl>
                  <dt>지세</dt>
                  <dd>{tableData(data.jise)}</dd>
                </dl>
                <dl>
                  <dt>개별공시지가 / 기준년도</dt>
                  <dd>
                    {officialPriceByToggleType(
                      toggle_type,
                      data.official_price
                    ) || '0'}
                    원({onChangeToggleTypeUnit(toggle_type)}) /{' '}
                    {onChangeDateYmType(data.stdr_year, data.stdr_month)}
                    {/* 기준년도 */}
                  </dd>
                </dl>
                <dl>
                  <dt>다른 법령 등에 따른 지역·지구등</dt>
                  <dd>{tableData(data.use_plan)}</dd>
                </dl>
              </div>
            </>
          ) : (
            <>
              <table>
                <Colgroup count={5} />
                <thead>
                  <tr>
                    <th>총 토지 면적</th>
                    <th>지목</th>
                    <th>이용상황</th>
                    <th>용도지역·지구</th>
                    <th>도로조건</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bb-unset'>
                    <td>
                      {onChangeToggleTypeValue(
                        toggle_type,
                        data?.total_plat_area
                      ) || '0'}
                      {onChangeToggleTypeUnit(toggle_type)}
                    </td>
                    <td>{tableData(data.jimok)}</td>
                    <td>{tableData(data.use_situation)}</td>
                    <td>{tableData(data.jijigugb)}</td>
                    <td>{tableData(data.road)}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <Colgroup count={4} />
                <colgroup>
                  {Array(4)
                    .fill(1)
                    .map((_, index) => {
                      return <col width='25%' key={`${index}`} />
                    })}
                </colgroup>
                <thead>
                  <tr>
                    <th>형상</th>
                    <th>지세</th>
                    <th>개별공시지가 / 기준년도</th>
                    <th colSpan={2}>다른 법령 등에 따른 지역·지구등</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tableData(data.shape)}</td>
                    <td>{tableData(data.jise)}</td>
                    <td>
                      {officialPriceByToggleType(
                        toggle_type,
                        data.official_price
                      ) || '0'}
                      원({onChangeToggleTypeUnit(toggle_type)}) /{' '}
                      {onChangeDateYmType(data.stdr_year, data.stdr_month)}
                      {/* 기준년도 */}
                    </td>
                    <td colSpan={2}>{tableData(data.use_plan)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </article>
      ) : (
        <>
          <article>
            <Skeleton width={95.16} height={25} marginBottom={16} radius={4} />
            <Skeleton width={'100%'} height={150} />
          </article>
        </>
      )}
    </>
  )
}

export default AreaTable
