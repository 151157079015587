import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Map from '.'
import map_filter_atom from '../../atom/map-filter'
import map_filter_options_atom from '../../atom/map-filter-options'
import media_atom from '../../atom/media-atom'
import map_filter_is_filtered_seletor from '../../selector/map-filter-is-filtered-seletor'
import { bigNumber, comma } from '../../shared/function'

import filter_default from '../../static/img/icons/filter_default(2).svg'
import filter_on from '../../static/img/icons/filter_on(2).svg'
import MainButton from '../button/main-button'
import DevFilterRange, {
  DevFilterRangeOnChangeEventHandler,
} from '../input/dev-filter-range'

interface Props {
  onClickClose: React.MouseEventHandler<HTMLDivElement>
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const FilterAll = (props: Props) => {
  const [filter, setFilter] = useRecoilState(map_filter_options_atom)
  const { filter_all_pop } = filter
  // const [filter, setFilter] = useState(false)

  const media = useRecoilValue(media_atom)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const is_filtered = useRecoilValue(map_filter_is_filtered_seletor)

  const [price_once_max, setPriceOnceMax] = useState(map_filter.price_once_max)
  const [price_once_min, setPriceOnceMin] = useState(map_filter.price_once_min)
  const [price_total_max, setPriceTotalMax] = useState(
    map_filter.price_total_max
  )
  const [price_total_min, setPriceTotalMin] = useState(
    map_filter.price_total_min
  )

  const [tot_area_max, setTotAreaMax] = useState(map_filter.tot_area_max)
  const [tot_area_min, setTotAreaMin] = useState(map_filter.tot_area_min)
  const [plat_area_max, setPlatAreaMax] = useState(map_filter.plat_area_max)
  const [plat_area_min, setPlatAreaMin] = useState(map_filter.plat_area_min)

  const [area_type, setAreaType] = useState(map_filter.area_type)

  const [trade, setTrade] = useState(map_filter.deal_kind.trade)
  const [rent, setRent] = useState(map_filter.deal_kind.rent)

  const [complete_after, setCompleteAfter] = useState(map_filter.complete_after)

  const onChangePriceTotal: DevFilterRangeOnChangeEventHandler = (e) => {
    setPriceTotalMax(e.max)
    setPriceTotalMin(e.min)
  }

  const onChangePriceOnce: DevFilterRangeOnChangeEventHandler = (e) => {
    setPriceOnceMax(e.max)
    setPriceOnceMin(e.min)
  }

  const onChangeAreaTotal: DevFilterRangeOnChangeEventHandler = (e) => {
    setTotAreaMax(e.max)
    setTotAreaMin(e.min)
  }

  const onChangeAreaPlat: DevFilterRangeOnChangeEventHandler = (e) => {
    setPlatAreaMax(e.max)
    setPlatAreaMin(e.min)
  }

  const onChangeDealKind = (e: 'trade' | 'rent') => {
    const state = e === 'trade' ? trade : rent
    const another_state = e !== 'trade' ? trade : rent

    if (state === 'Y' && another_state === 'N') {
      return alert('최소한 하나의 유형은 선택해야합니다.')
    }

    const setStateAction = e === 'trade' ? setTrade : setRent

    setStateAction((e) => (e === 'Y' ? 'N' : 'Y'))
  }

  const onClickBox: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (media !== 'M') {
      setFilter({
        price_pop: false,
        area_pop: false,
        deal_type_pop: false,
        complete_after_pop: false,
        filter_all_pop: filter_all_pop === true ? false : true,
      })
    } else props.onClickClose(e)
  }

  const onClickReset = () => {
    setPriceTotalMax(100)
    setPriceTotalMin(0)
    setPriceOnceMax(100)
    setPriceOnceMin(0)
    setTotAreaMax(200)
    setTotAreaMin(0)
    setPlatAreaMax(200)
    setPlatAreaMin(0)
    setAreaType('PY')
    setTrade('Y')
    setRent('Y')
    setCompleteAfter('3Y')
  }

  const onClickApply = () => {
    setMapFilter({
      ...map_filter,
      price_total_max,
      price_total_min,
      price_once_max,
      price_once_min,
      tot_area_max,
      tot_area_min,
      plat_area_max,
      plat_area_min,
      area_type,
      price_area_type:area_type,
      deal_kind: {
        ...map_filter.deal_kind,
        trade,
        rent,
      },
      complete_after,
      realdeal_yn: complete_after === 'N' ? 'N' : 'Y',
    })
    setFilter({
      ...filter,
      filter_all_pop: false,
    })
  }

  const area_type_kor = area_type === 'PY' ? '평' : '㎡'

  useEffect(() => {
    setPriceTotalMax(map_filter.price_total_max)
    setPriceTotalMin(map_filter.price_total_min)
    setPriceOnceMax(map_filter.price_once_max)
    setPriceOnceMin(map_filter.price_once_min)
    setTotAreaMax(map_filter.tot_area_max)
    setTotAreaMin(map_filter.tot_area_min)
    setPlatAreaMax(map_filter.plat_area_max)
    setPlatAreaMin(map_filter.plat_area_min)
    setAreaType(map_filter.area_type)
    setTrade(map_filter.deal_kind.trade)
    setRent(map_filter.deal_kind.rent)
    setCompleteAfter(map_filter.complete_after)
  }, [filter_all_pop, map_filter])

  return (
    <div className='filter_all'>
      <div className='filter_btn' onClick={onClickBox}>
        <img
          src={is_filtered ? filter_on : filter_default}
          alt='전체 필터 아이콘'
        />
      </div>
      {filter_all_pop && (
        <div className='filter_detail_wrap'>
          <section>
            <article>
            {/* //[유지보수] 230105 거래단가 필터도 ㎡와 평으로 나눌 수 있도록 버튼 추가 */}
              <div className='subject'>
                <h3>가격대</h3>
                <Map.AreaTypeToggle
                  // area_type={area_type}
                  // setAreaType={setAreaType}
                />
              </div>
              <DevFilterRange
                title='거래 총액'
                min_text='0'
                max_text='999억+'
                min={0}
                max={999}
                min_value={price_total_min}
                max_value={price_total_max}
                formatter={(e) => e + '억'}
                onChange={onChangePriceTotal}
              />
              <DevFilterRange
                title='거래 단가'
                min_text='0'
                max_text='1억+'
                min={0}
                max={100}
                min_value={price_once_min}
                max_value={price_once_max}
                formatter={(e) => bigNumber(e * 1_000_000)}
                onChange={onChangePriceOnce}
              />
            </article>
            <article>
              <div className='subject'>
                <h3>면적</h3>
                <Map.AreaTypeToggle
                  // area_type={area_type}
                  // setAreaType={setAreaType}
                />
              </div>
              <DevFilterRange
                title='토지 면적'
                min_text={`0${area_type_kor}`}
                max_text={`10,000${area_type_kor}+`}
                min={0}
                max={200}
                min_value={plat_area_min}
                max_value={plat_area_max}
                formatter={(e) => comma(e * 50) + area_type_kor}
                onChange={onChangeAreaPlat}
              />

              <DevFilterRange
                title='연 면적'
                min_text={`0${area_type_kor}`}
                max_text={`10,000${area_type_kor}+`}
                min={0}
                max={200}
                min_value={tot_area_min}
                max_value={tot_area_max}
                formatter={(e) => comma(e * 50) + area_type_kor}
                onChange={onChangeAreaTotal}
              />
            </article>
            <article
              className={map_filter.deal_kind.sub_division === 'Y' ? 'h' : ''}
            >
              <h3>매물유형</h3>
              <ul className='filter_checkbox size2'>
                <li>
                  <input
                    type='checkbox'
                    id='_trading_all'
                    name='_sale_types_all'
                    checked={trade === 'Y'}
                    onChange={() => onChangeDealKind('trade')}
                  />
                  <label htmlFor='_trading_all'>매매</label>
                </li>
                <li>
                  <input
                    type='checkbox'
                    id='_rent_all'
                    name='_sale_types_all'
                    checked={rent === 'Y'}
                    onChange={() => onChangeDealKind('rent')}
                  />
                  <label htmlFor='_rent_all'>임대</label>
                </li>
              </ul>
            </article>
            <article>
              <h3>거래완료 여부</h3>
              <ul className='filter_checkbox'>
                <li>
                  <input
                    type='radio'
                    id='_term1'
                    name='_trade_complete'
                    checked={complete_after === '1Y'}
                    onChange={() => setCompleteAfter('1Y')}
                  />
                  <label htmlFor='_term1'>최근 1년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term2'
                    name='_trade_complete'
                    checked={complete_after === '2Y'}
                    onChange={() => setCompleteAfter('2Y')}
                  />
                  <label htmlFor='_term2'>최근 2년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term3'
                    name='_trade_complete'
                    checked={complete_after === '3Y'}
                    onChange={() => setCompleteAfter('3Y')}
                  />
                  <label htmlFor='_term3'>최근 3년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term4'
                    name='_trade_complete'
                    checked={complete_after === '5Y'}
                    onChange={() => setCompleteAfter('5Y')}
                  />
                  <label htmlFor='_term4'>최근 5년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term5'
                    name='_trade_complete'
                    checked={complete_after === 'N'}
                    onChange={() => setCompleteAfter('N')}
                  />
                  <label htmlFor='_term5'>미포함</label>
                </li>
              </ul>
            </article>
          </section>
          <div className='btn_wrap'>
            <button
              type='button'
              className='reset_btn'
              onClick={onClickReset}
            ></button>
            <MainButton
              title='필터 적용'
              size='small'
              types='priority'
              onClick={onClickApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterAll
