/* eslint-disable @typescript-eslint/no-unused-vars */
const MapLoading = () => {

  return (
    <div className="">
      
    </div>
  )
}

export default MapLoading