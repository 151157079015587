import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"

/* eslint-disable @typescript-eslint/no-unused-vars */
const Bottom = () => {

  const media = useRecoilValue(media_atom)
  
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data
  
  return (
    <div className="bottom">
      {/* 230915 고객사 요청으로 인해 주석처리 */}
      {/* <ul className='hashtag'>
        {
          detail.tags_text &&
          detail
            .tags_text
            .split('#')
            .filter(i => i !== "")
            .map((i , index) => {
              return (
                <li key={index}>#{i}</li>
              )
            })
        }
      </ul> */}
      {
        ( media === "P" || media === "T" ) &&
        <div className='item_info'>
          <span>
            분양정보번호 : <strong className='fw500'>{detail.code || "No CODE"}</strong>
          </span>
          <span>
            등록일자 : <strong className='fw500'>{detail.regist_ymd}</strong>
          </span>
        </div>
      }
    </div>
  )
}

export default Bottom