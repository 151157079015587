import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_filter_options_atom from '../../atom/map-filter-options'
import MainButton from '../button/main-button'

/* eslint-disable @typescript-eslint/no-unused-vars */
const DealTypePop = () => {
  const [filter, setFilter] = useRecoilState(map_filter_options_atom)
  const { deal_type_pop } = filter
  // const [filter, setFilter] = useState(false)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const { deal_kind } = map_filter

  const [trade, setTrade] = useState(deal_kind.trade)

  const [rent, setRent] = useState(deal_kind.rent)

  const onChangeDealKind = (e: 'trade' | 'rent') => {
    const state = e === 'trade' ? trade : rent
    const another_state = e !== 'trade' ? trade : rent

    if (state === 'Y' && another_state === 'N') {
      return alert('최소한 하나의 유형은 선택해야합니다.')
    }

    const setStateAction = e === 'trade' ? setTrade : setRent

    setStateAction((e) => (e === 'Y' ? 'N' : 'Y'))
  }

  const onClickFilter = () => {
    setFilter({
      price_pop: false,
      area_pop: false,
      deal_type_pop: deal_type_pop === true ? false : true,
      complete_after_pop: false,
      filter_all_pop: false,
    })
  }

  const onClickReset = () => {
    setTrade('Y')
    setRent('Y')
  }

  const onClickApply = () => {
    setMapFilter({
      ...map_filter,
      deal_kind: {
        ...deal_kind,
        trade,
        rent,
      },
    })
    setFilter({
      ...filter,
      deal_type_pop: false,
    })
  }

  useEffect(() => {
    setTrade(map_filter.deal_kind.trade)
    setRent(map_filter.deal_kind.rent)
  }, [deal_type_pop, map_filter])

  return (
    <div
      className={`filter ${deal_type_pop ? 'on' : ''} ${
        deal_kind.sub_division === 'Y' ? 'h' : ''
      }`}
    >
      <div className='filter_btn' onClick={() => onClickFilter()}>
        <p>유형</p>
        <span className='arrow'></span>
      </div>
      {deal_type_pop && (
        <div className='filter_detail_wrap size2'>
          <section>
            <article>
              <h3>매물 유형</h3>
              <ul className='filter_checkbox size2'>
                <li>
                  <input
                    type='checkbox'
                    id='_trading'
                    name='_sale_types'
                    checked={trade === 'Y'}
                    onChange={() => onChangeDealKind('trade')}
                  />
                  <label htmlFor='_trading'>매매</label>
                </li>
                <li>
                  <input
                    type='checkbox'
                    id='_rent'
                    name='_sale_types'
                    checked={rent === 'Y'}
                    onChange={() => onChangeDealKind('rent')}
                  />
                  <label htmlFor='_rent'>임대</label>
                </li>
              </ul>
            </article>
          </section>
          <div className='btn_wrap'>
            <button
              type='button'
              className='reset_btn'
              onClick={onClickReset}
            ></button>
            <MainButton
              title='필터 적용'
              size='small'
              types='priority'
              onClick={onClickApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DealTypePop
