interface Props {
  count : number
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Colgroup = (props : Props) => {
  return (
    <colgroup>
      {
        Array(props.count).fill(1).map((_,i) => {
          return (
            <col key={i} width={ i === props.count - 1 ? '*' : `${100 / (props.count)}%`} />
          )
        })
      }
    </colgroup>
  )
}

export default Colgroup