import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom'
import arg from '../../static/img/icons/arrow-right-gray.svg'
import logout from '../../static/img/icons/logout.svg'
import '../../style/components/layout/gnb.scss'
import accountManager from '../../shared/account-manager'
import { PATH } from '../../shared/constant'
import { onErrorProfile } from '../../shared/function'
import error_profile from '../../static/img/nodata/default-profile.svg'
import { useRecoilValue } from 'recoil'
import new_alarm_atom from '../../atom/new-alarm'

interface Props {
  is_main?: boolean
}

const Gnb = ({ is_main }: Props) => {
  const activeStyle: React.CSSProperties = {
    color: '#1A519F',
  }

  const router = useLocation()

  const history = useHistory()

  const new_alarm = useRecoilValue(new_alarm_atom)

  const [scrollY, setScrollY] = useState(0)

  const [drop, setDrop] = useState(false)

  const [now, setNow] = useState('')

  const onScroll = () => {
    setScrollY(window.scrollY)
  }

  const onClickLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    accountManager.logout()
    history.push(PATH.LOGIN)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    onScroll()

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  useEffect(() => {
    if (router) {
      setNow(router.pathname + router.search)
    }
  }, [router])

  return (
    <>
      <header
        id='gnb'
        className={`${scrollY > 30 ? 'active' : ''} ${
          is_main ? 'is_main' : ''
        }`}
      >
        <div className='inner'>
          <Link to={PATH.MAIN} className='logo'>
            <div></div>
          </Link>
          <div className='right_nav'>
            <ul className='menu_list'>
              <li>
                <NavLink to='/map' className='nanumB' activeStyle={activeStyle}>
                  지도
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/sub-division'
                  className='nanumB'
                  activeStyle={activeStyle}
                >
                  분양
                </NavLink>
              </li>
              <li>
                <Link
                  to={'/mypage?tab=2'}
                  className={`nanumB`}
                  style={{
                    color: now === '/mypage?tab=2' ? '#1A519F' : '',
                  }}
                >
                  최근 본 매물
                </Link>
              </li>
              <li>
                <NavLink
                  to={accountManager.isLogin() ? PATH.ESTATE : PATH.LOGIN}
                  className='nanumB'
                  activeStyle={
                    accountManager.isLogin() ? activeStyle : undefined
                  }
                >
                  매물등록
                </NavLink>
              </li>
            </ul>
            {!accountManager.isLogin() ? (
              <ul className='sign_in'>
                <li>
                  <Link to={PATH.LOGIN}>
                    로그인 <i /> 회원가입
                  </Link>
                </li>
                <li className='broker_sign_up' onClick={()=> history.push('/introduction/broker')}>중개사 회원가입</li>
              </ul>
            ) : (
              <div className='my_profile' onClick={() => setDrop(!drop)}>
                <div className='img_holder'>
                  <img
                    src={accountManager.getProfileUrl() ?? error_profile}
                    alt='회원 프로필 이미지'
                    onError={(e) => onErrorProfile(e)}
                  />
                </div>
                <span className={`c-ink2 fw500 ${new_alarm > 0 ? 'new-alarm-dot' : ''}`}>{accountManager.getName()}</span>
                {drop ? (
                  <div className='my_profile_detail'>
                    <ul>
                      <li>
                        <Link to={PATH.ESTATE}>
                          매물 관리
                          <img src={arg} alt='매물 관리 페이지 이동 화살표' />
                        </Link>
                      </li>
                      <li>
                        <Link to='/mypage'>
                          <span
                            className={
                              new_alarm > 0 ? 'new-alarm-dot' : undefined
                            }
                          >
                            알림
                          </span>
                          <img src={arg} alt='알림 페이지 이동 화살표' />
                        </Link>
                      </li>
                      <li>
                        <Link to='/mypage?tab=2'>
                          최근 본 매물
                          <img
                            src={arg}
                            alt='최근 본 매물 페이지 이동 화살표'
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to='/mypage?tab=3'>
                          관심 매물
                          <img src={arg} alt='관심 매물 페이지 이동 화살표' />
                        </Link>
                      </li>
                      <li>
                        <Link to='/mypage?tab=4'>
                          1:1문의
                          <img src={arg} alt='1:1문의 페이지 이동 화살표' />
                        </Link>
                      </li>
                      <li>
                        <Link to='/mypage?tab=5'>
                          내 정보 수정
                          <img
                            src={arg}
                            alt='내 정보 수정 페이지 이동 화살표'
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to='' onClick={onClickLogout}>
                          로그아웃
                          <img src={logout} alt='로그아웃 처리 화살표' />
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default Gnb
