import { useRecoilState, useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import search_atom from '../../atom/search-atom'
import search_focus_atom from '../../atom/search-focus-atom'
import search_address_atom from '../../atom/search-address-atom'

import gg from '../../static/img/icons/gray-glass.svg'
import bg from '../../static/img/icons/blue-glass.svg'
import React, { useState } from 'react'
import SearchResultArea from './search-result-area'
import useComponentDidMount from '../../hooks/use-component-did-mount'
import useComponentWillUnmount from '../../hooks/use-component-will-unmount'
import API from '../../service/api'
import AddressToCoordResponseModel from '../../service/kakao/model/address-to-coord-response-model'
import { useHistory, useLocation } from 'react-router-dom'
import { PATH } from '../../shared/constant'

interface WrapperModel {
  children: React.ReactNode[]
}

interface SearchIconModel {
  active: boolean
}

const Component = {
  PcTablet: {
    Wrapper: (props: WrapperModel) => {
      return React.createElement(
        'div',
        { className: 'search_area rel' },
        ...props.children
      )
    },
    SearchIcon: (props: SearchIconModel) => {
      return React.createElement(
        'button',
        { type: 'button' },
        React.createElement('img', {
          src: props.active ? bg : gg,
          alt: '검색 아이콘',
        })
      )
    },
  },
  Mobile: {
    Wrapper: (props: WrapperModel) => {
      return React.createElement(
        'div',
        { className: 'col middle search_area' },
        React.createElement(
          'div',
          { className: 'mobile_search_box rel' },
          ...props.children
        )
      )
    },
    SearchIcon: (props: SearchIconModel) => {
      return React.createElement('img', {
        src: props.active ? bg : gg,
        alt: '검색 아이콘',
        className: 'glass',
      })
    },
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const SearchArea = () => {
  const location = useLocation()
  const { replace } = useHistory()

  const media = useRecoilValue(media_atom)

  const [search, setSearch] = useRecoilState(search_atom)
  const [focused, setFocused] = useRecoilState(search_focus_atom)
  const [searchNoJibun, setSearchNoJibun] = useRecoilState(search_address_atom)

  function onChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
    setSearchNoJibun(e.target.value)
  }

  function onKeyUpSearch(e: React.KeyboardEvent<HTMLInputElement>){
    const keyCode = e.keyCode
    
    if(keyCode === 13){
      API.kakao
        .addressToCoord(searchNoJibun)
        .then((res) => {
          addressToCoordSuccessCallback(res.data)
        })
        .catch(() => {})
    }
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel) {
    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]

      replace(`${PATH.MAP}${location.search}#LatLng=${y}^${x}`)
    } else {
      throw new Error()
    }
  }

  function onFocus() {
    setFocused(true)
  }

  function globalClickObserver(this: Window, e: MouseEvent) {
    try {
      const target = e.target as Element

      if (this.document.querySelector('.search_area')!.contains(target)) {
        if (target.tagName === 'SPAN') {
          setFocused(false)
        }
      } else {
        setFocused(false)
      }
    } catch {}
  }

  useComponentDidMount(() => {
    window.addEventListener('click', globalClickObserver)
  })

  useComponentWillUnmount(() => {
    window.removeEventListener('click', globalClickObserver)
  })

  const { Wrapper, SearchIcon } =
    media === 'M' ? Component.Mobile : Component.PcTablet

  return (
    <Wrapper>
      <SearchIcon active={search.length > 0} />
      <input
        type='text'
        placeholder='주소를 검색 해주세요.'
        value={searchNoJibun}
        onKeyUp={onKeyUpSearch}
        onChange={onChangeSearch}
        onFocus={onFocus}
      />
      {focused && <SearchResultArea search={search} />}
    </Wrapper>
  )
}

export default SearchArea
