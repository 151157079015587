import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import map_filter_atom from "../../atom/map-filter"
import API from "../../service/api"
import CoordToAddressResponseModel from "../../service/kakao/model/coord-to-address-response-model"

function CurrentPosition() {
  const [position, setPosition] = useState('')

  const map_filter = useRecoilValue(map_filter_atom)

  function getCurrentPosition() {
    const { sw_lat, sw_lng, ne_lat, ne_lng } = map_filter

    if (sw_lat && sw_lng && ne_lat && ne_lng) {
      const lat = (ne_lat - sw_lat) / 2 + sw_lat
      const lng = (ne_lng - sw_lng) / 2 + sw_lng

      API.kakao.coordToAddress(lat, lng)
        .then(res => {
          const data = res.data as CoordToAddressResponseModel

          if (data.documents.length) {
            const { region_1depth_name, region_2depth_name, region_3depth_name } = data.documents[0]

            setPosition(`${region_1depth_name} ${region_2depth_name} ${region_3depth_name}`)
          } else {
            setPosition('')
          }
        }).catch(() => {
          setPosition('')
        })
    }
  }

  useEffect(() => {
    getCurrentPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_filter])

  if (!position) return <></>

  return (
    <div className="current-position">
      {position}
    </div>
  )
}

export default CurrentPosition