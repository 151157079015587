import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import MobileGNB from "../layout/mobile-gnb"
import get_sandan_detail_selector from "../../selector/get-sandan-detail-selector"

/* eslint-disable @typescript-eslint/no-unused-vars */
const SandanMobileGnb = () => {

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))
  return (
    <MobileGNB title={data.name ?? data.api_name} />
  )
}

export default SandanMobileGnb