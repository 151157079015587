import { useRecoilValue, useSetRecoilState } from 'recoil'
import { getQuery } from '../../shared/function'
import AreaTypeToggle from './area-type-toggle'
import get_sandan_detail_selector from '../../selector/get-sandan-detail-selector'
import sandan_detail_atom from '../../atom/sandan-detail'

declare const window:any

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileTop = () => {
  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const setSandanDetail = useSetRecoilState(sandan_detail_atom)

  const url = window.location.href

  const onClickDeclare = () => {
    setSandanDetail((_) => ({
      ..._,
      popup: 1,
    }))
  }

  const onClickShare = () => {
    if (typeof window.AndroidScript != 'undefined' && window.AndroidScript?.sharingText) {
      window.AndroidScript.sharingText(url)
      return
    }

    try{
      window.webkit.messageHandlers.sharing.postMessage(url)
      return
    }catch{}

    setSandanDetail((_) => ({
      ..._,
      popup: 3,
    }))
  }

  return (
    <>
      <div className='mobile_top_btn'>
        <div></div>
        <div className='top_btn'>
          <AreaTypeToggle />
          <button type='button' className='declare' onClick={onClickDeclare} />
          <button type='button' className='share' onClick={onClickShare} />
        </div>
      </div>
      <p className='toggle_desc pd_bottom'>* 평전환시 소수점 첫째자리에서 반올림한 값입니다.</p>
    </>
  )
}

export default MobileTop
