import { useHistory } from 'react-router-dom'
import useComponentDidMount from '../../hooks/use-component-did-mount'

interface Props {
  message: string
  path: string
}

const ErrorBoundaryFallbackKick = (props: Props) => {
  const { replace } = useHistory()

  useComponentDidMount(() => {
    alert(props.message)
    //replace(props.path)
  })

  return <></>
}

export default ErrorBoundaryFallbackKick
