import React, { useMemo } from 'react'
import { randomString } from '../../shared/function'

import '../../style/components/input/range-copy.scss'

interface Props  {
  title: string
  min_value: number
  max_value: number
  value: number
  onChange: (e: React.ChangeEvent<HTMLInputElement> ) => void
  disabled?: boolean
  value_text: string
}

const RangeCopy = (props: Props) => {
  const { value, onChange, min_value, max_value, disabled, value_text} = props

  const fill_width = useMemo(() => {
    const aspect = (value - min_value) / (max_value - min_value)
    return `calc(${aspect * 100}% - ${aspect * 24}px)`
  }, [value, max_value, min_value])

  const id = useMemo(() => {
    return randomString(10)
  }, [])

  return (
    <div className='range'>
      <div className='range_title'>
        {props.title} :
      </div>
      <div className='filter_range_input'>
        <input
          type='range'
          className={disabled ? 'range-disabled' : undefined}
          id={id}
          onChange={onChange}
          min={min_value === max_value ? min_value - 1 : min_value}
          max={max_value}
          step={1}
          value={value}
          disabled={disabled || min_value === max_value}
        />
        <div
          className='fill'
          style={{
            width: min_value === max_value ? 'calc(100% - 24px)' : fill_width,
          }}
        ></div>
      </div>
      <p className={`max_value ${disabled && 'disabled'}`}>{value_text}</p>
    </div>
  )
}

export default RangeCopy
