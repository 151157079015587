import Range from '../input/range'

import '../../style/components/detail/view-3d.scss'
// import '../../style/components/detail/view-3d-develop.scss'

import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  Fragment,
} from 'react'

import Area3d, { SIZE } from './area-3d'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import { useRecoilValue, useRecoilState } from 'recoil'
import media_atom from '../../atom/media-atom'
import { getQuery, three_util } from '../../shared/function'
import Map from '../map'
import { AreaType } from '../../atom/map-filter'
import RangeCopy from '../input/range-copy'
import InputText from '../input/input-text'
import accountManager from '../../shared/account-manager'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { toSvg } from 'html-to-image'
import logo from '../../static/img/logo.svg'

import bunyang_detail_atom from '../../atom/bunyang-detail'

interface Props {
  vol2?: boolean
  lot: Array<LotModel>
  building_area_calc_y_text?: string | null
  tot_area_calc_y_text?: string | null
  is_IC?: boolean
}

interface ShapeListModel {
  shape: ShapeType
  writing: string
}

export interface BoxModel {
  idx: number
  area: number
  area_py: number
  width: number
  depth: number
  floor: number
  rotate: number
  // position?: [number, number]
}

declare const document: any;

const View3D = (props: Props) => {
  const { vol2, lot } = props

  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const printRef = useRef<HTMLDivElement | null>(null)
  const [today, setToday] = useState<string>('0000.00.00')
  const pageTitle = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  const DEFAULT_FLOOR = 3

  useEffect(() => {
    const today = new Date()
    const year = today.getFullYear()
    const month = ('0' + (today.getMonth() + 1)).slice(-2)
    const day = ('0' + today.getDate()).slice(-2)
    const dateString = year + '-' + month + '-' + day
    setToday(dateString)
  }, [])

  const media = useRecoilValue(media_atom)

  const {
    building_area,
    tot_area, //총 연 면적 //대지에 들어선 하나의 건축물의 바닥면적의 합계. 지상층은 물론 지하층, 주차장시설 등을 모두 포함
    bc_rat,
    vl_rat,
    title,
    plat_area, //대지면적
    upjong_name, //[유지보수] 230105 필지 선택시, 3d가설계 화면에서 필지명 옆에 업종도 함께 노출
    upjong_code,
  } = lot[0]

  const upjongCodeArr = props.is_IC ? upjong_code?.split(',') : []
  const upjongNameArr = props.is_IC ? upjong_name?.split(',') : []
  

  //[이벤트] ㎡ 단위를 평 단위로 변경
  const convertSQToPy = (num?: number) => {
    if (!num || num <= 0) return 0
    return parseInt((num / 3.3058).toString())
  }

  // const [areaType, setAreaType] = useState<AreaType>('SQ')
    const [bunyang_detail, setBunyangDetail] = useRecoilState(bunyang_detail_atom)

    const { area_type } = bunyang_detail  

  const plat_area_3d: number = useMemo(() => {
    const path = lot[0].lot.map((i) => new window.kakao.maps.LatLng(...i))

    const polygon = new window.kakao.maps.Polygon({ path })

    return polygon.getArea()
  }, [lot])

  const [boxList, setBoxList] = useState<Array<BoxModel>>([])
  const [selectedBox, setSelectedBox] = useState<BoxModel>()

  //건물들의 면적 총 건축면적 [㎡단위]
  const current_tot_building_area = boxList.reduce(
    (acc: number, current: BoxModel) => {
      return acc + current.area
    },
    0
  )

  //건물들의 면적 총 연면적 [㎡ 단위]
  const current_tot_area = boxList.reduce((acc: number, current: BoxModel) => {
    return acc + current.area * current.floor
  }, 0)

  //최대 층 수
  const [maxFloor, setMaxFloor] = useState(0)
  //최대 가로, 세로 길이 [㎡ 단위]
  const [maxLength, setMaxLength] = useState<[number, number]>([0, 0]) //width, depth
  //필지에 따른 최대 길이 [㎡ 단위]
  const [maxDefaultLength, setMaxDefaultLength] = useState<[number, number]>([
    0, 0,
  ]) //width, depth
  //필지에 따른 최소 길이 [㎡ 단위]
  const [minLength, setMinLength] = useState(0)

  //23.04.12 평단위 range 제거로 인한 주석처리
  //최대 면적 [평 단위]
  // const [maxArea, setMaxArea] = useState(0)
  //최소 면적 [평 단위]
  // const [minArea, setMinArea] = useState(0)
  //면적 비율
  // const [ratio, setRatio] = useState<[number, number]>([0, 0])

  // 선택한 필지에 대한 현재 건폐율 //대지면적 대비 건축 면적의 비율 (얼마나 넓게 지을 수 있는지)
  const current_bc_rat: number = useMemo(() => {
    let rat = (current_tot_building_area / plat_area) * 100
    return isNaN(rat) ? 0 : rat
  }, [plat_area, current_tot_building_area])

  // 선택한 필지에 대한 현재 용적률 //대지면적 대비 건축물 연 면적 비율 (얼마나 높게 올릴 수 있는지)
  const current_vl_rat: number = useMemo(() => {
    let rat = (current_tot_area / plat_area) * 100
    return isNaN(rat) ? 0 : rat
  }, [plat_area, current_tot_area])

  useEffect(() => {
    //★처음 필지 선택 시

    // setAreaType('SQ')
    const { building_area, tot_area } = lot[0]

    const building_area_tenth = building_area / 10

    const maxLength = Math.sqrt(building_area) //직사각형 width, depth
    const length = Math.sqrt(building_area_tenth) //직사각형 width, depth

    //Plat 최대값 조회 //박스 최대 사이즈가 Plat 면적 넘어가지 않게 default 최대값 지정
    const { square_area } = three_util.getLocationData(lot[0])
    const plat_width = Math.sqrt(square_area)
    setMaxDefaultLength([Math.round(plat_width), Math.round(plat_width)])

    const maxWidth = Math.round(maxLength * SIZE.RECTANGLE_Z)
    const maxDepth = Math.round(maxLength * SIZE.RECTANGLE_X)
    // const maxArea = maxWidth * maxDepth
    // const maxArea_py = convertSQToPy(maxArea)
    
    
    const width = Math.round(length * SIZE.RECTANGLE_Z)
    const depth = Math.round(length * SIZE.RECTANGLE_X)
    const area = width * depth
    const area_py = convertSQToPy(area)
    
    const maxFloor = Math.floor(tot_area / area) > 0 ? Math.floor(tot_area / area) : 1
    const floor = maxFloor > DEFAULT_FLOOR ? DEFAULT_FLOOR : maxFloor
    
    const min_length = Math.round(Math.sqrt(building_area / 100)) > 0 ? Math.round(Math.sqrt(building_area / 100)) : 1

    setMinLength(min_length)
    setMaxLength([maxWidth, maxDepth])
    setMaxFloor(maxFloor)

    //23.04.12 평단위 range 제거로 인한 주석처리
    // setMaxArea(maxArea_py)
    // setMinArea(min_length * min_length)

    const boxData: BoxModel = {
      idx: 0,
      area,
      area_py,
      width,
      depth,
      floor,
      rotate: 0,
    }
    setBoxList([boxData])
    setSelectedBox(boxData)

    //23.04.12 평단위 range 제거로 인한 주석처리
    // if (areaType === 'PY') {
      //   setRatio([SIZE.RECTANGLE_Z, SIZE.RECTANGLE_X])
      // }
      
    }, [lot])
    
  //23.04.12 평단위 range 제거로 인한 주석처리
  //타입 변경 시 최대값 지정
  // useEffect(() => {
  //   if (!selectedBox) return
  //   onChangeSelectedBox(selectedBox)
  // }, [areaType])

  //[이벤트] 건물 추가 버튼 클릭 시
  const addBox = () => {
    if (!boxList || boxList.length >= 6) return

    //23.04.12 평단위 range 제거로 인한 주석처리
    // if (areaType === 'PY') {
    //   if (
    //     current_tot_area + minArea / 0.3025 > tot_area || //기존 건물 + 추가 건물(최소)의 연 면적 > 총 연 면적
    //     ((current_tot_building_area + minArea / 0.3025) / plat_area) * 100 >
    //       bc_rat
    //   ) {
    //     //기존 건물 + 추가 건물(최소)의 건폐율 > 최대 건폐율
    //     alert(
    //       '최대 용적률(건폐율)을 초과하여 건물을 추가하실 수 없습니다.\n건물을 추가하시려면 기존 건물의 면적(높이)를 축소 후 추가 바랍니다.'
    //     )
    //     return
    //   }
    // }

    // if (areaType === 'SQ') {
    if (
      current_tot_area +
        minLength * SIZE.RECTANGLE_Z * (minLength * SIZE.RECTANGLE_X) >
        tot_area || //기존 건물 + 추가 건물(최소)의 연 면적 > 총 연 면적
      ((current_tot_building_area + minLength * minLength) / plat_area) *
        100 >
        bc_rat
    ) {
      //기존 건물 + 추가 건물(최소)의 건폐율 > 최대 건폐율
      alert(
        '최대 용적률(건폐율)을 초과하여 건물을 추가하실 수 없습니다.\n건물을 추가하시려면 기존 건물의 면적(높이)를 축소 후 추가 바랍니다.'
      )
      return
    }
    // }

    const availableBuildingArea = tot_area - current_tot_area //사용 가능한 연 면적 = 전체 연 면적 - 사용중인 연 면적
    
    const maxTotArea = tot_area - current_tot_area //추가될 수 있는 연 면적
    const maxBuildingArea =
      (bc_rat / 100) * plat_area - current_tot_building_area //추가될 수 있는 건축 면적

    const maxArea = Math.min(maxTotArea, maxBuildingArea) //추가 가능한 최대 면적
    const maxLength = Math.sqrt(maxArea)
    const maxWidth = Math.round(maxLength * SIZE.RECTANGLE_Z)
    const maxDepth = Math.round(maxLength * SIZE.RECTANGLE_X)
    
    
    const area = maxArea > building_area / 10 ? building_area / 10 : maxArea
    const length = Math.sqrt(area)
    const width = Math.round(length * SIZE.RECTANGLE_Z)
    const depth = Math.round(length * SIZE.RECTANGLE_X)
    const area_py = convertSQToPy(width * depth)
    
    const maxFloor =
      Math.floor(availableBuildingArea / area) > 0
        ? Math.floor(availableBuildingArea / area)
        : 1
    const floor = maxFloor > DEFAULT_FLOOR ? DEFAULT_FLOOR : maxFloor
      
    const newBoxData: BoxModel = {
      idx: boxList[boxList.length - 1].idx + 1,
      area: width * depth,
      area_py,
      width,
      depth,
      floor,
      rotate: 0,
    }
    setSelectedBox(newBoxData)
    setBoxList((prev) => [...prev, newBoxData])

    setMaxFloor(maxFloor)
    setMaxLength([maxWidth, maxDepth])
    //23.04.12 평단위 range 제거로 인한 주석처리
    // setMaxArea(convertSQToPy(maxWidth * maxDepth))
    // setRatio([SIZE.RECTANGLE_Z, SIZE.RECTANGLE_X])
  }

  //[이벤트] 건물 삭제 버튼 클릭 시
  const removeBox = (removeBox: BoxModel) => {
    if (boxList.length <= 1) return
    const newBoxList = boxList.filter((i) => i.idx !== removeBox.idx)
    const newSelectedBox = newBoxList[newBoxList.length - 1]
    setBoxList(newBoxList)
    //마지막 인덱스 건물 active
    setSelectedBox(newSelectedBox)
    onChangeSelectedBox(newSelectedBox, newBoxList)
  }

  //[이벤트] 선택된 건물 층수 변경
  const onChangeFloor = (floor: number) => {
    if (!selectedBox) return
    const newBoxList = boxList.map((box) => {
      if (box.idx === selectedBox?.idx) return { ...box, floor }
      else {
        return box
      }
    })
    setSelectedBox((prev) => {
      if (prev) return { ...prev, floor }
    })
    setBoxList(newBoxList)
  }

  //[이벤트] 선택된 건물 가로, 세로 길이 변경
  const onChangeLength = (length: number, direction: 'W' | 'D') => {
    if (!selectedBox) return

    //건물 전체 면적, 총 연 면적을 고려한 최대 길이
    const _maxLengthByTot = getMaxLengthByTot(
      length,
      selectedBox.idx,
      selectedBox.floor
    )
    const _maxLength =
      _maxLengthByTot > maxDefaultLength[1]
        ? maxDefaultLength[1]
        : _maxLengthByTot
    //연면적을 고려한 최대 층수
    const _maxFloor =
      direction === 'W'
        ? getMaxFloorByTot(length * selectedBox.depth, selectedBox.idx)
        : getMaxFloorByTot(length * selectedBox.width, selectedBox.idx)

    //선택된 건물 업데이트
    if (direction === 'W') {
      setSelectedBox((box) => {
        if (box) {
          const depth = box.depth > _maxLength ? _maxLength : box.depth
          return {
            ...box,
            area: length * depth,
            area_py: convertSQToPy(length * depth),
            width: length,
            depth,
            floor: box.floor > _maxFloor ? _maxFloor : box.floor,
          }
        }
      })
      setMaxLength((prev) => [prev[0], _maxLength])
    } else {
      setSelectedBox((box) => {
        if (box) {
          const width = box.width > _maxLength ? _maxLength : box.width
          return {
            ...box,
            area: width * length,
            area_py: convertSQToPy(width * length),
            width,
            depth: length,
            floor: box.floor > _maxFloor ? _maxFloor : box.floor,
          }
        }
      })
      setMaxLength((prev) => [_maxLength, prev[1]])
    }
    setMaxFloor(_maxFloor)

    //건물 리스트 업데이트
    const newBoxList = boxList.map((box) => {
      if (box.idx === selectedBox?.idx) {
        if (direction === 'W') {
          //가로
          const depth = box.depth > _maxLength ? _maxLength : box.depth
          return {
            ...box,
            area: length * depth,
            area_py: convertSQToPy(length * depth),
            width: length,
            depth,
            floor: box.floor > _maxFloor ? _maxFloor : box.floor,
          }
        } else {
          //세로
          const width = box.width > _maxLength ? _maxLength : box.width
          return {
            ...box,
            area: width * length,
            area_py: convertSQToPy(width * length),
            width,
            depth: length,
            floor: box.floor > _maxFloor ? _maxFloor : box.floor,
          }
        }
      } else {
        return box
      }
    })
    setBoxList(newBoxList)
  }

  //23.04.12 평단위 range 제거로 인한 주석처리
  //[이벤트] 선택된 건물 면적 변경
  // const onChangeAreaPy = (area_py: number) => {
  //   if (!selectedBox) return
  //   const area = Math.round(area_py / 0.3025)
  //   const length = Math.sqrt(area)
  //   const _maxFloor: number = getMaxFloorByTot(
  //     selectedBox.area,
  //     selectedBox.idx
  //   )

  //   setSelectedBox((box) => {
  //     if (box) {
  //       return {
  //         ...box,
  //         area,
  //         area_py,
  //         width: Math.round(length * ratio[0]),
  //         depth: Math.round(length * ratio[1]),
  //         floor: box.floor > _maxFloor ? _maxFloor : box.floor,
  //       }
  //     }
  //   })

  //   const newBoxList = boxList.map((box) => {
  //     if (box.idx === selectedBox?.idx) {
  //       return {
  //         ...box,
  //         area,
  //         area_py,
  //         width: Math.round(length * ratio[0]),
  //         depth: Math.round(length * ratio[1]),
  //         floor: box.floor > _maxFloor ? _maxFloor : box.floor,
  //       }
  //     } else {
  //       return box
  //     }
  //   })

  //   setBoxList(newBoxList)
  //   setMaxFloor(_maxFloor)
  // }

  //[이벤트] 선택된 건물 회전
  const onClickRotateBtn = (direction: 'R' | 'L') => {
    if (!selectedBox) return
    const newBoxList = boxList.map((box) => {
      if (box.idx === selectedBox?.idx) {
        const rotate_deg = direction === 'R' ? box.rotate + 10 : box.rotate - 10
        return { ...box, rotate: rotate_deg }
      } else {
        return box
      }
    })
    setBoxList(newBoxList)
    setSelectedBox((prev) => {
      if (prev)
        return {
          ...prev,
          rotate: direction === 'R' ? prev.rotate + 10 : prev.rotate - 10,
        }
    })
  }

  //[이벤트] 선택된 건물 최대값, 비율 지정
  const onChangeSelectedBox = (box: BoxModel, newBoxList?: BoxModel[]) => {
    onChangeMaxValue(box, newBoxList)
    //23.04.12 평단위 range 제거로 인한 주석처리
    // if (areaType === 'PY') onChangeRatio(box)
  }

  //[이벤트] 선택된 건물 최대값 지정
  const onChangeMaxValue = (box: BoxModel, newBoxList?: BoxModel[]) => {
    if (!selectedBox) return
    onChangeMaxFloor(box.width * box.depth, box.idx, newBoxList)

    //23.04.12 평단위 range 제거로 인한 주석처리
    // if (areaType === 'PY') {
    //   onChangeMaxArea(box.floor, box.idx, newBoxList)
    // } else if (areaType === 'SQ') {
      onChangeMaxLength(
        box.width,
        box.depth,
        box.idx,
        box.floor,
        newBoxList
      )
    // }
  }

  //23.04.12 평단위 range 제거로 인한 주석처리
  //[이벤트] 선택된 건물 가로 세로 비율 저장
  // const onChangeRatio = (box: BoxModel) => {
  //   const widthRatio = 2.03 * (box.width / (box.width + box.depth))
  //   const depthRatio = 2.03 * (box.depth / (box.width + box.depth))
  //   setRatio([widthRatio, depthRatio])
  // }

  //[이벤트] 건물 선택 시 최대 길이 변경
  const onChangeMaxLength = (
    width: number,
    depth: number,
    selectedBoxIdx: number,
    floor: number,
    boxList?: BoxModel[]
  ) => {

    const direction =  width >= depth ? 'W' : 'D'
    const length = Math.max(width, depth)
    
    const _length = length * 1.3
    const _maxLength = getMaxLengthByTot(
      _length,
      selectedBoxIdx,
      floor,
      boxList

    ) //건물 전체 면적, 총 연 면적을 고려한 최대 길이
    if (direction === 'W') {
      setMaxLength([
        _length,
        _maxLength > maxDefaultLength[1]
          ? maxDefaultLength[1]
          : depth > _maxLength
          ? depth
          : _maxLength,
      ])
    } else if (direction === 'D') {
        setMaxLength([
          _maxLength > maxDefaultLength[0]
            ? maxDefaultLength[0]
            : width > _maxLength
            ? width
            : _maxLength,
          _length,
        ])
    }
  }
  //[이벤트] 건물 선택 시 최대 층수 변경
  const onChangeMaxFloor = (
    building_area: number,
    selectedBoxIdx: number,
    boxList?: BoxModel[]
  ) => {
    const _maxFloor = getMaxFloorByTot(building_area, selectedBoxIdx, boxList) //건축면적, 연면적을 고려한 최대 길이
    setMaxFloor(_maxFloor)
  }

  //23.04.12 평단위 range 제거로 인한 주석처리
  //[이벤트] 건물 선택 시 최대 면적 변경
  // const onChangeMaxArea = (
  //   floor: number,
  //   selectedBoxIdx: number,
  //   boxList?: BoxModel[]
  // ) => {
  //   const _maxArea = getMaxAreaByTot(floor, selectedBoxIdx, boxList)
  //   setMaxArea(convertSQToPy(_maxArea))
  // }

  //23.04.12 box Canvas 하나로 통합하며 주석처리
  //[이벤트] 선택된 건물 위치 변경 (PDF이미지 용도)
  // const onChangeBoxPosition = (
  //   selectedBoxIdx: number,
  //   xz: [number, number]
  // ) => {
  //   const newBoxList = boxList.map((box) => {
  //     if (box.idx === selectedBoxIdx) {
  //       return { ...box, position: xz }
  //     } else {
  //       return box
  //     }
  //   })
  //   setBoxList(newBoxList)
  // }

  //총 연 면적을 고려한 최대 높이
  const getMaxFloorByTot = (
    building_area: number,
    selectedBoxIdx: number,
    _boxList?: BoxModel[]
  ) => {
    const currentBoxList = _boxList ? _boxList : boxList

    const availableArea = getAvailableArea(currentBoxList, selectedBoxIdx)

    return Math.floor(availableArea / building_area) > 0
      ? Math.floor(availableArea / building_area)
      : 1
  }

  //건물 전체 면적, 총 연 면적을 고려한 최대 길이
  const getMaxLengthByTot = (
    length: number,
    selectedBoxIdx: number,
    floor: number,
    _boxList?: BoxModel[]
  ): number => {
    const currentBoxList = _boxList ? _boxList : boxList

    const availableArea = getAvailableArea(currentBoxList, selectedBoxIdx)
    const availableBuildingArea = getAvailableBuildingArea(
      currentBoxList,
      selectedBoxIdx
    )

    const maxLengthByTotArea = Math.round(availableArea / floor / length) //연면적을 고려한 최대 길이
    const maxLengthBybuildingArea = Math.round(availableBuildingArea / length) //건축면적(건폐율)을 고려한 최대 길이
    const maxLength = Math.min(maxLengthByTotArea, maxLengthBybuildingArea)

    return maxLength > minLength ? maxLength : minLength
  }

  //23.04.12 평단위 range 제거로 인한 주석처리
  //총 연 면적을 고려한 최대 면적
  // const getMaxAreaByTot = (
  //   floor: number,
  //   selectedBoxIdx: number,
  //   _boxList?: BoxModel[]
  // ) => {
  //   const currentBoxList = _boxList ? _boxList : boxList

  //   const availableArea = getAvailableArea(currentBoxList, selectedBoxIdx)
  //   const availableBuildingArea = getAvailableBuildingArea(
  //     currentBoxList,
  //     selectedBoxIdx
  //   )

  //   const maxAreaByTotArea = Math.round(availableArea / floor) //연면적을 고려한 최대 면적
  //   const maxAreaBybuildingArea = Math.round(availableBuildingArea) //건축면적(건폐율)을 고려한 최대 면적
  //   const maxArea = Math.min(maxAreaByTotArea, maxAreaBybuildingArea)

  //   return maxArea > minArea ? maxArea : minArea
  // }

  //선택된 건물이 사용 가능한 최대 연면적 //전체 연면적 - 나머지 건물의 연 면적
  const getAvailableArea = (boxList: BoxModel[], selectedBoxIdx: number) => {
    return boxList.reduce((acc: number, current: BoxModel) => {
      if (current.idx === selectedBoxIdx) {
        return acc
      } else {
        return acc - current.area * current.floor
      }
    }, tot_area)
  }

  //선택된 건물이 사용 가능한 최대 건축 면적 //전체 건축 면적 - 나머지 건물의 건축 면적
  const getAvailableBuildingArea = (
    boxList: BoxModel[],
    selectedBoxIdx: number
  ) => {
    return boxList.reduce((acc: number, current: BoxModel) => {
      if (current.idx === selectedBoxIdx) {
        return acc
      } else {
        return acc - current.area
      }
    }, building_area)
  }

   const onClickPDFSave = () => {
    if(isPdfLoading) return
    setIsPdfLoading(true)
    setTimeout(()=>{
      const PDFtitle = pageTitle.detail.title ? `_${pageTitle.detail.title}_${title}필지` : ''
      pdfExport(`필지 가설계${PDFtitle}`)
    },1000)
  }

  //PDF 다운로드
  const pdfExport = async (filename: string) => {
    let div = printRef.current as HTMLDivElement
    const pdf_3d = document.querySelector('#pdf_3d') as HTMLDivElement
    const _pdf_3d = pdf_3d?.firstChild as HTMLCanvasElement

    if(_pdf_3d){
      try {
        const src = _pdf_3d.toDataURL('image/png')
        const _div = div.querySelector('.view_3d_image') as HTMLImageElement
        if(_div){
          let _img = document.createElement("img");
          _img.src = src;
          _img.width = 800;
          _img.height = 770;
          _img.onload = () => {
            _div.appendChild(_img)
            div = printRef.current as HTMLDivElement
            convertPdf(div, filename)
          };
        } else {
          setIsPdfLoading(false)
        }
      } catch{
        alert("지원하지 않는 브라우저입니다.")
        const _div = div.querySelector('.view_3d_image') as HTMLImageElement
        _div.replaceChildren();
        setIsPdfLoading(false)
      }
    } else {
      setIsPdfLoading(false)
    }

    // if (div) {
    //   toCanvas(div, {
    //     backgroundColor: '#fff',
    //   }).then((canvas) => {
    //     const imgUrl = canvas.toDataURL('image/jpeg')
    //     const doc = new jsPDF('l', 'px', 'a4')
    //     const imgProperties = doc.getImageProperties(imgUrl)
    //     const pdfWidth = doc.internal.pageSize.getWidth()
    //     const pageHeight = doc.internal.pageSize.getHeight()
    //     const pdfHeight =
    //       (imgProperties.height * pdfWidth) / imgProperties.width

    //     doc.addImage(imgUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight)
    //     doc.save(`${filename}.pdf`)
    //   }).catch((e) => {
    //     alert("지원하지 않는 브라우저입니다.")
    //   })
    // }
  }

  const convertPdf = (div: HTMLElement, filename: string) => {
    try{
      toSvg(div).then((svg)=>{
        let width = 1800
        let heigth = 1070
        
        let _img = new Image();
        _img.src = svg;
        _img.width = width;
        _img.height = heigth;
        
        _img.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = heigth;
          let context = canvas.getContext("2d");


          setTimeout(function () {
            try{
              context.drawImage(_img, 0, 0, width, heigth);
              const imgUrl = canvas.toDataURL('image/jpeg')
              const doc = new jsPDF('l', 'px', 'a4')
              const imgProperties = doc.getImageProperties(imgUrl)
              const pdfWidth = doc.internal.pageSize.getWidth()
              const pageHeight = doc.internal.pageSize.getHeight()
              const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width

              doc.addImage(imgUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight)
              doc.save(`${filename}.pdf`)

            } catch {
              alert("지원하지 않는 브라우저입니다.")

            } finally{
              const _div = div.querySelector('.view_3d_image') as HTMLImageElement
              _div?.replaceChildren();
              setIsPdfLoading(false)
            }

          }, 300);
        };
      })
    } catch {
      setIsPdfLoading(false)
    }
  }

  // if (floor === Infinity || floor === -Infinity || isNaN(floor)) return <></>

  return (
    <>
      <div className={`view_3d_wrap${vol2 ? ' vol2' : ''}`}>
        <div className='view_3d_title flex f-j-sb f-a-c'>
          <h2>
            필지 3D 가설계
            <span>선택한 필지에 건물을 가상으로 설계해보실 수 있습니다.</span>
          </h2>
          <button className='pdf_btn' onClick={onClickPDFSave}>
            PDF 저장하기
          </button>
        </div>
        <div className='row'>
          <Area3d
            area={building_area}
            lot={lot}
            plat_area={plat_area_3d}
            boxList={boxList}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            onClickRotateBtn={onClickRotateBtn}
            onChangeSelectedBox={onChangeSelectedBox}
            // onChangeBoxPosition={onChangeBoxPosition}
            addBox={addBox}
            removeBox={removeBox}
            isPdfImg={isPdfLoading}
          />
          <div className='modify_area'>
            <div className='modify_title flex f-j-sb'>
              <p>
                {String.fromCharCode(
                  65 + boxList.findIndex((box) => box.idx === selectedBox?.idx)
                )}
                동<span>(선택한 건물의 크기를 조절 할 수 있습니다.)</span>
              </p>
              <Map.AreaTypeToggle
              // area_type={areaType}
              // setAreaType={setAreaType}
              />
            </div>
            <div className='range_wrap'>
              {/* {areaType === 'SQ' && (
                <> */}
              <RangeCopy
                title='가로'
                value={selectedBox ? selectedBox.width : 0}
                onChange={(e) => onChangeLength(Number(e.target.value), 'W')}
                max_value={maxLength[0]}
                min_value={minLength}
                value_text={`${
                  selectedBox ? selectedBox?.width.toLocaleString() : 0
                }m`}
              />
              <RangeCopy
                title='세로'
                value={selectedBox ? selectedBox.depth : 0}
                onChange={(e) => onChangeLength(Number(e.target.value), 'D')}
                max_value={maxLength[1]}
                min_value={minLength}
                value_text={`${
                  selectedBox ? selectedBox?.depth.toLocaleString() : 0
                }m`}
              />
              {/* </>
              )} */}
              {/* //23.04.12 평단위 range 제거로 인한 주석처리 */}
              {/* {areaType === 'PY' && (
                <RangeCopy
                  title='면적'
                  value={selectedBox ? selectedBox.area_py : 0}
                  onChange={(e) => onChangeAreaPy(Number(e.target.value))}
                  max_value={maxArea}
                  min_value={minArea}
                  value_text={`${
                    selectedBox ? selectedBox?.area_py.toLocaleString() : 0
                  }평`}
                />
              )} */}
              <RangeCopy
                title='층수'
                value={selectedBox ? selectedBox.floor : 0}
                onChange={(e) => onChangeFloor(Number(e.target.value))}
                max_value={maxFloor}
                min_value={1}
                value_text={`${
                  selectedBox ? selectedBox.floor.toLocaleString() : 0
                }층`}
              />
            </div>
            <div className='building_type'>
              <ul>
                <li>
                  <span>건물 면적 : </span>
                  <InputText
                    types='text'
                    id='area1'
                    name='area1'
                    value={
                      selectedBox && selectedBox.area > 0
                        ? area_type === 'SQ'
                          ? `${selectedBox.area.toLocaleString()}㎡`
                          : `${convertSQToPy(
                              selectedBox.area
                            ).toLocaleString()}평`
                        : `0${area_type === 'SQ' ? '㎡' : '평'}`
                    }
                    readonly
                    placeholder='자동계산 입력'
                    className='ta-r'
                  />
                </li>
                <li>
                  <span>연 면적 : </span>
                  <InputText
                    types='text'
                    id='area2'
                    name='area2'
                    value={
                      selectedBox &&
                      selectedBox.area > 0 &&
                      selectedBox.floor > 0
                        ? area_type === 'SQ'
                          ? `${(
                              selectedBox.area * selectedBox.floor
                            ).toLocaleString()}㎡`
                          : `${convertSQToPy(
                              selectedBox.area * selectedBox.floor
                            ).toLocaleString()}평`
                        : `0${area_type === 'SQ' ? '㎡' : '평'}`
                    }
                    readonly
                    className='ta-r'
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='rat-area'>
          <p>
            최대 건폐율 : <span>{bc_rat.toLocaleString()}%</span>
          </p>
          <div className='bar' />
          <p>
            최대 용적률 : <span>{vl_rat.toLocaleString()}%</span>
          </p>
          <div className='bar bar_768' />
          <br className='br_768' />
          <p>
            현재 건폐율 : <span>{current_bc_rat.toLocaleString()}%</span>
          </p>
          <div className='bar bar_1024' />
          <br className='br_1024' />
          <p>
            현재 용적률 : <span>{current_vl_rat.toLocaleString()}%</span>
          </p>
          <div className='bar bar_768' />
          <br className='br_768' />
          <p>
            건물 전체 면적 :{' '}
            <span>
              {area_type === 'SQ'
                ? `${current_tot_building_area.toLocaleString()}㎡`
                : `${convertSQToPy(
                    current_tot_building_area
                  ).toLocaleString()}평`}
            </span>
          </p>
          <div className='bar' />
          <p>
            총 연 면적 :{' '}
            <span>
              {area_type === 'SQ'
                ? `${current_tot_area.toLocaleString()}㎡`
                : `${convertSQToPy(current_tot_area).toLocaleString()}평`}
            </span>
          </p>
        </div>
      </div>
      <div className='view_3d_warning'>
        {typeof props.building_area_calc_y_text === 'string' &&
          props.building_area_calc_y_text !== '' && (
            <p>* {props.building_area_calc_y_text}</p>
          )}
        {typeof props.tot_area_calc_y_text === 'string' &&
          props.tot_area_calc_y_text !== '' && (
            <p>* {props.tot_area_calc_y_text}</p>
          )}
        <p>* 바닥 1칸의 면적은 약 30평(100㎡, 가로 10m * 세로 10m)입니다.</p>
        <p>* 건물 한개층의 높이는 5m입니다.</p>
      </div>

      {/* pdf 다운 영역 start */}
      <div className='pdfImgDim'></div>
      <div
        className='pdfImg'
        ref={printRef}
        style={{ backgroundColor: 'white' }}
      >
        <img className='logo' src={logo} alt='로고' />
        <div className='pdfImg_title'>
          <p>필지 가설계</p>
          <div className='pdfImg_info'>
            <span className='date'>{today}</span>
            {accountManager.isLogin() ? (
              <>
                <span className='division' />
                {accountManager.getName()}님의 가설계
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='info'>
          <div className='view_3d'>
            <h2>가설계 도면 </h2>
            <div className='view_3d_image' />
          </div>
          <div className='detail_info'>
            <h2>필지정보</h2>
            <div className='area_info_wrap'>
              {props.is_IC && (
                <p>
                  {pageTitle.detail.title}
                  <span className='division' />
                  {title}필지
                </p>
              )}
              {plat_area && (
                <p>
                  <span>대지면적 :</span>
                  {plat_area.toLocaleString()}㎡ (
                  {convertSQToPy(plat_area).toLocaleString()}평)
                </p>
              )}
              {props.is_IC &&
                <p>
                  <span>업종 :</span>
                  {upjongCodeArr.map((code, index) => {
                    return (
                      <Fragment key={`${code}_index`}>
                        {code}
                        {upjongNameArr &&
                          upjongNameArr[index] &&
                          `_${upjongNameArr[index]}`}
                        {index < upjongCodeArr.length - 1 ? (
                          <span className='comma'></span>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    )
                  })}
                </p>
              }
              <div>
                <p>
                  <span>최대 건폐율 :</span>
                  {bc_rat.toLocaleString()}%
                </p>
                <p>
                  <span>최대 용적률 :</span>
                  {vl_rat.toLocaleString()}%
                </p>
              </div>
              <div>
                <p>
                  <span>현재 건폐율 :</span>
                  {current_bc_rat.toLocaleString()}%
                </p>
                <p>
                  <span>현재 용적률 :</span>
                  {current_vl_rat.toLocaleString()}%
                </p>
              </div>
            </div>
            <div className='table_wrap'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>가로(m)</th>
                    <th>세로(m)</th>
                    <th>층수</th>
                    <th>건축면적(㎡/평)</th>
                    <th>연면적(㎡/평)</th>
                  </tr>
                </thead>
                <tbody>
                  {boxList?.length > 0 ? (
                    <>
                      {boxList.map((el: BoxModel, index: number) => {
                        return (
                          <tr key={el.idx}>
                            <td style={{ fontWeight: '500' }}>
                              {String.fromCharCode(65 + index)}
                            </td>
                            <td>{el.width.toLocaleString()}</td>
                            <td>{el.depth.toLocaleString()}</td>
                            <td>{el.floor.toLocaleString()}</td>
                            <td>{`${el.area.toLocaleString()} /  ${convertSQToPy(
                              el.area
                            ).toLocaleString()}`}</td>
                            <td>{`${(
                              el.area * el.floor
                            ).toLocaleString()} /  ${convertSQToPy(
                              el.area * el.floor
                            ).toLocaleString()}`}</td>
                          </tr>
                        )
                      })}
                      <tr className='sum'>
                        <td colSpan={4}>합계</td>
                        <td>{`${current_tot_building_area.toLocaleString()} /  ${convertSQToPy(
                          current_tot_building_area
                        ).toLocaleString()}`}</td>
                        <td>{`${current_tot_area.toLocaleString()} /  ${convertSQToPy(
                          current_tot_area
                        ).toLocaleString()}`}</td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6}>리스트가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='view_3d_description'>
          <p>
            * 해당 가설계는 이용자의 이해를 돕기 위해 사용되는 기능으로, 실제와
            다를 수 있습니다.
          </p>
          <p>* 건물 한개층의 높이는 5m입니다.</p>
        </div>
      </div>
    </>
  )
}

export default View3D
