import axios, { CancelTokenSource } from 'axios'
import { selector } from 'recoil'
import map_filter_atom from '../atom/map-filter'
import API from '../service/api'
import { SIGHT } from '../shared/constant'
import { EstateModel } from './get-map-data-selector'

let cancel_token: CancelTokenSource | null = null

const error_data: Array<any> = []

const get_map_cluster_selector = selector<Array<EstateModel>>({
  key: '@map/get-map-cluster',
  get: async ({ get }) => {
    const request = get(map_filter_atom)

    if (request.level > SIGHT.GU.max || request.level < SIGHT.GU.min)
      return error_data

    if (cancel_token) cancel_token.cancel()

    cancel_token = axios.CancelToken.source()

    const response = await API.estate.getMapCluster(request, cancel_token.token)

    cancel_token = null

    if (response.data.code !== 200) return error_data

    return response.data.estate
  },
})

export default get_map_cluster_selector
