import Axios, { AxiosRequestConfig, CancelToken } from 'axios'
import { GET } from '../../shared/api-constant'

const kakao = {
  addressToCoord: (address: string, size?: number) => {
    const config: AxiosRequestConfig = {
      url: `https://dapi.kakao.com/v2/local/search/address.json?analyze_type=similar&page=1&size=${
        size ?? 10
      }&query=${address}`,
      method: GET,
      headers: {
        Authorization: `KakaoAK 8e5d2befbe04d46dac612d0b04b7f44f`,
      },
    }

    return Axios(config)
  },

  coordToAddress: (lat: number, lng: number) => {
    const config: AxiosRequestConfig = {
      url: `https://dapi.kakao.com/v2/local/geo/coord2regioncode.json`,
      method: GET,
      params: {
        x: lng,
        y: lat,
      },
      headers: {
        Authorization: `KakaoAK 8e5d2befbe04d46dac612d0b04b7f44f`,
      },
    }

    return Axios(config)
  },

  localSearch: (query: string, cancelToken?: CancelToken) => {
    const config: AxiosRequestConfig = {
      url: `https://dapi.kakao.com/v2/local/search/address.json?page=1&query=${query}`,
      method: GET,
      headers: {
        Authorization: `KakaoAK 8e5d2befbe04d46dac612d0b04b7f44f`,
      },
      cancelToken,
    }

    return Axios(config)
  },
}

export default kakao
