import { Suspense } from "react"
import ErrorBoundary from "../layout/error-boundary"
import Skeleton from "../skeleton"
import Notice from "./notice"

/* eslint-disable @typescript-eslint/no-unused-vars */
const NoticeWrapper = () => {
  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<Skeleton width={'100%'} height={150} radius={4} className="notice-wrapper" />}>
        <Notice />
      </Suspense>
    </ErrorBoundary>
  )
}

export default NoticeWrapper