import Axios,{ AxiosRequestConfig } from "axios"
import { BUNYANG_URL, GET, JSON_HEADER } from "../../shared/api-constant"
import { RecommendBunyangType } from "../../shared/types"

const bunyang = {
  getRecommendBunYang : (type : RecommendBunyangType) => {
    const config : AxiosRequestConfig = {
      url : `${BUNYANG_URL}/recommend_bunyang.php?type=${type}` ,
      method : GET ,
      headers : {
        ...JSON_HEADER
      }
    }
    return Axios(config)
  },

  getNewBunyangList : () => {
    const config : AxiosRequestConfig = {
      url : `${BUNYANG_URL}/bunyang_main_new_bunyang_list.php` ,
      method : GET ,
      headers : {
        ...JSON_HEADER
      }
    }
    return Axios(config)
  },

  getNewestBunyangList : () => {
    const config : AxiosRequestConfig = {
      url : `${BUNYANG_URL}/bunyang_main_newest_bunyang_list.php` ,
      method : GET ,
      headers : {
        ...JSON_HEADER
      }
    }
    return Axios(config)
  },
}

export default bunyang