import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_filter_options_atom from '../../atom/map-filter-options'
import MainButton from '../button/main-button'

/* eslint-disable @typescript-eslint/no-unused-vars */
const CompleteAfterPop = () => {
  const [filter, setFilter] = useRecoilState(map_filter_options_atom)
  const { complete_after_pop } = filter
  // const [ filter , setFilter ] = useState(false)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const [complete_after, setCompleteAfter] = useState(map_filter.complete_after)

  const onClickFilter = () => {
    setFilter({
      price_pop: false,
      area_pop: false,
      deal_type_pop: false,
      complete_after_pop: complete_after_pop === true ? false : true,
      filter_all_pop: false,
    })
  }

  const onClickReset = () => {
    setCompleteAfter('3Y')
  }

  const onClickApply = () => {
    setMapFilter({
      ...map_filter,
      complete_after,
      realdeal_yn: complete_after === 'N' ? 'N' : 'Y'
    })
    setFilter({
      ...filter,
      complete_after_pop: false,
    })
  }

  useEffect(() => {
    setCompleteAfter(map_filter.complete_after)
  }, [complete_after_pop, map_filter])

  return (
    <div className={`filter ${complete_after_pop ? 'on' : ''}`}>
      <div className='filter_btn' onClick={() => onClickFilter()}>
        <p>실거래가</p>
        <span className='arrow'></span>
      </div>
      {complete_after_pop && (
        <div className='filter_detail_wrap'>
          <section>
            <article>
              <h3>거래완료 여부</h3>
              <ul className='filter_checkbox'>
                <li>
                  <input
                    type='radio'
                    id='_term1'
                    name='_trade_complete'
                    checked={complete_after === '1Y'}
                    onChange={() => setCompleteAfter('1Y')}
                  />
                  <label htmlFor='_term1'>최근 1년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term2'
                    name='_trade_complete'
                    checked={complete_after === '2Y'}
                    onChange={() => setCompleteAfter('2Y')}
                  />
                  <label htmlFor='_term2'>최근 2년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term3'
                    name='_trade_complete'
                    checked={complete_after === '3Y'}
                    onChange={() => setCompleteAfter('3Y')}
                  />
                  <label htmlFor='_term3'>최근 3년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term4'
                    name='_trade_complete'
                    checked={complete_after === '5Y'}
                    onChange={() => setCompleteAfter('5Y')}
                  />
                  <label htmlFor='_term4'>최근 5년</label>
                </li>
                <li>
                  <input
                    type='radio'
                    id='_term5'
                    name='_trade_complete'
                    checked={complete_after === 'N'}
                    onChange={() => setCompleteAfter('N')}
                  />
                  <label htmlFor='_term5'>미포함</label>
                </li>
              </ul>
            </article>
          </section>
          <div className='btn_wrap'>
            <button
              type='button'
              className='reset_btn'
              onClick={onClickReset}
            ></button>
            <MainButton
              title='필터 적용'
              size='small'
              types='priority'
              onClick={onClickApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CompleteAfterPop
