import { Suspense } from "react"
import LoadingFallback from "../loading/loading-fallback"
import News from "./news"

/* eslint-disable @typescript-eslint/no-unused-vars */
const NewsWrapper = () => {

  return (
    <Suspense fallback={<LoadingFallback className='detail-news-loading' />}>
      <News />
    </Suspense>
  )
}

export default NewsWrapper