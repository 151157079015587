import React from 'react'
import '../../style/components/input/input-text.scss'

type InputTextTypes = {
  types: 'text' | 'password'
  placeholder?: string
  label?: string | boolean
  id: string
  className?: string
  name: string
  error?: string | boolean
  info?: string
  required?: boolean
  side?: boolean | JSX.Element
  readonly?: boolean
  value?: string
  onChange?: (value: string) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  maxLength?: number
  onEnter?: () => void
}

const InputText = (props: InputTextTypes) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onEnter && e.key === 'Enter') {
      props.onEnter()
    }
  }

  const className = props.className ?? ''

  return (
    <>
      <div className={`input_text_wrap ${className}`}>
        {props.label ? (
          <label htmlFor={props.id}>
            {props.label}
            <strong>{props.required ? '*' : ''}</strong>
          </label>
        ) : (
          <></>
        )}

        <div className='input_text'>
          <input
            type={props.types}
            id={props.id}
            name={props.name}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            value={props.value}
            autoComplete={
              props.types === 'password' ? 'current-password' : 'off'
            }
            onChange={onChange}
            onBlur={props.onBlur && props.onBlur}
            maxLength={props.maxLength}
            onKeyDown={onKeyDown}
          />
          {props.side ? <>{props.side}</> : <></>}
        </div>

        {props.error ? <p className='error_txt'>{props.error}</p> : <></>}
        {props.info ? <p className='info_txt'>{props.info}</p> : <></>}
      </div>
    </>
  )
}

export default InputText
