import { AreaType } from '../../atom/map-filter'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'

interface Props {
  area_type: AreaType
  history: GetDetailResponseModel['history']
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const HistoryTable = ({ area_type, history }: Props) => {
  const area_type_text = area_type === 'PY' ? '평' : '㎡'

  const getPricePerArea = (area: number, price: number) => {
    const py = area / 3.3058

    const sq = area

    return price / (area_type === 'PY' ? py : sq)
  }

  if (history.length === 0) return <></>

  return (
    <article>
      <h2>실거래가 정보</h2>
      <table>
        <thead>
          <tr>
            <th>거래일자</th>
            <th>거래금액</th>
            <th>단가</th>
          </tr>
        </thead>
        <tbody>
          {history.map((i) => {
            return (
              <tr>
                <td>{i.deal_ym_text}</td>
                <td>{i.price_deal_text}</td>
                <td>
                  {getPricePerArea(
                    i.total_tot_area ? i.total_tot_area : i.total_plat_area,
                    i.price_deal
                  ).toLocaleString()}
                  원/{area_type_text}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </article>
  )
}

export default HistoryTable
