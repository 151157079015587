import { onError } from '../../shared/function'

interface Props {
  src: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LeftImage = (props: Props) => {
  return (
    <img
      src={props.src}
      // style={{ height: '100%' }}
      alt='분양 이미지'
      onError={(e) => onError(e, 'large')}
      onContextMenu={(e) => {e.preventDefault()}}
    />
  )
}

export default LeftImage
