import { saveAs } from "file-saver"
import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector, { NoticeModel } from "../../selector/get-bunyang-detail-selector"
import { SERVER_URL } from "../../shared/api-constant"
import { getQuery } from "../../shared/function"

import notice_icon from '../../static/img/icons/notice-icon.svg'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Notice = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { notice } = data

  const onClickFile = (i : NoticeModel) => {
    const path = '/upload' + i.path.split('/upload')[1]
        
    /*
    saveAs(
      i.path ,
      i.original_name
    )
    */

    window.open(`${SERVER_URL}/backend/app/api/download.php?filepath=${path}&filename=${i.original_name}`)
  }

  if ( !notice ) return <></>

  if ( notice.length === 0 ) return <></>

  return (
    <div className="notice-wrapper loaded">
      <h3>
        · 고시/공고문
      </h3>
      <ul>
        {
          notice.map((i , index) => {
            return (
              <li key={index}>
                <img src={notice_icon} alt="고시 / 공고문 아이콘" />
                <p onClick={() => onClickFile(i)}>
                  {i.original_name}
                </p>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Notice