import { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import get_map_cluster_selector from '../../selector/get-map-cluster-selector'
import { EstateModel } from '../../selector/get-map-data-selector'
import { SIGHT } from '../../shared/constant'
import { encSha256 } from '../../shared/passport'

interface Props {
  map_ref: React.MutableRefObject<any>
}

interface GuOverlayGeneratorProps {
  lat: number
  lng: number
  count: number
}

interface MatrixModel {
  lat_min: number
  lng_min: number
  lat_max: number
  lng_max: number
  count: number
}

interface MapperModel {
  lat: number
  lng: number
  count: number
}

const util = {
  guOverlayGenerator: (props: GuOverlayGeneratorProps) => {
    const { lat, lng, count } = props

    const id = encSha256(`${lat}/${lng}`)

    return `<div onclick="window._gc(this)" id="gu_${id}" data-loc_lat="${lat}" data-loc_lng="${lng}" class="gu">${count}</div>`
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapCluster = (props: Props) => {
  const map = props.map_ref.current

  const map_filter = useRecoilValue(map_filter_atom)

  const data = useRecoilValue(get_map_cluster_selector)

  const { ne_lat, sw_lat, ne_lng, sw_lng } = useRecoilValue(map_filter_atom)

  const { sub_division } = map_filter.deal_kind

  const gu_overlays = useRef<Array<any>>([])

  const guDrawing = () => {
    try {
      const level = map.getLevel()

      if (
        SIGHT.GU.min <= level &&
        SIGHT.GU.max >= level &&
        sub_division === 'N'
      ) {
        const grid = 8

        const matrix: Array<Array<MatrixModel>> = Array(grid)
          .fill(1)
          .map((_, lat_index) => {
            return Array(grid)
              .fill(1)
              .map((_, lng_index) => {
                const lng = sw_lng + ((ne_lng - sw_lng) / grid) * lng_index
                const lat = sw_lat + ((ne_lat - sw_lat) / grid) * lat_index

                const lng_step = (ne_lng - sw_lng) / grid
                const lat_step = (ne_lat - sw_lat) / grid

                return {
                  lat_min: lat,
                  lng_min: lng,
                  lat_max: lat + lat_step,
                  lng_max: lng + lng_step,
                  count: 0,
                }
              })
          })

        const mapper: Array<Array<MapperModel>> = matrix.map((i) =>
          i.map((matrix) => {
            const matcher = data.filter(
              (i) =>
                i.lat >= matrix.lat_min &&
                i.lat <= matrix.lat_max &&
                i.lng >= matrix.lng_min &&
                i.lng <= matrix.lng_max &&
                i.status !== 'COMPLETE'
            )

            if (matcher.length === 0)
              return {
                lat: -1,
                lng: -1,
                count: 0,
              }

            const lat =
              matcher.reduce((acc: number, current: EstateModel) => {
                return acc + current.lat
              }, 0) / matcher.length
            const lng =
              matcher.reduce((acc: number, current: EstateModel) => {
                return acc + current.lng
              }, 0) / matcher.length

            return {
              lat,
              lng,
              count: matcher.length,
            }
          })
        )

        for (const item of mapper.flat()) {
          const { lat, lng, count } = item

          const position = new window.kakao.maps.LatLng(lat, lng)

          const overlay = new window.kakao.maps.CustomOverlay({
            position,
            content: util.guOverlayGenerator({
              lat,
              lng,
              count,
            }),
          })

          overlay.setMap(map)

          gu_overlays.current.push(overlay)
        }

        return
      }
    } catch {}
  }

  const guRemove = () => {
    for (const overlay of gu_overlays.current) {
      overlay.setMap(null)
    }
  }

  useEffect(() => {
    if (map) {
      guDrawing()
    }

    return () => {
      guRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, map])

  return <></>
}

export default MapCluster
