import { Suspense } from "react"
import MobileBottomCard from "./mobile-bottom-card"
import MobileBottomCardSkeleton from "./mobile-bottom-card-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileBottomCardWrapper = () => {
  return (
    <Suspense fallback={<MobileBottomCardSkeleton />}>
      <MobileBottomCard />
    </Suspense>
  )
}

export default MobileBottomCardWrapper