import { dec, enc, encSha256 } from './passport'

import DecryptDataModel from '../model/dataModel/decrypt-data-model'

import DecryptData from './decrypt-data'

// 비어있는 데이터
// null로 처리
const empty_decrypt_data = new DecryptData({
  value: null,
  date: null,
  href: null,
})

/**
 * 마대리 localStorage data 정리
 * @returns maderi-token : { access : string , refresh : string } : API 토큰
 */
export default class StorageManager {
  // 로컬 스토리지 데이터 암호화로 저장
  set(_key: string, __value: string | number | Object | Array<any>): void {
    const _value = typeof __value === 'object' ? JSON.stringify(__value) : __value

    const key = encSha256(_key)
    const value = enc(_value)

    localStorage.setItem(key, value)
  }

  // 암호화된 로컬 스토리지 데이터 가져오기
  get(_key: string): DecryptDataModel {
    const key = encSha256(_key)

    const get_item = localStorage.getItem(key)

    // 값이 존재하는 경우에만 반환
    if (get_item) {
      // 암호화된 데이터 복호화
      const d = dec(get_item)

      return d
    }
    // 값이 존재하지 않은 경우 비어있는 데이터 반환
    else {
      return empty_decrypt_data
    }
  }

  // 암호회된 로컬 스토리지 데이터 삭제
  remove(_key: string): void {
    const key = encSha256(_key)

    localStorage.removeItem(key)
  }

  // 로컬 스토리지 비우기
  clean(): void {
    const sm = new StorageManager()

    const restore_show_never = sm.get('show-never').getValue() !== null

    const restore_show_never_today = sm.get('show-never-today').getValue() !== null

    const restore_show_never_today_temp = sm.get('show-never-today').getValue() as any

    localStorage.clear()

    if (restore_show_never) {
      sm.set('show-never', 1)
    }

    if (restore_show_never_today) {
      sm.set('show-never-today', restore_show_never_today_temp)
    }
  }
}
