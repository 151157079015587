import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const MiddleSkeleton = () => {
  const media = useRecoilValue(media_atom)

  return (
    <>
      <div>
        <Skeleton marginRight={6} width={150} height={36} radius={4} />
        <Skeleton width={100} height={24} radius={4} />
      </div>
      {
        media === "P" &&
        <div className="item_info">
          <Skeleton width={150} height={20} radius={4} />
        </div>
      }
    </>
  )
}

export default MiddleSkeleton