import { useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { bigNumber, getQuery } from '../../shared/function'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Middle = () => {
  const { area_type } = useRecoilValue(bunyang_detail_atom)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data

  const { price_per_area_yn } = detail

  const area_type_text = area_type === 'PY' ? '평' : '㎡'

  let unit_price = 0

  if(detail.total_plat_area > 0){

    const py = detail.total_plat_area / 3.3058
  
    const sq = detail.total_plat_area
  
    unit_price = detail.price_main / (area_type === 'PY' ? py : sq)

  } 

  if (price_per_area_yn === 'N') return <></>

  return (
    <div className='middle'>
      <h2 className='c-price fw600'>{detail.price_text}</h2>
      <p>
        ({bigNumber(unit_price)}원 /{area_type_text})
      </p>
    </div>
  )
}

export default Middle
