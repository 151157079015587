interface Props {
  className?: string
  id?: string
  height: number | string
  width: number | string
  radius?: number | string
  marginRight?: number | string
  marginBottom?: number | string
  marginTop?: number | string
  front_icon?: JSX.Element
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Skeleton = (props: Props) => {
  const class_name = props.className ? ` ${props.className}` : ''

  return (
    <div
      className={`skeleton ${class_name}`}
      {...(props.id ? { id: props.id } : {})}
      style={{
        height: props.height,
        width: props.width,
        borderRadius: props.radius ?? 0,
        marginRight: props.marginRight ?? 0,
        marginBottom: props.marginBottom ?? 0,
        marginTop: props.marginTop ?? 0,
      }}
    >
      {props.front_icon}
    </div>
  )
}

export default Skeleton
