import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function useScrollControl() {
  const history = useHistory()
  const MAX_SYNC_ATTEMPT = 5

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const syncScroll = useCallback(
    debounce((y: number, attempt:number) => {
      requestAnimationFrame(() => {
        if (attempt < 1) return;
        if (y !== window.scrollY) {
          window.scrollTo({top : y})
          syncScroll(y, attempt - 1);
        }
      });
    }, 100)
  ,[]);

  useEffect(()=>{
    const unlisten = () => {
      if(history.location.pathname === '/'){ //메인페이지 우선적용
        if(history.action === 'PUSH'){
          window.scrollTo(0, 0)
        } else if (history.action === 'POP'){
          const scrollY = localStorage.getItem('scrollY')
          if(scrollY){
            syncScroll(Number(scrollY), MAX_SYNC_ATTEMPT);
          }
        }
      }
    }
    return unlisten
  },[])
}

export default useScrollControl
