import C from 'crypto-js'
import { MapFilterAtomModel } from '../atom/map-filter'

import DecryptDataModel from '../model/dataModel/decrypt-data-model'
import DecryptData from './decrypt-data'
import { encNumber, getQueryValues } from './function'
import { YNType } from './types'

// 암호화 함수
export const enc = (__e: string | number): string => {
  // number 데이터의 경우 to String 필요
  const _e = __e.toString()

  // 데이터 암호화 시
  // 암호화할 데이터 + 암호화 시점 + 암호화된 URL 합하여 암호화
  const e = `${_e}${process.env.REACT_APP_SPLITTER}${new Date().valueOf()}${
    process.env.REACT_APP_SPLITTER
  }${window.location.href}`

  // 이중 암호화
  return C.AES.encrypt(
    C.AES.encrypt(e, process.env.REACT_APP_CK ?? '').toString(),
    process.env.REACT_APP_SK ?? ''
  ).toString()
}

// 복호화 함수
export const dec = (e: string): DecryptDataModel => {
  // 이중 암호호된 데이터 복호화
  const _ = C.AES.decrypt(
    C.AES.decrypt(e, process.env.REACT_APP_SK ?? '').toString(C.enc.Utf8),
    process.env.REACT_APP_CK ?? ''
  )
    .toString(C.enc.Utf8)
    .split(process.env.REACT_APP_SPLITTER ?? '')

  // 구분 기호 기준
  // 0번째 데이터 : 암호화된 실질적 데이터
  // 1번째 데이터 : 암호화 시점
  // 2번째 데이터 : 암호화된 URL

  // 실질적 데이터가 number인 경우 parseInt하여 전달
  const v = isNaN(parseInt(_[0])) ? _[0] : parseInt(_[0])

  const d = parseInt(_[1])

  const h = _[2]

  // 직접접근 불가능한 데이터로 반환
  return new DecryptData({ value: v, date: d, href: h })
}

// 로컬스토리지 key 반환 함수
// sha256의 경우 단방향 암호화이기 때문에
// 복호화는 불가능하지만 값이 일정하므로 key값 암호화 방식으로 채택
export const encSha256 = (e: string): string => {
  return C.SHA256(e).toString()
}

export const recordEnc = (record: MapFilterAtomModel) => {
  const {
    area_type,
    broker_idx,
    broker_yn,
    certify_yn,
    complete_after,
    deal_kind,
    keyword,
    lat,
    level,
    lng,
    ne_lat,
    ne_lng,
    plat_area_max,
    plat_area_min,
    price_once_max,
    price_once_min,
    price_total_max,
    price_total_min,
    realdeal_yn,
    sandan_yn,
    sw_lat,
    sw_lng,
    tot_area_max,
    tot_area_min,
    type,
  } = record

  const { tr, trade, rent, sub_division } = deal_kind

  const { FT, IC, KC, LC, LD, WH } = type

  const query: Array<any> = [
    area_type,
    broker_idx,
    broker_yn,
    certify_yn,
    complete_after,
    tr,
    trade,
    rent,
    sub_division,
    keyword,
    lat,
    level,
    lng,
    ne_lat,
    ne_lng,
    plat_area_max,
    plat_area_min,
    price_once_max,
    price_once_min,
    price_total_max,
    price_total_min,
    realdeal_yn,
    sandan_yn,
    sw_lat,
    sw_lng,
    tot_area_max,
    tot_area_min,
    FT,
    IC,
    KC,
    LC,
    LD,
    WH,
  ].map((value, index) => {
    return `${encNumber(index.toString())}=${value}`
  })

  return query.join('&')
}

export const recordDec = (): MapFilterAtomModel => {
  const values = getQueryValues(true)

  const [
    area_type,
    broker_idx,
    broker_yn,
    certify_yn,
    complete_after,
    tr,
    trade,
    rent,
    sub_division,
    keyword,
    lat,
    level,
    lng,
    ne_lat,
    ne_lng,
    plat_area_max,
    plat_area_min,
    price_once_max,
    price_once_min,
    price_total_max,
    price_total_min,
    realdeal_yn,
    sandan_yn,
    sw_lat,
    sw_lng,
    tot_area_max,
    tot_area_min,
    FT,
    IC,
    KC,
    LC,
    LD,
    WH,
  ] = values as [
    MapFilterAtomModel['area_type'],
    number,
    YNType,
    YNType,
    MapFilterAtomModel['complete_after'],
    YNType,
    YNType,
    YNType,
    YNType,
    string,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    YNType,
    YNType,
    number,
    number,
    number,
    number,
    YNType,
    YNType,
    YNType,
    YNType,
    YNType,
    YNType
  ]

  return {
    area_type,
    broker_idx: Number(broker_idx),
    broker_yn,
    certify_yn,
    complete_after,
    deal_kind: {
      tr,
      trade,
      rent,
      sub_division,
    },
    keyword,
    lat: Number(lat),
    level: Number(level),
    lng: Number(lng),
    ne_lat: Number(ne_lat),
    ne_lng: Number(ne_lng),
    plat_area_max: Number(plat_area_max),
    plat_area_min: Number(plat_area_min),
    price_once_max: Number(price_once_max),
    price_once_min: Number(price_once_min),
    price_total_max: Number(price_total_max),
    price_total_min: Number(price_total_min),
    realdeal_yn,
    sandan_yn,
    sw_lat: Number(sw_lat),
    sw_lng: Number(sw_lng),
    tot_area_max: Number(tot_area_max),
    tot_area_min: Number(tot_area_min),
    type: {
      FT,
      IC,
      KC,
      LC,
      LD,
      WH,
    },
  }
}
