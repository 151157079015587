import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import RealEstateInfo from '../detail/real-estate-info'

interface Props {
  detail: GetDetailResponseModel['detail']
}

function News({ detail }: Props) {
  return <RealEstateInfo lat={detail.lat} lng={detail.lng} />
}

export default News
