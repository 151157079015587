import React from 'react'
import tel from '../../static/img/icons/tel_icon.svg'
import '../../style/components/popup/register-popup.scss'
import Popup from './popup'
import { GetDetailBrokerResponseDataModel } from '../../service/estate/model/get-detail-response-model'
import { useHistory } from 'react-router-dom'
import {
  copyInClipBoard,
  getQuery,
  onClickTel,
  onErrorProfile,
} from '../../shared/function'
import { YNType } from '../../shared/types'
import error_profile from '../../static/img/nodata/default-profile.svg'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'

type PopupTypes = {
  title?: string
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
  is_broker_others?: boolean
}
export default function RegisterPopup(props: PopupTypes) {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  const {broker} = data

  const onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }
  const media = useRecoilValue(media_atom)

  const history = useHistory()

  const estate_idx = parseInt(getQuery().idx)

  const broker_others = props.is_broker_others ? [broker, ...broker?.other] : undefined

  const onClickBrokerProfile = (y_n: YNType, broker_idx: number) => {
    if (y_n === 'Y') {
      return history.push(`/profile-detail?i=${broker_idx}&e=${estate_idx}`)
    }
  }

  return (
    <Popup title='등록자 정보 더보기' onClickClose={(e) => onClickClose(e)}>
      <div className='register_popup'>
        <ul>
          {broker_others?.map((item) => {
            return (
              <li key={item.idx}>
                <div className='regster'>
                  <div className='img_holder'>
                    <img
                      src={item.profile_path ?? error_profile}
                      alt='매물 등록자 프로필'
                      onError={(e) => onErrorProfile(e)}
                      onClick={() =>
                        // history.push(
                        //   `/profile-detail?i=${item.idx}&e=${estate_idx}`
                        // )
                        onClickBrokerProfile(item.broker_yn, item.idx)
                      }
                    />
                  </div>
                  <div className='profile_info'>
                    <h3 className='name'>{item.nick_name?? item.name}</h3>
                    <p className='company'>{item.store_name}</p>
                    <p className='address'>{item.store_address_text}</p>
                  </div>
                </div>
                <span
                  className='tel'
                  onClick={() => {
                    media === ('M' || 'T') && onClickTel(estate_idx, item.tel)
                    media === 'P' && copyInClipBoard(item.tel)
                  }}
                >
                  <img src={tel} alt='' />
                  {item.tel}
                </span>
              </li>
            )
          })}
          {/* <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li>
          <li>
            <div className='regster'>
              <div className='img_holder'>
                <img src={profileSample} alt='' />
              </div>
              <div className='profile_info'>
                <h3 className='name'>김순덕</h3>
                <p className='company'>아름다운세상 중개소</p>
                <p className='address'>서울시 강남구 신사동 559-20 캐빈빌딩</p>
              </div>
            </div>
            <span className='tel'>
              <img src={tel} alt='' />
              010-1234-5678
            </span>
          </li> */}
        </ul>
      </div>
    </Popup>
  )
}
