import { useEffect, useState } from "react";
import backtop from "../static/img/icons/back-top.svg";

export default function BackTop() {
  const [scrollY, setScrollY] = useState(0);

  const [bottom, setBottom] = useState(40);

  const onClickBackTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollY(0);
  };

  const handleGnb = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    handleGnb();

    window.addEventListener("scroll", handleGnb);

    return () => {
      window.removeEventListener("scroll", handleGnb);
    };
  }, []);

  useEffect(() => {
    const footer_size = 112;

    const end_scroll =
      document.body.clientHeight - window.innerHeight - footer_size;

    const scroll = end_scroll - scrollY;

    const bottom_extension = scroll < 0 ? scroll * -1 : 0;

    setBottom(40 + bottom_extension);
  }, [scrollY]);

  return (
    <>
      <button
        type="button"
        className={`back_top ${scrollY > 79 ? "active" : ""}`}
        onClick={onClickBackTop}
        style={{ bottom }}
      >
        <img src={backtop} alt="bact-top" />
      </button>
    </>
  );
}
