import Axios, { AxiosRequestConfig} from "axios"
import { BANNER_URL, GET, JSON_HEADER, POST } from "../../shared/api-constant"

const banner = {
  getBannerList : () => {
      const config:AxiosRequestConfig = {
        url : `${BANNER_URL}/get_list.php`,
        method : GET ,
        headers : {
          ...JSON_HEADER
        }
      }
      return Axios(config)
    },

  getOutBannerList : () => {
      const config:AxiosRequestConfig = {
        url : `${BANNER_URL}/get_out_list.php`,
        method : GET ,
        headers : {
          ...JSON_HEADER
        }
      }
      return Axios(config)
    },

  setViewCount : (no: number) => {
      const config:AxiosRequestConfig = {
        url : `${BANNER_URL}/set_view.php`,
        method : POST ,
        headers : {
          ...JSON_HEADER
        },
        data:{
          idx: no
        }
      }
      return Axios(config)
    },
}

export default banner