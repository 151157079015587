import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import search_atom from '../../atom/search-atom'
import search_focus_atom from '../../atom/search-focus-atom'

import API from '../../service/api'
import AddressToCoordResponseModel from '../../service/kakao/model/address-to-coord-response-model'
import { PATH } from '../../shared/constant'

interface Props {
  contents: string
}

const SearchResultItem = (props: Props) => {
  const { search } = useLocation()

  const { replace } = useHistory()

  const setSearch = useSetRecoilState(search_atom)
  const setFocused = useSetRecoilState(search_focus_atom)

  function onClick() {
    API.kakao
      .addressToCoord(
        props.contents.replace("<b class='on'>", '').replace('</b>', '')
      )
      .then((res) => {
        addressToCoordSuccessCallback(res.data)
      })
      .catch(addressToCoordFailCallback)
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel) {
    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]

      setSearch(props.contents.replace("<b class='on'>", '').replace('</b>', ''))
      setFocused(false)
      replace(`${PATH.MAP}${search}#LatLng=${y}^${x}`)
    } else {
      throw new Error()
    }
  }

  function addressToCoordFailCallback() {}

  return React.createElement(
    'li',
    {},
    React.createElement('span', {
      dangerouslySetInnerHTML: { __html: props.contents },
      onClick,
    })
  )
}

export default SearchResultItem
