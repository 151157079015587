import React from 'react'
import '../../style/components/button/main-button.scss'
import LoadingMini from '../layout/loading-mini'
type MainButtonTypes = {
  title: string | JSX.Element
  types?:
    | 'prime'
    | 'second'
    | 'inactive'
    | 'priority'
    | 'side'
    | 'back'
    | 'list'
    | 'more'
    | 'blue'
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  front_icon?: JSX.Element
  show?: 'btn_hidden'
  type?: 'button' | 'submit'
  is_loading?: boolean
  id?: string
  loading_color?: string
  fixed_secondly?: 'fixed_top'
}
export default function MainButton(props: MainButtonTypes) {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (props.onClick) {
      props.onClick(e)
    }
  }

  const type = props.type ?? 'button'

  const size_class =
    props.size === 'xxsmall'
      ? 'xxsmall'
      : props.size === 'xsmall'
      ? 'xsmall'
      : props.size === 'small'
      ? 'small'
      : props.size === 'medium'
      ? 'medium'
      : props.size === 'large'
      ? 'large'
      : 'medium'

  const color_class =
    props.types === 'prime'
      ? 'prime'
      : props.types === 'second'
      ? 'second'
      : props.types === 'inactive'
      ? 'inactive'
      : props.types === 'priority'
      ? 'priority'
      : props.types === 'side'
      ? 'side'
      : props.types === 'back'
      ? 'back'
      : props.types === 'more'
      ? 'more'
      : props.types === 'list'
      ? 'list'
      : props.types === 'blue'
      ? 'blue'
      : 'inactive'

  const hidden_class = props.show === 'btn_hidden' ? ' btn_hidden' : ''
  const fixed_top_class = props.fixed_secondly === 'fixed_top' ? ' fixed_top' : ''

  const loading_class = props.is_loading ? ' is_loading' : ''

  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={`main-btn ${size_class} ${color_class}${hidden_class}${loading_class}${fixed_top_class}`}
        {...(props.id ? { id: props.id } : {})}
      >
        {props.front_icon}
        <span>{props.title}</span>
        <LoadingMini
          is_loading={props.is_loading ?? false}
          size={25}
          color={props.loading_color ?? undefined}
        />
      </button>
    </>
  )
}
