import { useState } from 'react'
import { AreaType } from '../../atom/map-filter'
import { blankFn } from '../../shared/function'
import Range from '../input/range'
import RangeCopy from '../input/range-copy'
import Nodata from '../layout/nodata'
import Map from '../map'
import InputText from '../input/input-text'
import { useRecoilState } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'

/* eslint-disable @typescript-eslint/no-unused-vars */
const PleaseSelectLot = () => {
  // const [areaType, setAreaType] = useState<AreaType>('SQ')
    const [bunyang_detail, setBunyangDetail] = useRecoilState(bunyang_detail_atom)

    const { area_type } = bunyang_detail
  return (
    <div className='view_3d_wrap'>
      {/* HUBDNCHYJ 수정 */}
      <div className='view_3d_title flex f-j-sb f-a-c'>
        <h2>
          필지 3D 가설계
          <span>선택한 필지에 건물을 가상으로 설계해보실 수 있습니다.</span>
        </h2>
        {/* <button className='pdf_btn'>PDF 저장하기</button> */}
      </div>
      {/* HUBDNCHYJ 수정 끝 */}
      <div className='row'>
        <div className='view_area'>
          <Nodata writing='필지를 선택하면 건물 가상설계가 가능합니다' />
        </div>
        <div className='modify_area'>
          <div className='modify_title flex f-j-sb'>
            <p>
              건물
              <span>(선택한 건물의 크기를 조절 할 수 있습니다.)</span>
            </p>
            <Map.AreaTypeToggle
              // area_type={area_type}
              // setAreaType={setAreaType}
            />
          </div>
          <div className='range_wrap'>
            <RangeCopy
              title='가로'
              value={1}
              onChange={blankFn}
              max_value={1}
              min_value={0}
              value_text='100m'
              disabled
            />
            <RangeCopy
              title='세로'
              value={1}
              onChange={blankFn}
              max_value={1}
              min_value={0}
              value_text='100m'
              disabled
            />
            <RangeCopy
              title='층수'
              value={1}
              onChange={blankFn}
              max_value={1}
              min_value={0}
              value_text='10층'
              disabled
            />
          </div>
          <div className='building_type'>
            <ul>
              <li>
                <span>건물 면적 :</span>
                <InputText
                  types='text'
                  id='area1'
                  name='area1'
                  readonly
                  placeholder='자동계산 입력'
                  className='ta-r'
                />
              </li>
              <li>
                <span>연 면적 :</span>
                <InputText
                  types='text'
                  id='area1'
                  name='area1'
                  readonly
                  placeholder='자동계산 입력'
                  className='ta-r'
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PleaseSelectLot
