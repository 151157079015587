import { Suspense } from "react"
import MobileGNB from "../layout/mobile-gnb"
import SandanMobileGnb from "./sandan-mobile-gnb"

/* eslint-disable @typescript-eslint/no-unused-vars */
const SandanMobileGnbWrapper = () => {
  return (
    <Suspense fallback={ <MobileGNB title="" /> }>
      <SandanMobileGnb />
    </Suspense>
  )
}

export default SandanMobileGnbWrapper