/* eslint-disable @typescript-eslint/no-unused-vars */
const NewsSkeleton = () => {
  return (
    <ul className="news-skeleton-wrap">
      {
        Array(8).fill(0).map((i , index) => {
          return (
            <li key={index} className="skeleton" />
          )
        })
      }
    </ul>
  )
}

export default NewsSkeleton