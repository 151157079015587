import { Suspense } from "react"
import Pyeong from "./pyeong"
import PyeongSkeleton from "./pyeong-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const PyeongWrapper = () => {
  return (
    <Suspense fallback={ <PyeongSkeleton /> }>
      <Pyeong />
    </Suspense>
  )
}

export default PyeongWrapper