import React, { useEffect, useMemo, useRef } from 'react'
import { randomString } from '../../shared/function'

import '../../style/components/layout/layer-map.scss'
import MapTypeButton from '../bunyang/map-type-button'
import { useRecoilValue } from 'recoil'
import map_overlay_type_atom from '../../atom/map-overlay-type'

interface Props {
  lat: number
  lng: number
  onClickClose: React.Dispatch<React.SetStateAction<boolean>>
  currentTypeId_ref: React.MutableRefObject<any>
  name?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LayerMap = (props: Props) => {
  const { currentTypeId_ref } = props
  const layer_map_ref = useRef<any>(null)
  const currentOverlayMapTypeId = useRecoilValue(map_overlay_type_atom)

  const onClickClose = () => {
    props.onClickClose(false)
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const mapDraw = () => {
    const container = document.getElementById(id)

    const options = {
      center: new window.window.kakao.maps.LatLng(props.lat, props.lng),
      level: props.name && props.name === 'bunyang' ? 7 : 2,
    }

    const map = new window.kakao.maps.Map(container, options)

    map.setMinLevel(1)
    map.setMaxLevel(12)

    layer_map_ref.current = map

    if (props.currentTypeId_ref.current) {
      layer_map_ref.current.addOverlayMapTypeId(props.currentTypeId_ref.current)
    } else {
      layer_map_ref.current.addOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT)
    }
    markerDraw()
  }

  const markerDraw = () => {
    const position = new window.kakao.maps.LatLng(props.lat, props.lng)

    const marker = new window.kakao.maps.Marker({
      position: position,
    })

    marker.setMap(layer_map_ref.current)
  }

  useEffect(() => {
    mapDraw()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const id = useMemo(() => {
    return randomString(15)
  }, [])

  useEffect(()=> {
     // maptype에 따라 지도에 추가할 지도타입을 결정
    const changeMaptype = window.kakao.maps.MapTypeId[currentOverlayMapTypeId]
    // 이미 등록된 지도 타입이 있으면 제거
    if (currentTypeId_ref.current) {
      layer_map_ref.current.removeOverlayMapTypeId(currentTypeId_ref.current)
    }
    // maptype에 해당하는 지도타입을 지도에 추가
    layer_map_ref.current.addOverlayMapTypeId(changeMaptype)
  }, [currentOverlayMapTypeId])

  return (
    <div className='layer-map-dim' onClick={onClickClose}>
      <div onClick={stopPropagation}>
        <button onClick={onClickClose}>
          <div></div>
          <div></div>
        </button>
        <div className='rel'>
          <div id={id} className='estate-full-map' />
          <MapTypeButton full_map />
        </div>
      </div>
    </div>
  )
}

export default LayerMap
