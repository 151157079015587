import * as T from 'three'
import { Canvas, ThreeEvent, useFrame, useLoader, useThree } from '@react-three/fiber'
import {
  PerspectiveCamera,
  OrbitControls,
  Sky,
  Box,
  Text,
} from '@react-three/drei'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { qs, three_util } from '../../shared/function'
import useComponentWillUnmount from '../../hooks/use-component-will-unmount'

import rotate from '../../static/img/icons/icon_rotate.svg'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import { BoxModel } from './view-3d'
import Skeleton from '../skeleton'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import compass from '../../static/img/icons/compass-icon.svg'

const PALETTE = {
  PRIME: '#007eff',
  SECONDARY: '#1f8fff',
  PLANE: '#001105',
}

export const SIZE = {
  FLOOR_HEIGHT_BASE: 1.5,
  WIDTH: 0.4,
  RECTANGLE_X: 1.2,
  RECTANGLE_Z: 0.83,
}

const BaseBox = (props : {onMove:(position: [number, number])=> void}) => {
    return (
      <mesh
        onPointerMove={(e)=>props.onMove([e.point.x, e.point.z])}
        position={[0, 0, 0]} // 해당 오브젝트의 설치 위치를 설정합니다.
        scale={[1, 1, 1]} // 해당 오브젝트의 크기를 설정합니다.
        rotation={[-Math.PI / 2, 0, 0]}
        receiveShadow
      >
        {/* <boxGeometry // 부피 설정
        args={[100, 1, 100]} // 부피 인자 값 ( width , height , depth , widthSegments , heightSegments , depthSegments )
      /> */}
        <planeGeometry args={[100, 100]} />
        <meshStandardMaterial // 질감 설정
          color='#fff' // 질감 색상 값
        />
      </mesh>
    )
  }

interface PlatModel {
  lot: LotModel
  isPdfImg?: boolean
}

const Plat = React.memo((props: PlatModel) => {
  const { lot } = props

  const { _min_x, x_gap, _min_y, y_gap } = three_util.getLocationData(lot)

  const material = new T.MeshStandardMaterial({ color: PALETTE.PLANE })

  const shape = new T.Shape()

  let index = 0

  for (const item of lot.lot) {
    const x_aspect = (item[0] - _min_x) / x_gap - 0.5
    const y_aspect = (item[1] - _min_y) / y_gap - 0.5

    if (index === 0) shape.moveTo(x_aspect, y_aspect)
    else shape.lineTo(x_aspect, y_aspect)

    index += 1
  }

  const geometry = new T.ShapeGeometry(shape)

  geometry.rotateX(-0.5 * Math.PI)
  geometry.rotateY(0.5 * Math.PI)

  const compassData = useLoader(SVGLoader, compass)
  const shapes = compassData.paths.flatMap((g, index) => g.toShapes(true).map((shape) => ({ shape, color: g.color, index })))

  const cpNMaterial = new T.MeshBasicMaterial({ color: '#FF0000' })
  const cpMaterial = new T.MeshBasicMaterial({ color: '#333' })

  const cpGeometry = new T.ShapeGeometry(shapes.map(s=> s.shape))
  const cpRotation = new T.Euler(-0.5 * Math.PI, 0, -0.5 * Math.PI)

  return (
    <group>
      <mesh material={material} position={[0, 0.001, 0]} geometry={geometry} />
      {!props.isPdfImg &&
        <mesh
          rotation={cpRotation}
          material={[cpMaterial, cpNMaterial, cpMaterial]}
          position={[0.3, 0.01, 0.5]}
          scale={[0.001, 0.001, 0.001]}
          geometry={cpGeometry}
        />
      }
    </group>
  )
})

interface RectangleModel {
  box: BoxModel
  boxIndex: number
  boxCount: number
  lot: LotModel
  focused: boolean //focused => true 카메라 줌 및 회전 제한
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  castShadow: boolean
  selectedBox?: BoxModel
  setSelectedBox: React.Dispatch<React.SetStateAction<BoxModel | undefined>>
  onChangeSelectedBox: (box: BoxModel, newBoxList?: BoxModel[]) => void
  // onChangeBoxPosition?: (selectedBoxIdx: number, xz: [number, number]) => void
  isPdfImg?: boolean
  pointer: [number, number]
}

interface UnitModel {
  shape: RectangleModel
  index: number
  hovered: boolean
  setHovered: Dispatch<SetStateAction<boolean>>
  xz: [number, number]
  setXz: Dispatch<SetStateAction<[number, number]>>
}

const Rectangle = (props: RectangleModel): JSX.Element => {
  const [hovered, setHovered] = useState(false)
  const [xz, setXz] = useState<[number, number]>([0, 0])

  useEffect(() => {
    if (props.box.idx !== 0) {
      const { square_area } = three_util.getLocationData(props.lot)
      const plat_width = Math.sqrt(square_area)
      const size = props.box.depth / plat_width
      setXz([0, (0.5 + size / 2) * -1])
    } else {
      setXz([0, 0])
    }
  }, [props.lot])

  return (
    <>
      {Array(props.box.floor)
        .fill(0)
        .map((_, index) => {
          return (
            <Unit
              shape={props}
              key={`${props.box.idx}_${index}`}
              index={index}
              hovered={hovered}
              setHovered={setHovered}
              xz={xz}
              setXz={setXz}
            />
          )
        })}
    </>
  )
}

const Unit = (props: UnitModel): JSX.Element => {
  const ref = useRef<any>()
  const { box, isPdfImg } = props.shape

  const prev_xz = useRef<[number, number]>([99, 99])
  const start_xz = useRef<[number, number]>([99, 99])

  useEffect(() => {
    qs('html')!.style.cursor = props.hovered ? 'pointer' : 'unset'
  }, [props.hovered])

  useEffect(() => {
    if (ref.current) {
      ref.current.rotation.y = T.MathUtils.degToRad(box.rotate) * -1
    }
  }, [box.rotate])

  const isSelected = props.shape.selectedBox
    ? props.shape.selectedBox.idx === box.idx
      ? true
      : false
    : false

  // 기본 옵션 값 얻기
  const { floor_height, color, opacity, downstairs_height, square_area } =
    three_util.getDefaultOption(
      props.index,
      props.shape.lot,
      props.hovered,
      isSelected
    )

  const _width = Math.sqrt(box.area)

  const plat_width = Math.sqrt(square_area)

  const size = _width / plat_width

  const { RECTANGLE_X, RECTANGLE_Z } = SIZE
  // 직사각형의 크기
  const scale = new T.Vector3(
    box.width / plat_width,
    isPdfImg ? 0.01 : floor_height,
    box.depth / plat_width
  )

  const position = new T.Vector3( props.xz[0], isPdfImg ? 0 : downstairs_height,props.xz[1] )

  //건물 TEXT
  const fontSize = size / 3 < 0.15 ? size / 3 : 0.15
  const textPosition = new T.Vector3( props.xz[0], isPdfImg ? 0.02 : downstairs_height + floor_height / 2 + 0.01, props.xz[1] )
  const textRotation = new T.Euler((Math.PI / 2) * -1, 0, 0)

  //pointer 변경 시 박스 이동
  useEffect(() => {
    if (props.shape.selectedBox?.idx === box.idx) {
      const [start_x, start_z] = start_xz.current
      const [prev_x, prev_z] = prev_xz.current

      if (
        props.shape.focused &&
        start_x !== 99 &&
        start_z !== 99 &&
        prev_x !== 99 &&
        prev_z !== 99
      ) {
        const x = props.shape.pointer[0]
        const z = props.shape.pointer[1]

        const max_x = 0.5 - (size / 2) * RECTANGLE_Z
        const min_x = max_x * -1

        const max_z = 0.5 - (size / 2) * RECTANGLE_X
        const min_z = max_z * -1

        const _x = prev_x + x - start_x
        const _z = prev_z + z - start_z

        if (props.shape.boxCount <= 1) {
          //건물이 하나일 때 Plat 넘어가지 않도록 제한
          props.setXz([
            _x > max_x ? max_x : _x < min_x ? min_x : _x,
            _z > max_z ? max_z : _z < min_z ? min_z : _z,
          ])
        } else {
          props.setXz([_x, _z])
        }
      }
    }
  }, [props.shape.pointer])

  const onPointerDown = (e: ThreeEvent<PointerEvent>) => {
        if (box.idx === props.shape.selectedBox?.idx){
      const { x, z } = e.point

      start_xz.current = [x, z]
      prev_xz.current = [...props.xz]
      props.shape.setFocused(true)
    } else {
      // onClick(e)
    }
  }

  //MouseUp시 box이동 stop
  useEffect(() => {
    if (!props.shape.focused) {
      start_xz.current = [99, 99]
      prev_xz.current = [99, 99]
      // props.shape.onChangeBoxPosition &&
      //   props.shape.onChangeBoxPosition(box.idx, props.xz)
    }
  }, [props.shape.focused])

  //건물 선택
  const onClick = (e: ThreeEvent<MouseEvent | PointerEvent>) => {
    e.stopPropagation()
    props.shape.setSelectedBox(box)
    if (box.idx !== props.shape.selectedBox?.idx) {
      props.shape.onChangeSelectedBox(box)
    }
  }

  return (
    <>
      <group>
        <Box
          ref={ref}
          // onClick={onClick}
          onPointerUp={onClick}
          onPointerDown={onPointerDown}
          onPointerOver={() => props.setHovered(true)}
          onPointerLeave={() => props.setHovered(false)}
          scale={scale}
          position={position}
          castShadow={props.shape.castShadow}
        >
          {/* <meshPhongMaterial
            color={color}
            opacity={isPdfImg ? 1 : opacity}
            transparent
          /> */}
          <meshLambertMaterial 
            color={color}
            opacity={isPdfImg ? 1 : opacity}
            transparent
          />
        </Box>
        {props.index + 1 === box.floor && (
          <Text
            position={textPosition}
            rotation={textRotation}
            color={'#0048ff'}
            fontSize={fontSize}
            fillOpacity={isPdfImg ? 1 : opacity}
          >
            {String.fromCharCode(65 + props.shape.boxIndex)}
          </Text>
        )}
      </group>
    </>
  )
}

interface GridModel {
  lot: LotModel
}

const Grid = ({ lot }: GridModel) => {
  const _ = three_util.getLocationData(lot)

  const standard_size = Math.max(_.y_meter, _.x_meter)

  return (
    <gridHelper
      args={[1 * 1000, standard_size * 100, '#fff', '#c2cad1']}
      position={[0, 0.002, 0]}
    />
  )
}

interface Props {
  area: number
  lot: Array<LotModel>
  plat_area: number

  boxList?: Array<BoxModel>
  selectedBox?: BoxModel
  setSelectedBox: Dispatch<SetStateAction<BoxModel | undefined>>
  onClickRotateBtn: (direction: 'R' | 'L') => void
  onChangeSelectedBox: (box: BoxModel, newBoxList?: BoxModel[]) => void
  addBox: () => void
  removeBox: (removeBox: BoxModel) => void
  // onChangeBoxPosition?: (selectedBoxIdx: number, xz: [number, number]) => void
  isPdfImg?: boolean
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Area3d = (props: Props) => {
  const media = useRecoilValue(media_atom)

  const {
    lot,
    boxList,
    selectedBox,
    setSelectedBox,
    onClickRotateBtn,
    onChangeSelectedBox,
    // onChangeBoxPosition,
  } = props

  const [focused, setFocused] = useState(false)
  const [pointer, setPointer] = useState<[number, number]>([0, 0])

  useComponentWillUnmount(() => {
    qs('html')!.style.cursor = 'unset'
  })

  //건물 드래그 수정
  const canvasRef = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    
  }, [canvasRef])

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.addEventListener('mouseup', onMouseUp)
      canvasRef.current.addEventListener('touchend', onMouseUp)
    }

    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('mouseup', onMouseUp)
        canvasRef.current.removeEventListener('touchend', onMouseUp)
      }
    }
  }, [])

  const onMouseUp = (e: any) => {
    e.preventDefault()

    setFocused(false)
  }

  const onPointerMove = (position: [number, number]) => {
    if(focused){
      setPointer(position)
    }
  }

  const [isSkeleton, setIsSkeleton] = useState(false)

  useEffect(()=>{
    if(props.isPdfImg){
      setIsSkeleton(true)
    } else {
      setTimeout(()=>{
        setIsSkeleton(false)
      },1000)
    }

  },[props.isPdfImg])

  return (
    <div className='view_area_box'>
      {isSkeleton &&
        <div className='pdfLoading'>
          <Skeleton width='100%' height='100%' />
        </div>
      }
      <div className='view_area rel'>
        <button
          className={`controll-button add-button ${
            boxList && boxList?.length >= 6 && 'disable'
          }`}
          disabled={boxList && boxList?.length >= 6 ? true : false}
          onClick={props.addBox}
        >
          추가
        </button>
        <button
          className={`controll-button remove-button ${
            boxList && boxList?.length <= 1 && 'disable'
          }`}
          disabled={boxList && boxList?.length <= 1 ? true : false}
          onClick={() => {
            selectedBox && props.removeBox(selectedBox)
          }}
        >
          삭제
        </button>
        <button className='rotate-button left'>
          <img
            src={rotate}
            alt='회전 아이콘'
            onClick={() => onClickRotateBtn('L')}
          />
        </button>
        <button className='rotate-button right'>
          <img
            src={rotate}
            alt='회전 아이콘'
            onClick={() => onClickRotateBtn('R')}
            style={{ transform: 'scaleX(-1)' }}
          />
        </button>

        {/**
         * @date
         * 2022-03-07 11:19:51
         * @member
         * HUBDNCKS
         * @history
         * @add
         * @Canvas.frameloop='demand'
         * @reason
         * 메모리 릭 발생으로 해당 props 추가함
         * Geometry 혹은 Material을 그리는 라이프 사이클을
         * Scene 에 변화가 생길 경우게만 재계산하는 방식으로 변경
         */}
        <Canvas
          id='pdf_3d'
          ref={canvasRef}
          gl={{ preserveDrawingBuffer: true }}
          style={
            props.isPdfImg
              ? media === 'P' ? {
                  width: '385px',
                  height: '368px',
                }:{
                  width: '262px',
                  height: '250px',
                }
              : {
                  position: 'absolute',
                  // width: `calc(100% - 32px)`,
                  // height: `calc(100% + 40px)`,
                  width: '100%',
                  height: '100%',
                  overflow: `hidden`,
                  left: `0px`,
                  top: `0px`,
                  // bottom: `28px`,
                  // transform: `translateX(-50%)`,
                }
          }
          shadows // 그림자 생성을 위한 속성 추가
          // frameloop='demand' // 시점 이동 시, 버벅임으로 인한 주석 처리
        >
          {/** 전체 Scene에 하늘 배경을 적용합니다. */}
          <Sky
            sunPosition={[5, 2, 0]} // 태양의 위치
          />
          {/** 주변 광원 : 모든 오브젝트에 빛을 비추어 가시 형태로 만듭니다. */}
          <ambientLight />
          {/** 포인트 광원 : 한 방향에서 빛이 방출됩니다. 전구와 같은 형태로 오브젝트에 그림자가 생길 수 있습니다. */}
          <pointLight
            position={[10, 10, 0]} // 광원의 설치 위치를 설정
            color={0xffffff} // 광원의 컬러 값을 설정
            intensity={5} // 광원의 세기를 설정합니다.
            castShadow // 그림자를 주고자 하는 light에 속성 부여
            shadow-mapSize-height={2048}
            shadow-mapSize-width={2048}
          />
          {/** 원근법 기법에 따른 카메라를 설치합니다. */}
          <PerspectiveCamera
            zoom={props.isPdfImg ? 0.8 : 1}
            aspect={(window.innerWidth - 28) / 250}
            position={props.isPdfImg ? [0, 1, 0] : [1, 1, 1]} // 카메라의 위치입니다.
            makeDefault // 기본 카메라로 지정합니다.
          />
          {/** 카메라 줌 및 회전을 위한 컨트롤러를 설치합니다. */}
          <OrbitControls
            maxDistance={10} // 최대 줌 out 값을 설정
            minDistance={0.5} // 최소 줌 in 값을 설정
            maxPolarAngle={Math.PI / 2 - 0.1} // 최대 수직 회전 theta 값을 설정
            autoRotateSpeed={-1} // 자동 회원 속도 설정 ( 음수로 설정 시 역방향으로 회전 )
            enablePan={false} // 카메라 위치 이동 비활성화 설정
            enableRotate={!focused}
          />
          {/** 바닥 지형을 설치합니다. */}
          <BaseBox onMove={onPointerMove} />

          {/** 토지 지형을 설치합니다. */}
          <Plat lot={lot[0]} isPdfImg={props.isPdfImg} />

          {/** 현재 층 수에 맞게 설정된 건물 형태를 렌더링합니다. */}
          {boxList &&
            boxList.map((box, index) => {
              return (
                <Rectangle
                  key={box.idx}
                  box={box}
                  boxIndex={index}
                  boxCount={boxList.length}
                  focused={focused}
                  setFocused={setFocused}
                  lot={lot[0]}
                  castShadow={false}
                  selectedBox={selectedBox}
                  setSelectedBox={setSelectedBox}
                  onChangeSelectedBox={onChangeSelectedBox}
                  // onChangeBoxPosition={onChangeBoxPosition}
                  isPdfImg={props.isPdfImg}
                  pointer={pointer}
                />
              )
            })}
          {/** 그리드 영역 */}
          <Grid lot={lot[0]} />
        </Canvas>
      </div>
    </div>
  )
}

export default Area3d
