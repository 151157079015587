import EstateMap from '../detail/estate-map'

interface Props {
  lat: number
  lng: number
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Top = ({ lat, lng }: Props) => {
  return (
    <div className='detail_card'>
      <EstateMap
        id='__rt_map__'
        className='rt_map'
        lat={lat}
        lng={lng}
        location_bottom_right
      />
    </div>
  )
}

export default Top
