import React, { useMemo } from 'react'
import { AreaType } from '../../atom/map-filter'
import { encNumber, random } from '../../shared/function'
import '../../style/components/input/unit-toggle.scss'

interface Props {
  area_type: AreaType
  setAreaType: React.Dispatch<React.SetStateAction<AreaType>>
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const AreaTypeToggle = ({ area_type, setAreaType }: Props) => {
  const onChange = () => {
    setAreaType(area_type === 'PY' ? 'SQ' : 'PY')
  }

  const name = useMemo(() => {
    return encNumber(random(10, 9999).toString())
  }, [])

  const id = useMemo(() => {
    return random(10000, 1000000)
  }, [])

  const left_id = encNumber((id + 502).toString())

  const right_id = encNumber((id + 702).toString())

  return (
    <div className={`unit_toggle`}>
      <input
        className='_left'
        type='checkbox'
        id={left_id}
        name={name}
        onChange={onChange}
        checked={area_type === 'PY'}
      />
      <label htmlFor={left_id}>평</label>
      <input
        className='_right'
        type='checkbox'
        id={right_id}
        name={name}
        onChange={onChange}
        checked={area_type === 'SQ'}
      />
      <label htmlFor={right_id}>㎡</label>
      <div className='handle'>{area_type === 'PY' ? '평' : '㎡'}</div>
    </div>
  )
}

export default AreaTypeToggle
