interface Props {
  className? : string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const LoadingFallback = (props:Props) => {
  return (
      <div className={`__loading-fall__${props.className ? ` ${props.className}` : ''}`}>
        <div className='__loading__'></div>
      </div>
  )
}

export default LoadingFallback