import { useRecoilState, useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import { comma, getQuery } from "../../shared/function"
import get_sandan_detail_selector from "../../selector/get-sandan-detail-selector"
import sandan_detail_atom from "../../atom/sandan-detail"

/* eslint-disable @typescript-eslint/no-unused-vars */
const Detail = () => {

  const media = useRecoilValue(media_atom)

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const sandan_detail = useRecoilValue(sandan_detail_atom)

  let avg_price = data?.avg_route_price ?? undefined
  let set_area  = data.set_area ?? undefined
  const k = sandan_detail.area_type === 'PY' ? '평' : 'm²'

  if(sandan_detail.area_type === 'PY'){
    avg_price = avg_price ? avg_price * (set_area ?? 0) : undefined
    set_area  = set_area ? set_area / 3.3058 : undefined

    avg_price = avg_price && set_area ? Math.round(avg_price / set_area) : undefined
    set_area  = set_area ? Math.round(set_area) : undefined
  }

  return (
    <article className="more_info ic_base_info">
      <div className="desc_text ws-bs" style={{ marginTop : media === "P" ? 40 : 25 }}>
        <h2>사업개요</h2>
        <table>
          <colgroup>
            <col width="15%"/>
            <col width="35%"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr className="top_line">
              <th>단지명</th>
              <td>{data.name ?? data.api_name ?? '데이터없음'}</td>
              <th>단지 유형</th>
              <td>{data.type ?? '데이터없음'}</td>
            </tr>
            <tr >
              <th>조성상태</th>
              <td>{data.design_status ?? '데이터없음'}</td>
              <th>분양상태</th>
              <td>{data.route_status ?? '데이터없음'}</td>
            </tr>
            <tr>
              <th>위치</th>
              <td colSpan={3}>{data.address ?? '데이터없음'}</td>
            </tr>
            <tr>
              <th>조성목적 및 특징</th>
              <td colSpan={3} style={{paddingLeft: '5px', textAlign: 'left'}} dangerouslySetInnerHTML={{__html: data?.purpose?.length > 0 ? data.purpose.map((item) => item.purpose).join('<br/>') : '데이터없음'}} />
            </tr>
            <tr>
              <th>사업기간(년월)</th>
              <td colSpan={3}>{data.business_schedule ?? '데이터없음'}</td>
            </tr>
            <tr>
              <th>산업용지 평균분양가<br/>(원/{k})</th>
              <td>{avg_price ? `${comma(avg_price ?? 0)}원` : '데이터없음'}</td>
              <th>지정일</th>
              <td>{data.set_date ?? '데이터없음'}</td>
            </tr>
            <tr>
              <th>지정면적</th>
              <td>{set_area ? `${comma(set_area ?? 0)}${k}` : '데이터없음'}</td>
              <th>조성공정률</th>
              <td>{data.route_rate ? `${data.route_rate}%` : '데이터없음'}</td>
            </tr>
            <tr>
              <th>실시승인일</th>
              <td>{data.work_start_date ?? '데이터없음'}</td>
              <th>준공만기일</th>
              <td>{data.work_end_date ?? '데이터없음'}</td>
            </tr>
            <tr>
              <th>사업시행자명</th>
              <td>{data.corp ?? '데이터없음'}</td>
              <th>관리기관명</th>
              <td>{data.organize ?? '데이터없음'}</td>
            </tr>
          </tbody>
        </table>
       
        <h2>유치업종</h2>
        <table>
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
          </colgroup>
          <tbody>
            <tr className="top_line">
              <th>번호</th>
              <th>업종명</th>
              <th>업종코드</th>
            </tr>
            {data.attract_type.map((item, index) => {
              return (
                <tr key={`attract-type-list-${item.work_type_id}`}>
                  <td>{comma(index + 1)}</td>
                  <td>{item.name}</td>
                  <td>{item?.work_type_id ? item.work_type_id.toString().substring(0,2) : '데이터없음'}</td>
                </tr>
              )
            })}

            {data.attract_type.length < 1 && (
              <tr><td colSpan={3}>데이터 없음</td></tr>
            )}
          </tbody>
        </table>
      </div>
    </article>
  )
}

export default Detail