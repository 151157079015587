import { Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import Skeleton from '../skeleton'
import Top from './top'

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileTopWrapper = () => {
  const media = useRecoilValue(media_atom)

  if (media === 'P') return <></>

  return (
    <Suspense fallback={<Skeleton width='100%' height={300} />}>
      <Top />
    </Suspense>
  )
}

export default MobileTopWrapper
