import { useState } from "react"
import { useRecoilState } from "recoil"
import map_overlay_type_atom from "../../atom/map-overlay-type"

interface Props {
  bottom_right?: boolean
  full_map?: boolean
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapTypeButton = (props : Props) => {

  const [detail_btn2, setDetailBtn2] = useState(false)
  const [currentOverlayMapTypeId, setCurrentOverlayMapTypeId] = useRecoilState(map_overlay_type_atom)

  const onClickMapHandler = (type: 'TERRAIN' | 'HYBRID' | 'ROADMAP') => {
    setCurrentOverlayMapTypeId(type)
  }
  
  const onClickDetailBtn2 = () => {
    if (currentOverlayMapTypeId !== 'USE_DISTRICT') {
      setDetailBtn2(false)
      setCurrentOverlayMapTypeId('USE_DISTRICT')
    } else {
      setDetailBtn2(!detail_btn2)
    }
  }
  
  return (
    <div className={`map-type ${props.bottom_right ? 'br' : ''} ${props.full_map ? 'full_map' : ''}`}>
      <button
        className={`tool ${detail_btn2 ? 'active' : ''} ${
          currentOverlayMapTypeId !== 'USE_DISTRICT' ? 'on' : ''
        }`}
        onClick={() => onClickDetailBtn2()}
      >
        {currentOverlayMapTypeId === 'HYBRID'
          ? '위성'
          : currentOverlayMapTypeId === 'TERRAIN'
          ? '지형'
          : currentOverlayMapTypeId === 'ROADMAP'
          ? '지도'
          : '지적'}
        {detail_btn2 ? (
          <div className='detail_btn'>
            <button
              type='button'
              className='tool size2'
              onClick={() => onClickMapHandler('HYBRID')}
            >
              위성
            </button>
            <button
              type='button'
              className='tool size2'
              onClick={() => onClickMapHandler('TERRAIN')}
            >
              지형
            </button>
            <button
              type='button'
              className='tool size2'
              onClick={() => onClickMapHandler('ROADMAP')}
            >
              지도
            </button>
          </div>
        ) : (
          <></>
        )}
      </button>
    </div>
  )
}

export default MapTypeButton