import axios, { CancelTokenSource } from 'axios'
import { selectorFamily } from 'recoil'
import API from '../service/api'
import {
  GetDetailBrokerResponseDataModel,
  GetDetailFloorResponseListModel,
  GetDetailHistoryResponseListModel,
  GetDetailLocationResponseListModel,
  GetDetailResponseDataModel,
  GetDetailSimilarResponseListModel,
  GetDetailTrendResponseDataModel,
  TemporaryDesignModel,
} from '../service/estate/model/get-detail-response-model'
import { YNType } from '../shared/types'
import { useHistory } from 'react-router-dom'
import { PATH } from '../shared/constant'
import { useRecoilValue } from 'recoil'
import detail_type3d_atom from '../atom/detail-type3d'
import estateData from '../pages/sale-detail/estate.json'

interface PersonalModel {
  fave_yn: YNType
}

interface BrokerModel {
  homepage: string
  tel: string
  idx: number
  profile_path: string
  name: string
  nick_name: string
  store_name: string
  store_address_text: string
  estate_count: number
  broker_yn: YNType
  other: Array<GetDetailBrokerResponseDataModel> // 동일 매물 작성한 중개인 배열
  brokerage_number?: string
}

interface DetailModel extends GetDetailResponseDataModel {
  title: string
  price_main: number
  total_plat_area: number
  period_bunyang_end_ymd: string
  period_bunyang_start_ymd: string
  bunyang_due_ymd: string
  contractor: string
  executor: string
  supply_type_text: string
  finance_company: string
  construction_completion_ymd: string
  period_business_start_ymd: string
  period_business_end_ymd: string
  scale_complex: string
  total_household_count: number
  bunyang_household_count: number
  floor_lowest: number
  floor_top: number
  complete_ymd: string
  price_per_area_yn: YNType
  explain: string
  explain2: string

  bunyang_status: string //분양 진행현황
  bunyang_price_text: string //평당분양가
  shape_type: string //형태(지식산업센터)
  bld_area: number //건물면적

  price_bunyang_txt: string // 평단 분양가(텍스트)
}

export interface NoticeModel {
  original_name: string
  path: string
}

interface NearbyModel {
  icon_path: string
  name: string
}

interface PyeongModel {
  path: string
  title: string
}

type PolygonType = [number, number]

interface LotModel extends TemporaryDesignModel {
  title: string
  lot: Array<PolygonType>
  upjong_code: string
  upjong_color_inner: string
}

interface GetBunyangDetailResponseModel {
  preview: Array<string>
  detail: DetailModel
  personal: PersonalModel
  broker: BrokerModel
  notice: Array<NoticeModel>
  nearby: Array<NearbyModel>
  pyeong: Array<PyeongModel>
  lot: Array<LotModel>
  history: Array<GetDetailHistoryResponseListModel>
  trend: GetDetailTrendResponseDataModel
  location: Array<GetDetailLocationResponseListModel>
  floor: Array<GetDetailFloorResponseListModel>
  similar: Array<GetDetailSimilarResponseListModel>
}

let cancel_token: CancelTokenSource | null = null

const get_bunyang_detail_selector = selectorFamily<
  GetBunyangDetailResponseModel,
  string
>({
  key: '@bunyang/get_bunyang_detail_selector',
  get: (idx: string) => async () => {
    // 3d 가설계 페이지 여부 확인하여 redirect 방지
    const isType3d = useRecoilValue(detail_type3d_atom)

    const { replace } = useHistory()

    if (idx === '' || idx == null) {
      isType3d && alert('조회할 수 없는 정보입니다.')
      isType3d && replace(PATH.MAIN)
      return
    }
    if (cancel_token) cancel_token.cancel()

    cancel_token = axios.CancelToken.source()
    const response = await API.estate
      .getDetail(parseInt(idx), cancel_token.token)
      .catch(() => {
        isType3d && alert('조회할 수 없는 정보입니다.')
        isType3d && replace(PATH.MAIN)
        return
      })

    const data = response?.data

    return data
  },
})

export default get_bunyang_detail_selector
