import EChartsReact from 'echarts-for-react'
import { useEffect, useState } from 'react'
import API from '../../service/api'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import CoordToAddressResponseModel from '../../service/kakao/model/coord-to-address-response-model'
import { bigNumberDot } from '../../shared/function'
import Skeleton from '../skeleton'

interface Props {
  data: GetDetailResponseModel
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const TrendChart = ({ data }: Props) => {
  const { detail, trend } = data

  const { avg_price, cnt, ym } = trend

  const [location, setLocation] = useState('')

  const getDong = () => {
    API.kakao
      .coordToAddress(detail.lat, detail.lng)
      .then((res) => {
        const data = res.data as CoordToAddressResponseModel

        if (data.meta.total_count === 0) throw new Error()

        const item = data.documents[0]

        if (item.region_3depth_name) setLocation(item.region_3depth_name)
        else if (item.region_2depth_name) setLocation(item.region_2depth_name)
        else throw new Error()
      })
      .catch(coordToAddressFailCallback)
  }

  const coordToAddressFailCallback = () => {
    setLocation('강남구')
  }

  useEffect(() => {
    getDong()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (ym.length === 0) return <></>

  return (
    <article>
      {location === '' ? (
        <Skeleton width={100} height={25} marginBottom={16} radius={4} />
      ) : (
        <h2>'{location}' 매물 시세</h2>
      )}
      <div className='chart_box'>
        <EChartsReact
          style={{ width: '100%', height: '100%' }}
          option={{
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['거래량', '실거래가'],
              right: '20px',
              top: '0',
              textStyle: {
                fontSize: 12,
                color: '#515C64',
              },
              icon: 'circle',
            },
            xAxis: {
              data: ym,
              axisLine: {
                show: true,
                onZero: true,
                lineStyle: {
                  width: '2',
                  color: '#e9eaec',
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: { show: false },
              splitArea: { show: false },
              axisLabel: {
                color: '#acacac',
              },
            },
            yAxis: [
              {
                type: 'value',
                axisLine: {
                  show: false,
                  lineStyle: {
                    show: false,
                    color: '#959EA5',
                  },
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: 'dashed',
                    color: '#eaeaec',
                  },
                },
              },
              {
                type: 'value',
                axisLine: {
                  show: false,
                  lineStyle: {
                    show: false,
                    color: '#959EA5',
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: 'dashed',
                    color: '#eaeaec',
                  },
                },
                axisLabel: {
                  formatter: function (data: any) {
                    return bigNumberDot(data) + '원'
                  },
                },
              },
            ],
            grid: {
              left: '30px',
              right: '65px',
              bottom: '30px',
              top: '40px',
            },
            series: [
              {
                name: '실거래가',
                type: 'bar',
                yAxisIndex: 1,
                data: avg_price,
                itemStyle: {
                  color: '#65378040',
                },
                animationDelay: function (i: number) {
                  return i * 10
                },
              },
              {
                name: '거래량',
                type: 'line',
                stack: 'one',
                data: cnt,
                itemStyle: {
                  color: '#5d85e5',
                },
                lineStyle: {
                  width: '3',
                },
                symbolSize: 10,
                symbol: 'circle',
              },
            ],
            animationEasing: 'elasticOut',
            animationDelayUpdate: function (i: number) {
              return i * 5
            },
          }}
        />
      </div>
    </article>
  )
}

export default TrendChart
