import { Link, useHistory } from 'react-router-dom'
import logofooter from '../../static/img/logo-footer.svg'
import blog from '../../static/img/icons/footer-blog.svg'
import youtube from '../../static/img/icons/footer-youtube.svg'
import facebook from '../../static/img/icons/footer-facebook.svg'
import instagram from '../../static/img/icons/footer-instagram.svg'
import '../../style/components/layout/footer.scss'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import accountManager from '../../shared/account-manager'
import React from 'react'
import { PATH } from '../../shared/constant'

type FooterTypes = {
  vis_footer?: boolean
}
export default function Footer(props: FooterTypes) {
  const media = useRecoilValue(media_atom)

  const is_mobile = media === 'M'

  const history = useHistory()

  function onClickLogout(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    accountManager.logout()
    history.push(PATH.LOGIN)
  }

  return (
    <>
      <footer id='footer' className={`${props.vis_footer ? 'vis_footer' : ''}`}>
        <div className='inner'>
          <article className='left_content'>
            {media === 'M' ? (
              <></>
            ) : (
              <Link to='/'>
                <img src={logofooter} alt='' />
              </Link>
            )}

            <div className='footer_info_warp'>
              <ul className='footer_menu_list'>
                {is_mobile && (
                  <>
                    <li className='mb_only'>
                      <Link to='/introduction'>회사소개</Link>
                    </li>
                    {/* 23.05.15 해당 영역 미노출 */}
                    {/* <li className='mb_only'>
                      <Link
                        to='/product-plus'
                        onClick={() =>
                          window.scrollTo({
                            top: 0,
                          })
                        }
                      >
                        광고/제휴문의
                      </Link>
                    </li> */}
                  </>
                )}
                <li>
                  <Link to='/notice?tab=1'>공지사항</Link>
                </li>
                {is_mobile && (
                  <li>
                    <Link to='/notice?tab=2'>이벤트</Link>
                  </li>
                )}
                <li>
                  <Link to='/notice?tab=3'>FAQ</Link>
                </li>
                <li>
                  <Link to='/terms'>이용약관</Link>
                </li>
                {/* 23.05.15 해당 영역 미노출 */}
                {/* <li>
                  <Link
                    to='/product-plus'
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                      })
                    }
                  >
                    광고/제휴문의
                  </Link>
                </li> */}
                {is_mobile && accountManager.isLogin() && (
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href='#' onClick={onClickLogout}>
                      로그아웃
                    </a>
                  </li>
                )}
              </ul>
              <address>
                {media === 'M' ? (
                  <>
                    <ul>
                      <li>공짱 Tel : 1899-2296</li>
                    </ul>
                    <ul>
                      <li>Email : gongzzanginc@naver.com</li>
                      <li>사업자번호 : 204-87-02334</li>
                    </ul>
                    {/* <ul>
                      <li>통신판매업신고번호 : 123-45-678999</li>
                    </ul> */}
                    <ul>
                      <li>
                        주소 : 경기도 화성시 동탄기흥로 590,
                        동탄센트럴에이스타워 B동 9층 14호
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul>
                      <li>공짱 Tel : 1899-2296</li>
                      <li>Email : gongzzanginc@naver.com</li>
                      <li>사업자번호 : 204-87-02334</li>
                    </ul>
                    <ul>
                      {/* <li>통신판매업신고번호 : 123-45-678999</li> */}
                      <li>
                        주소 : 경기도 화성시 동탄기흥로 590,
                        동탄센트럴에이스타워 B동 9층 14호
                      </li>
                    </ul>
                  </>
                )}
              </address>
              <p className='copyright'>
                Copyright © GONGZZANG. All rights reserved.
              </p>
            </div>
          </article>
          <article className='right_content'>
            <button
              type='button'
              onClick={() => history.push('/introduction/broker')}
            >
              공짱 중개사
            </button>
            <ul>
              <li>
                <a href='https://blog.naver.com/gongzzanginc'>
                  <img src={blog} alt='' />
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/@user-iz7iy5es7t/'>
                  <img src={youtube} alt='' />
                </a>
              </li>
              {/* <li>
                <Link to=''>
                <img src={facebook} alt='' />
                </Link>
              </li> */}
              <li>
                <a href='https://instagram.com/gongzzang__app?igshid=NGVhN2U2NjQ0Yg=='>
                  <img src={instagram} alt='' />
                </a>
              </li>
            </ul>
          </article>
        </div>
      </footer>
    </>
  )
}
