import axios, { AxiosError, CancelTokenSource } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_side_bar_list_atom from '../../atom/map-side-bar-list'
import { EstateModel } from '../../selector/get-map-data-selector'
import API from '../../service/api'
import ApiBaseResponseModel from '../../service/model/api-base-response-model'
import { apiCallback, getEstateDetailPath } from '../../shared/function'
import LoadingFallback from '../loading/loading-fallback'
import MapSideNodata from './map-side-nodata'

interface Props {
  fold: boolean
}

interface DataModel extends ApiBaseResponseModel {
  list: Array<EstateModel>
  is_last: boolean
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapSideBarList = (props: Props) => {
  const { fold } = props

  const page = useRef<number>(1)

  const is_last = useRef<boolean>(false)

  const loading_ref = useRef<boolean>(false)

  const cancel_token = useRef<null | CancelTokenSource>(null)

  const map_filter = useRecoilValue(map_filter_atom)

  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [data, setData] = useRecoilState(map_side_bar_list_atom)

  function onScroll(e: React.UIEvent<HTMLUListElement>) {
    const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget

    if (
      scrollHeight - scrollTop - offsetHeight < 20 &&
      !loading_ref.current &&
      !is_last.current
    ) {
      page.current += 1
      getData()
    }
  }

  function getData() {
    if (cancel_token.current) cancel_token.current.cancel()

    cancel_token.current = axios.CancelToken.source()

    loading_ref.current = true

    setLoading(true)

    API.estate
      .getMapSideData(map_filter, page.current, cancel_token.current.token)
      .then((res) => {
        apiCallback(res, {
          success: getDataSuccessCallback,
          fail: () => {
            throw new Error()
          },
        })
      })
      .catch(getDataFailCallback)
      .finally(getDataFinallyCallback)
  }

  function getDataSuccessCallback(res: DataModel) {
    setData(page.current === 1 ? res.list : data.concat(res.list))
    is_last.current = res.is_last
  }

  function getDataFailCallback(err: Error | AxiosError) {
    if (!axios.isCancel(err)) {
      setData([])
      is_last.current = true
    }
  }

  function getDataFinallyCallback() {
    setLoading(false)
    loading_ref.current = false
    cancel_token.current = null
  }

  useEffect(() => {
    page.current = 1
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map_filter])

  if (loading && data.length === 0) return <LoadingFallback />

  if (data.length === 0) return <MapSideNodata />

  return (
    <ul className={fold ? 'hide' : undefined} onScroll={onScroll}>
      {data.map((item) => {
        return (
          <li
            key={item.idx}
            onClick={() =>
              history.push(
                `/sale-detail-type${getEstateDetailPath(
                  item.deal_kind_text,
                  item.idx,
                  item.status === 'COMPLETE'
                )}`
              )
            }
          >
            {item.thumbnail_path ? (
              <div className='img_holder'>
                <img
                  src={item.thumbnail_path}
                  alt=''
                  onContextMenu={(e) => {
                    e.preventDefault()
                  }}
                />
                <div
                  className={`tag ${
                    item.status === 'COMPLETE'
                      ? 'bg-complate'
                      : item.type_text === '공장'
                      ? 'bg-factory'
                      : item.type_text === '창고'
                      ? 'bg-store'
                      : item.type_text === '토지'
                      ? 'bg-ground'
                      : item.type_text === '지식' ||
                        item.type_text === '지식산업센터'
                      ? 'bg-center'
                      : item.type_text === '산업' ||
                        item.type_text === '산업단지'
                      ? 'bg-industry'
                      : 'bg-logistics'
                  }`}
                >
                  {item.type_text}
                </div>
                
                {item.recommend_map_yn === 'Y' && (
                  <div className="ad_icon">AD</div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className={`list_desc ${item.thumbnail_path ? '' : 'full'}`}>
              <div className='desc_top'>
                {item.thumbnail_path ? (
                  <></>
                ) : (
                  <>
                    <div
                      className={`tag ${
                        item.status === 'COMPLETE'
                          ? 'bg-complate'
                          : item.type_text === '공장'
                          ? 'bg-factory'
                          : item.type_text === '창고'
                          ? 'bg-store'
                          : item.type_text === '토지'
                          ? 'bg-ground'
                          : item.type_text === '지식' ||
                            item.type_text === '지식산업센터'
                          ? 'bg-center'
                          : item.type_text === '산업' ||
                            item.type_text === '산업단지'
                          ? 'bg-industry'
                          : 'bg-logistics'
                      }`}
                    >
                      {item.type_text}
                    </div>
                    {item.recommend_map_yn === 'Y' && (
                      <div className="ad_icon">AD</div>
                    )}
                  </>
                )}

                <h3 className='title ellipsis'>{item.address_text}</h3>
              </div>
              <div className='desc_middle'>
                {item.price_per_area_yn === 'Y' &&
                  item.deal_kind_text === '분양' && (
                    <p className='c-price fs-s fw500'>{item.price_text}</p>
                  )}
                <p className='c-ink2 fs-s fw500'>{item.total_plat_area_text}</p>
              </div>
              {item.tags_text ? (
                <div className='desc_bottom'>
                  <p className='c-hash fs-xs ellipsis'>{item.tags_text}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </li>
        )
      })}
      {loading && data.length > 0 && (
        <li>
          <LoadingFallback className='mg-10' />
        </li>
      )}
    </ul>
  )
}

export default MapSideBarList
