import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const RightSkeleton = () => {
  return (
    <div className="right">
      <Skeleton width={'100%'} height={218} marginBottom={20} />
      <Skeleton width={'100%'} height={200} marginBottom={20} radius={4} />
    </div>
  )
}

export default RightSkeleton