import Rt from '../rt'

export default function PriceInfo() {
  return (
    <div className='detail_bottom_wrap price_info'>
      <Rt.RtTableWrapper />
      <Rt.RtChartWrapper />
    </div>
  )
}
