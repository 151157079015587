import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import get_location_cluster_selector from '../../selector/get-location-cluster-selector'
import { SIGHT } from '../../shared/constant'
import { encNumber, getHash } from '../../shared/function'

interface Props {
  map_ref: React.MutableRefObject<any>
}

interface CitiesOverlayGeneratorProps {
  name: string
  index: number
}

const util = {
  citiesOverlayGenerator: (props: CitiesOverlayGeneratorProps) => {
    const { index, name } = props

    return `<div onclick="window._cc('${encNumber(
      index.toString()
    )}')" class="cityes">${name}</div>`
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapLocationCluster = (props: Props) => {
  const map = props.map_ref.current

  const cluster = useRecoilValue(get_location_cluster_selector)

  const map_filter = useRecoilValue(map_filter_atom)

  const cities_overlays = useRef<Array<any>>([])

  const count = useRef<number>(0)

  const location = useLocation()

  const moveAndZoom = (level: number, move_location: any) => {
    map.setLevel(level)
    map.panTo(move_location)
  }

  const setNewLocationAndLevelCity = () => {
    const hash = getHash()

    const t = hash.t

    let i = 0

    for (const item of cluster) {
      const id = encNumber(i.toString())

      if (id === t) {
        const level = map.getLevel()

        const { lat, lng } = item

        const move_location = new window.kakao.maps.LatLng(lat, lng)

        const next_level =
          level >= SIGHT.SIDO.min && level <= SIGHT.SIDO.max
            ? 10
            : SIGHT.ICONS.max

        moveAndZoom(next_level, move_location)
      }

      i += 1
    }
  }

  const citiesDrawing = () => {
    try {
      const level = map.getLevel()

      if (SIGHT.CITIES.min <= level && SIGHT.SIDO.max >= level) {
        let index = 0

        for (const item of cluster) {
          const position = new window.kakao.maps.LatLng(item.lat, item.lng)

          const overlay = new window.kakao.maps.CustomOverlay({
            position,
            content: util.citiesOverlayGenerator({
              name: item.name,
              index,
            }),
          })

          overlay.setMap(map)

          cities_overlays.current.push(overlay)

          index += 1
        }
      }
    } catch {}
  }

  const citiesRemove = () => {
    for (const overlay of cities_overlays.current) {
      overlay.setMap(null)
    }
  }

  useEffect(() => {
    if (map) {
      citiesDrawing()
    }

    return () => {
      citiesRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster, map, map_filter])

  useEffect(() => {
    if (
      location.hash &&
      location.hash.includes('t=') &&
      map &&
      count.current > 1
    ) {
      setNewLocationAndLevelCity()
    }
    count.current += 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, map])

  return <></>
}

export default MapLocationCluster
