import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import MobileGNB from "../layout/mobile-gnb"

/* eslint-disable @typescript-eslint/no-unused-vars */
const BunyangMobileGnb = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const { detail } = data

  return (
    <MobileGNB title={detail.title ?? ''} />
  )
}

export default BunyangMobileGnb