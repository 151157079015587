import '../../style/components/detail/trade-price-info.scss'
import Rt from '../rt'

export default function TradePriceInfo() {
  return (
    <div className='trade_price_info'>
      <Rt.RtTableWrapper />
      <Rt.View3dWrapper />
      <Rt.RtChartWrapper />
    </div>
  )
}
