import logo from "../static/img/logo.svg";
import loading from '../static/img/LODING.svg'

import "../style/pages/loading.scss";

const Loading = () => {
  return (
    <main id='loading'>
      <img src={loading} alt='로고' />
    </main>
  )
};

export default Loading;
