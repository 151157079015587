import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const MiddleSkeleton = () => {
  const media = useRecoilValue(media_atom)
  
  return (
    <div className="middle">
      <Skeleton width={100} height={media === "P" ? 36 : 29.5} radius={4} marginRight={6} />
      <Skeleton width={100} height={media === "P" ? 24 : 19} radius={4} />
    </div>
  )
}

export default MiddleSkeleton