import { Suspense } from 'react'
import Lot from './lot'

/* eslint-disable @typescript-eslint/no-unused-vars */
const LotWrapper = () => {
  return (
    <Suspense fallback={<></>}>
      <Lot />
    </Suspense>
  )
}

export default LotWrapper
