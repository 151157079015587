import { useEffect } from 'react'

import Bunyang from '../../components/bunyang'

import '../../style/pages/sale-detail/sale-detail-type.scss'
import '../../style/components/detail/type2.scss'
import '../../style/components/detail/type3.scss'

const SaleDetailTypeSaenal = () => {
  useEffect(() => {
    const sendHeight = () => {
      const height = document.documentElement.scrollHeight
      window.parent.postMessage(
        { type: 'iframe-resize', height },
        '*' // 필요 시 'https://gongzzang.net' 으로 제한 가능
      )
    }

    sendHeight()

    // 높이 변경에 대응하도록 ResizeObserver 사용 (정교한 대응)
    const resizeObserver = new ResizeObserver(() => {
      sendHeight()
    })

    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <main id='sale_detail'>
      <div className='container'>
        <div className='inner for_type2 pb-zero'>
          <div id='type' className='detail_bottom_wrap type2'>
            <Bunyang.LotWrapper />
            <Bunyang.DetailWrapper type='explain' />
          </div>
        </div>
      </div>
    </main>
  )
}

export default SaleDetailTypeSaenal