import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const TableSkeleton = () => {

  const media = useRecoilValue(media_atom)

  return (
    <Skeleton width={'100%'} height={110} marginBottom={media === "M" ? 20 : 40} className="table-skeleton" />
  )
}

export default TableSkeleton