import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import RealEstateInfo from "../detail/real-estate-info"
import get_sandan_detail_selector from "../../selector/get-sandan-detail-selector"

/* eslint-disable @typescript-eslint/no-unused-vars */
const News = () => {

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))
  
  return (
    <RealEstateInfo lat={data.lat} lng={data.lng} />
  )
}

export default News