import DecryptDataModel from "../model/dataModel/decrypt-data-model"
import DecryptObjectModel from "../model/dataModel/decrypt-object-model"

// 암호화 데이터 Class
export default class DecryptData implements DecryptDataModel {

  value:number|string|null = null
  date :number|null        = null
  href :string|null        = null

  // 생성자
  constructor(props:DecryptObjectModel) {
    this.value = props.value
    this.date = props.date
    this.href = props.href
  }

  // 실질적 데이터만 가져오는 메소드
  getValue():number|string|null {
    return this.value
  }

  // 암호화 시점 가져오는 메소드
  getDate():number|null {
    return this.date
  }

  // 암호회된 URL 가져오는 메소드
  getHref():string|null {
    return this.href
  }

  // 실질적 데이터의 타입이 Object인 경우
  // 데이터 가져올 함수
  // key 값 던져줄 시 object[key] 값 반환
  getObject(key?:string):{ [key:string] : any } {
    try {
      const _ = JSON.parse((this.value ?? '').toString())
      return key ? (_[key] ?? null) : _
    } catch {
      return {}
    }
  }
}