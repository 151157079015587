import { useHistory } from 'react-router-dom'
import { PATH } from '../shared/constant'
import { selectorFamily } from 'recoil'
import API from '../service/api'

interface sandanRealDealDataResponseDataModel {
  avg_price: Array<number>
  cnt: Array<number>
  history: Array<RendDataResponseDataHistoryModel>
  ym: Array<string>
}

export interface RendDataResponseDataHistoryModel {
  address_lat: string
  address_lng: string
  address_jibun: string
  complete_ymd: string
  jijigugb: string | null
  jimok: string | null
  price_main: number | null
  total_plat_area: number | null
  type: string
}

const get_sandan_real_deal_selector = selectorFamily<sandanRealDealDataResponseDataModel, string>({
  key: '@sandan/get_sandan_trend_data_selector',
  get: (id: string, jibun?: string) => async () => {
    const { replace } = useHistory()

    const response = await API.sandan.getRealDeal(id).catch(() => {
      //alert('조회할 수 없는 정보입니다.')
      //replace(PATH.MAIN)
      return
    })

    const data = response?.data

    return data
  },
})

export default get_sandan_real_deal_selector
