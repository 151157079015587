import { Suspense } from "react"
import RtChart from "./rt-chart"
import RtChartSkeleton from "./rt-chart-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const RtChartWrapper = () => {
  return (
    <Suspense fallback={<RtChartSkeleton />}>
      <RtChart />
    </Suspense>
  )
}

export default RtChartWrapper