import React, { useEffect, useMemo, useState } from 'react'
import API from '../../service/api'
import GetInterestRateDataResponseModel, {
  GetInterestRateDataListModel,
} from '../../service/estate/model/get-interest-rate-data-model'
import { apiCallback, blankFn, comma } from '../../shared/function'
import '../../style/components/detail/loan-info.scss'
import LoanMonthlyPaymentPopup from '../popup/loan-monthly-payment-popup'
import TaxGuideTable from './tax-guide-table'

interface Props {
  price: number
  is_farmland: boolean
}        

export default function LoanInfo(props: Props) {
  // const price = props.price // 매물 가격

  //23.05.17 매물가격 수정 가능하도록 변경
  const [price, setPrice] = useState<number>(props.price)

  const [interest_rate, setInterestRate] = useState<
    Array<GetInterestRateDataListModel>
  >([]) // 대출 최저 금리 정보

  const [method_text, setMethodText] = useState<string>('변동') // 금리 방식
  const [condition_text, setConditionText] = useState<string>('만기일시') // 대출 조건
  const [lowest_rate, setLowestRate] = useState<number>(0) // 대출 최저 금리

  const [rate, setRate] = useState<number>(0) // 매물에 대한 대출금 퍼센트

  const [loan_price, setLoanPrice] = useState<number>(0) // 대출금
  const [repayment_period, setRepaymentPeriod] = useState<number>(10) // 상환 기간
  
  const [popup, setPopup] = useState<boolean>(false) // 상환 기간

  const getInterestRateData = () => {
    API.estate
      .getInterestRateData()
      .then((res) => {
        apiCallback(res, {
          success: getInterestRateDataSuccessCallback,
          fail: blankFn,
        })
      })
      .catch(blankFn)
  }

  const getInterestRateDataSuccessCallback = (
    res: GetInterestRateDataResponseModel
  ) => {
    setInterestRate(res.info)
  }

  // 금리 방식 선택
  const selectedMethodText = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMethodText(e.currentTarget.value)
  }

  // 대출 조건 선택
  const selectedConditionText = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setConditionText(e.currentTarget.value)
  }

  // 상환 기간 선택
  const selectedRepaymentPeriod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRepaymentPeriod(parseInt(e.currentTarget.value))
  }

  // 퍼센트에 따른 대출금 계산
  const getLoanPrice = (rate: number) => {
    price && setLoanPrice(Math.round((price * (rate / 100)) / 10000))
  }

  // 대출금 라디오 버튼
  const onChangeRateButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(parseInt(e.currentTarget.name))
    getLoanPrice(parseInt(e.currentTarget.name))
  }

  //23.05.17 매물가격 수정 가능하도록 변경
  // 사용자가 직접 매물가격을 입력할 경우
  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let price = 0
    if(e.target.value !== ''){
      price = parseInt(e.target.value.replace(/,/gi, '')) * 10000
    }
    setPrice(price)
  }

  // 사용자가 직접 대출금을 입력할 경우
  const onChangeLoanPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(0)
    let loanPrice = 0
    if(e.target.value !== ''){
      loanPrice = parseInt(e.target.value.replace(/,/gi, ''))
    }
    setLoanPrice(loanPrice)
  }

  // 사용자가 직접 평균 금리를 입력할 경우
  const onChangeRateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLowestRate(parseFloat(e.currentTarget.value || '0'))
  }

  // 금리 방식, 대출 조건에 따른 대출 최저 금리 값 찾기
  const getLowestRate = () => {
    if (interest_rate.length > 0) {
      for (let data of interest_rate) {
        if (
          data.method_text === method_text &&
          data.condition_text === condition_text
        ) {
          setLowestRate(data.lowest_rate)
        }
      }
    }
  }

  useEffect(() => {
    getInterestRateData()
    // eslint-disable-next-line
  }, [])

  // 대출 금리 퍼센트 라디오 버튼 클릭 시, 해당 퍼센트 기준으로 대출금 계산
  // useEffect(() => {
  //   getLoanPrice()
  // }, [rate])

  useEffect(() => {
    getLowestRate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method_text, condition_text, interest_rate])

  const monthly_rent = useMemo(() => {
    const A = loan_price

    const b = (lowest_rate * 0.01) / 12

    const n = repayment_period * 12

    if (condition_text === '원리금 균등') {
      const denominator = (1 + b) ** n - 1

      const numerator = A * b * (1 + b) ** n

      return numerator / denominator
    } else if (condition_text === '원금 균등') {
      
      if(isNaN(A)) return 0
      
      const principal =(A * 10000) / n //원금
      let balance  = A * 10000 //잔액
      let totalBalance = 0 //총 이자액

      for (let i = 0; i < n; i++) {
        totalBalance += balance * b
        balance = balance - principal
      }
      
      // console.log('총이자액',totalBalance.toLocaleString());

      return (principal + ( totalBalance / n )) / 10000 

    } else { //만기일시
      return A * b
    }
  }, [loan_price, repayment_period, lowest_rate, condition_text])

  //월별 내역 더보기 클릭 시
  const onClickMonthlyMoreView = () => {
    if(loan_price === 0){
      alert('대출금을 입력해주세요.')
      return
    }
    setPopup(true)
  }

  return (
    <div className='detail_bottom_wrap loan_info'>
      <article>
        <h2>대출정보</h2>
        <div className='loan_calc'>
          <div className='left'>
            <ul>
              <li>
                <h3>매물가격</h3>
                <div className='info_item'>
                  <input
                    type='text'
                    value={price && comma((price / 10000).toFixed(0))}
                    //23.05.17 매물가격 수정 가능하도록 변경
                    onChange={onChangePrice}
                    // disabled
                  />
                  <span>만원</span>
                </div>
              </li>
              <li>
                <h3>대출금(LTV)</h3>
                <div className='info_item'>
                  <input
                    type='text'
                    value={loan_price ? comma(loan_price) : ''}
                    onChange={onChangeLoanPrice}
                  />
                  <span>만원</span>
                </div>
              </li>
              <li>
                <ul className='rate'>
                  <li>
                    <input
                      type='radio'
                      id='_rate1'
                      name='50'
                      onChange={onChangeRateButton}
                      checked={rate === 50}
                    />
                    <label htmlFor='_rate1'>50%</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='_rate2'
                      name='60'
                      onChange={onChangeRateButton}
                      checked={rate === 60}
                    />
                    <label htmlFor='_rate2'>60%</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='_rate3'
                      name='70'
                      onChange={onChangeRateButton}
                      checked={rate === 70}
                    />
                    <label htmlFor='_rate3'>70%</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='_rate4'
                      name='80'
                      onChange={onChangeRateButton}
                      checked={rate === 80}
                    />
                    <label htmlFor='_rate4'>80%</label>
                  </li>
                </ul>
              </li>
              <li className='loan_four'>
                <h3>상환 기간</h3>
                <div className='info_item'>
                  <select
                    name='상환 기간 카테고리'
                    value={repayment_period}
                    onChange={selectedRepaymentPeriod}
                  >
                    <option value='10'>10년</option>
                    <option value='20'>20년</option>
                    <option value='30'>30년</option>
                    <option value='40'>40년</option>
                  </select>
                </div>
              </li>
              <li className='loan_four'>
                <h3>금리 방식</h3>
                <div className='info_item'>
                  <select
                    name='금리 방식 카테고리'
                    value={method_text}
                    onChange={selectedMethodText}
                  >
                    <option value='고정'>고정</option>
                    <option value='변동'>변동</option>
                  </select>
                </div>
              </li>
              <li className='loan_four'>
                <h3>평균 금리 (%)</h3>
                <div className='info_item'>
                  <input
                    type='text'
                    className='ta-l'
                    value={lowest_rate}
                    onChange={onChangeRateText}
                  />
                </div>
              </li>
              <li className='loan_four'>
                <h3>대출 조건</h3>
                <div className='info_item'>
                  <select
                    name='대출 조건 카테고리'
                    value={condition_text}
                    onChange={selectedConditionText}
                  >
                    <option value='원리금 균등'>원리금 균등</option>
                    <option value='원금 균등'>원금 균등</option>
                    <option value='만기일시'>만기일시</option>
                  </select>
                </div>
              </li>
            </ul>
          </div>
          <div className='right'>
            {/* 23.05.17 원금 균등일 경우에만 월 평균납입액, 원리금 균등과 만기일시의 경우 월 납입액으로 표기 */}
            {condition_text === '원금 균등' ?
              <div>
                <h3>예상 월 평균 납입액</h3>
                <button onClick={onClickMonthlyMoreView} className='monthly_more_view_btn'>월별 내역 더보기</button>
              </div>
              :
              <h3>예상 월 납입액</h3>
            }
            <span>{comma(monthly_rent.toFixed(0) ?? 0)}만원</span>
          </div>
        </div>
        <div className='loan_warning'>
          <p>* 평균 금리는 코픽스 기준금리(or 한국은행 기준금리)에 가산금리 1.5%를 합한 금리(%)입니다.</p>
          <p>* 해당정보는 금융감독원 자료를 기반으로 도출된것으로, 실제 대출조건은 각 금융회사의 담보가치 산정, 대출 신청자의 소득, 금융정책등에 따라서 달라집니다. 본 정보는 참고용으로만 활용하여 주시며 각 금융기관 및 전문상담가와 문의하여 주시기 바랍니다.</p>
        </div>
      </article>
      <article>
        <div className='tax_title'>
          <h2>취득세정보</h2>
          <TaxGuideTable />
        </div>
        <div className='tax_calc'>
          <div className='left'>
            <ul>
              <li className='tax_list'>
                <h3>취득세</h3>
                <div className='info_item tax_info'>
                  <input
                    type='text'
                    value={price ? comma((price * (props.is_farmland ? 0.03 : 0.04) / 10000).toFixed(0)) : ''}
                    disabled
                  />
                  <span>만원</span>
                </div>
              </li>
              <li className='tax_list'>
                <h3>농어촌특별세</h3>
                <div className='info_item tax_info'>
                  <input
                    type='text'
                    value={price ? comma((price * 0.002 / 10000).toFixed(0)) : ''}
                    disabled
                  />
                  <span>만원</span>
                </div>
              </li>
              <li className='tax_list'>
                <h3>지방교육세</h3>
                <div className='info_item tax_info'>
                  <input
                    type='text'
                    value={price ? comma((price * (props.is_farmland ? 0.002 : 0.004) / 10000).toFixed(0)) : ''}
                    disabled
                  />
                  <span>만원</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='right tax_tot'>
            <h3>예상 총 세액</h3>
            {props.is_farmland ? (
              <span>{comma(price? Math.floor(price * 0.03 / 10000) + Math.floor(price * 0.002 / 10000) + Math.floor(price * 0.002 / 10000) : 0)}만원</span>
            ) : (
              <span>{comma(price? Math.floor(price * 0.04 / 10000) + Math.floor(price * 0.002 / 10000) + Math.floor(price * 0.004 / 10000) : 0)}만원</span>
            )}
          </div>
        </div>
        <div className='tax_warning'>
          <p>* 부동산 거래 시 부과되는 취득세 예상 금액을 산정하기 위한 참고용 계산이며, 실제 세액과는 다소 차이가 있을 수 있습니다.</p>
          <p>* 자세한 정보는 위텍스를 통해 확인하시기 바랍니다.</p>
        </div>
      </article>
      {popup && <LoanMonthlyPaymentPopup onClickClose={() => setPopup(false)} loan_price={loan_price} repayment_period={repayment_period} lowest_rate={lowest_rate} />}
    </div>
  )
}
