import { Suspense, useEffect, useState } from 'react'
import API from '../../service/api'
import CoordToAddressResponseModel from '../../service/kakao/model/coord-to-address-response-model'
import { getGeoLocation } from '../../shared/function'
import DetailNews from '../detail/detail-news'
import ErrorBoundary from '../layout/error-boundary'
import LoadingFallback from '../loading/loading-fallback'
import Skeleton from '../skeleton'
import News from './news'
import NewsSkeleton from './news-skeleton'
interface Props {
  // 매물 상세 페이지에서 사용되는 현재 매물 위도와 경도
  lat?: number
  lng?: number
  detail?: boolean
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const NewsWrapper = (props: Props) => {
  const [locations, setLocations] = useState<Array<string>>([])
  const [selectedLocation, setSelectedLocation] = useState<string>('')

  const getLocation = () => {
    getGeoLocation({
      success: (pos) =>
        coordToAddress(pos.coords.latitude, pos.coords.longitude),
      fail: coordToAddressFailCallback,
    })
  }

  const coordToAddress = (lat: number, lng: number) => {
    API.kakao
      .coordToAddress(lat, lng)
      .then((res) => {
        const data = res.data as CoordToAddressResponseModel

        if (data.meta.total_count === 0) throw new Error()

        const item = data.documents[0]

        let locationsList = []
        if(item.region_1depth_name && item.region_1depth_name !== '') locationsList.push(item.region_1depth_name)
        if(item.region_2depth_name && item.region_2depth_name !== '') locationsList.push(item.region_2depth_name)
        if(item.region_3depth_name && item.region_3depth_name !== '') locationsList.push(item.region_3depth_name)
        if(item.region_4depth_name && item.region_4depth_name !== '') locationsList.push(item.region_4depth_name)

        if(locationsList.length < 1) throw new Error()

        setLocations(locationsList)
      })
      .catch(coordToAddressFailCallback)
  }

  const coordToAddressFailCallback = () => {
    setLocations(['서울특별시', '강남구'])
  }

  const onClickLocation = (location: string) => {
    const locationText = getLocationText(location)
    setSelectedLocation(locationText)
  }

  const getLocationText = (location: string) => {
    const selectedIndex = locations.findIndex(loc => loc === location)
    let locationText = ''
    
    if(selectedIndex >= 0){
      
      let locationArr: Array<string> = []
      if (selectedIndex > 0) locationArr = locations.slice(selectedIndex-1, selectedIndex + 1)
      else locationArr = locations.slice(0, selectedIndex + 1)
      
      locationText = locationArr.join(' ')
    }

    return locationText
  }

  useEffect(()=>{
    if(locations.length > 0) {
      const shownLocations = locations.slice(-2)
      const locationText = getLocationText(shownLocations[0])
      setSelectedLocation(locationText)
    }
  },[locations])

  useEffect(() => {
    if (props.lat && props.lng) {
      coordToAddress(props.lat, props.lng)
    } else {
      getLocation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='news_info'>
      <div className='content_head'>
        {locations.length < 1 ? (
          <Skeleton width={150} height={24} />
        ) : (
          <h3 className='nanumB c-headline'>
            {locations.slice(-2).map((location, index) => {
              const selectedLocationArr = selectedLocation.split(' ')
              
              return (
                <strong
                  key={`${location}_${index}`}
                  className={`nanumEB c-headline address_btn ${location === selectedLocationArr[selectedLocationArr.length - 1] ? 'on' : ''}`}
                  onClick={()=> onClickLocation(location)}>
                  {location}
                </strong>
              )
            })}
            부동산 뉴스
          </h3>
        )}
      </div>
      <div className='content_body'>
        {selectedLocation === '' ? (
          props.detail ? (
            <LoadingFallback className='detail-news-loading' />
          ) : (
            <NewsSkeleton />
          )
        ) : (
          <ErrorBoundary fallback={<></>}>
            <Suspense
              fallback={
                props.detail ? (
                  <LoadingFallback className='detail-news-loading' />
                ) : (
                  <NewsSkeleton />
                )
              }
            >
              {props.detail ? (
                <DetailNews location={selectedLocation} />
              ) : (
                <News location={selectedLocation} />
              )}
            </Suspense>
          </ErrorBoundary>
        )}
      </div>
    </div>
  )
}

export default NewsWrapper
