import { useRef, useState, Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import { getQuery, lotReverse } from '../../shared/function'
import PleaseSelectLot from '../detail/please-select-lot'
import View3D from '../detail/view-3d'
import LotControll from './lot-controll'
import LotGuide from './lot-guide'
import LotMap from './lot-map'

/* eslint-disable @typescript-eslint/no-unused-vars */
const Lot = () => {
  const map_ref = useRef<any>(null)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const [selected_lot, setSelectedLot] = useState<LotModel | null>(null)

  const { lot } = data

  if (!lot) return <></>

  if (lot.length === 0) return <></>

  const _lot = lotReverse(selected_lot ? [selected_lot] : [])

  return (
    <article>
      {data.detail.type !== 'KC' &&
        data.detail.type_text !== '지식산업센터' && (
          <>
            <div className='f-r f-a-c f-j-sb top'>
              <h2>필지 정보</h2>
              <LotGuide />
            </div>
            <div
              className='img_area rel'
              style={{ width: '100%', maxWidth: 'unset' }}
            >
              {/* 원본 */}
              <LotMap
                map_ref={map_ref}
                setSelectedLot={setSelectedLot}
                selected_lot={selected_lot}
              />
              <LotControll map_ref={map_ref} />
            </div>
            {lot.length > 1 && (
              <Suspense fallback={<></>}>
                {selected_lot !== null && (
                  <View3D
                    lot={_lot}
                    building_area_calc_y_text={
                      selected_lot.building_area_calc_y_text
                    }
                    tot_area_calc_y_text={selected_lot.tot_area_calc_y_text}
                    is_IC={data.detail.type === 'IC'}
                  />
                )}
                {selected_lot === null && <PleaseSelectLot />}
              </Suspense>
            )}
          </>
        )}
    </article>
  )
}

export default Lot
