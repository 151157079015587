import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getEstateClassName, getQuery } from "../../shared/function"

/* eslint-disable @typescript-eslint/no-unused-vars */
const LeftCategoryIcon = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  
  const { type_text } = data.detail

  return (
    <div className={getEstateClassName(type_text)}>{type_text}</div>
  )
}

export default LeftCategoryIcon