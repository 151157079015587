import React from 'react'

interface Props {
  children: React.ReactNode | React.ReactNodeArray
  fallback: React.ReactNode | React.ReactNodeArray
}

interface State {
  has_error: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      has_error: false,
    }
  }

  componentDidCatch() {
    this.setState({
      has_error: true,
    })
  }

  public reset() {
    this.setState({ has_error: false })
  }

  render() {
    if (this.state.has_error) {
      return <>{this.props.fallback}</>
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
