import { useRecoilValue } from 'recoil'
import { AreaType } from '../../atom/map-filter'
import media_atom from '../../atom/media-atom'
import GetDetailResponseModel from '../../service/estate/model/get-detail-response-model'
import Colgroup from '../layout/colgroup'

interface Props {
  floor: GetDetailResponseModel['floor']
  area_type: AreaType
}

function FloorTable({ floor, area_type }: Props) {
  const media = useRecoilValue(media_atom)

  const area_type_text = area_type === 'PY' ? '평' : '㎡'

  function getArea(area: number) {
    return (area / (area_type === 'PY' ? 3.3058 : 1)).toFixed(2)
  }

  if (floor.length === 0) return <></>

  return (
    <article>
      <h2>층별정보</h2>
      {media === 'M' && 'T' ? (
        <>
          {floor.map((item) => {
            return (
              <div key={floor.indexOf(item)} className='dl_wrap floor_info'>
                <dl>
                  <dt>층수</dt>
                  <dd>{item.floor_name}</dd>
                </dl>
                <dl>
                  <dt>구조</dt>
                  <dd>{item.strct}</dd>
                </dl>
                <dl>
                  <dt>용도</dt>
                  <dd>{item.purps}</dd>
                </dl>
                <dl>
                  <dt>면적</dt>
                  <dd>
                    {getArea(item.area)}
                    {area_type_text}
                  </dd>
                </dl>
              </div>
            )
          })}
        </>
      ) : (
        <table>
          <Colgroup count={4} />
          <thead>
            <tr>
              <th>층수</th>
              <th>구조</th>
              <th>용도</th>
              <th>면적</th>
            </tr>
          </thead>
          {floor.map((item) => {
            return (
              <tbody key={floor.indexOf(item)}>
                <tr>
                  <td>{item.floor_name}</td>
                  <td>{item.strct}</td>
                  <td>{item.purps}</td>
                  <td>
                    {getArea(item.area)}
                    {area_type_text}
                  </td>
                </tr>
              </tbody>
            )
          })}
        </table>
      )}
    </article>
  )
}

export default FloorTable
