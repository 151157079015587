import Axios, { AxiosRequestConfig, CancelToken } from 'axios'
import { MapFilterAtomModel } from '../../atom/map-filter'
import accountManager from '../../shared/account-manager'
import {
  ESTATE_URL,
  FORM_HEADER,
  GET,
  JSON_HEADER,
  MAP_URL,
  POST,
  SERVER_URL,
} from '../../shared/api-constant'
import {
  formGenerator,
  getQuery,
  objectToHeaderParameter,
} from '../../shared/function'
import { ElasticType, YNType } from '../../shared/types'
import EstateCompleteRequestModel from './model/estate-complete-request-model'
import GetLocationClusterRequestModel from './model/get-location-cluster-request-model'
import GetLocationDataRequestModel from './model/get-location-data-request-model'
import GetWiriteDataRequestModel from './model/get-wirite-data-request-model'
import SaleDetailCallLogRequestModel from './model/sale-detail-call-log-request-model'
import SaleDetailDeclareRequestModel from './model/sale-detail-declare-request-model'
import SetBookMarkRequestModel from './model/set-bookmark-request-model'
import WriteStep1RequestModel from './model/write-step-1-request-model'
import WriteStep2RequestModel from './model/write-step-2-request-model'
import WriteStep3RequestModel from './model/write-step-3-request-model'
import WriteStep4RequestModel from './model/write-step-4-request-model'

const estate = {
  writeStep1: (request: WriteStep1RequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/write.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  getLocationData: (request: GetLocationDataRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_location_data.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  writeStep2: (request: WriteStep2RequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/write.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  writeStep3: (request: WriteStep3RequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/write.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  writeStep4: (request: WriteStep4RequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/write.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator(request),
    }

    return Axios(config)
  },

  getNearbyData: () => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_nearby_data.php`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
    }

    return Axios(config)
  },

  getEstateList: (current_page: number, page_size: number, status: string) => {
    const account_idx = accountManager.getIdx()

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_my_list.php?account_idx=${account_idx}&current_page=${current_page}&page_size=${page_size}&status=${status}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getNewestEstateList: (params?: {[key: string]: string}) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_newest_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params
    }

    return Axios(config)
  },

  estateComplete: (request: EstateCompleteRequestModel) => {
    const account_idx = accountManager.getIdx()!.toString()

    const fd = formGenerator(request)
    fd.append('account_idx', account_idx)

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/complete.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: fd,
    }

    return Axios(config)
  },

  getWriteData: (request: GetWiriteDataRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_write_data.php?${objectToHeaderParameter(
        request
      )}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getRecommendHashtag: (type: ElasticType) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/recommend_hashtag.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params: {
        type,
      },
    }

    return Axios(config)
  },

  setBookMark: (request: SetBookMarkRequestModel) => {
    const account_idx = accountManager.getIdx()!.toString()

    const fd = formGenerator(request)
    fd.append('account_idx', account_idx)

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/set_bookmark.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: fd,
    }

    return Axios(config)
  },

  getMapData: (__request__: MapFilterAtomModel, cancel_token: CancelToken) => {
    let request = { ...__request__ } as any

    request.deal_kind = `${request.deal_kind.trade}${request.deal_kind.rent}${request.deal_kind.sub_division}`
    request.type = Object.values(request.type).join('')

    request.price_once_max = request.price_once_max * 1_000_000
    request.price_once_min = request.price_once_min * 1_000_000
    request.price_total_max = request.price_total_max * 100_000_000
    request.price_total_min = request.price_total_min * 100_000_000

    request.tot_area_max = request.tot_area_max * 50
    request.tot_area_min = request.tot_area_min * 50
    request.plat_area_max = request.plat_area_max * 50
    request.plat_area_min = request.plat_area_min * 50

    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_list.php?${objectToHeaderParameter(request)}`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getMapCluster: (
    __request__: MapFilterAtomModel,
    cancel_token: CancelToken
  ) => {
    let request = { ...__request__ } as any

    request.deal_kind = `${request.deal_kind.trade}${request.deal_kind.rent}${request.deal_kind.sub_division}`

    const { FT, WH, LD, IC, KC, LC } = request.type

    request.type = `${FT}${WH}${LD}${IC}${KC}${LC}`

    request.price_once_max = request.price_once_max * 1_000_000
    request.price_once_min = request.price_once_min * 1_000_000
    request.price_total_max = request.price_total_max * 100_000_000
    request.price_total_min = request.price_total_min * 100_000_000

    request.tot_area_max = request.tot_area_max * 50
    request.tot_area_min = request.tot_area_min * 50
    request.plat_area_max = request.plat_area_max * 50
    request.plat_area_min = request.plat_area_min * 50

    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_marker_list.php?${objectToHeaderParameter(request)}`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getMapSideData: (
    __request__: MapFilterAtomModel,
    page: number,
    cancel_token: CancelToken
  ) => {
    let request = { ...__request__ } as any

    request.deal_kind = `${request.deal_kind.trade}${request.deal_kind.rent}${request.deal_kind.sub_division}`

    const { FT, WH, LD, IC, KC, LC } = request.type

    request.type = `${FT}${WH}${LD}${IC}${KC}${LC}`

    request.price_once_max = request.price_once_max * 1_000_000
    request.price_once_min = request.price_once_min * 1_000_000
    request.price_total_max = request.price_total_max * 100_000_000
    request.price_total_min = request.price_total_min * 100_000_000

    request.tot_area_max = request.tot_area_max * 50
    request.tot_area_min = request.tot_area_min * 50
    request.plat_area_max = request.plat_area_max * 50
    request.plat_area_min = request.plat_area_min * 50

    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_info_list.php?${objectToHeaderParameter(
        request
      )}&current_page=${page}&page_size=20`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getSameAddress: (
    page: number,
    cancel_token: CancelToken,
    real_deal_y: Array<number>,
    real_deal_n: Array<number>
  ) => {
    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_same_address_list.php`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
      params: {
        page,
        page_size: 20,
        real_deal_y: real_deal_y.join(),
        real_deal_n: real_deal_n.join(),
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getLocationCluster: (request: GetLocationClusterRequestModel) => {
    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_location_cluster.php`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
      params: request,
    }

    return Axios(config)
  },

  getDetail: (idx: number, cancel_token: CancelToken, jibun?: string) => {
    const account_idx = accountManager.getIdx()

    const type = getQuery().rd === 'Y' ? 'realdeal' : ''

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_detail.php`,
      method: GET,
      params: {
        idx,
        account_idx,
        jibun,
        type,
      },
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  getBuildingData: (idx: string, pnu: string, jibun: string) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_building_data.php`,
      method: GET,
      params: {
        idx,
        pnu,
        jibun,
      },
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getTrendData: (idx: string, jibun?: string) => {
    const account_idx = accountManager.getIdx()
    const type = getQuery().rd === 'Y' ? 'realdeal' : ''

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_trend_data.php`,
      method: GET,
      params: {
        idx,
        account_idx,
        jibun,
        type,
      },
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },

  getRecapTitleData: (idx: string, pnu: string, jibun: string) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_recap_title_data.php`,
      method: GET,
      params: {
        idx,
        pnu,
        jibun,
      },
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getBrTitleData: (idx: string, pnu: string, jibun: string) => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_br_title_data.php`,
      method: GET,
      params: {
        idx,
        pnu,
        jibun,
      },
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getSaleDetailReportMetaData: () => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/sale_detail_report_metadata.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  saleDetailDeclare: (request: SaleDetailDeclareRequestModel) => {
    const account_idx = accountManager.getIdx()?.toString()

    const fd = formGenerator(request)

    fd.append('account_idx', account_idx ?? '0')

    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/sale_detail_report.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: fd,
    }

    return Axios(config)
  },

  saleDetailCallLog: (estate_idx: number) => {
    const account_idx = parseInt(accountManager.getIdx()!.toString())
    let request: SaleDetailCallLogRequestModel = {
      estate_idx: estate_idx,
      account_idx: account_idx,
    }
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/sale_detail_call_log.php?${objectToHeaderParameter(
        request
      )}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getInterestRateData: () => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_interest_rate_data.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getMainRecommendEstate: () => {
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_recommend_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  imageUpload: (file: File) => {
    const form_data = new FormData()
    form_data.append('flag', 'file_upload')
    form_data.append('file', file)

    const config: AxiosRequestConfig = {
      url: `${SERVER_URL}/backend/app/ajax/admin/file.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: form_data,
    }

    return Axios(config)
  },

  getPnuPolygon(address: string) {
    const config: AxiosRequestConfig = {
      url: `${MAP_URL}/get_pnu_polygon.php?address=${address}`,
      method: GET,
      headers: {
        ...FORM_HEADER,
      },
    }

    return Axios(config)
  },

  getDetailForJibun(jibun: string) {
    const account_idx: string | null = accountManager.getIdx()
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_detail.php?account_idx=${account_idx}&jibun=${jibun}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getPnuAreaData(jibun: string) {
    const account_idx: string | null = accountManager.getIdx()
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_pnu_area_data.php?account_idx=${account_idx}&jibun=${jibun}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getPnuBuildingData(jibun: string, type: string, pnu?: string) {
    const account_idx: string | null = accountManager.getIdx()
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/get_pnu_building_data.php?account_idx=${account_idx}&jibun=${jibun}&type=${type}&pnu=${pnu ?? ''}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  deleteEstate(estate_idx: number) {
    const account_idx = accountManager.getIdx()
    const config: AxiosRequestConfig = {
      url: `${ESTATE_URL}/remove.php`,
      method: POST,
      headers: {
        ...FORM_HEADER,
      },
      data: formGenerator({
        account_idx,
        estate_idx,
      }),
    }

    return Axios(config)
  },

}

export default estate
