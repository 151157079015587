import { Suspense } from "react"
import View3dArea from "./view-3d-area"

/* eslint-disable @typescript-eslint/no-unused-vars */
const View3dWrapper = () => {
  return (
    <Suspense fallback={<></>}>
      <View3dArea />
    </Suspense>
  )
}

export default View3dWrapper