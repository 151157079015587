import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SIGHT } from '../../shared/constant'
import { getEstateDetailPath, getHash } from '../../shared/function'
import { ElasticDealKindTextType } from '../../shared/types'

interface Props {
  map_ref: React.MutableRefObject<any>
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapControll = (props: Props) => {
  const map = props.map_ref.current

  const location = useLocation()

  const history = useHistory()

  const count = useRef(0)

  const [latLngFromdetailPage, setLatLngFromdetailPage] = useState<string>()

  const moveAndZoom = (move_location: any, level?: number) => {
    if (level) {
      map.setLevel(level)
    }
    map.panTo(move_location)
  }

  const setNewLocationAndLevelForGu = () => {
    const hash = getHash()

    const g = hash.g

    const element = document.querySelector('#' + g)

    if (element) {
      const { loc_lat, loc_lng } = element.dataset

      const move_location = new window.kakao.maps.LatLng(loc_lat, loc_lng)

      moveAndZoom(move_location, SIGHT.ICONS.max)
    }
  }

  const setNewLocationForLatLng = (latLngFromDetailPage?: string) => {
    
    let latLng
    let level

    if(!latLngFromDetailPage) {
      const hash = getHash()
      latLng = hash.LatLng
      level = 3
    } else {
      latLng = latLngFromDetailPage
      level = 3
      setLatLngFromdetailPage(undefined)
    }

    const [lat, lng] = latLng.split('^')

    const move_location = new window.kakao.maps.LatLng(lat, lng)

    moveAndZoom(move_location, level)
  }

  const redirectDetail = () => {
    try {
      const { key, status, t, rd } = getHash()

      if (new Date().valueOf() - parseInt(t) > 2000) return

      const dkt = decodeURIComponent(getHash().dkt) as ElasticDealKindTextType

      let path = `/sale-detail-type${getEstateDetailPath(
        dkt,
        key,
        status === 'COMPLETE'
      )}`

      if (status === 'COMPLETE' && rd === "Y") {
        path += '&rd=Y'
      }

      history.push(path)
    } catch { }
  }

  useEffect(() => {

    //매물/분양 상세페이지에서 주소로 접근 시
    if(location.state === 'address-from-detail-page') {
      if(location.hash && location.hash.includes('LatLng=')){
        const hash = getHash()
        setLatLngFromdetailPage(hash.LatLng)
      }
    }
    
    if(map && latLngFromdetailPage) {
      setNewLocationForLatLng(latLngFromdetailPage)
    }

    if (count.current > 2) {
      if (location.hash && location.hash.includes('g=') && map) {
        setNewLocationAndLevelForGu()
      }
      if (location.hash && location.hash.includes('LatLng=') && map) {
        setNewLocationForLatLng()
      }
      if (location.hash && location.hash.includes('key=') && map) {
        redirectDetail()
      }
    }

    count.current += 1

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, map])

  return <></>
}

export default MapControll
