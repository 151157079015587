import { Suspense } from "react"
import Middle from "./middle"
import MiddleSkeleton from "./middle-skeleton"

const MiddleWrapper = () => {

	return (
		<div className="middle">
			<Suspense fallback={<MiddleSkeleton />}>
				<Middle />
			</Suspense>
		</div>
	)
}

export default MiddleWrapper