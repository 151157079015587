import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Zoom } from 'swiper'

import React from 'react'
import { useRecoilValue } from 'recoil'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'
import LeftImage from '../bunyang/left-image'
import closeOffset from "../../static/img/icons/close-white-offset.svg";

type PopupTypes = {
  onClickClose?: React.MouseEventHandler<HTMLElement>
  imgIdx: number
  name?: string
}
export default function ImageSlidePopup(props: PopupTypes) {
  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  const { preview, pyeong } = data

  const onClickClose = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClickClose) {
      props.onClickClose(e)
    }
  }
  
  SwiperCore.use([Zoom]);

  if((!preview || !preview.length) && !props.name) return (<></>)
  return (
    <div id='popup'>
      <div className='dim' onClick={onClickClose}></div>
      <div className='pop_image_slide'>
        <button type='button' className='pop_close' onClick={onClickClose}>
          <img src={closeOffset} alt='close'  />
        </button>
        <Swiper
          className='popup_img_list'
          slidesPerView={1}
          slidesPerGroup={1}
          slideToClickedSlide={false}
          scrollbar={{ draggable: true }}
          pagination={{ clickable: true }}
          initialSlide={props.imgIdx}
          zoom={true}
        >
          {!props.name
            ? preview.map((item) => {
                return (
                  <SwiperSlide
                    style={{ width: '100%' }}
                    key={preview.indexOf(item)}
                  >
                    <div className='img_holder popup_img img_watermark'>
                      <LeftImage src={item} />
                    </div>
                  </SwiperSlide>
                )
              })
            : pyeong && pyeong.length > 0 && (
              pyeong.map((item) => {
                return (
                  <SwiperSlide
                    style={{ width: '100%' }}
                    key={`pyeong_`+item.title}
                  >
                    <div className='img_holder popup_img pyeong_popup_img'>
                      <div className="swiper-zoom-container">
                        <LeftImage src={item.path} />
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })
            )}
        </Swiper>
      </div>
    </div>
  )
}
