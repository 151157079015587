import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import useComponentDidMount from '../../hooks/use-component-did-mount'
import useComponentWillUnmount from '../../hooks/use-component-will-unmount'
import API from '../../service/api'
import ApiBaseResponseModel from '../../service/model/api-base-response-model'
import { apiCallback, blankFn, getHash } from '../../shared/function'
import { YNType } from '../../shared/types'
import get_map_data_selector from '../../selector/get-map-data-selector'

interface Props {
  map_ref: React.MutableRefObject<any>
}

interface AddressModel {
  address_name: string
  main_address_no: string
  mountain_yn: YNType
  region_1depth_name: string
  region_2depth_name: string
  region_3depth_name: string
  sub_address_no: string
  zip_code: string
}

interface RoadAddressModel {
  address_name: string
  building_name: string
  main_building_no: string
  region_1depth_name: string
  region_2depth_name: string
  region_3depth_name: string
  road_name: string
  sub_building_no: string
  underground_yn: YNType
  zone_no: string
}

interface KakaoCoord2AddressResultModel {
  address: AddressModel
  road_address: RoadAddressModel | null
}

interface GetPnuPolygonResponseModel extends ApiBaseResponseModel {
  jibun: string
  polygon: Array<[number, number]>
  lat: number
  lng: number
}

function MapClickControll(props: Props) {
  const map = props.map_ref.current

  const location = useLocation()

  const _polygon = useRef<any>(null)
  const _marker = useRef<any>(null)
  const _content = useRef<HTMLDivElement | null>(null)
  
  const _prevent_init = useRef<boolean>(false)

  const _jibun = useRef<string>('')

  const { push } = useHistory()

  const { search, level } = useRecoilValue(map_filter_atom)

  const { estate } = useRecoilValue(get_map_data_selector)

  function onClick(e: any) {
    const level = map.getLevel()

    if (level > 3) return

    const geocoder = new window.kakao.maps.services.Geocoder()

    const { latLng } = e

    geocoder.coord2Address(
      latLng.getLng(),
      latLng.getLat(),
      (result: Array<KakaoCoord2AddressResultModel>, status: any) =>
        searchAddressCallback(result, status, latLng.getLat(), latLng.getLng())
    )
  }

  function onChangeCurrentPosition__Hash(latLng: Array<number>) {
    const [lat, lng] = latLng

    const geocoder = new window.kakao.maps.services.Geocoder()

    geocoder.coord2Address(
      lng,
      lat,
      (result: Array<KakaoCoord2AddressResultModel>, status: any) =>
        searchAddressCallback(result, status, lat, lng)
    )
  }

  function searchAddressCallback(
    result: Array<KakaoCoord2AddressResultModel>,
    status: any,
    lat: number,
    lng: number
  ) {
    if (status === window.kakao.maps.services.Status.OK) {
      if (result.length) {
        const target = result[0]

        const { address_name } = target.address

        getPnuPolygon(address_name, lat, lng, address_name)
      }
    }
  }

  function getPnuPolygon(
    address_name: string,
    lat: number,
    lng: number,
    jibun: string
  ) {
    API.estate
      .getPnuPolygon(address_name)
      .then((res) => {
        apiCallback(res, {
          success: (res) => getPnuPolygonSuccessCallback(res, lat, lng, jibun),
          fail: blankFn,
        })
      })
      .catch(blankFn)
  }

  function onClickContent(e: MouseEvent) {
    e.stopPropagation()
    if (_jibun.current) {
      //23.08.23 #89.한글파라미터부분 인코딩 된 형태로 저장
      const encodingAddress = window.btoa(encodeURIComponent(_jibun.current))
      push('/sale-detail-type-pun?j=' + encodingAddress)
    }
  }

  function getPnuPolygonSuccessCallback(
    res: GetPnuPolygonResponseModel,
    lat: number,
    lng: number,
    jibun: string
  ) {
    eraseAll()
    if (res.polygon && jibun) {
      _prevent_init.current = true

      const existEstate = estate.find((e) => e.lat === res?.lat && e.lng === res?.lng)
      drawPolygon(res.polygon, existEstate !== undefined)
      drawMarker(lat, lng, jibun, existEstate !== undefined)
      updateJibun(jibun)
    }
  }

  function drawPolygon(
    polygon_path: GetPnuPolygonResponseModel['polygon'],
    isExistEstate: boolean
  ) {
    if (isExistEstate) return
    const path = polygon_path.map((i) => new window.kakao.maps.LatLng(...i))

    const polygon = new window.kakao.maps.Polygon({
      path,
      strokeWeight: 2,
      strokeColor: '#FF8216',
      fillColor: '#FF6C24',
      fillOpacity: 0.3,
    })

    polygon.setMap(map)

    _polygon.current = polygon
  }

  function drawMarker(lat: number, lng: number, jibun: string, isExistEstate: boolean) {
    const position = new window.kakao.maps.LatLng(lat, lng)

    let content = document.createElement('div')

    content.className = 'pnu'

    content.innerHTML = `<p>${jibun}</p><span>상세보기</span><img src='/icon/pnu-arrow.png' alt='화살표 아이콘' />`

    if (!isExistEstate) content.addEventListener('click', onClickContent)

    _content.current = content

    let marker = new window.kakao.maps.CustomOverlay({
      position,
      content,
    })

    if (!isExistEstate) {
      marker.setMap(map)
      _marker.current = marker      
    } 
  }

  function updateJibun(jibun: string) {
    _jibun.current = jibun
  }

  function eraseAll() {
    if (_polygon.current) {
      _polygon.current.setMap(null)
      _polygon.current = null
    }
    if (_marker.current) {
      _marker.current.setMap(null)
      _marker.current = null
    }
    if (_jibun.current) {
      _jibun.current = ''
    }
    if (_content.current) {
      _content.current.removeEventListener('click', onClickContent)
      _content.current = null
    }
  }

  function drawSearchAddressPolygon(lat: number, lng: number){
    const geocoder = new window.kakao.maps.services.Geocoder()
    geocoder.coord2Address(
      lng,
      lat,
      (result: Array<KakaoCoord2AddressResultModel>, status: any) =>
        searchAddressCallback(result, status, lat, lng)
    )
  }

  //23.10.12 마커가 변경할 때 마다 이벤트 등록 ( 클릭 지점 마커 존재 시 pnu 노출 X )
  useEffect(() => {
    window.kakao.maps.event.addListener(map, 'click', onClick)
    return () => {
      window.kakao.maps.event.removeListener(map, 'click', onClick)

      if(_prevent_init.current){
        _prevent_init.current = false
        return
      }

      eraseAll()
    }
  }, [estate])

  useEffect(() => {
    if (level > 3) {
      eraseAll()
    }else{
      if(search){
        drawSearchAddressPolygon(search.lat, search.lng)
      }
    }
    // eslint-disable-next-line
  }, [level, search])

  useEffect(() => {
    if (getHash().LatLng) {
      onChangeCurrentPosition__Hash(
        getHash().LatLng.split('^').map(position => Number(position))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])

  return <></>
}

export default MapClickControll
