import { useState, useEffect } from 'react'

import Main from '../../components/layout/main'

import '../../style/pages/trade-price-detail.scss'

import DeclarePopup from '../../components/popup/declare-popup'
import RegisterPopup from '../../components/popup/register-popup'
import SharePopup from '../../components/popup/share-popup'
import MobileGNB from '../../components/layout/mobile-gnb'
import ScrollDetailTab from '../../components/tab/scroll_detail_tab'

import Bunyang from '../../components/bunyang'
import { useRecoilState, useRecoilValue } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import Rt from '../../components/rt'
import media_atom from '../../atom/media-atom'
import ErrorBoundary from '../../components/layout/error-boundary'
import ErrorBoundaryFallbackKick from '../../components/layout/error-boundary-fallback-kick'
import { PATH } from '../../shared/constant'
import PNU from '../../components/@pnu'
import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'
import { getQuery } from '../../shared/function'

const SaleDetailTypeRT = () => {
  const media = useRecoilValue(media_atom)

  const [bunyang_detail, setBunyangDetail] = useRecoilState(bunyang_detail_atom) 

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  const [officialPrice, setOfficialPrice] = useState<number>(0)
  const [totalTotArea, setTotalTotArea] = useState<number>(0)
  const [jimok, setJimok] = useState<string>('')

  const { popup } = bunyang_detail

  const setPopup = (e: number) => {
    setBunyangDetail({
      ...bunyang_detail,
      popup: e,
    })
  }

  const [scrollY, setScrollY] = useState(0)

  const handleGnb = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleGnb)
    }

    watch()

    return () => {
      window.removeEventListener('scroll', handleGnb)
    }
  })

  const [연면적, set연면적] = useState<number>(0)
  const [신축년도, set신축년도] = useState<string>('')

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryFallbackKick
          message='조회할 수 없는 정보입니다.'
          path={PATH.MAIN}
        />
      }
    >
      <MobileGNB title='실거래 상세' />
      <Main className='trade_price_detail detail_bottom_wrap'>
        <div className='container'>
          <Rt.MobileTopWrapper />
          <div className='inner'>
            <Rt.PcTopWrapper />
            <div className='detail_desc'>
              <div className='desc_text'>
                <Bunyang.TopWrapper />
                <Rt.MiddleWrapper />
                <Rt.BottomWrapper 연면적={연면적} 신축년도={신축년도} />
              </div>
            </div>
          </div>
          <ScrollDetailTab
            className={
              media
                ? scrollY >= 600
                  ? 'fixed'
                  : ''
                : scrollY >= 1024
                ? 'fixed'
                : ''
            }
            data={[
              { name: '토지 정보' },
              { name: '매물 시세' },
              { name: '부동산 뉴스' },
            ]}
          />
          <div className='inner'>
            <div id='location--0' style={{ height: 0, width: '1px' }} />
            {/* 토지정보 */}
            <PNU.AreaTable
              // detail={data.detail}
              area_type={bunyang_detail.area_type}
              jibun={data.detail.address_text}
              setOfficialPrice={setOfficialPrice}
              setTotalTotArea={setTotalTotArea}
              setJimok={setJimok}
            />
            {/* 건물정보 */}
            <PNU.BuildingTable
              // detail={data.detail}
              area_type={bunyang_detail.area_type}
              jibun={data.detail.address_text}
              type={'RT'}
              pnu={'2871032027106970006'}
              set연면적={set연면적}
              set신축년도={set신축년도}
            />
            <div id='location--1' style={{ height: 0, width: '1px' }} />
            <Rt.TradePriceInfo />
            {/* <div id='location--1' style={{ height: 0, width: '1px' }} /> */}
            {/* <Rt.LoanWrapper /> */}
            <div id='location--2' style={{ height: 0, width: '1px' }} />
            <Rt.NewsWrapper />
          </div>
        </div>
      </Main>
      {popup === 1 && <DeclarePopup onClickClose={() => setPopup(0)} />}
      {popup === 2 && <RegisterPopup onClickClose={() => setPopup(0)} />}
      {popup === 3 && <SharePopup onClickClose={() => setPopup(0)} />}
    </ErrorBoundary>
  )
}

export default SaleDetailTypeRT
