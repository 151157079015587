import { useRecoilValue } from "recoil"
import media_atom from "../../atom/media-atom"
import Skeleton from "../skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileBottomCardSkeleton = () => {

  const media = useRecoilValue(media_atom)

  if ( media === "P" || media === "T" ) return <></>

  return (
    <div className="mobile_register_card">
      <Skeleton width={'100%'} height={208} />
    </div>
  )
}

export default MobileBottomCardSkeleton