import { useState } from "react"
import { hexToRgba } from "../../shared/function"

import lot_guide_icon from '../../static/img/icons/lot-guide-icon.png'

// const list = [
//   {
//     name: ['전기·가스·증기 및 공기조절 공급업', '하수 폐수 및 분뇨 처리업', '폐기물 수집운반 처리 및 원료재생업'],
//     backgroundColor: '#dde89a',
//     borderColor: '#d4e857'
//   },
//   {
//     name: ['음료제조업', '가죽/가방/신발제조업', '전기장비제조업'],
//     backgroundColor: '#ffe292',
//     borderColor: '#ffc934'
//   },
//   {
//     name: ['담배제조업', '인쇄및기록매체복제업', '기타기계및장비제조업'],
//     backgroundColor: '#ffdaa3',
//     borderColor: '#ffbb54'
//   },
//   {
//     name: ['섬유제품제조업', '고무제품/플라스틱제품제조업', '1차금속제조업'],
//     backgroundColor: '#f8a980',
//     borderColor: '#f86418'
//   },
//   {
//     name: ['의복/의복액세서리/모피제품제조업', '금속가공제품제조업', '산업용 기계 및 장비 수리업'],
//     backgroundColor: '#f8a198',
//     borderColor: '#f85645'
//   },
//   {
//     name: ['기타제품제조업', '창고/운송관련서비스업', '기타업종'],
//     backgroundColor: '#7bcbbe',
//     borderColor: '#36cbb2'
//   },
//   {
//     name: ['코크스/연탄/석유정제품제조업', '의료/정밀/광학기기/시계제조업', '자동차/트레일러제조업'],
//     backgroundColor: '#81d2e5',
//     borderColor: '#2bc2e5'
//   },
//   {
//     name: ['식료품제조업', '기타운송장비제조업', '펄프/종이/종이제품제조업'],
//     backgroundColor: '#6f85c1',
//     borderColor: '#2851c1'
//   },
//   {
//     name: ['목재/나무제품제조업', '가구제조업'],
//     backgroundColor: '#9d86be',
//     borderColor: '#8156be'
//   },
//   {
//     name: ['의료용물질/의약품제조업', '비금속광물제품제조업', '전자부품/컴퓨터/영상/음향/통신장비제조업'],
//     backgroundColor: '#d792bf',
//     borderColor: '#d756aa'
//   },
// ]

const list = [
  {code: 'C10', name: '식료품제조업'},
  {code: 'C11', name: '음료제조업'},
  {code: 'C12', name: '담배제조업'},
  {code: 'C13', name: '섬유제품제조업'},
  {code: 'C14', name: '의복/의복액세서리/모피제품제조업'},
  {code: 'C15', name: '가죽/가방/신발제조업'},
  {code: 'C16', name: '목재/나무제품제조업'},
  {code: 'C17', name: '펄프/종이/종이제품제조업'},
  {code: 'C18', name: '인쇄및기록매체복제업'},
  {code: 'C19', name: '코크스/연탄/석유정제품제조업'},
  {code: 'C20', name: '화학물질/화학제품제조업'},
  {code: 'C21', name: '의료용물질/의약품제조업'},
  {code: 'C22', name: '고무제품/플라스틱제품제조업'},
  {code: 'C23', name: '비금속광물제품제조업'},
  {code: 'C24', name: '1차금속제조업'},
  {code: 'C25', name: '금속가공제품제조업'},
  {code: 'C26', name: '전자부품/컴퓨터/영상/음향/통신장비제조업'},
  {code: 'C27', name: '의료/정밀/광학기기/시계제조업'},
  {code: 'C28', name: '전기장비제조업'},
  {code: 'C29', name: '기타기계및장비제조업'},
  {code: 'C30', name: '자동차/트레일러제조업'},
  {code: 'C31', name: '기타운송장비제조업'},
  {code: 'C32', name: '가구제조업'},
  {code: 'C33', name: '기타제품제조업'},
  {code: 'C34', name: '산업용 기계 및 장비 수리업'},
  {code: 'D35', name: '전기·가스·증기 및 공기조절 공급업'},
  {code: 'E37', name: '하수 폐수 및 분뇨 처리업'},
  {code: 'E38', name: '폐기물 수집운반 처리 및 원료재생업'},
  {code: 'H52', name: '창고/운송관련서비스업'},
]

function LotGuide() {
  const [is_show, setIsShow] = useState(false)

  const onClickTable = () => {
    setIsShow(!is_show)
  }

  return (
    <div className='rel lot-guide-wrap'>
      <button
        onClick={onClickTable}
        className='mini_gray_line_btn'
      >
        업종 분류표
      </button>
      {is_show && (
        // <ul>
        //   {list.map((item, index) => {
        //     return (
        //       <li>
        //         <div
        //           style={{
        //             backgroundColor: hexToRgba(item.backgroundColor, 0.4),
        //             border: `1px solid ${item.borderColor}`,
        //           }}
        //         />
        //         <p
        //           dangerouslySetInnerHTML={{ __html: `- ${item.name.join('<br/>- ')}` }}
        //         />
        //       </li>
        //     )
        //   })}
        // </ul>
        <ul>
          {list.map((item, index) => {
            return (
              <li key={item.code}>
                <p className="code">{item.code}</p>
                <p className="name">{item.name}</p>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default LotGuide