import { useRecoilValue } from "recoil"
import { comma, getQuery, onChangeToggleTypeUnit, onChangeToggleTypeValue, tableData } from "../../shared/function"

import item1 from '../../static/img/icons/info-item13.svg'
import item2 from '../../static/img/icons/info-item1.svg'
import item3 from '../../static/img/icons/info-item7.svg'
import item4 from '../../static/img/icons/info-item6.svg'
import item5 from '../../static/img/icons/info-item15.svg'
import item6 from '../../static/img/icons/info-item1.svg'
import item7 from '../../static/img/icons/info-item12.svg'
import item8 from '../../static/img/icons/info-item13.svg'
import bunyang_detail_atom from "../../atom/bunyang-detail"
import get_sandan_detail_selector from "../../selector/get-sandan-detail-selector"
import sandan_detail_atom from "../../atom/sandan-detail"

interface upjongDataModel {
  code: string
  name?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const Table = () => {
  
  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const { area_type } = useRecoilValue(sandan_detail_atom)

  const toggle_type = area_type === 'PY' ? 'left' : 'right'

  const area = data.set_area ?? 0
  let py = 0

  if((area ?? 0) > 0){
    py = area / 3.3058
  }

  let table_data = [
    {
      icon : item1 ,
      name : '단지유형' ,
      text : <><strong>{tableData(data.type)}</strong></>
    },{
      icon : item2 ,
      name : '조성상태' ,
      text :  <><strong>{tableData(data.design_status ?? '데이터없음')}</strong></>
    },{
      icon : item3 ,
      name : '분양상태' ,
      text :  <><strong>{tableData(data.route_status ?? '데이터없음')}</strong></>
    },{
      icon : item4 ,
      name : '사업기간(년월)' ,
      text :  <><strong>{tableData(data.business_schedule ?? '데이터없음')}</strong></>
    }
  ]

  return (
    <>
      <ul className='detail_info_items'>
        {
          table_data.map((i , index) => {
            return (
              <li key={index}>
                <div className='item_title'>
                  <img src={i.icon} alt='' />
                  {i.name}
                </div>
                <div className='item_desc'>
                  {i.text}
                </div>
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default Table