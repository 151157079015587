import React from 'react'
import ReactDOM from 'react-dom'
import App from './gongzzang'
import '../src/style/common.scss'
import '../src/style/components/tab/detail-tab.scss'
import { RecoilRoot } from 'recoil'
// import RecoilIndicator from './components/util/recoil-indicator'
// import atoms from './atom'
// import selectors from './selector'

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    {/* <RecoilIndicator atoms={atoms} selectors={selectors} /> */}
  </RecoilRoot>,
  document.getElementById('root')
)
