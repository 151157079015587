import Axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import accountManager from '../../shared/account-manager'
import { GET, JSON_HEADER, SEARCH_URL } from '../../shared/api-constant'
import { ElasticType } from '../../shared/types'
import AddLogRequestModel from './model/add-log-request-model'
import GetLogRequestModel from './model/get-log-request-model'
import GetResultRequestModel from './model/get-result-request-model'

const search = {
  getRecommend: (type: ElasticType) => {
    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/recommend.php?type=${type}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return Axios(config)
  },

  getSigungudong: (keyword: string, cancel_token: CancelToken) => {
    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/sigungudong.php?keyword=${keyword}`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
    }

    return Axios(config)
  },

  cancelSigungudong: async (cancel_token: CancelTokenSource) => {
    cancel_token.cancel()
  },

  addLog: (request: AddLogRequestModel) => {
    let params = { ...request }

    if (accountManager.isLogin()) {
      params.account_idx = accountManager.getIdx() ?? ''
    }

    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/add_log.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params,
    }

    return Axios(config)
  },

  getLog: () => {
    let params = {} as GetLogRequestModel

    if (accountManager.isLogin()) {
      params.account_idx = accountManager.getIdx() ?? ''
    }

    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/get_log.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params,
    }

    return Axios(config)
  },

  getResult: (request: GetResultRequestModel, cancel_token: CancelToken) => {
    let params = { ...request }

    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/get_result.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      cancelToken: cancel_token,
      params,
    }
    return Axios(config)
  },

  getType: () => {
    const config: AxiosRequestConfig = {
      url: `${SEARCH_URL}/get_type.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }
    return Axios(config)
  },
}

export default search
