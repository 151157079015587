import TradePriceInfo from "../detail/trade-price-info"
import BottomWrapper from "./bottom-wrapper"
import LoanWrapper from "./loan-wrapper"
import MiddleWrapper from "./middle-wrapper"
import MobileTopWrapper from "./mobile-top-wrapper"
import NewsWrapper from "./news-wrapper"
import PcTopWrapper from "./pc-top-wrapper"
import RtChartWrapper from "./rt-chart-wrapper"
import RtTableWrapper from "./rt-table-wrapper"
import View3dWrapper from "./view-3d-wrapper"

const Rt = {
  MiddleWrapper ,
  BottomWrapper ,
  PcTopWrapper ,
  MobileTopWrapper ,
  LoanWrapper ,
  NewsWrapper ,
  TradePriceInfo ,
  RtTableWrapper ,
  RtChartWrapper ,
  View3dWrapper
}

export default Rt