import EChartsReact from 'echarts-for-react'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import API from '../../../service/api'
import CoordToAddressResponseModel from '../../../service/kakao/model/coord-to-address-response-model'
import { bigNumberDot, comma, convertPY, convertType, getQuery } from '../../../shared/function'
import Skeleton from '../../skeleton'
import { RendDataResponseDataHistoryModel } from '../../../selector/get-trend-data-selector'
import moment from 'moment'
import get_sandan_detail_selector from '../../../selector/get-sandan-detail-selector'
import get_sandan_real_deal_selector from '../../../selector/get-sandan-real-deal-selector'

/* eslint-disable @typescript-eslint/no-unused-vars */
const RtChart = () => {
  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const realDealData = useRecoilValue(get_sandan_real_deal_selector(getQuery().id))
  const realDealDataHistoryList = realDealData?.history ?? []


  //const { avg_price, cnt, ym } = trend

  const [location, setLocation] = useState('')
  const [historyList, setHistoryList] = useState<Array<RendDataResponseDataHistoryModel>>(realDealDataHistoryList)
  const [page, setPage] = useState<number>(1)

  const getDong = () => {
    API.kakao
      .coordToAddress(data.lat, data.lng)
      .then((res) => {
        const data = res.data as CoordToAddressResponseModel

        if (data.meta.total_count === 0) throw new Error()

        const item = data.documents[0]

        if (item.region_3depth_name) setLocation(item.region_3depth_name)
        else if (item.region_2depth_name) setLocation(item.region_2depth_name)
        else throw new Error()
      })
      .catch(coordToAddressFailCallback)
  }

  const coordToAddressFailCallback = () => {
    setLocation('강남구')
  }

  const onClickMore = () => {
    setPage((page) => page + 1)
  }

  useEffect(() => {
    getDong()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ymList = realDealData?.ym ?? []

  if (ymList.length < 1) return <></>

  return (
    <article>
      {location === '' ? (
        <Skeleton width={100} height={25} marginBottom={16} radius={4} />
      ) : (
        // <h2>'{location}' 매물 시세</h2>
        <h2>
          '{location}' 실거래 평단가
          추이
        </h2>
      )}
      <div className='chart_box'>
        <EChartsReact
          style={{ width: '100%', height: '100%' }}
          option={{
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['거래량', '실거래가'],
              right: '20px',
              top: '0',
              textStyle: {
                fontSize: 12,
                color: '#515C64',
              },
              icon: 'circle',
            },
            xAxis: {
              data: realDealData.ym,
              axisLine: {
                show: true,
                onZero: true,
                lineStyle: {
                  width: '2',
                  color: '#e9eaec',
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: { show: false },
              splitArea: { show: false },
              axisLabel: {
                color: '#acacac',
              },
            },
            yAxis: [
              {
                type: 'value',
                axisLine: {
                  show: false,
                  lineStyle: {
                    show: false,
                    color: '#959EA5',
                  },
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: 'dashed',
                    color: '#eaeaec',
                  },
                },
              },
              {
                type: 'value',
                axisLine: {
                  show: false,
                  lineStyle: {
                    show: false,
                    color: '#959EA5',
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: 'dashed',
                    color: '#eaeaec',
                  },
                },
                axisLabel: {
                  formatter: function (data: any) {
                    return bigNumberDot(data) + '원'
                  },
                },
              },
            ],
            grid: {
              left: '30px',
              right: '65px',
              bottom: '30px',
              top: '40px',
            },
            series: [
              {
                name: '실거래가',
                type: 'bar',
                yAxisIndex: 1,
                data: realDealData.avg_price,
                itemStyle: {
                  color: '#65378040',
                },
                animationDelay: function (i: number) {
                  return i * 10
                },
              },
              {
                name: '거래량',
                type: 'line',
                stack: 'one',
                data: realDealData.cnt,
                itemStyle: {
                  color: '#5d85e5',
                },
                lineStyle: {
                  width: '3',
                },
                symbolSize: 10,
                symbol: 'circle',
              },
            ],
            animationEasing: 'elasticOut',
            animationDelayUpdate: function (i: number) {
              return i * 5
            },
          }}
        />
      </div>
      <div className='chart_table'>
        <table>
          <thead>
            <tr>
              <th>거래일</th>
              <th>종류</th>
              <th>지목</th>
              <th>지번</th>
              <th>면적</th>
              <th>실거래가</th>
            </tr>
          </thead>
          <tbody>
            {historyList.length > 0 ? (
              <>
                {historyList.map((item, index) => {
                  const offset = page * 5

                  if(index < offset){
                    return (
                      <tr>
                        <td>{moment(item.complete_ymd).format('YYYY.MM')}</td>
                        <td>{convertType(item.type)}</td>
                        <td>{item.jimok ?? '-'}</td>
                        <td><a href={`/map?sp=1&splat=${data.lat}&splng=${data.lng}&level=6&search_kor=${encodeURIComponent(item.address_jibun)}`} className='link'>{item.address_jibun ?? '-'}</a></td>
                        <td>{item.total_plat_area ??  '-'} ㎡<br/>({item.total_plat_area ? convertPY(item.total_plat_area) : '-'} 평)</td>
                        <td>{item.price_main ? comma(item.price_main) : '-'}<br/>({item.price_main && item.total_plat_area ? comma(Math.round(item.price_main/convertPY(item.total_plat_area))) : '-'}원/평)</td>
                      </tr>
                    )
                  }else{
                    <></>
                  }
                })}
              </>
            ) : (
              <tr><td colSpan={7}>거래 내역이 없습니다.</td></tr>
            )}
            
          </tbody>
        </table>
        {historyList.length > 5 && page <= Math.ceil(historyList.length / 5) && (
          <div className='more_btn_wrap' onClick={onClickMore}>더보기</div>
        )}
      </div>
    </article>
  )
}

export default RtChart
