import { useState } from 'react'
import '../../style/components/detail/tax-guide-table.scss'
import tax_guide_icon from '../../static/img/icons/tax-guide-icon.svg'
import { useRecoilValue } from 'recoil'
import media_atom from '../../atom/media-atom'
import Popup from '../popup/popup'

interface Props {
  isMobile?: boolean
}

function TaxGuide(props: Props) {
  return (
    <div className='tax_guide'>
      {!props.isMobile && <h3 className='tax_guide_title'>주택 외 취득세표</h3>}
      <div className='tax_guide_table'>
        <table>
          <thead>
            <tr>
              <th colSpan={3}>주택외</th>
              <th>취득세</th>
              <th>농어촌특별세</th>
              <th>지방교육세</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>주택 외 매매(토지, 건물 등)</td>
              <td>4%</td>
              <td>0.2%</td>
              <td>0.4%</td>
            </tr>
            <tr>
              <td colSpan={3}>원시취득(신축), 상속(농지 외)</td>
              <td>2.8%</td>
              <td>0.2%</td>
              <td>0.16%</td>
            </tr>
            <tr>
              <td colSpan={3}>무상취득(증여)</td>
              <td>3.5%</td>
              <td>0.2%</td>
              <td>0.3%</td>
            </tr>
            <tr>
              <td rowSpan={3}>농지</td>
              <td rowSpan={2}>매매</td>
              <td>신규</td>
              <td>3%</td>
              <td>0.2%</td>
              <td>0.2%</td>
            </tr>
            <tr>
              <td>2년이상 자경</td>
              <td>1.5%</td>
              <td></td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td>상속</td>
              <td></td>
              <td>2.3%</td>
              <td>0.2%</td>
              <td>0.06%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default function TaxGuideTable() {

  const media = useRecoilValue(media_atom)
  
  const [show, setShow] = useState(false)

  const showGuide = () => {
    setShow(true)
  }
  const hideGuide = () => {
    setShow(false)
  }

  return (
    <div className='tax_guide_wrap'>
      {media === 'M' ?
        <>
          <div className='tax_icon' onClick={showGuide}>
            <img src={tax_guide_icon} alt='취득세 정보 가이드' />
          </div>
          {show && 
            <>
            <Popup onClickClose={hideGuide} title='주택 외 취득세표'>
              <TaxGuide isMobile />
            </Popup>
            </>
          }
        </>
      :
        <>
          <div className='tax_icon' onMouseOver={showGuide} onMouseOut={hideGuide}>
            <img src={tax_guide_icon} alt='취득세 정보 가이드' />
          </div>
          {show && <TaxGuide />}
        </>
      }
    </div>
  )
}
