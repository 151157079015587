import axios, { AxiosRequestConfig, CancelToken } from 'axios'
import { SelectBunyangAtomModel } from '../../atom/select-bunyang'
import { SelectThemeAtomModel } from '../../atom/select-theme'
import { BUNYANG_URL, GET, JSON_HEADER } from '../../shared/api-constant'

const theme = {
  getBunyangMainThemeList: () => {
    const config: AxiosRequestConfig = {
      url: `${BUNYANG_URL}/bunyang_main_theme_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return axios(config)
  },

  getBunyangDataAddress: () => {
    const config: AxiosRequestConfig = {
      url: `${BUNYANG_URL}/bunyang_data_address.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
    }

    return axios(config)
  },

  getBunyangTheme: (
    request: SelectThemeAtomModel,
    cancel_token: CancelToken
  ) => {
    const config: AxiosRequestConfig = {
      url: `${BUNYANG_URL}/bunyang_theme_list.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params: {
        theme_idx: request.idx,
        order: request.order_by,
        estate_type: request.estate_type,
        metadata_sigungudong_idx: request.location_idx,
        current_page: request.current_page,
      },
      cancelToken: cancel_token,
    }

    return axios(config)
  },

  getBunyangData: (
    request: SelectBunyangAtomModel,
    cancel_token: CancelToken
  ) => {
    const config: AxiosRequestConfig = {
      url: `${BUNYANG_URL}/bunyang_data.php`,
      method: GET,
      headers: {
        ...JSON_HEADER,
      },
      params: {
        order: request.order_by,
        estate_type: request.estate_type,
        metadata_sigungudong_idx: request.location_idx,
        current_page: request.current_page,
        search_value: request.search,
      },
      cancelToken: cancel_token,
    }

    return axios(config)
  },
}

export default theme
