import { selector } from 'recoil'
import map_filter_atom from '../atom/map-filter'

const map_filter_is_filtered_seletor = selector<boolean>({
  key: '@map/filter-is-filtered',
  get: ({ get }) => {
    const map_filter = get(map_filter_atom)

    return (
      map_filter.price_once_max !== 100 ||
      map_filter.price_once_min !== 0 ||
      map_filter.price_total_max !== 999 ||
      map_filter.price_total_min !== 0 ||
      map_filter.plat_area_max !== 200 ||
      map_filter.plat_area_min !== 0 ||
      map_filter.tot_area_max !== 200 ||
      map_filter.tot_area_min !== 0 ||
      map_filter.complete_after !== 'N' ||
      map_filter.deal_kind.tr !== 'Y' ||
      map_filter.deal_kind.trade !== 'Y' ||
      map_filter.deal_kind.rent !== 'Y' ||
      map_filter.deal_kind.sub_division !== 'N' ||
      Object.values(map_filter.type).includes('N') ||
      map_filter.broker_yn !== 'N'
    )
  },
})

export default map_filter_is_filtered_seletor
