import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import bunyang_detail_atom from '../../atom/bunyang-detail'
import { encNumber, random } from '../../shared/function'
import '../../style/components/input/unit-toggle.scss'
import sandan_detail_atom from '../../atom/sandan-detail'

/* eslint-disable @typescript-eslint/no-unused-vars */
const AreaTypeToggle = () => {
  const [sandan_detail, setSandanDetail] = useRecoilState(sandan_detail_atom)

  const { area_type } = sandan_detail

  const onChange = () => {
    setSandanDetail({
      ...sandan_detail,
      area_type: area_type === 'PY' ? 'SQ' : 'PY',
    })
  }

  const name = useMemo(() => {
    return encNumber(random(10, 9999).toString())
  }, [])

  const id = useMemo(() => {
    return random(10000, 1000000)
  }, [])

  const left_id = encNumber((id + 502).toString())

  const right_id = encNumber((id + 702).toString())

  return (
    <div className={`unit_toggle`}>
      <input
        className='_left'
        type='checkbox'
        id={left_id}
        name={name}
        onChange={onChange}
        checked={area_type === 'PY'}
      />
      <label htmlFor={left_id}>평</label>
      <input
        className='_right'
        type='checkbox'
        id={right_id}
        name={name}
        onChange={onChange}
        checked={area_type === 'SQ'}
      />
      <label htmlFor={right_id}>㎡</label>
      <div className='handle'>{area_type === 'PY' ? '평' : '㎡'}</div>
    </div>
  )
}

export default AreaTypeToggle
