import { useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import { encNumber, random } from '../../shared/function'

/* eslint-disable @typescript-eslint/no-unused-vars */
const DealTypeToggle = () => {
  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const { type } = map_filter

  const { deal_kind } = map_filter

  const { tr, sub_division } = deal_kind

  const onChangeDealKind = () => {
    setMapFilter({
      ...map_filter,
      deal_kind: {
        ...deal_kind,
        tr: tr === 'Y' ? 'N' : 'Y',
        sub_division: sub_division === 'Y' ? 'N' : 'Y',
      },
    })
  }

  const name = useMemo(() => {
    return encNumber(random(10, 9999).toString())
  }, [])

  const id = useMemo(() => {
    return random(10000, 1000000)
  }, [])

  const left_id = encNumber((id + 500).toString())

  const right_id = encNumber((id + 700).toString())

  useEffect(() => {
    if (deal_kind.sub_division === 'Y') {
      setMapFilter({
        ...map_filter,
        type: {
          ...type,
          IC: 'Y',
          LC: 'Y',
        },
      })
    } else {
      if (
        type.FT === 'N' &&
        type.WH === 'N' &&
        type.LD === 'N' &&
        type.KC === 'N'
      ) {
        setMapFilter({
          ...map_filter,
          type: {
            FT: 'Y',
            WH: 'Y',
            LD: 'Y',
            KC: 'Y',
            IC: 'Y',
            LC: 'Y',
          },
        })
      }
    }
    // eslint-disable-next-line
  }, [tr, sub_division])

  return (
    <div className={`unit_toggle map`}>
      <input
        className='_left'
        type='checkbox'
        id={left_id}
        name={name}
        onChange={onChangeDealKind}
        checked={tr === 'Y'}
      />
      <label htmlFor={left_id}>매물</label>
      <input
        className='_right'
        type='checkbox'
        id={right_id}
        name={name}
        onChange={onChangeDealKind}
        checked={sub_division === 'Y'}
      />
      <label htmlFor={right_id}>분양</label>
      <div className='handle'>{tr === 'Y' ? '매물' : '분양'}</div>
    </div>
  )
}

export default DealTypeToggle
