import { atom } from "recoil"

type Model = {
    ymin: number
    xmin: number
    ymax: number
    xmax: number
}

const sandan_map_atom = atom<Model|undefined>({
  key: 'sandan_map_atom',
  default: undefined,
})

export default sandan_map_atom
