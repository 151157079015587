export const SERVER_URL = process.env.REACT_APP_REACT_BACKEND_URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const ACCOUNT_URL = `${BASE_URL}/account`
export const BOARD_URL = `${BASE_URL}/board`
export const SEARCH_URL = `${BASE_URL}/search`
export const ESTATE_URL = `${BASE_URL}/estate`
export const SANDAN_URL = `${BASE_URL}/sandan`
export const MYPAGE_URL = `${BASE_URL}/mypage`
export const BANNER_URL = `${BASE_URL}/banner`
export const BUNYANG_URL = `${BASE_URL}/bunyang`
export const MAIN_URL = `${BASE_URL}/main`
export const MAP_URL = `${BASE_URL}/map`
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'
export const JSON_HEADER = { 'Content-type': 'application/json' }
export const FORM_HEADER = { 'Content-type': 'multipart/form-data' }
export const NETWORK_ERROR_MSG = '네트워크 에러가 발생하였습니다.'
export const MCE_API_KEY = process.env.REACT_APP_REACT_TINY_EDITOR_KEY