import { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import estate_detail_news_atom from '../../atom/estate-detail-news'
import estate_detail_news_btn_click_atom from '../../atom/estate-detail-news-btn-click'
import estate_detail_news_start_point_atom from '../../atom/estate-detail-news-start-point'
import get_detail_page_news_selector from '../../selector/get-detail-page-news-selector'
import { getQuery } from '../../shared/function'
import NewsCard from '../main/news-card'
import estate_detail_news_type_atom from '../../atom/estate-detail-news-type'

interface Props {
  location: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const DetailNews = (props: Props) => {

  const [news_type, setNewsType] = useRecoilState(estate_detail_news_type_atom)

  const news = useRecoilValue(get_detail_page_news_selector(props.location))
  
  const [newsStartPointAtom, setNewsStartPointAtom] = useRecoilState(
    estate_detail_news_start_point_atom
  )

  const [news_atom, setNewsAtom] = useRecoilState(estate_detail_news_atom)
  const [btn_click, setBtnClick] = useRecoilState(
    estate_detail_news_btn_click_atom
  )

  const e_idx = parseInt(getQuery().idx) || getQuery().j


  useEffect(() => {
    if (news_atom.idx !== e_idx || news_atom.location !== props.location) {
      if(news_type !== 1){
        setNewsType(1)
        setNewsStartPointAtom(1)
      }else if(newsStartPointAtom !== 1){
        setNewsStartPointAtom(1)
      } else {
        setNewsAtom({
          list: news,
          idx: e_idx,
          location: props.location
        })
      }
    } else {
      if (btn_click) {
        
        setNewsAtom((prevData) => ({
          ...prevData,
          list: news_atom.list.concat(news),
        }))
        setBtnClick(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news, news_type])

  return (
    <ul className='nnews for-detail'>
      {news_atom.list.map((item, index) => {
        return <NewsCard {...item} index={index} key={index} />
      })}
    </ul>
  )
}

export default DetailNews
