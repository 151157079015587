import { atom } from 'recoil'

export type MediaType = 'M' | 'T' | 'P'

const media_atom = atom<MediaType>({
  key: '@window/media',
  default:
    window.innerWidth <= 768 ? 'M' : window.innerWidth <= 1024 ? 'T' : 'P',
})

export default media_atom
