import { lazy, Suspense, useCallback, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import media_atom from './atom/media-atom'
import width_atom from './atom/width-atom'
import BackTop from './components/back-top'
import useGlobalFunction from './hooks/use-global-function'
import Loading from './pages/loading'
import SaleDetailTypeBY from './pages/sale-detail/sale-detail-type-by'
import SaleDetailType3D from './pages/sale-detail/sale-detail-type-3d'
import SaleDetailTypeRT from './pages/sale-detail/sale-detail-type-rt'
import SaleDetailTypePNU from './pages/sale-detail/sale-detail-type-pnu'
import { PATH } from './shared/constant'
import GetDefault from './components/get-default'
import SaleDetailTypeSandan from './pages/sale-detail/sale-detail-type-sandan'

const LoginIndex = lazy(() => import('./pages/login'))
const Join = lazy(() => import('./pages/join'))
const Find = lazy(() => import('./pages/join/find'))

const Main = lazy(() => import('./pages/main'))

const Map = lazy(() => import('./pages/map'))

const SaleDetailTypeES = lazy(
  () => import('./pages/sale-detail/sale-detail-type-es')
)
const Register = lazy(() => import('./pages/register'))
const RegisterDetail = lazy(() => import('./pages/register/register_detail'))

const SubDivision = lazy(() => import('./pages/sub-division'))
const InfoDetail = lazy(() => import('./pages/sub-division/info-detail'))
const ThemeDetail = lazy(() => import('./pages/sub-division/theme-detail'))
const GuideDetail = lazy(() => import('./pages/sub-division/guide-detail'))
const NewsDetail = lazy(() => import('./pages/sub-division/news-detail'))
const SearchDetail = lazy(() => import('./pages/search-detail'))
const Index2 = lazy(() => import('./pages/index2'))
const ProductPlus = lazy(() => import('./pages/product-plus/index'))

const NewsDetailContent = lazy(
  () => import('./pages/sub-division/news-detail-content')
)

const ProfileDetail = lazy(() => import('./pages/profile-detail'))

const Mypage = lazy(() => import('./pages/mypage'))
const Terms = lazy(() => import('./pages/terms'))
const Notice = lazy(() => import('./pages/notice'))

// 회사소개페이지
const Introduction = lazy(() => import('./pages/introduction/index'))
const Broker = lazy(() => import('./pages/introduction/broker'))

// 검색 결과 페이지
const Search = lazy(() => import('./pages/search'))

declare global {
  interface Window {
    Kakao: any
  }
}

export default function GongZzang() {
  const [media, setMedia] = useRecoilState(media_atom)
  const setWidth = useSetRecoilState(width_atom)

  const onResize = useCallback(() => {
    setMedia(
      window.innerWidth <= 768 ? 'M' : window.innerWidth <= 1024 ? 'T' : 'P'
    )
    setWidth(window.innerWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const kakaoInit = () => {
    try {
      if (window.kakao) {
        window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY)
      }
    } catch { }
  }

  useEffect(() => {
    kakaoInit()

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useGlobalFunction()

  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <GetDefault />
          <Switch>
            <Route path={PATH.LOGIN} exact component={LoginIndex} />
            <Route path={PATH.JOIN} exact component={Join} />
            <Route path={PATH.FIND} exact component={Find} />

            <Route path={PATH.MAIN} exact component={Main} />
            <Route path='/e1' exact component={Main} />
            <Route path='/e2' exact component={Main} />
            <Route path='/e3' exact component={Main} />
            <Route path='/e4' exact component={Main} />
            <Route path='/e5' exact component={Main} />
            <Route path='/e6' exact component={Main} />
            <Route path='/e7' exact component={Main} />
            <Route path='/e8' exact component={Main} />
            <Route path='/e9' exact component={Main} />
            <Route path='/e10' exact component={Main} />

            <Route path={PATH.MAP} exact component={Map} />

            <Route
              path='/sale-detail-type-es'
              exact
              component={SaleDetailTypeES}
            />

            <Route
              path='/sale-detail-type-by'
              exact
              component={SaleDetailTypeBY}
            />

            <Route
              path='/sale-detail-type-3d'
              exact
              component={SaleDetailType3D}
            />

            <Route
              path='/sale-detail-type-rt'
              exact
              component={SaleDetailTypeRT}
            />

            <Route
              path='/sale-detail-type-pun'
              exact
              component={SaleDetailTypePNU}
            />

            <Route
              path='/sale-detail-type-sandan'
              exact
              component={SaleDetailTypeSandan}
            />

            <Route path='/register' exact component={Register} />
            <Route path={PATH.REGISTER} exact component={RegisterDetail} />
            <Route path='/sub-division' exact component={SubDivision} />
            <Route
              path='/sub-division/info-detail'
              exact
              component={InfoDetail}
            />
            <Route
              path='/sub-division/theme-detail'
              exact
              component={ThemeDetail}
            />
            <Route
              path='/sub-division/guide-detail'
              exact
              component={GuideDetail}
            />
            <Route
              path='/sub-division/news-detail'
              exact
              component={NewsDetail}
            />
            <Route
              path='/sub-division/news-detail-content'
              exact
              component={NewsDetailContent}
            />

            <Route path='/search-detail' exact component={SearchDetail} />
            <Route path='/profile-detail' exact component={ProfileDetail} />
            <Route path={PATH.MYPAGE} exact component={Mypage} />
            <Route path='/terms' exact component={Terms} />
            <Route path='/notice' exact component={Notice} />
            {/* <Route path="/product-plus" exact component={ProductPlus} /> */}
            <Route path='/index2' exact component={Index2} />
            <Route path='/product-plus' exact component={ProductPlus} />

            {/* 회사소개페이지 */}
            <Route path='/introduction' exact component={Introduction} />
            <Route path='/introduction/broker' exact component={Broker} />

            {/* 검색 결과 페이지 */}
            <Route path={PATH.SEARCH} exact component={Search} />
          </Switch>
        </BrowserRouter>
      </Suspense>
      {media === 'M' && 'T' ? <></> : <BackTop />}
    </>
  )
}
