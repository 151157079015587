import gps from '../../static/img/icons/gps.svg'
import zoomin from '../../static/img/icons/zoom-in.svg'
import zoomout from '../../static/img/icons/zoom-out.svg'
import webcam from '../../static/img/icons/webcam.svg'
import webcamPrime from '../../static/img/icons/webcam-prime.svg'

import abscissa from '../../static/img/icons/abscissa.svg'
import abscissa_active from '../../static/img/icons/abscissa_active.svg'
import attendance from '../../static/img/icons/attendance.svg'
import attendance_active from '../../static/img/icons/attendance_active.svg'
import ierase from '../../static/img/icons/ierase.svg'
import iradius from '../../static/img/icons/iradius.svg'
import iradius_active from '../../static/img/icons/iradius_active.svg'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import { comma, getGeoLocation, getQuery } from '../../shared/function'

declare global {
  interface Window {
    kakao: any
  }
}

declare const window: any

interface Props {
  map_ref: React.MutableRefObject<any>
  currentTypeId_ref: React.MutableRefObject<any>
  map_toggle: boolean
  setMapToggle: React.Dispatch<React.SetStateAction<boolean>>
}

const Controller = (props: Props) => {
  const { map_ref, currentTypeId_ref, map_toggle, setMapToggle } = props
  const [activedButton, setActivedButton] = useState<{ line: boolean, shape: boolean, circle: boolean }>({ line: false, shape: false, circle: false })

  // 거리계산
  const move_line = useRef<any>(null)
  const draw_line = useRef<Array<{ id: string, click_line: any, overlay: any, dots: any }>>([])

  const drawing = useRef<boolean | undefined>(undefined)

  // 다각형 면적계산
  const polygon_shape = useRef<any>(null)
  const area_shape = useRef<Array<{ id: string, polygon: any, overlay: any, dots: any }>>([])

  const shaping = useRef<boolean | undefined>(undefined)

  // 반경계산
  const move_circle = useRef<{ center: any, circle: any, polyline: any, overlay: any, dots: any } | undefined>(undefined)
  const draw_circle = useRef<Array<{ id: string, circle: any, polyline: any, overlay: any, dots: any }>>([])

  const calculating = useRef<boolean | undefined>(undefined)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const { broker_yn, sandan_yn } = map_filter

  const [check2, setCheck2] = useState(0)

  const [detail_btn2, setDetailBtn2] = useState(false)

  const [my_location_active, setMyLocationActive] = useState<boolean>(false)

  // useCallback
  // 거리계산 - 클릭
  const drawLineClickEvent = useCallback((mouseEvent: any) => {
    setTimeout(() => {
      const drawingFlag = drawing.current;


      if (drawingFlag === undefined) return

      // 마우스로 클릭한 위치입니다
      let clickPosition = mouseEvent.latLng;

      // 지도 클릭이벤트가 발생했는데 선을 그리고있는 상태가 아니면
      if (!drawingFlag) {

        // 상태를 true로, 선이 그리고있는 상태로 변경합니다
        drawing.current = true

        const drawLineData = {
          id: '' + new Date().getTime(),
          // 클릭한 위치를 기준으로 선을 생성하고 지도위에 표시합니다
          click_line: new window.kakao.maps.Polyline({
            map: map_ref.current, // 선을 표시할 지도입니다 
            path: [clickPosition], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
            strokeWeight: 3, // 선의 두께입니다 
            strokeColor: '#db4040', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid' // 선의 스타일입니다
          }),
          overlay: null,
          dots: [],
        }

        draw_line.current = [...draw_line.current, drawLineData]

        // 선이 그려지고 있을 때 마우스 움직임에 따라 선이 그려질 위치를 표시할 선을 생성합니다
        move_line.current = new window.kakao.maps.Polyline({
          strokeWeight: 3, // 선의 두께입니다 
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 0.5, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid' // 선의 스타일입니다    
        });

        // 클릭한 지점에 대한 정보를 지도에 표시합니다
        const drawLine = [...draw_line.current]
        const circleOverlay = displayCircleDot(clickPosition, 'red');
        const distanceOverlay = displayDistance(clickPosition, 0);

        drawLine[drawLine.length - 1] = {
          ...drawLine[drawLine.length - 1],
          dots: [
            ...(drawLine[drawLine.length - 1].dots ?? []),
            { circle: circleOverlay, distance: distanceOverlay }
          ]
        }

        draw_line.current = drawLine

      } else { // 선이 그려지고 있는 상태이면
        const clickLine = draw_line.current[draw_line.current.length - 1].click_line

        // 그려지고 있는 선의 좌표 배열을 얻어옵니다
        const path = clickLine.getPath();

        // 좌표 배열에 클릭한 위치를 추가합니다
        path.push(clickPosition);

        // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
        clickLine.setPath(path);

        var distance = Math.round(clickLine.getLength());
        const drawLine = [...draw_line.current]
        const circleOverlay = displayCircleDot(clickPosition, 'red');
        const distanceOverlay = displayDistance(clickPosition, distance);

        drawLine[drawLine.length - 1] = {
          ...drawLine[drawLine.length - 1],
          dots: [
            ...(drawLine[drawLine.length - 1].dots ?? []),
            { circle: circleOverlay, distance: distanceOverlay }
          ]
        }

        draw_line.current = drawLine
      }
    }, 0)
  }, [])

  // 거리계산 - 마우스이동
  const drawLineMoveEvent = useCallback((mouseEvent: any) => {
    const drawingFlag = drawing.current;

    // 지도 마우스무브 이벤트가 발생했는데 선을 그리고있는 상태이면
    if (drawingFlag) {
      const drawLine = draw_line.current.length > 0 ? draw_line.current[draw_line.current.length - 1] : undefined
      const id = drawLine?.id ?? ''
      const clickLine = draw_line.current[draw_line.current.length - 1].click_line
      const moveLine = move_line.current

      // 마우스 커서의 현재 위치를 얻어옵니다 
      const mousePosition = mouseEvent.latLng;

      // 마우스 클릭으로 그려진 선의 좌표 배열을 얻어옵니다
      const path = clickLine.getPath();

      // 마우스 클릭으로 그려진 마지막 좌표와 마우스 커서 위치의 좌표로 선을 표시합니다
      const movepath = [path[path.length - 1], mousePosition];
      moveLine.setPath(movepath);
      moveLine.setMap(map_ref.current);

      const distance = Math.round(clickLine.getLength() + moveLine.getLength()); // 선의 총 거리를 계산합니다
      const relativeDistance = Math.round(moveLine.getLength()); // 상대 거리 계산

      // 커스텀오버레이에 추가될 내용입니다
      const content = getTimeHTML(id, distance, relativeDistance, true);

      // 거리정보를 지도에 표시합니다
      showDistance(content, mousePosition);
    }
  }, [])

  // 거리계산 - 오른쪽 클릭
  const drawLineRightClickEvent = useCallback(() => {
    const drawingFlag = drawing.current;
    const drawLine = draw_line.current.length > 0 ? draw_line.current[draw_line.current.length - 1] : undefined

    const id = drawLine?.id ?? ''
    const clickLine = drawLine ? drawLine.click_line : drawLine
    const dots = drawLine ? drawLine.dots : []
    const moveLine = move_line.current

    if (drawingFlag === undefined) return

    // 지도 오른쪽 클릭 이벤트가 발생했는데 선을 그리고있는 상태이면
    if (drawingFlag) {
      // 마우스무브로 그려진 선은 지도에서 제거합니다
      moveLine.setMap(null);
      move_line.current = null;

      // 마우스 클릭으로 그린 선의 좌표 배열을 얻어옵니다
      var path = clickLine ? clickLine.getPath() : [];

      // 선을 구성하는 좌표의 개수가 2개 이상이면
      if (path.length > 1) {
        // 마지막 클릭 지점에 대한 거리 정보 커스텀 오버레이를 지웁니다
        if (dots[dots.length - 1].distance) {
          dots[dots.length - 1].distance.setMap(null);
          dots[dots.length - 1].distance = null;
        }

        var distance = Math.round(clickLine.getLength()), // 선의 총 거리를 계산합니다
          content = getTimeHTML(id, distance); // 커스텀오버레이에 추가될 내용입니다

        // 그려진 선의 거리정보를 지도에 표시합니다
        showDistance(content, path[path.length - 1]);

      } else {
        // 선을 구성하는 좌표의 개수가 1개 이하이면 
        // 지도에 표시되고 있는 선과 정보들을 지도에서 제거합니다.
        removeDrawLine()
      }

      // 상태를 false로, 그리지 않고 있는 상태로 변경합니다
      drawing.current = false;
    }
  }, [])

  // 다각형 면적계산 - 클릭
  const drawShapeClickEvent = useCallback((mouseEvent: any) => {
    setTimeout(() => {
      const shapingFlag = shaping.current;

      if (shapingFlag === undefined) return

      // 마우스로 클릭한 위치입니다 
      let clickPosition = mouseEvent.latLng;

      // 지도 클릭이벤트가 발생했는데 다각형이 그려지고 있는 상태가 아니면
      if (!shapingFlag) {

        // 상태를 true로, 다각형을 그리고 있는 상태로 변경합니다
        shaping.current = true;

        // 그려지고 있는 다각형을 표시할 다각형을 생성하고 지도에 표시합니다
        polygon_shape.current = new window.kakao.maps.Polygon({
          map: map_ref.current, // 다각형을 표시할 지도입니다
          path: [clickPosition], // 다각형을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
          strokeWeight: 3, // 선의 두께입니다 
          strokeColor: '#1833e5', // 선의 색깔입니다
          strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor: '#1833e5', // 채우기 색깔입니다
          fillOpacity: 0.2 // 채우기 불투명도입니다
        });

        // 그리기가 종료됐을때 지도에 표시할 다각형을 생성합니다 
        const polygonAreaData = {
          id: '' + new Date().getTime(),
          polygon: new window.kakao.maps.Polygon({
            path: [clickPosition], // 다각형을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다 
            strokeWeight: 3, // 선의 두께입니다 
            strokeColor: '#1833e5', // 선의 색깔입니다   
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#1833e5', // 채우기 색깔입니다
            fillOpacity: 0.2 // 채우기 불투명도입니다
          }),
          overlay: null,
          dots: [],
        }

        area_shape.current = [...area_shape.current, polygonAreaData]

        const areaShape = [...area_shape.current]
        const circleOverlay = displayCircleDot(clickPosition, 'blue');

        areaShape[areaShape.length - 1] = {
          ...areaShape[areaShape.length - 1],
          dots: [
            ...(areaShape[areaShape.length - 1].dots ?? []),
            { circle: circleOverlay }
          ]
        }

        area_shape.current = areaShape
      } else { // 다각형이 그려지고 있는 상태이면 
        // 그려지고 있는 다각형의 좌표에 클릭위치를 추가합니다
        // 다각형의 좌표 배열을 얻어옵니다
        var drawingPath = polygon_shape.current.getPath();

        // 좌표 배열에 클릭한 위치를 추가하고
        drawingPath.push(clickPosition);

        // 다시 다각형 좌표 배열을 설정합니다
        polygon_shape.current.setPath(drawingPath);

        // 그리기가 종료됐을때 지도에 표시할 다각형의 좌표에 클릭 위치를 추가합니다
        // 다각형의 좌표 배열을 얻어옵니다
        const polygon = area_shape.current[area_shape.current.length - 1].polygon
        const path = polygon.getPath();

        // 좌표 배열에 클릭한 위치를 추가하고
        path.push(clickPosition);

        // 다시 다각형 좌표 배열을 설정합니다
        polygon.setPath(path);

        const areaShape = [...area_shape.current]
        const circleOverlay = displayCircleDot(clickPosition, 'blue');

        areaShape[areaShape.length - 1] = {
          ...areaShape[areaShape.length - 1],
          dots: [
            ...(areaShape[areaShape.length - 1].dots ?? []),
            { circle: circleOverlay }
          ]
        }

        area_shape.current = areaShape
      }
    }, 0)
  }, [])

  // 다각형 면적계산 - 마우스이동
  const drawShapeMoveEvent = useCallback((mouseEvent: any) => {
    const drawingFlag = shaping.current;
    const drawShape = area_shape.current.length > 0 ? area_shape.current[area_shape.current.length - 1] : undefined
    const id = drawShape?.id ?? ''

    // 지도 마우스무브 이벤트가 발생했는데 다각형을 그리고있는 상태이면
    if (drawingFlag) {
      // 마우스 커서의 현재 위치를 얻어옵니다 
      var mousePosition = mouseEvent.latLng;

      // 그려지고있는 다각형의 좌표배열을 얻어옵니다
      var path = polygon_shape.current.getPath();

      // 마우스무브로 추가된 마지막 좌표를 제거합니다
      if (path.length > 1) {
        path.pop();
      }

      // 마우스의 커서 위치를 좌표 배열에 추가합니다
      path.push(mousePosition);
      const area = Math.round(polygon_shape.current.getArea());

      const content = getAreaHTML(id, area === 0 ? undefined : comma(area), true)

      showArea(content, mousePosition)

      // 그려지고 있는 다각형의 좌표를 다시 설정합니다
      polygon_shape.current.setPath(path);
    }
  }, [])

  // 다각형 면적계산 - 오른쪽 클릭
  const drawShapeRightClickEvent = useCallback(() => {
    const drawingFlag = shaping.current;
    const drawShape = area_shape.current.length > 0 ? area_shape.current[area_shape.current.length - 1] : undefined
    const id = drawShape?.id ?? ''

    // 지도 오른쪽 클릭 이벤트가 발생했는데 다각형을 그리고있는 상태이면
    if (drawingFlag) {
      // 그려지고있는 다각형을  지도에서 제거합니다
      polygon_shape.current.setMap(null);
      polygon_shape.current = null;

      // 클릭된 죄표로 그릴 다각형의 좌표배열을 얻어옵니다
      const areaShapeList = [...area_shape.current]
      const areaShape = areaShapeList[area_shape.current.length - 1]

      const polygon = areaShape.polygon
      const path = polygon.getPath();

      // 다각형을 구성하는 좌표의 개수가 3개 이상이면 
      if (path.length > 2) {
        // 지도에 다각형을 표시합니다
        polygon.setMap(map_ref.current);

        const area = Math.round(polygon.getArea()); // 다각형의 총면적을 계산합니다


        // 커스텀오버레이에 추가될 내용입니다
        const content = getAreaHTML(id, comma(area))

        // 면적정보를 지도에 표시합니다
        showArea(content, path[path.length - 1])
      } else {
        // 다각형을 구성하는 좌표가 2개 이하이면 다각형을 지도에 표시하지 않습니다
        if (areaShapeList.length > 0) {
          const idx = areaShapeList.findIndex((item) => item.id === id)
          const dots = areaShapeList[idx].dots

          for(let a = 0; a < dots.length; a++){
            const dot = dots[a]

            if(dot.circle){
              dot.circle.setMap(null)
            }
          }

          areaShapeList[idx].overlay.setMap(null)
        
          areaShapeList.splice(areaShapeList.length - 1)
        }
      }

      area_shape.current = areaShapeList

      // 상태를 false로, 그리지 않고 있는 상태로 변경합니다
      shaping.current = false;
    }
  }, [])

  // 반경계산 - 클릭
  const drawCircleClickEvent = useCallback((mouseEvent: any) => {
    setTimeout(() => {
      const drawingFlag = calculating.current;

      if (drawingFlag === undefined) return

      // 마우스로 클릭한 위치입니다 
      let clickPosition = mouseEvent.latLng;

      // 지도 클릭이벤트가 발생했는데 다각형이 그려지고 있는 상태가 아니면
      if (!drawingFlag) {

        // 상태를 그리고있는 상태로 변경합니다
        calculating.current = true;

        // 원이 그려질 중심좌표를 클릭한 위치로 설정합니다 
        move_circle.current = {
          center: mouseEvent.latLng,
          polyline: new window.kakao.maps.Polyline({
            strokeWeight: 3, // 선의 두께입니다
            strokeColor: '#ff4dec', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid' // 선의 스타일입니다
          }),
          circle: new window.kakao.maps.Circle({
            strokeWeight: 1, // 선의 두께입니다
            strokeColor: '#ff4dec', // 선의 색깔입니다
            strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#ff4dec', // 채우기 색깔입니다
            fillOpacity: 0.2 // 채우기 불투명도입니다 
          }),
          overlay: new window.kakao.maps.CustomOverlay({
            xAnchor: 0,
            yAnchor: 0,
            zIndex: 5
          }),
          dots: [],
        }

        let moveCircle = { ...move_circle.current }
        const circleOverlay = displayCircleDot(clickPosition, 'pink');

        moveCircle = {
          ...moveCircle,
          dots: [
            ...(moveCircle.dots ?? []),
            { circle: circleOverlay }
          ]
        }

        move_circle.current = moveCircle
      } else {
        // 원이 그려지고 있는 상태이면 ....

        // 마우스로 오른쪽 클릭한 위치입니다 
        const rClickPosition = mouseEvent.latLng;
        const move = move_circle.current

        if (move === undefined) return

        // 원의 반경을 표시할 선 객체를 생성합니다
        const polyline = new window.kakao.maps.Polyline({
          path: [move.center, rClickPosition], // 선을 구성하는 좌표 배열입니다. 원의 중심좌표와 클릭한 위치로 설정합니다
          strokeWeight: 3, // 선의 두께 입니다
          strokeColor: '#ff4dec', // 선의 색깔입니다
          strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid' // 선의 스타일입니다
        });

        // 원 객체를 생성합니다
        const circle = new window.kakao.maps.Circle({
          center: move.center, // 원의 중심좌표입니다
          radius: polyline.getLength(), // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
          strokeWeight: 1, // 선의 두께입니다
          strokeColor: '#ff4dec', // 선의 색깔입니다
          strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
          fillColor: '#ff4dec', // 채우기 색깔입니다
          fillOpacity: 0.2  // 채우기 불투명도입니다 
        });

        const id = '' + new Date().getTime()
        const radius = Math.round(circle.getRadius()); // 원의 반경 정보를 얻어옵니다
        const content = getTimeInCircleHTML(id, radius); // 커스텀 오버레이에 표시할 반경 정보입니다


        // 반경정보를 표시할 커스텀 오버레이를 생성합니다
        const radiusOverlay = new window.kakao.maps.CustomOverlay({
          clickalble: true,
          content: content, // 표시할 내용입니다
          position: rClickPosition, // 표시할 위치입니다. 클릭한 위치로 설정합니다
          xAnchor: 0,
          yAnchor: 0,
          zIndex: 5
        });

        // 원을 지도에 표시합니다
        circle.setMap(map_ref.current);
        // 선을 지도에 표시합니다
        polyline.setMap(map_ref.current);
        // 반경 정보 커스텀 오버레이를 지도에 표시합니다
        radiusOverlay.setMap(map_ref.current);

        const circleOverlay = displayCircleDot(clickPosition, 'pink');

        // 배열에 담을 객체입니다. 원, 선, 커스텀오버레이 객체를 가지고 있습니다
        var radiusObj = {
          id: id,
          'polyline': polyline,
          'circle': circle,
          'overlay': radiusOverlay,
          'dots': [...move.dots, { circle: circleOverlay }],
        };

        // 배열에 추가합니다
        // 이 배열을 이용해서 "모두 지우기" 버튼을 클릭했을 때 지도에 그려진 원, 선, 커스텀오버레이들을 지웁니다
        draw_circle.current.push(radiusObj);
      }
    }, 0)
  }, [])

  // 반경계산 - 마우스이동
  const drawCircleMoveEvent = useCallback((mouseEvent: any) => {
    const drawingFlag = calculating.current;
    const circle = move_circle.current;
    const drawCircle = draw_circle.current.length > 0 ? draw_circle.current[draw_circle.current.length - 1] : undefined
    const id = drawCircle?.id ?? '';

    if (circle === undefined) return

    // 지도 마우스무브 이벤트가 발생했는데 다각형을 그리고있는 상태이면
    if (drawingFlag) {
      // 마우스 커서의 현재 위치를 얻어옵니다 
      var mousePosition = mouseEvent.latLng;

      // 그려지고 있는 선을 표시할 좌표 배열입니다. 클릭한 중심좌표와 마우스커서의 위치로 설정합니다
      var linePath = [circle.center, mousePosition];

      // 그려지고 있는 선을 표시할 선 객체에 좌표 배열을 설정합니다
      circle.polyline.setPath(linePath);

      // 원의 반지름을 선 객체를 이용해서 얻어옵니다 
      var length = circle.polyline.getLength();

      if (length > 0) {
        // 그려지고 있는 원의 중심좌표와 반지름입니다
        var circleOptions = {
          center: circle.center,
          radius: length,
        };

        // 그려지고 있는 원의 옵션을 설정합니다
        circle.circle.setOptions(circleOptions);

        // 반경 정보를 표시할 커스텀오버레이의 내용입니다
        var radius = Math.round(circle.circle.getRadius());
        // const content = '<div class="info">반경 <span class="number">' + comma(radius) + '</span>m</div>';

        const content = getTimeInCircleHTML(id, radius, true);

        // 반경 정보를 표시할 커스텀 오버레이의 좌표를 마우스커서 위치로 설정합니다
        circle.overlay.setPosition(mousePosition);

        // 반경 정보를 표시할 커스텀 오버레이의 표시할 내용을 설정합니다
        circle.overlay.setContent(content);

        // 그려지고 있는 원을 지도에 표시합니다
        circle.circle.setMap(map_ref.current);

        // 그려지고 있는 선을 지도에 표시합니다
        circle.polyline.setMap(map_ref.current);

        // 그려지고 있는 원의 반경정보 커스텀 오버레이를 지도에 표시합니다
        circle.overlay.setMap(map_ref.current);
      } else {
        circle.circle.setMap(null);
        circle.polyline.setMap(null);
        circle.overlay.setMap(null);
        circle.dots.forEach((dot: any) => { dot.circle.setMap(null) })
      }
    }
  }, [])

  // 반경계산 - 오른쪽 클릭
  const drawCircleRightClickEvent = useCallback(() => {
    const drawingFlag = calculating.current;
    const move = move_circle.current;

    if (drawingFlag) {
      // 그리기 상태를 그리고 있지 않는 상태로 바꿉니다
      calculating.current = false;

      const dots = move?.dots ?? []

      for(let a = 0; a < dots.length; a++){
        const dot = dots[a]
        if(dot.circle){
          dot.circle.setMap(null)
        }
      }

      // 그려지고 있는 원, 선, 커스텀오버레이를 지도에서 제거합니다
      move?.circle.setMap(null);
      move?.overlay.setMap(null);
      move?.polyline.setMap(null);

      move_circle.current = undefined
    }
  }, [])

  const escKeyupEvent = useCallback((event: any) => {
    const key: string = event.key?.toString()

    if (key.toLowerCase() === 'escape') {
      if (drawing.current) {
        drawLineRightClickEvent() // 그리기 종료
      }
      if (shaping.current) {
        drawShapeRightClickEvent() // 그리기 종료
      }
      if (calculating.current) {
        drawCircleRightClickEvent() // 그리기 종료
      }
    }

    if(key.toLowerCase() === 'backspace') {
      if (drawing.current || shaping.current) {
        const target = [...(drawing.current ? draw_line.current : area_shape.current)]

        if(Array.isArray(target) && target.length > 0){
          const dots = [...(target[target.length - 1].dots ?? [])]

          if(dots.length > 1){
            const dot = dots[dots.length - 1]

            if(dot.circle){
              dot.circle.setMap(null)
            }
            if(dot.distance){
              dot.distance.setMap(null)
            }

            if(drawing.current){
              const drawTarget = {...target[target.length - 1]} as { id: string, click_line: any, overlay: any, dots: any }
              const id = drawTarget?.id ?? ''
              const clickLine = drawTarget.click_line
              const moveLine = move_line.current
  
              // 그려지고 있는 선의 좌표 배열을 얻어옵니다
              const path = clickLine.getPath();
              const pl = path.length
  
              // 좌표 배열에 클릭한 마지막 위치를 제거합니다
              path.splice(pl - 1, 1)
  
              // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
              clickLine.setPath(path);

              let mv = moveLine.getPath()
              mv[0] = path[path.length - 1]
                      
              move_line.current.setPath(mv);
              move_line.current.setMap(map_ref.current);

              const distance = Math.round(clickLine.getLength() + moveLine.getLength()); // 선의 총 거리를 계산합니다
              const relativeDistance = Math.round(moveLine.getLength()); // 상대 거리 계산

              // 커스텀오버레이에 추가될 내용입니다
              const content = getTimeHTML(id, distance, relativeDistance, true);

              // 거리정보를 지도에 표시합니다
              showDistance(content, mv[1]);

              target[target.length - 1] = drawTarget
            }else{
              const areaTarget = {...target[target.length - 1]} as { id: string, polygon: any, overlay: any, dots: any }
              const id = areaTarget?.id ?? ''

              // 그려지고 있는 다각형의 좌표에 클릭위치를 추가합니다
              // 다각형의 좌표 배열을 얻어옵니다
              const drawingPath = polygon_shape.current.getPath();
              const mousePosition = drawingPath[drawingPath.length - 1]

              // 좌표 배열에 클릭한 마지막 위치를 제거하고
              drawingPath.splice(drawingPath.length - 2, 1)

              // 다시 다각형 좌표 배열을 설정합니다
              polygon_shape.current.setPath(drawingPath);

              // 그리기가 종료됐을때 지도에 표시할 다각형의 좌표에 클릭 위치를 추가합니다
              // 다각형의 좌표 배열을 얻어옵니다
              const polygon = areaTarget.polygon
              const path = polygon.getPath();

              // 좌표 배열에 클릭한 마지막 위치를 제거하고
              path.splice(path.length - 1, 1)

              // 다시 다각형 좌표 배열을 설정합니다
              polygon.setPath(path);

              // 마우스무브로 추가된 마지막 좌표를 제거합니다
              if (drawingPath.length > 1) {
                drawingPath.pop();
              }

              // 마우스의 커서 위치를 좌표 배열에 추가합니다
              drawingPath.push(mousePosition);
              // 마우스의 커서 위치를 좌표 배열에 추가합니다
              const area = Math.round(polygon_shape.current.getArea());

              const content = getAreaHTML(id, area === 0 ? undefined : comma(area), true)

              showArea(content, mousePosition)

              // 그려지고 있는 다각형의 좌표를 다시 설정합니다
              polygon_shape.current.setPath(drawingPath);

              target[target.length - 1] = areaTarget
            }

            dots.splice(dots.length - 1, 1)

            target[target.length - 1] = {...target[target.length - 1], dots}

            if(drawing.current){
              draw_line.current = target as Array<{ id: string, click_line: any, overlay: any, dots: any }>
            }else{
              area_shape.current = target as Array<{ id: string, polygon: any, overlay: any, dots: any }>
            }
          }
        }
      }
    }
  }, [])

  // Event Function
  const onChangeBrokerYn = () => {
    setMapFilter({
      ...map_filter,
      broker_yn: broker_yn === 'Y' ? 'N' : 'Y',
    })
  }

  const onChangeSandanYn = () => {
    setMapFilter({
      ...map_filter,
      sandan_yn: sandan_yn === 'Y' ? 'N' : 'Y',
    })
  }

  const zoomIn = () => {
    map_ref.current.setLevel(map_ref.current.getLevel() - 1)
  }

  // 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
  const zoomOut = () => {
    map_ref.current.setLevel(map_ref.current.getLevel() + 1)
  }

  const onClickDrawLine = () => {
    // 그리는 중 버튼을 클릭했을 경우 초기화
    drawLineRightClickEvent()
    drawShapeRightClickEvent()
    drawCircleRightClickEvent()


    shaping.current = undefined
    calculating.current = undefined
    setActivedButton(prev => ({ line: !prev.line, shape: false, circle: false }))

    if (drawing.current === undefined) {
      drawing.current = false
      map_ref.current.setCursor(`url(//t1.daumcdn.net/kakaomapweb/resource/distance.cur.ico), default`)
    } else {
      if (drawing.current) {
        drawLineRightClickEvent()
      }
      drawing.current = undefined
      map_ref.current.setCursor(`pointer`)
    }
  }

  const onClickDrawShape = () => {
    // 그리는 중 버튼을 클릭했을 경우 초기화
    drawLineRightClickEvent()
    drawShapeRightClickEvent()
    drawCircleRightClickEvent()

    drawing.current = undefined
    calculating.current = undefined
    setActivedButton(prev => ({ line: false, shape: !prev.shape, circle: false }))

    if (shaping.current === undefined) {
      shaping.current = false
      map_ref.current.setCursor(`url(//t1.daumcdn.net/kakaomapweb/resource/area.cur.ico), default`)
    } else {
      if (shaping.current) {
        drawShapeRightClickEvent()
      }
      shaping.current = undefined
      map_ref.current.setCursor(`pointer`)
    }
  }

  const onClickCircle = () => {
    // 그리는 중 버튼을 클릭했을 경우 초기화
    drawLineRightClickEvent()
    drawShapeRightClickEvent()
    drawCircleRightClickEvent()

    shaping.current = undefined
    drawing.current = undefined
    setActivedButton(prev => ({ line: false, shape: false, circle: !prev.circle }))

    if (calculating.current === undefined) {
      calculating.current = false
      map_ref.current.setCursor(`url(//t1.daumcdn.net/localimg/localimages/07/2013/img/point_radius.cur.ico), default`)
    } else {
      if (calculating.current) {
        calculating.current = false;

        const move = move_circle.current

        // 그려지고 있는 원, 선, 커스텀오버레이를 지도에서 제거합니다
        move?.circle.setMap(null);
        move?.overlay.setMap(null);
        move?.polyline.setMap(null);
      }

      calculating.current = undefined
      map_ref.current.setCursor(`pointer`)
    }
  }

  const onClickRemove = () => {
    // 그리기 상태 초기화
    drawing.current = undefined
    shaping.current = undefined
    calculating.current = undefined
    map_ref.current.setCursor(`pointer`)
    setActivedButton({ line: false, shape: false, circle: false })

    // 그리기 중인 항목 초기화
    const moveLine = move_line.current
    const polygonShape = polygon_shape.current
    const moveCircle = move_circle.current

    if (moveLine) {
      moveLine.setMap(null)
    }
    if (polygonShape) {
      polygonShape.setMap(null)
    }
    if (moveCircle) {
      moveCircle?.polyline?.setMap(null)
      moveCircle?.circle?.setMap(null)
      moveCircle?.overlay?.setMap(null)
      moveCircle.dots.forEach((dot: any) => { dot.circle.setMap(null) });
    }

    move_line.current = null
    polygon_shape.current = null
    move_circle.current = undefined

    // 그려진 항목 초기화
    const lineList = draw_line.current
    const areaShapeList = area_shape.current
    const circleList = draw_circle.current

    for (let a = 0; a < lineList.length; a++) {
      lineList[a].click_line.setMap(null)
      lineList[a].overlay.setMap(null)

      for (let b = 0; b < lineList[a].dots.length; b++) {
        const dots = lineList[a].dots[b]

        if (dots.circle) {
          dots.circle.setMap(null);
        }
        if (dots.distance) {
          dots.distance.setMap(null);
        }
      }
    }
    draw_line.current = []

    areaShapeList.forEach((shape: any) => {
      shape.polygon.setMap(null)
      shape.overlay.setMap(null)
      shape.dots.forEach((dot: any) => { dot.circle.setMap(null) })
    })
    area_shape.current = []

    circleList.forEach((circle: any) => {
      circle.circle.setMap(null)
      circle.overlay.setMap(null)
      circle.polyline.setMap(null)
      circle.dots.forEach((dot: any) => { dot.circle.setMap(null) })
    })
    draw_circle.current = []
  }

  const onClickRemoveLine = (event: any, id: string) => {
    event?.preventDefault()
    event?.stopPropagation()

    drawing.current = undefined
    shaping.current = undefined
    calculating.current = undefined
    map_ref.current.setCursor(`pointer`)
    setActivedButton({ line: false, shape: false, circle: false })

    const lineList = [...draw_line.current]
    const idx = lineList.findIndex((item) => item.id === id)

    if (idx !== -1) {
      lineList[idx].click_line.setMap(null)
      lineList[idx].overlay.setMap(null)

      for (let a = 0; a < lineList[idx].dots.length; a++) {
        const dot = lineList[idx].dots[a]

        if (dot.circle) {
          dot.circle.setMap(null)
        }

        if (dot.distance) {
          dot.distance.setMap(null)
        }
      }

      lineList.splice(idx, 1)

      draw_line.current = lineList
    }
  }

  const onClickRemoveShape = (event: any, id: string) => {
    event?.preventDefault()
    event?.stopPropagation()

    drawing.current = undefined
    shaping.current = undefined
    calculating.current = undefined
    map_ref.current.setCursor(`pointer`)
    setActivedButton({ line: false, shape: false, circle: false })

    const shapeList = [...area_shape.current]
    const idx = shapeList.findIndex((item) => item.id === id)

    if (idx === -1 || idx >= shapeList.length) return

    shapeList[idx].polygon.setMap(null)
    shapeList[idx].overlay.setMap(null)
    shapeList[idx].dots.forEach((dot: any) => { dot.circle.setMap(null) })

    shapeList.splice(idx, 1)

    area_shape.current = shapeList
  }

  const onClickRemoveCircle = (event: any, id: string) => {
    event?.preventDefault()
    event?.stopPropagation()

    drawing.current = undefined
    shaping.current = undefined
    calculating.current = undefined
    map_ref.current.setCursor(`pointer`)
    setActivedButton({ line: false, shape: false, circle: false })

    const circleList = [...draw_circle.current]
    const idx = circleList.findIndex((item) => item.id === id)

    if (idx === -1 || idx >= circleList.length) return

    circleList[idx].circle.setMap(null)
    circleList[idx].polyline.setMap(null)
    circleList[idx].overlay.setMap(null)
    circleList[idx].dots.forEach((dot: any) => { dot.circle.setMap(null) })

    circleList.splice(idx, 1)

    draw_circle.current = circleList
  }

  const onClickGps = () => {
    if (my_location_active === true) return

    getGeoLocation({
      success: (pos) => {
        const move_location = new window.kakao.maps.LatLng(
          pos.coords.latitude,
          pos.coords.longitude
        )
        map_ref.current.panTo(move_location)
        setMyLocationActive(true)
        myLocationTwinkling(pos.coords.latitude, pos.coords.longitude)
      },
      fail: () => {
        alert('브라우저의 "내 위치 확인" 기능을 활성화 해주세요.')
      },
    })
  }

  const myLocationTwinkling = (lat: number, lng: number) => {
    const position = new window.kakao.maps.LatLng(lat, lng)
    const circle = new window.kakao.maps.CustomOverlay({
      position,
      content: '<div class="my-location-circle" />',
      zIndex: 10,
    })

    circle.setMap(map_ref.current)

    setTimeout(() => {
      circle.setMap(null)
    }, 3000)
  }

  // 버튼이 클릭되면 호출되는 함수입니다
  const setOverlayMapTypeId = (maptype: string) => {
    let changeMaptype

    // maptype에 따라 지도에 추가할 지도타입을 결정합니다
    if (maptype === 'traffic') {
      // 교통정보 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.TRAFFIC
    } else if (maptype === 'roadview') {
      // 로드뷰 도로정보 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.ROADVIEW
    } else if (maptype === 'terrain') {
      // 지형정보 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.TERRAIN
    } else if (maptype === 'use_district') {
      // 지적편집도 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.USE_DISTRICT
    } else if (maptype === 'hybrid') {
      // 스카이뷰 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.HYBRID
    } else if (maptype === 'roadmap') {
      // 일반 지도타입
      changeMaptype = window.kakao.maps.MapTypeId.ROADMAP
    }

    // 이미 등록된 지도 타입이 있으면 제거합니다
    if (currentTypeId_ref.current) {
      map_ref.current.removeOverlayMapTypeId(currentTypeId_ref.current)
    }

    // maptype에 해당하는 지도타입을 지도에 추가합니다
    map_ref.current.addOverlayMapTypeId(changeMaptype)

    // 지도에 추가된 타입정보를 갱신합니다
    currentTypeId_ref.current = changeMaptype
  }

  const onClickMapHandler = (type: 'type1' | 'type2' | 'type3') => {
    switch (type) {
      case 'type1':
        setDetailBtn2(false)
        setCheck2(1)
        setOverlayMapTypeId('hybrid')
        break
      case 'type2':
        setDetailBtn2(false)
        setCheck2(2)
        setOverlayMapTypeId('terrain')
        break
      case 'type3':
        setDetailBtn2(false)
        setCheck2(3)
        setOverlayMapTypeId('use_district')
        break
    }
  }

  const onClickMapToggle = () => {
    if (!map_toggle) {
      // 지도 선택버튼이 켜져있으면 꺼준다
      if (check2 !== 0 || detail_btn2) {
        setDetailBtn2(false)
        setCheck2(0)
      }
      setOverlayMapTypeId('roadview')
      setMapToggle(true)
    } else {
      setOverlayMapTypeId('roadmap')
      setMapToggle(false)
    }
  }

  const onClickRT = () => {
    setMapFilter({
      ...map_filter,
      realdeal_yn: map_filter.realdeal_yn === 'Y' ? 'N' : 'Y',
    })
  }

  const onClickDetailBtn2 = () => {
    if (check2 !== 0) {
      setDetailBtn2(false)
      setCheck2(0)
      setOverlayMapTypeId('roadmap')
    } else {
      // 로드뷰가 켜져있으면 꺼준다
      if (map_toggle) {
        setOverlayMapTypeId('roadmap')
        setMapToggle(false)
      }
      setDetailBtn2(!detail_btn2)
    }
  }

  // 클릭으로 그려진 선을 지도에서 제거하는 함수입니다
  function removeDrawLine() {
    const l = draw_line.current

    if (l.length < 1) return

    const draw = l[l.length - 1]

    if (draw.click_line) {
      draw.click_line.setMap(null);
      draw.click_line = null;
    }

    if (draw.overlay) {
      draw.overlay.setMap(null);
      draw.overlay = null;
    }

    if (Array.isArray(draw.dots)) {
      for (let a = 0; a < draw.dots.length; a++) {
        const dots = draw.dots[a]
        if (dots.circle) {
          dots.circle.setMap(null);
        }
        if (dots.distance) {
          dots.distance.setMap(null);
        }
      }
    }

    draw_line.current = draw_line.current.splice(0, draw_line.current.length - 2)
  }

  // 마우스 드래그로 그려지고 있는 선의 총거리 정보를 표시하거
  // 마우스 오른쪽 클릭으로 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 생성하고 지도에 표시하는 함수입니다
  function showDistance(content: any, position: any) {
    let drawLine = draw_line.current.length > 0 ? draw_line.current[draw_line.current.length - 1] : undefined

    const distanceOverlay = drawLine ? drawLine.overlay : undefined

    if (distanceOverlay) { // 커스텀오버레이가 생성된 상태이면

      // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
      distanceOverlay.setPosition(position);
      distanceOverlay.setContent(content);

    } else if (drawLine) { // 커스텀 오버레이가 생성되지 않은 상태이면
      const d = [...draw_line.current]

      d[draw_line.current.length - 1] = {
        ...drawLine,
        overlay: new window.kakao.maps.CustomOverlay({
          map: map_ref.current, // 커스텀오버레이를 표시할 지도입니다
          content: content,  // 커스텀오버레이에 표시할 내용입니다
          position: position, // 커스텀오버레이를 표시할 위치입니다.
          xAnchor: 0,
          yAnchor: 0,
          zIndex: 3
        })
      }

      // 커스텀 오버레이를 생성하고 지도에 표시합니다
      draw_line.current = d
    }
  }

  function showArea(content: any, position: any) {
    let areaShape = area_shape.current.length > 0 ? area_shape.current[area_shape.current.length - 1] : undefined

    const areaOverlay = areaShape ? areaShape.overlay : undefined

    if (areaOverlay) { // 커스텀오버레이가 생성된 상태이면

      // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
      areaOverlay.setPosition(position);
      areaOverlay.setContent(content);

    } else if (areaShape) { // 커스텀 오버레이가 생성되지 않은 상태이면
      const d = [...area_shape.current]

      d[area_shape.current.length - 1] = {
        ...areaShape,
        overlay: new window.kakao.maps.CustomOverlay({
          map: map_ref.current, // 커스텀오버레이를 표시할 지도입니다
          content: content,  // 커스텀오버레이에 표시할 내용입니다
          position: position, // 커스텀오버레이를 표시할 위치입니다.
          xAnchor: 0,
          yAnchor: 0,
          zIndex: 3
        })
      }

      // 커스텀 오버레이를 생성하고 지도에 표시합니다
      area_shape.current = d
    }
  }

  // 선이 그려지고 있는 상태일 때 지도를 클릭하면 호출하여 
  // 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 표출하는 함수입니다
  function displayCircleDot(position: any, className: string = '') {
    // 클릭 지점을 표시할 빨간 동그라미 커스텀오버레이를 생성합니다
    const circleOverlay = new window.kakao.maps.CustomOverlay({
      content: `<span class="dot ${className}"></span>`,
      position: position,
      zIndex: 1
    });

    // 지도에 표시합니다
    circleOverlay.setMap(map_ref.current);

    return circleOverlay
  }

  function displayDistance(position: any, distance: any) {
    let drawLine = draw_line.current.length > 0 ? draw_line.current[draw_line.current.length - 1].click_line : undefined
    let distanceOverlay = drawLine ? drawLine.overlay : drawLine

    if (distance > 0) {
      // 클릭한 지점까지의 그려진 선의 총 거리를 표시할 커스텀 오버레이를 생성합니다
      distanceOverlay = new window.kakao.maps.CustomOverlay({
        content: '<div class="dotOverlay"><span class="number">' + comma(distance) + ' m</span></div>',
        position: position,
        yAnchor: 1,
        zIndex: 5
      });

      // 지도에 표시합니다
      distanceOverlay.setMap(map_ref.current);
    }

    return distanceOverlay
  }

  // 마우스 우클릭 하여 선 그리기가 종료됐을 때 호출하여 
  // 그려진 선의 총거리 정보와 거리에 대한 도보, 자전거 시간을 계산하여
  // HTML Content를 만들어 리턴하는 함수입니다
  function getTimeHTML(id: string, distance: any, relativeDistance?: any, showHelp?: boolean) {

    // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
    var walkkTime = distance / 67 | 0;
    var walkHour = '', walkMin = '';

    // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
    if (walkkTime > 60) {
      walkHour = '<span class="number">' + comma(Math.floor(walkkTime / 60)) + '</span>시간 '
    }
    walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

    // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
    var bycicleTime = distance / 227 | 0;
    var bycicleHour = '', bycicleMin = '';

    // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
    if (bycicleTime > 60) {
      bycicleHour = '<span class="number">' + comma(Math.floor(bycicleTime / 60)) + '</span>시간 '
    }
    bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

    // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
    var content = '<ul class="dotOverlay distanceInfo">';
    if (relativeDistance !== undefined) {
      content += '    <li>';
      content += '        <span class="label">상대거리</span><span class="number">' + comma(relativeDistance) + '</span>㎥';
      content += '    </li>';
    }

    content += '    <li>';
    content += '        <span class="label">총거리</span><span class="number">' + comma(distance) + '</span>㎥';
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="label">도보</span>' + walkHour + walkMin;
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="label">자전거</span>' + bycicleHour + bycicleMin;
    content += '    </li>';
    content += '    <li class="detailToolClose" onclick="onClickRemoveLine(event, \'' + id + '\')">';
    content += '        <a href="#" data-id="hider">닫기</a>';
    content += '    </li>';
    content += '</ul>'

    if(showHelp){
      content += '<ul class="dotOverlay help">'
      content += '    <li>';
      content += '        <span class="label">부분취소</span><span class="desc">백스페이스</span>';
      content += '    </li>';
      content += '    <li>';
      content += '        <span class="label">지정완료</span><span class="desc">마우스 오른쪽, ESC</span>';
      content += '    </li>';
      content += '</ul>'
    }

    return content;
  }

  function getAreaHTML(id: string, area?: any, showHelp?: boolean) {
    var content = '';

    if (area !== undefined) {
      content += '<ul class="dotOverlay areaInfo">';
      content += '    <li>';
      content += '        <span class="label">총면적</span><span class="number"> ' + area + '</span>㎥';
      content += '    </li>';
      content += '    <li class="detailToolClose" onclick="onClickRemoveShape(event, \'' + id + '\')">';
      content += '        <a href="#" data-id="hider">닫기</a>';
      content += '    </li>';
      content += '</ul>'
    }

    if (showHelp) {
      content += '<ul class="dotOverlay help">'
      content += '    <li>';
      content += '        <span class="label">부분취소</span><span class="desc">백스페이스</span>';
      content += '    </li>';
      content += '    <li>';
      content += '        <span class="label">지정완료</span><span class="desc">마우스 오른쪽, ESC</span>';
      content += '    </li>';
      content += '</ul>'
    }

    return content
  }

  function getTimeInCircleHTML(id: string, distance: any, showHelp?: boolean) {
    // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
    var walkkTime = distance / 67 | 0;
    var walkHour = '', walkMin = '';

    // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
    if (walkkTime > 60) {
      walkHour = '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 '
    }
    walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

    // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
    var bycicleTime = distance / 227 | 0;
    var bycicleHour = '', bycicleMin = '';

    // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
    if (bycicleTime > 60) {
      bycicleHour = '<span class="number">' + Math.floor(bycicleTime / 60) + '</span>시간 '
    }
    bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

    // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
    var content = '<ul class="dotOverlay distanceInfo">';
    content += '    <li>';
    content += '        <span class="label">반경</span><span class="number">' + comma(distance) + '</span>m';
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="label">도보</span>' + walkHour + walkMin;
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="label">자전거</span>' + bycicleHour + bycicleMin;
    content += '    </li>';
    content += '    <li class="detailToolClose" onclick="onClickRemoveCircle(event, \'' + id + '\')">';
    content += '        <a href="#" data-id="hider">닫기</a>';
    content += '    </li>';
    content += '</ul>'

    if (showHelp) {
      content += '<ul class="dotOverlay help">';
      content += '    <li>';
      content += '        <span class="label">지정완료</span><span class="desc">마우스 오른쪽, ESC</span>';
      content += '    </li>';
      content += '</ul>'
    }

    return content;
  }

  useEffect(() => {
    window.addEventListener('keyup', escKeyupEvent)
    window.onClickRemoveLine = onClickRemoveLine
    window.onClickRemoveShape = onClickRemoveShape
    window.onClickRemoveCircle = onClickRemoveCircle

    return () => {
      if (map_ref.current) {
        window.kakao.maps.event.removeListener(map_ref.current, 'click', drawLineClickEvent)
        window.kakao.maps.event.removeListener(map_ref.current, 'mousemove', drawLineMoveEvent)
        window.kakao.maps.event.removeListener(map_ref.current, 'rightclick', drawLineRightClickEvent)
      }

      window.removeEventListener('keyup', escKeyupEvent)
      window.onClickRemoveLine = null
      window.onClickRemoveShape = null
      window.onClickRemoveCircle = null
    }
  }, [])

  useEffect(() => {
    setMyLocationActive(false)
  }, [map_filter.lat, map_filter.lng])

  useEffect(() => {
    if (map_ref.current) {
      // 거리계산
      window.kakao.maps.event.removeListener(map_ref.current, 'click', drawLineClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'click', drawLineClickEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'mousemove', drawLineMoveEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'mousemove', drawLineMoveEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'rightclick', drawLineRightClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'rightclick', drawLineRightClickEvent)

      // 다각형
      window.kakao.maps.event.removeListener(map_ref.current, 'click', drawShapeClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'click', drawShapeClickEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'mousemove', drawShapeMoveEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'mousemove', drawShapeMoveEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'rightclick', drawShapeRightClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'rightclick', drawShapeRightClickEvent)

      // 반경
      window.kakao.maps.event.removeListener(map_ref.current, 'click', drawCircleClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'click', drawCircleClickEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'mousemove', drawCircleMoveEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'mousemove', drawCircleMoveEvent)
      window.kakao.maps.event.removeListener(map_ref.current, 'rightclick', drawCircleRightClickEvent)
      window.kakao.maps.event.addListener(map_ref.current, 'rightclick', drawCircleRightClickEvent)
    }
  })

  return (
    <div className='controller'>
      <ul className='m_left'>
        <li>
          <button
            type='button'
            className={`tool gps ${my_location_active ? 'on' : ''}`}
            onClick={onClickGps}
          >
            <img src={gps} alt='gps' />
          </button>
        </li>
        <li>
          <div className='zoom_btn'>
            <button type='button' className='zoom in' onClick={zoomIn}>
              <img src={zoomin} alt='확대' />
            </button>
            <button type='button' className='zoom out' onClick={zoomOut}>
              <img src={zoomout} alt='축소' />
            </button>
          </div>
        </li>
      </ul>
      <ul className='m_right'>
        <li className={map_filter.deal_kind.sub_division === 'Y' ? 'h' : ''}>
          <button
            type='button'
            className={`tool size2 price ${map_filter.realdeal_yn === 'Y' ? 'on' : ''
              }`}
            onClick={onClickRT}
          >
            실거
            <br />
            래가
          </button>
        </li>
        <li>
          <button
            className={`tool size2 price ${sandan_yn === 'Y' ? 'on' : ''}`}
            onClick={() => onChangeSandanYn()}
          >
            산업
            <br />
            단지
          </button>
        </li>
        <li>
          <button
            type='button'
            className={'tool size2 price ' + (broker_yn === 'Y' ? 'on' : '')}
            onClick={onChangeBrokerYn}
          >
            중개
          </button>
        </li>
        <li>
          <button
            className={`tool size2 price ${detail_btn2 ? 'active' : ''} ${check2 > 0 ? 'on' : ''
              }`}
            onClick={() => onClickDetailBtn2()}
          >
            {check2 === 1
              ? '위성'
              : check2 === 2
                ? '지형'
                : check2 === 3
                  ? '지적'
                  : '지도'}
          </button>
          {detail_btn2 ? (
            <div className='detail_btn'>
              <button
                type='button'
                className='tool size2'
                onClick={() => onClickMapHandler('type1')}
              >
                위성
              </button>
              <button
                type='button'
                className='tool size2'
                onClick={() => onClickMapHandler('type2')}
              >
                지형
              </button>
              <button
                type='button'
                className='tool size2'
                onClick={() => onClickMapHandler('type3')}
              >
                지적
              </button>
            </div>
          ) : (
            <></>
          )}
        </li>
        <li>
          <button
            type='button'
            className={`tool size2 price `}
            onClick={onClickMapToggle}
          >
            {!map_toggle ? (
              <img src={webcam} alt='webcam' />
            ) : (
              <img src={webcamPrime} alt='webcam' />
            )}
          </button>
        </li>
        <li>
          <div className='zoom_btn'>
            <button type='button' className='zoom in' onClick={onClickDrawLine}>
              <img className='small_img' src={activedButton.line ? attendance_active : attendance} alt='거리재기' />
              <span className="tooltip line">거리재기</span>
            </button>
            <button type='button' className='zoom out' onClick={onClickDrawShape}>
              <img className='small_img' src={activedButton.shape ? abscissa_active : abscissa} alt='면적재기' />
              <span className="tooltip polygon">면적재기</span>
            </button>
            <button type='button' className='zoom out' onClick={onClickCircle}>
              <img className='small_img' src={activedButton.circle ? iradius_active : iradius} alt='반경재기' />
              <span className="tooltip circle">반경재기</span>
            </button>
            <button type='button' className='zoom out' onClick={onClickRemove}>
              <img className='small_img' src={ierase} alt='지우기' />
              <span className="tooltip earser">지우기</span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Controller
