import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import media_atom from "../../atom/media-atom"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import MobileTop from "./mobile-top"
import PcTop from "./pc-top"
import API from "../../service/api"
import AddressToCoordResponseModel from "../../service/kakao/model/address-to-coord-response-model"
import search_atom from "../../atom/search-atom"
import { useHistory } from "react-router-dom"
import { PATH } from "../../shared/constant"
import map_filter_atom from "../../atom/map-filter"

/* eslint-disable @typescript-eslint/no-unused-vars */
const Top = () => {

  const media = useRecoilValue(media_atom)
  const history = useHistory()

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  const { detail } = data

  const setSearch = useSetRecoilState(search_atom)
  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)
  
  const onClickMapBtn = (e: React.MouseEvent<HTMLButtonElement>, address: string) => {
    e.preventDefault()
    API.kakao
      .addressToCoord(address)
      .then((res) => {
        addressToCoordSuccessCallback(res.data, address)
      })
      .catch(addressToCoordFailCallback)
  }

  function addressToCoordSuccessCallback(data: AddressToCoordResponseModel, address: string) {
    if (data.meta.total_count > 0) {
      let { x, y } = data.documents[0]
      if(detail.deal_kind === 'BY') {
        setMapFilter({
          ...map_filter,
          deal_kind: {
            ...map_filter.deal_kind,
            tr: 'N',
            sub_division: 'Y',
          },
        })
      } else {
        setMapFilter({
          ...map_filter,
          complete_after: '3Y',
          realdeal_yn: 'Y',
          deal_kind: {
            ...map_filter.deal_kind,
            tr: 'Y',
            sub_division: 'N',
          },
        })
      }
      setSearch(address)
      history.push({
        pathname : `${PATH.MAP}`,
        hash : `LatLng=${y}^${x}`,
        state : 'address-from-detail-page'
      })
    } else {
      throw new Error()
    }
  }

  function addressToCoordFailCallback() {}

  return (
    <div>
      {/* #179. 분양상세페이지 수정 (산업단지 타이틀 추가) */}
      {media === 'M' ?(
        <div className="top">
          <div className='desc_address'>
            <span>{detail.address_text}</span>
            <button className='map_button' onClick={(e) => onClickMapBtn(e, detail.address_text)}>지도보기</button>
          </div>
          {detail.deal_kind === 'BY' && <div className="bunyang_title">{detail.title}</div>}
          <MobileTop />
        </div>
      ) : (
        <>
        {detail.deal_kind === 'BY' && <div className="bunyang_title">{detail.title}</div>}
        <div className="top">
          <div className='desc_address'>
            <span>{detail.address_text}</span>
            <button className='map_button' onClick={(e) => onClickMapBtn(e, detail.address_text)}>지도보기</button>
          </div>
          <PcTop />
        </div>
        </>
      )}
    </div>
  )
}

export default Top