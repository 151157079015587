import { AxiosError, AxiosResponse } from 'axios'
import React from 'react'
import ApiBaseResponseModel from '../service/model/api-base-response-model'
import { NETWORK_ERROR_MSG } from './api-constant'
import { enc } from './passport'
import { ElasticDealKindTextType, ElasticTextType, YNType } from './types'
import error_1x from '../static/img/nodata/1x.jpg'
import error_2x from '../static/img/nodata/2x.jpg'
import error_3x from '../static/img/nodata/3x.jpg'
import error_4x from '../static/img/nodata/4x.jpg'
import error_large from '../static/img/nodata/large.jpg'
import error_profile from '../static/img/nodata/default-profile.svg'
import API from '../service/api'
import { LotModel } from '../service/estate/model/get-detail-response-model'
import * as T from 'three'
import accountManager from './account-manager'

export const getQuery = (decode?: boolean) => {
  const location = decode
    ? decodeURIComponent(window.location.search)
    : window.location.search
  const result: { [key: string]: string } = {}
  for (let i = 0; i < location.replace('?', '').split('&').length; i++) {
    const item = location.replace('?', '').split('&')[i]
    const key_val = item.split('=')
    const key = key_val[0]
    const val = key_val[1]
    result[key] = val
  }

  return result
}

export const getQueryValues = (decode?: boolean) => {
  const location = decode
    ? decodeURIComponent(window.location.search)
    : window.location.search
  const result: Array<string> = []
  for (let i = 0; i < location.replace('?', '').split('&').length; i++) {
    const item = location.replace('?', '').split('&')[i]
    const key_val = item.split('=')
    const val = key_val[1]
    result.push(val)
  }

  return result
}

export const getHash = () => {
  const location = window.location
  const result: { [key: string]: string } = {}
  for (let i = 0; i < location.hash.replace('#', '').split('&').length; i++) {
    const item = location.hash.replace('#', '').split('&')[i]
    const key_val = item.split('=')
    const key = key_val[0]
    const val = key_val[1]
    result[key] = val
  }

  return result
}

export const queryToString = (obj: { [key: string]: string }) => {
  const searches = new URLSearchParams()

  for (const item in obj) {
    searches.append(item, obj[item])
  }

  return searches.toString()
}

type ObjectType = { [key: string]: any }

export const bodyGenerator = (flag: string, body: ObjectType) => {
  return {
    ...body,
    flag,
  }
}

export const formGenerator = (body: ObjectType) => {
  const form_data = new FormData()

  for (const key of Object.keys(body)) {
    if (body[key] !== null && body[key] !== undefined) {
      form_data.append(key, body[key])
    }
  }

  return form_data
}

interface ApiCallbackFnModel {
  success: (data: any) => void | Promise<void>
  fail: (error: ApiBaseResponseModel) => void
}

export const apiCallback = (res: AxiosResponse, fn: ApiCallbackFnModel) => {
  if (res.data.code === 200) fn.success(res.data)
  else
    fn.fail({
      code: res.data.code,
      msg: res.data.msg,
    })
}

export const errorAlert = (err: ApiBaseResponseModel) => {
  alert(err.msg)
}

export const networkErrorAlert = (err: AxiosError) => {
  try {
    if (err.response?.data?.msg) {
      alert(err.response?.data?.msg)
    } else {
      throw new Error()
    }
  } catch {
    alert(NETWORK_ERROR_MSG)
  }
}

export const errorGenerator = (msg: string): ApiBaseResponseModel => {
  return {
    code: 400,
    msg,
  }
}

export const getYN = (e: boolean): YNType => {
  return e ? 'Y' : 'N'
}

export const getNumber = (e: string): string => {
  return e.replace(/[^0-9]/gi, '')
}

export const blankFn = () => {}

/* REGEX */
// 이메일 형식 체크 함수
export const emailRegex = (e: string) => {
  return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(
    e
  )
}

export const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export const randomString = (length: number) => {
  let return_array = []

  const array = [
    'a',
    'b',
    '_',
    'c',
    'd',
    '_',
    'e',
    'f',
    'g',
    'h',
    'i',
    '_',
    'j',
    'k',
    'l',
    '_',
    'm',
    'n',
    '_',
    'o',
    'p',
    'q',
    'r',
    '_',
    's',
    't',
    '_',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '_',
    'A',
    'B',
    '_',
    'C',
    'D',
    '_',
    'E',
    'F',
    'G',
    '_',
    'H',
    'I',
    'J',
    '_',
    'K',
    'L',
    '_',
    'M',
    'N',
    '_',
    'O',
    'P',
    '_',
    'Q',
    'R',
    'S',
    'T',
    '_',
    '_',
    'U',
    'V',
    'W',
    '_',
    'X',
    'Y',
    '_',
    'Z',
  ]

  for (let x = 0; x < length; x++) {
    return_array.push(array[random(0, array.length - 1)])
  }

  return return_array.join('')
}
export const encNumber = (_id: string) => {
  const id = _id.toString()

  const char_array =
    'kI_fvG_LzJ_xpdYV_j_oXNnz_cv_A_MH_lmqemgpXZubFBIhA_DM_e_Syky_fENygYoJxH_XW_HQx_xyRyY_NC_H_Psp_P_X_TZTXHwq_F_DXHSqHX_WZu_ZJ_C_svOwP_DRQ_q_WM_uAQ_j_kLrRotCz_b_s_C_EplRkLt_lr_Q_x_KXjoXvqc_yvhXjZ_H_U_YR_Eqdo_u_M_Qi_E_OXlKjsONzIhDTETxuSI_Smvm_UZDhm_xQDLH_jok_rg_nZHaqNoBC_G_UZt_plx_vXOGO_kwyHb_wTHnb_Oa_jT_eT_a_YU_L_t_yBqP_lzR_iro_u_Ww_FQUSqSqlvuvWx_rFoRDuCHTn_D_g_QHzGmKNonIZ_dOW_Ic_RausB_cJY_W_JmxKv_q_K_h_b_W_OHwdaSr_oJ_D_w_UgWSGAGPM_uVyi_fVrNafLIVjPqC_zq_FZrkv_h'.split(
      ''
    )
  const char_array2 =
    'ZtrgHoQX_JfCP_Bp_H_UwCG_Q_nyoKaRE_J_gKG_cNTRrBWyhs_lZ_oSjVWnlbJ_JruDx_qR_cFSWYTYvJn_MPcP_p_Ii_H_NuSW_zoycw_gusLm_yQ_OG_Nm_TvCdNxIGw_T_zniQFc_WfszszF_SbPsyR_EVWlo_BPWwQrL_uRLfd_yhQqtloEq_Fr_oxw_hJ_EtXDjg_PsRE_lY_A_JE_MYK_z_N_R_y_iwu_La_NL_QSA_crD_r_xs_LTDT_IrXqTU_m_Y_sg_dx_KTw_CNo_CznHhHBxiH_Hpv_tUZE_E_JELd_RqeKjezOjH_sgDGvXTrli_f_R_ZSVBh_Cht_A_O_BQ_wAB_qYHTd_ZY_gD_mJq_O_lGlV_YjGyXru_mfTMYP_epc_nhz_YG_kpgfhkCT_Y_p_aEBpdWtgbsb_eIdD_x_AO_EuSd_v'.split(
      ''
    )

  const array = id.split('')

  return array
    .map((item, index) => {
      const next = array[index + 1]

      if (next) {
        const value = parseInt(item + index)
        return (
          char_array[value % (char_array.length - 1)] +
          char_array2[value % (char_array2.length - 1)]
        )
      } else {
        return char_array[+item] + char_array2[+index]
      }
    })
    .join('')
}
export const comma = (_x: string | number): string => {
  try {
    return _x
      .toString()
      .replace(/,/gi, '')
      .replace(/[^0-9]/gi, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch {
    return ''
  }
}
export const commaReplace = (e: string) => {
  return e.toString().replace(/,/gi, '')
}
export const onlyFloat = (e: string) => {
  const float = e.replace(/[^0-9 | ^.]/gi, '').replace(/\.\./gi, '')
  const first_dot_replace = float[0] === '.' ? float.replace('.', '') : float
  const first_dot_index = first_dot_replace.indexOf('.')

  const dot_replace_from_last = first_dot_replace
    .split('')
    .filter((char, index) => {
      return char !== '.' || (char === '.' && index === first_dot_index)
    })
    .join('')
  return dot_replace_from_last
}
export const isFinishedKor = (kor: string) => {
  return kor.replace(/[^가-힣]/gi, '').length > 0
}
export const isKor = (char: string) => {
  return char.replace(/[^ㄱ-힣]/gi, '').length > 0
}
export const isFloatOk = (e: string) => {
  try {
    return e[e.length - 1] !== '.'
  } catch {
    return true
  }
}
export const isFloat = (e: number) => {
  return e.toString().includes('.')
}
export const inIntersection = <T>(
  array1: Array<T>,
  array2: Array<T>
): boolean => {
  if (array2.length === 0) return false
  for (const element of array1) {
    if (array2.includes(element)) {
      return true
    }
  }

  return false
}

export const qs = (_: string): Element | null => {
  return document.querySelector(_) ?? null
}

export const click = (selector: string): void => {
  if (document.querySelector(selector) === null) return
  qs(selector)!.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1,
    })
  )
}

// Object 데이터 header paramter로 변환
export const objectToHeaderParameter = (object: ObjectType): string => {
  return Object.keys(object)
    .map((key) => (object[key] ? `${key}=${object[key]}` : ''))
    .filter((i) => i !== '')
    .join('&')
}

export const getArray = (length: number) => {
  return Array(length)
    .fill(1)
    .map((item, index) => item + index)
}

export const onChangeString = (
  e: React.ChangeEvent<HTMLInputElement>,
  action: React.Dispatch<React.SetStateAction<string>>
) => {
  action(e.target.value)
}

export const onChangeNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  action: React.Dispatch<React.SetStateAction<string>>
) => {
  action(e.target.value.replace(/[^0-9]/gi, ''))
}

export const onChangeFloat = (
  e: React.ChangeEvent<HTMLInputElement>,
  action: React.Dispatch<React.SetStateAction<string>>
) => {
  action(onlyFloat(e.target.value))
}

export const onChangeComma = (
  e: React.ChangeEvent<HTMLInputElement>,
  action: React.Dispatch<React.SetStateAction<string>>
) => {
  action(comma(e.target.value))
}

/**
 * 소셜 로그인 실패 함수
 */
export const socialLoginErrorAlert = (e: string) =>
  alert(`${e} 로그인에 실패하였습니다.`)

type SocialLoginType = 'kakao' | 'naver' | 'google' | 'apple'

export interface SocialLoginModel {
  email: string
  name: string
  type: SocialLoginType
  identifier: string
}

export const getSocialLoginQuery = (props: SocialLoginModel) => {
  return enc(JSON.stringify(props))
}

export const isReadOnly = (e: string | number) => {
  if (e) {
    return true
  } else {
    return false
  }
}

export const delay = (t: number) => {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null), t)
  })
}

export const number2PostCode = (postcode: number) => {
  const _postcode = postcode.toString()

  const prefix = Array(5 - _postcode.length)
    .fill(1)
    .map((i) => '0')
    .join('')

  return `${prefix}${_postcode}`
}

export const file2Base64 = (file: File) => {
  return new Promise(function (resolve) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const base64 = reader.result?.toString() ?? ''

      resolve(base64)
    }
  })
}

// 분양 상세 페이지 작업 완료 후, type_text 제거
export const getEstateDetailPath = (
  deal_kind_text: ElasticDealKindTextType,
  idx: number | string,
  is_complete?: boolean
) => {
  if (is_complete) return `-rt?idx=${idx}`
  else if (deal_kind_text === '분양') return `-by?idx=${idx}`
  else return `-es?idx=${idx}`
}

export const getEstateClassName = (type_text: ElasticTextType) => {
  switch (type_text) {
    case '공장':
      return 'cat factory'
    case '창고':
      return 'cat store'
    case '토지':
      return 'cat ground'
    case '지식':
    case '지식산업센터':
      return 'cat center'
    case '산업':
    case '산업단지':
      return 'cat industry'
    case '물류':
    case '물류단지':
      return 'cat logistics'
  }
}

export const bigNumber = (e: number) => {
  const number = parseInt(e ? e.toString() : '0')

  // 조 단위
  if (number >= 1_000_000_000_000) {
    const 조 = Math.floor(number / 1_000_000_000_000)
    const 억 = Math.floor((number - 조 * 1_000_000_000_000) / 100_000_000)
    const 만원 = Math.floor(
      (number - 억 * 100_000_000 - 조 * 1_000_000_000_000) / 10_000
    )

    return `${조}조${억 > 0 ? ` ${comma(억)}억` : ''}${
      만원 > 0 ? ` ${comma(만원)}만` : ''
    }`
  }
  // 억 단위
  else if (number >= 100_000_000) {
    const 억 = Math.floor(number / 100_000_000)
    const 만원 = Math.floor((number - 억 * 100_000_000) / 10_000)

    return `${comma(억)}억 ${만원 > 0 ? `${comma(만원)}만` : ''}`
  } else if (number >= 1_000_000) {
    const 만원 = Math.floor(number / 10_000)

    return `${comma(만원)}만`
  }

  return comma(number)
}

export const bigNumberDot = (e: number) => {
  const number = parseInt(e ? e.toString() : '0')

  // 조 단위
  if (number >= 1_000_000_000_000) {
    const 조 = Math.floor(number / 1_000_000_000_000)
    const 억 =
      Math.floor((number - 조 * 1_000_000_000_000) / 100_000_000) / 1000

    return `${조}${억 ? `.${억}` : ''}조`
  }
  // 억 단위
  else if (number >= 100_000_000) {
    const 억 = Math.floor(number / 100_000_000)
    const 만원 = Math.floor((number - 억 * 100_000_000) / 10_000) / 1000

    return `${comma(억)}${만원 ? `.${만원}` : ''}억`
  }
  // 천만 단위
  else if (number >= 10_000_000) {
    const 천만 = Math.floor(number / 10_000_000)

    return `${comma(천만)}천만`
  }
  // 백만 단위
  else if (number >= 1_000_000) {
    const 백만 = Math.floor(number / 1_000_000)

    return `${comma(백만)}천만`
  }
  // 십만 단위
  else if (number >= 100_000) {
    const 십만 = Math.floor(number / 100_000)

    return `${comma(십만)}천만`
  }
  // 만 단위
  else if (number >= 10_000) {
    const 만원 = Math.floor(number / 10_000)

    return `${comma(만원)}만`
  }

  return comma(number)
}

interface getLocationBetween0And100ParamModel {
  min: number
  max: number
  value: number
}

export const getLocationBetween0And100 = (
  props: getLocationBetween0And100ParamModel
) => {
  const { min, max, value } = props

  const i = 100 / (max - min)

  const v = value - min

  return i * v
}

interface GetGeoLocationParamModel {
  success: (pos: GeolocationPosition) => void
  fail?: () => void
}

export const getGeoLocation = (param: GetGeoLocationParamModel) => {
  window.navigator.geolocation.getCurrentPosition(
    param.success,
    param.fail ?? blankFn,
    {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    }
  )
}

/**
 * 1x : 96px ,
 * 2x : 192px ,
 * 3x : 288px ,
 * 4x : 384px ,
 * large : 3120px
 */
type OnErrorSizeType = '1x' | '2x' | '3x' | '4x' | 'large'

export const onError = (
  e: React.SyntheticEvent<HTMLImageElement>,
  size: OnErrorSizeType,
  class_add_pass?: boolean
) => {
  e.currentTarget.src =
    size === '1x'
      ? error_1x
      : size === '2x'
      ? error_2x
      : size === '3x'
      ? error_3x
      : size === '4x'
      ? error_4x
      : error_large

  if (!class_add_pass)
    e.currentTarget.parentElement!.classList.add('error-image-holder')
}

export const onErrorProfile = (
  e: React.SyntheticEvent<HTMLImageElement>,
  class_add_pass?: boolean
) => {
  message(e.currentTarget)
  e.currentTarget.src = error_profile

  if (!class_add_pass)
    e.currentTarget.parentElement!.classList.add('error-image-holder')
}

export const getArea = (e: string): [number, number] => {
  const data = e
    .replace('㎡', '')
    .replace('py', '')
    .replace(/,/g, '')
    .split(' / ')

  return [parseInt(data[0]), parseInt(data[1])]
}

export const onClickTel = (estate_idx: number, tel: string) => {
  if (accountManager.isLogin()) {
    API.estate.saleDetailCallLog(estate_idx).then(blankFn).catch(blankFn)
  }

  const a = document.createElement('a')

  a.href = `tel:${tel}`

  a.click()
}

export const onChangeToggleTypeValue = (
  toggle_type: 'left' | 'right',
  value?: number
) => {
  if (!value) {
    return 0
  } else if (toggle_type === 'left') {
    return comma((value * 0.3025).toFixed(0))
  } else if (toggle_type === 'right') {
    return comma(value.toFixed(0))
  }
}

export const onChangeToggleTypeUnit = (toggle_type: 'left' | 'right') => {
  if (toggle_type === 'left') {
    return '평'
  } else {
    return '㎡'
  }
}

 export const officialPriceByToggleType = (toggle_type: 'left' | 'right', value?: number) => {
    if (!value) {
      return 0
    } else if (toggle_type === 'left') {
      return comma((value * 3.3058).toFixed(0))
    } else if (toggle_type === 'right') {
      return comma(value.toFixed(0))
    }
  }

export const copyInClipBoard = (str: string) => {
  try {
    window.navigator.clipboard
      .writeText(str)
      .then(() => {
        alert('클립보드에 복사하였습니다.')
      })
      .catch(() => {
        alert('복사 실패.')
      })
  } catch {
    return blankFn()
  }
}

const PALETTE = {
  PRIME: '#007eff',
  SECONDARY: '#1f8fff',
  PLANE: '#001105',
  HOVERED_PRIME: '#4fa2f7',
  HOVERED_SECONDARY: '#6cb0f5',
  OPACITY: 0.7,
}

const SIZE = {
  FLOOR_HEIGHT_METER: 5,
}

export const three_util = {
  getColor: (index: number): string => {
    return index % 2 === 0 ? PALETTE.PRIME : PALETTE.SECONDARY
  },
  getDownstairsHeight: (index: number, floor_height: number): number => {
    return floor_height / 2 + index * floor_height
  },
  getFitDownstairsHeight: (index: number, floor_height: number) => {
    return index * floor_height
  },
  getXMeter: (x: number) => {
    return x * 88.74 * 1000
  },
  getYMeter: (y: number) => {
    return 110.574 * y * 1000
  },
  getLng: (meter: number) => {
    return meter / 88.74 / 1000
  },
  getLat: (meter: number) => {
    return meter / 110.574 / 1000
  },
  getLocationData: (lot: LotModel) => {
    const max_x = Math.max(...lot.lot.map((i) => i[0]))
    const min_x = Math.min(...lot.lot.map((i) => i[0]))

    const max_y = Math.max(...lot.lot.map((i) => i[1]))
    const min_y = Math.min(...lot.lot.map((i) => i[1]))

    const x_meter = Math.abs(
      three_util.getXMeter(max_x) - three_util.getXMeter(min_x)
    )

    const y_meter = Math.abs(
      three_util.getYMeter(max_y) - three_util.getYMeter(min_y)
    )

    // 지구 평면상 곡률로 0.8만큼으로 절감하여 계산
    const x_advantage =
      y_meter > x_meter
        ? Math.abs(three_util.getLng(y_meter - x_meter) / 2) * 0.8
        : 0
    const y_advantage =
      x_meter > y_meter
        ? Math.abs(three_util.getLat(x_meter - y_meter) / 2) * 0.8
        : 0

    const _max_x = max_x + x_advantage
    const _min_x = min_x - x_advantage
    const x_gap = _max_x - _min_x

    const _max_y = max_y + y_advantage
    const _min_y = min_y - y_advantage
    const y_gap = _max_y - _min_y

    const square_meter = x_meter > y_meter ? x_meter : y_meter

    const square_area = square_meter ** 2

    return {
      max_x,
      min_x,
      x_gap,
      max_y,
      min_y,
      y_gap,
      _max_x,
      _min_x,
      _max_y,
      _min_y,
      x_meter,
      y_meter,
      square_meter,
      square_area,
    }
  },
  getDefaultOption: (index: number, lot: LotModel, hovered: boolean, isSelected?: boolean) => {
    const color = three_util.getColor(index)

    const { x_meter, y_meter } = three_util.getLocationData(lot)

    const standard_size = Math.max(y_meter, x_meter)

    // const floor_height = (SIZE.FLOOR_HEIGHT_METER / standard_size) * 2
    const floor_height = (SIZE.FLOOR_HEIGHT_METER / standard_size)

    const downstairs_height = three_util.getDownstairsHeight(
      index,
      floor_height
    )

    const fit_downstairs_height = three_util.getFitDownstairsHeight(
      index,
      floor_height
    )

    const position_data = three_util.getLocationData(lot)

    return {
      color,
      // opacity: hovered || !isSelected ? PALETTE.OPACITY : 1,
      opacity: !isSelected ? PALETTE.OPACITY : 1,
      floor_height,
      downstairs_height,
      fit_downstairs_height,
      ...position_data,
    }
  },
}

export const lotReverse = (lot: LotModel[]) => {
  return lot.map((i) => ({
    ...i,
    lot: i.lot.map((i) => [Math.max(i[0], i[1]), Math.min(i[0], i[1])]) as [
      [number, number]
    ],
  }))
}

export const getLoginRedirectUrl = () => {
  const { pathname, search } = window.location

  const formated_search = search
    .replace('?', '_@')
    .replace(/=/g, '_$')
    .replace(/&/g, '_%')

  return `/login?redirect=${pathname}${formated_search}`
}

export const getRedirectUrl = (redirect: string) => {
  return (
    redirect
      .replace('_@', '?')
      // eslint-disable-next-line no-useless-escape
      .replace(/\_\$/g, '=')
      // eslint-disable-next-line no-useless-escape
      .replace(/\\_\%/g, '&')
  )
}

export const loginRead = (callback?: () => void) => {
  if (window.confirm('로그인 후 이용 가능합니다.\n로그인 하시겠습니까?')) {
    if (callback) {
      callback()
    }
  }
}

export const urlRegex = (url: string) => {
  const exp =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  const regex = new RegExp(exp)

  return url.match(regex)
}

export const onClickUrl = (url: string) => {
  if (urlRegex(url.toString())) {
    window.open(url.toString())
  }
}

export const deleteEstate = (estate_idx: number, success: () => void) => {
  if (window.confirm('정말로 삭제하시겠습니까?')) {
    API.estate
      .deleteEstate(estate_idx)
      .then((res) => {
        apiCallback(res, {
          success,
          fail: errorAlert,
        })
      })
      .catch(networkErrorAlert)
  }
}

// 분양 상세 > 필지 정보 : 업종별 polygon 라인 컬러
export const businessCodeLineColor = (code: string, color?: string) => {

  if(color && color !== '') return color
  let _code = code.toString().split(',')
  
  switch (_code[0]) {
    case 'C28':
    case 'C15':
    case 'C11':
      return '#ffc934'
    case 'C29':
    case 'C18':
    case 'C12':
      return '#ffbb54'
    case 'C30':
    case 'C22':
    case 'C13':
      return '#f86418'
    case 'C25':
    case 'C34':
    case 'C14':
      return '#f85645'
    case 'C26':
    case 'C23':
    case 'C21':
      return '#d756aa'
    case 'C20':
    case 'C32':
    case 'C16':
      return '#8156be'
    case 'C10':
    case 'C31':
    case 'C17':
      return '#2851c1'
    case 'C24':
    case 'C19':
    case 'C27':
      return '#2bc2e5'
    case 'H52':
    case 'C33':
    case '기타업종':
      return '#36cbb2'
    case 'E37':
    case 'E38':
    case 'D35':
      return '#d4e857'
    default:
      return '#36cbb2'
  }
}

// 분양 상세 > 필지 정보 : 업종별 polygon 내부 컬러
export const businessCodeInnerColor = (code: string, color?: string) => {

  if(color && color !== '') return color

  let _code = code.toString().split(',')

  switch (_code[0]) {
    case 'C28':
    case 'C15':
    case 'C11':
      return '#ffe292'
    case 'C29':
    case 'C18':
    case 'C12':
      return '#ffdaa3'
    case 'C30':
    case 'C22':
    case 'C13':
      return '#f8a980'
    case 'C25':
    case 'C34':
    case 'C14':
      return '#f8a198'
    case 'C26':
    case 'C23':
    case 'C21':
      return '#d792bf'
    case 'C20':
    case 'C32':
    case 'C16':
      return '#9d86be'
    case 'C10':
    case 'C31':
    case 'C17':
      return '#6f85c1'
    case 'C24':
    case 'C19':
    case 'C27':
      return '#81d2e5'
    case 'H52':
    case 'C33':
    case '기타업종':
      return '#81d2e5'
    case 'E37':
    case 'E38':
    case 'D35':
      return '#dde89a'
    default:
      return '#81d2e5'
  }
}

export const hexToRgba = (_hex: string, opacity: number) => {
  const hex = _hex.slice(1)

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  return `rgba(${r} , ${g} , ${b} , ${opacity})`
}

export const getKiloByte = (byte: number) => {
  return byte / 1024
}

export const getMegaByte = (byte: number) => {
  return getKiloByte(byte) / 1024
}

export const farmLandTypeCheck = (jimok?: string ) => {
  const farmLandTypeJimok = ['전', '답', '과수원', '과']
  return farmLandTypeJimok.includes(jimok ?? '')
}

export const tableData = (data?: string | number) => {
  return data == null || (typeof data === 'string' && data.trim() === '') ? (typeof data === 'number' ? 0 : '-') : data
}
 
export const onChangeDateYmType = (year?: number | string, month?: number | string) => {
  return `${tableData(year)}년 ${tableData(month)}월`
}

export const onChangeDateYmdType = (date: number | string) => {
  let _date = tableData(date).toString()
  let dateArr = []
  if(_date.length === 8){
    dateArr.push(_date.slice(0,4))
    dateArr.push(_date.slice(4,6))
    dateArr.push(_date.slice(6,8))
  } else {
    dateArr.push('-')
  }
  return dateArr.join('-')
}

// 제곱미터 -> 평 계산
export const convertPY = (m: number) =>{
  return Math.round(m * 0.3025)
}

// 유형 text 변환
export const convertType = (type: string) => {
  if(type === 'FT')      return '공장'
  else if(type === 'LD') return '토지'
  else if(type === 'WH') return '창고'
  else                   return '기타'
}