import Axios, { AxiosRequestConfig } from "axios"
import { BASE_URL, JSON_HEADER } from "../../shared/api-constant"

interface GetNewsModel {
  type: 0|1       // 0: 부동산, 1: 산업단지
  query : string
  display : number
  start : number
}

const naver = {
  getNews : (props : GetNewsModel) => {

    const config : AxiosRequestConfig = {
      url : `${BASE_URL}/search/get_naver_news.php` ,
      method : 'GET' ,
      headers : {
        ...JSON_HEADER,
      },
      params : {
        query : `${props.query} ${props.type === 1 ? '산업단지' : '부동산'}` ,
        display : props.display ,
        start : props.start
      }
    }

    return Axios(config)

  }
}

export default naver