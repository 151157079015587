import { Suspense } from "react"
import RtTable from "./rt-table"
import RtTableSkeleton from "./rt-table-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const RtTableWrapper = () => {
  return (
    <Suspense fallback={<RtTableSkeleton />}>
      <RtTable />
    </Suspense>
  )
}

export default RtTableWrapper