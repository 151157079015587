import AccountDataModel from '../model/dataModel/account-data-model'
import StorageManager from './storage-manager'
import { AccountEnterType, YNType } from './types'

type AccountKeyType =
  | 'idx'
  | 'name'
  | 'profile_url'
  | 'rest_yn'
  | 'enter_type'
  | 'alarm_not_read_cnt'
type AccountValueType = string | AccountEnterType | YNType

const util = {
  getData: <T>(key: string) => {
    const sm = new StorageManager()

    if (sm.get('account-data').getValue() === null) return null

    return sm.get('account-data').getObject(key) as T
  },

  setData: (key: AccountKeyType, value: AccountValueType) => {
    const sm = new StorageManager()

    let origin = sm.get('account-data').getObject() as AccountDataModel

    if (key === 'name') origin.name = value
    if (key === 'profile_url') origin.profile_url = value

    accountManager.login(origin)
  },
}

const accountManager = {
  login: (data: AccountDataModel) => {
    new StorageManager().set('account-data', data)
  },

  logout: () => {
    new StorageManager().remove('account-data')
  },

  isLogin: () => {
    return new StorageManager().get('account-data').getValue() !== null
  },

  isGuest: () => {
    return new StorageManager().get('account-data').getValue() === null
  },

  getIdx: () => util.getData<string>('idx'),
  getName: () => util.getData<string>('name'),
  getProfileUrl: () => util.getData<string>('profile_url'),
  getEnterType: () => util.getData<AccountEnterType>('enter_type'),
  getRestYn: () => util.getData<YNType>('rest_yn'),
  getAlarmNotReadCount: () => util.getData<number>('alarm_not_read_cnt'),
  setName: (e: string) => util.setData('name', e),
  setProfileUrl: (e: string) => util.setData('profile_url', e ?? ''),
}

export default accountManager
