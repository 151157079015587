import { useRecoilValue } from 'recoil'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import get_bunyang_detail_selector from '../../selector/get-bunyang-detail-selector'

import { getQuery } from '../../shared/function'

import error_large from '../../static/img/nodata/large.jpg'
import LeftImage from './left-image'
import { useState } from 'react'
import ImageSlidePopup from '../popup/image-slide-popup'

/* eslint-disable @typescript-eslint/no-unused-vars */
const LeftImageSlider = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

  const [popupImgIdx, setPopupImgIdx] = useState<number | undefined>(undefined)

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))

  const response_preview = data.preview

  const nodata_preview = [error_large]

  const preview = response_preview.length ? response_preview : nodata_preview

  return (
  <>
    <Swiper
      className='factory_list'
      scrollbar={{ draggable: true }}
      pagination={{ clickable: true }}
    >
      {preview.map((i, index) => {
        return (
          <SwiperSlide key={index} onClick={() => setPopupImgIdx(index)}>
            <div className='img_holder main_img'>
              <LeftImage src={i} />
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
    {popupImgIdx !== undefined && <ImageSlidePopup onClickClose={()=> setPopupImgIdx(undefined)} imgIdx={popupImgIdx} />}
  </>
  )
}

export default LeftImageSlider
