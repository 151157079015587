import { selector } from 'recoil'
import map_filter_atom from '../atom/map-filter'
import API from '../service/api'
import { SIGHT } from '../shared/constant'

const get_location_cluster_selector = selector({
  key: '@map/get_location_cluster_selector',
  get: async ({ get }) => {
    const { sw_lat, sw_lng, ne_lat, ne_lng, level } = get(map_filter_atom)

    if (level < SIGHT.GU.min) return []

    const type = level >= SIGHT.SIDO.min && level <= SIGHT.SIDO.max ? 1 : 2

    const response = await API.estate.getLocationCluster({
      sw_lat,
      sw_lng,
      ne_lat,
      ne_lng,
      type,
    })

    if (response.data.code !== 200) return []

    return response.data.list
  },
})

export default get_location_cluster_selector
