import { atom } from 'recoil'
interface NaverNewsModel {
  description: string
  link: string
  originallink: string
  pub_ymd: string
  title: string
}

interface Model {
  list: Array<NaverNewsModel>
  idx: number | string
  location: string
}

const estate_detail_news_atom = atom<Model>({
  key: '@estate/detail-news-atom',
  default: {
    list: [],
    idx: 0,
    location: '',
  },
})

export default estate_detail_news_atom
