import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import map_filter_atom from '../../atom/map-filter'
import map_filter_options_atom from '../../atom/map-filter-options'
import { comma } from '../../shared/function'
import MainButton from '../button/main-button'
import DevFilterRange, {
  DevFilterRangeOnChangeEventHandler,
} from '../input/dev-filter-range'

/* eslint-disable @typescript-eslint/no-unused-vars */
const AreaPop = () => {
  const [filter, setFilter] = useRecoilState(map_filter_options_atom)
  const { area_pop } = filter
  // const [ filter , setFilter ] = useState(false)

  const [map_filter, setMapFilter] = useRecoilState(map_filter_atom)

  const [tot_area_max, setTotAreaMax] = useState(map_filter.tot_area_max)
  const [tot_area_min, setTotAreaMin] = useState(map_filter.tot_area_min)
  const [plat_area_max, setPlatAreaMax] = useState(map_filter.plat_area_max)
  const [plat_area_min, setPlatAreaMin] = useState(map_filter.plat_area_min)
  const [area_type, setAreaType] = useState(map_filter.area_type)

  const onChangeTotal: DevFilterRangeOnChangeEventHandler = (e) => {
    setTotAreaMax(e.max)
    setTotAreaMin(e.min)
  }

  const onChangePlat: DevFilterRangeOnChangeEventHandler = (e) => {
    setPlatAreaMax(e.max)
    setPlatAreaMin(e.min)
  }

  const onChangeAreaType = () => {
    setAreaType(area_type === 'PY' ? 'SQ' : 'PY')
  }

  const onClickFilter = () => {
    setFilter({
      price_pop: false,
      area_pop: area_pop === true ? false : true,
      deal_type_pop: false,
      complete_after_pop: false,
      filter_all_pop: false,
    })
  }

  const onClickApply = () => {
    setMapFilter({
      ...map_filter,
      tot_area_max,
      tot_area_min,
      plat_area_max,
      plat_area_min,
      area_type,
      price_area_type: area_type
    })
    setFilter({
      ...filter,
      area_pop: false,
    })
  }

  const onClickReset = () => {
    setTotAreaMax(200)
    setTotAreaMin(1)
    setPlatAreaMax(200)
    setPlatAreaMin(1)
    setAreaType('PY')
  }

  useEffect(() => {
    setTotAreaMax(map_filter.tot_area_max)
    setTotAreaMin(map_filter.tot_area_min)
    setPlatAreaMax(map_filter.plat_area_max)
    setPlatAreaMin(map_filter.plat_area_min)
    setAreaType(map_filter.area_type)
  }, [area_pop, map_filter])

  const area_type_kor = area_type === 'PY' ? '평' : '㎡'

  return (
    <div className={`filter ${area_pop ? 'on' : ''}`}>
      <div className='filter_btn' onClick={() => onClickFilter()}>
        <p>면적</p>
        <span className='arrow'></span>
      </div>
      {area_pop && (
        <div className='filter_detail_wrap'>
          <section>
            <article>
              <button className='btn_area' onClick={onChangeAreaType}>
                {area_type_kor}
              </button>
              <DevFilterRange
                title='토지 면적'
                min_text={`0${area_type_kor}`}
                max_text={`10,000${area_type_kor}+`}
                min={0}
                max={200}
                min_value={plat_area_min}
                max_value={plat_area_max}
                formatter={(e) => comma(e * 50) + area_type_kor}
                onChange={onChangePlat}
              />
            </article>
            <article>
              <DevFilterRange
                title='연 면적'
                min_text={`0${area_type_kor}`}
                max_text={`10,000${area_type_kor}+`}
                min={0}
                max={200}
                min_value={tot_area_min}
                max_value={tot_area_max}
                formatter={(e) => comma(e * 50) + area_type_kor}
                onChange={onChangeTotal}
              />
            </article>
          </section>
          <div className='btn_wrap'>
            <button
              type='button'
              className='reset_btn'
              onClick={onClickReset}
            />
            <MainButton
              title='필터 적용'
              size='small'
              types='priority'
              onClick={onClickApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AreaPop
