import React, { useEffect, useRef, useState } from 'react'
import { NaverNewsModel } from '../../selector/get-detail-page-news-selector'
import API from '../../service/api'
import { blankFn } from '../../shared/function'

import '../../style/components/main/news-info.scss'
import MainButton from '../button/main-button'
import NewsCard from './news-card'

interface Props {
  location: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const News = (props: Props) => {
  const display = 8

  const news_type = useRef<number>(1)
  const ul_ref = useRef<HTMLUListElement | null>(null)

  const loading_ref = useRef<boolean>(false)

  const [news, setNews] = useState<Array<NaverNewsModel>>([])

  const [loading, setLoading] = useState(false)

  const [start, setStart] = useState(1)

  const [end, setEnd] = useState(false)

  async function getNews(is_add?: boolean) {
    if (loading_ref.current) return

    setLoadingBeforeGetNews()

    await API.naver
      .getNews({
        type: news_type.current === 1 ? 1 : 0,
        query: props.location,
        start,
        display,
      })
      .then((res) => {
        const total: number = res.data.total

        const list: Array<NaverNewsModel> = res.data.list ?? []

        const next = !is_add && start === 1 ? list : news.concat(list)

        setNews(next)

        if(news_type.current === 0){
          setEnd(total === next.length)
        }
      })
      .catch(blankFn)
      .finally(getNewsFinallyCallback)
  }

  function setLoadingBeforeGetNews() {
    setLoading(true)
    loading_ref.current = true
  }

  function getNewsFinallyCallback() {
    setLoading(false)
    loading_ref.current = false
  }

  function onClickMore() {
    if(news_type.current === 1){
      news_type.current = 0
      getNews(true)
    }else{
      setStart(start + display)
    }
  }

  function autoScroll() {
    if (ul_ref.current) {
      setTimeout(() => {
        ul_ref.current!.scrollTo({
          top: (news.length / display) * 556 + (news.length / display) * 20,
          behavior: 'smooth',
        })
      }, 100)
    }
  }

  useEffect(() => {
    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])

  useEffect(() => {
    autoScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news])

  useEffect(()=>{
    news_type.current = 1

    if(start === 1){
      getNews()
    } else if (start > 1){
      setStart(1)
    }
  },[props.location])

  return (
    <>
      <ul className='nnews' ref={ul_ref}>
        {news.map((item, index) => {
          return <NewsCard {...item} index={index} key={index} />
        })}
      </ul>
      {!end && (
        <div className='btn_wrap'>
          <MainButton
            title='더보기'
            types='more'
            size='xsmall'
            onClick={onClickMore}
            is_loading={loading && start > 1}
            loading_color={'#748398'}
          />
        </div>
      )}
    </>
  )
}

export default News
