import { Suspense, useEffect, useRef, useState } from 'react'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import { getEstateClassName } from '../../shared/function'
import SandanMap from './sandan-map'

declare const window: any

/* eslint-disable @typescript-eslint/no-unused-vars */
const MapCenterWrapper = () => {
  const map_ref = useRef<any>()

  return (
    <Suspense
      fallback={
        <div className='skeleton' style={{ aspectRatio: '778 / 465' }} />
      }
    >
         <div className='left rel'>
            <div className={getEstateClassName('산업단지')}>산업단지</div>
            <div
              className='img_area rel'
              style={{ width: '100%', maxWidth: 'unset' }}
            >
              <SandanMap map_ref={map_ref}/>
            </div>
         </div>
    </Suspense>
  )
}

export default MapCenterWrapper
