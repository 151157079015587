import { useEffect, useRef } from 'react'
import LocationIcon from '../bunyang/location-icon'
import MapTypeButton from '../bunyang/map-type-button'
import { useRecoilValue } from 'recoil'
import map_overlay_type_atom from '../../atom/map-overlay-type'

interface Props {
  id: string
  lat: number
  lng: number
  className?: string
  location_bottom_right?: boolean
  name?: string
}

export default function EstateMap(props: Props) {
  const currentTypeId_ref = useRef<any>()
  const currentOverlayMapTypeId = useRecoilValue(map_overlay_type_atom)
  const map_ref = useRef<any | null>(null)

  // 지도 그리기
  const mapDraw = () => {
    const container = document.getElementById(props.id)

    const options = {
      center: new window.window.kakao.maps.LatLng(props.lat, props.lng),
      level: props.name && props.name=== 'bunyang' ? 7 : 2,
    }

    const map = new window.kakao.maps.Map(container, options)
    map_ref.current = map

    map_ref.current.addOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT)
    currentTypeId_ref.current = window.kakao.maps.MapTypeId.USE_DISTRICT
    
    map.setMinLevel(1)
    map.setMaxLevel(12)

    // 마커가 표시될 위치입니다
    var markerPosition = new window.kakao.maps.LatLng(props.lat, props.lng)

    // 마커를 생성합니다
    var marker = new window.kakao.maps.Marker({
      position: markerPosition,
    })

    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map_ref.current)
  }

  useEffect(() => {
    mapDraw()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    // maptype에 따라 지도에 추가할 지도타입을 결정
    const changeMaptype = window.kakao.maps.MapTypeId[currentOverlayMapTypeId]
    // 이미 등록된 지도 타입이 있으면 제거
    if (currentTypeId_ref.current) {
      map_ref.current.removeOverlayMapTypeId(currentTypeId_ref.current)
    }
    // maptype에 해당하는 지도타입을 지도에 추가
    map_ref.current.addOverlayMapTypeId(changeMaptype)
    // 지도에 추가된 타입정보를 갱신
    currentTypeId_ref.current = changeMaptype
  },[currentOverlayMapTypeId])

  return (
    <>
      <div
        id={props.id}
        className={`estate-map ${props.className ? props.className : ''}`}
      />
      <LocationIcon
        lat={props.lat}
        lng={props.lng}
        bottom_right={props.location_bottom_right}
        currentTypeId_ref={currentTypeId_ref}
        name={props.name ?? ''}
      />
      <MapTypeButton
        bottom_right={props.location_bottom_right}
      />
    </>
  )
}
