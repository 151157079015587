import { Suspense } from "react"
import NearBy from "./nearby"
import NearbySkeleton from "./nearby-skeleton"

/* eslint-disable @typescript-eslint/no-unused-vars */
const NearbyWrapper = () => {
  return (
    <Suspense fallback={<NearbySkeleton />}>
      <NearBy />
    </Suspense>
  )
}

export default NearbyWrapper