import Skeleton from '../skeleton'

/* eslint-disable @typescript-eslint/no-unused-vars */
const PcTopSkeleton = () => {
  return (
    <div className='top'>
      <Skeleton height={32} width={150} radius={4} />
      <div className='f-r'>
        <Skeleton height={32} width={80} radius={16} marginRight={12} />
        <Skeleton height={32} width={32} radius={16} marginRight={12} />
        <Skeleton height={32} width={32} radius={16} />
      </div>
    </div>
  )
}

export default PcTopSkeleton
