import { atom } from 'recoil'

type Model = 'TERRAIN' | 'USE_DISTRICT' | 'HYBRID' | 'ROADMAP'

const map_overlay_type_atom = atom<Model>({
  key: 'map_overlay_type_atom',
  default: 'USE_DISTRICT',
})

export default map_overlay_type_atom
