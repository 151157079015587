import Skeleton from '../skeleton'

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileTopSkeleton = () => {
  return (
    <div className='top'>
      <Skeleton height={29} width={150} radius={4} />
      <div className='mobile_top_btn'>
        <Skeleton height={20} width={150} radius={4} />
        <div className='top_btn'>
          <Skeleton height={24} width={60} radius={12} marginRight={6} />
          <Skeleton height={24} width={24} radius={12} marginRight={6} />
          <Skeleton height={24} width={24} radius={12} />
        </div>
      </div>
    </div>
  )
}

export default MobileTopSkeleton
