import { atom } from 'recoil'
import { YNType } from '../shared/types'

interface DealKindModel {
  tr: YNType
  trade: YNType
  rent: YNType
  sub_division: YNType
}

interface TypeModel {
  FT: YNType
  WH: YNType
  LD: YNType
  IC: YNType
  KC: YNType
  LC: YNType
}

export type AreaType = 'SQ' | 'PY'

export type CompleteAfterType = '1Y' | '2Y' | '3Y' | '5Y' | 'N'

export interface MapFilterAtomModel {
  search?: {
    lat: number
    lng: number
  }
  deal_kind: DealKindModel
  certify_yn: YNType
  keyword: string
  type: TypeModel
  price_total_min: number
  price_total_max: number
  price_once_min: number
  price_once_max: number
  plat_area_min: number
  plat_area_max: number
  tot_area_min: number
  tot_area_max: number
  area_type: AreaType
  price_area_type?: AreaType
  complete_after: CompleteAfterType
  broker_yn: YNType
  sw_lat: number
  sw_lng: number
  ne_lat: number
  ne_lng: number
  level: number
  lat: number
  lng: number
  broker_idx: number
  sandan_yn: YNType
  realdeal_yn: YNType
}

const map_filter_atom = atom<MapFilterAtomModel>({
  key: '@map/map-fileter',
  default: {
    search: undefined,
    deal_kind: {
      tr: 'Y',
      trade: 'Y',
      rent: 'Y',
      sub_division: 'N',
    },
    certify_yn: 'N',
    keyword: '',
    type: {
      FT: 'Y',
      WH: 'Y',
      LD: 'Y',
      IC: 'Y',
      KC: 'Y',
      LC: 'Y',
    },
    price_total_min: 0,
    price_total_max: 999,
    price_once_min: 0,
    price_once_max: 100,
    plat_area_min: 0,
    plat_area_max: 200,
    tot_area_min: 0,
    tot_area_max: 200,
    area_type: 'PY',
    price_area_type: 'PY',
    complete_after: '3Y',
    broker_yn: 'N',
    sw_lat: 0,
    sw_lng: 0,
    ne_lat: 0,
    ne_lng: 0,
    level: 6,
    lat: 0,
    lng: 0,
    broker_idx: 0,
    sandan_yn: 'Y',
    realdeal_yn: 'N',
  },
})

export default map_filter_atom
