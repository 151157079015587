import React, { useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { LotModel } from '../../service/estate/model/get-detail-response-model'
import {
  businessCodeInnerColor,
  businessCodeLineColor,
  getQuery,
  isFloat,
} from '../../shared/function'
import '../../style/components/map/sandan-map.scss'
import media_atom from '../../atom/media-atom'
import get_sandan_detail_selector from '../../selector/get-sandan-detail-selector'
import sandan_detail_atom from '../../atom/sandan-detail'
import MapTypeButton from '../bunyang/map-type-button'
import map_overlay_type_atom from '../../atom/map-overlay-type'
import sandan_map_atom from '../../atom/sandan-map'

interface Props {
  map_ref: React.MutableRefObject<any>
}

const util = {
  getPolygon: (path: Array<any>, index: number, type: string, color?: string) => {

    const line_color = businessCodeLineColor(type, color)

    const fill_color = businessCodeInnerColor(type, color)

    return new window.kakao.maps.Polygon({
      path,
      strokeWeight: 2,
      strokeColor: '#748398',
      fillColor: '#748398',
      fillOpacity: 0.3,
    })
  },
  sandanOverlayGenerator: (name: string, type: string) => {
    return `<div class="sandan">
              <span>${type}</span>
              ${type !== '' ? '<div></div>' : ''}
              ${name}
            </div>`
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const SandanMap = (props: Props) => {
  const { map_ref } = props


  const currentTypeId_ref = useRef<any>()
  const last_select = useRef<number>(0)

  const polygon_overlays = useRef<Array<any>>([])
  const custom_overlays = useRef<Array<any>>([])

  const sandan_detail = useRecoilValue(sandan_detail_atom)
  const media = useRecoilValue(media_atom)

  const currentOverlayMapTypeId = useRecoilValue(map_overlay_type_atom)

  const { area_type } = sandan_detail

  const data = useRecoilValue(get_sandan_detail_selector(getQuery().id))

  const sandanBounds = useSetRecoilState(sandan_map_atom)

  const mapDraw = () => {
    const container = document.getElementById('sandan-map')

    const options = {
      center: new window.window.kakao.maps.LatLng(data.lat, data.lng),
      level: 6,
    }

    const map = new window.kakao.maps.Map(container, options)

    map.setMinLevel(1)
    map.setMaxLevel(12)
    
    const position = media === 'M' ? window.kakao.maps.CopyrightPosition.BOTTOMLEFT : window.kakao.maps.CopyrightPosition.BOTTOMRIGHT

    map.setCopyrightPosition(position, true);

    map_ref.current = map
    map_ref.current.addOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT)
    
    currentTypeId_ref.current = window.kakao.maps.MapTypeId.USE_DISTRICT

    const bounds = map.getBounds()

    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const ymin = sw.getLat();
    const xmin = sw.getLng();
    const ymax = ne.getLat();
    const xmax = ne.getLng();

    sandanBounds({
      ymin,
      xmin,
      ymax,
      xmax
    })

    // 4레벨 이하만 보여주도록 설정
    window.kakao.maps.event.addListener(
      map_ref.current,
      'zoom_changed',
      onResize
    )
  }

  function mapResizeEventRemove() {
    window.kakao.maps.event.removeListener(
      map_ref.current,
      'zoom_changed',
      onResize
    )
  }

  const customOverlayDraw = (item: LotModel) => {
    const land_position = getCentroid(item.lot)

    const land_content = document.createElement('div')
    land_content.className = 'c-overlay'

    const land_title = document.createElement('div')
    land_title.className = 'text strong'
    const land_title_text = document.createTextNode(item.title)
    land_title.appendChild(land_title_text)

    const land_plat_area = document.createElement('div')
    land_plat_area.className = 'text'

    let area_size = area_type === 'SQ' ? item.plat_area : item.plat_area / 3.3058

    if (isFloat(area_size)) {
      area_size = Number(area_size.toFixed(1))
    }

    const suffix = area_type === 'PY' ? '평' : '㎡'

    const land_plat_area_text = document.createTextNode(`${area_size}${suffix}`)
    land_plat_area.appendChild(land_plat_area_text)

    land_content.appendChild(land_title)
    land_content.appendChild(land_plat_area)

    return new window.kakao.maps.CustomOverlay({
      position: new window.kakao.maps.LatLng(...land_position),
      content: land_content,
    })
  }

  const polygonDraw = () => {
    let index = 0

    if(!data.polygon) return

    const path = data.polygon.map((i) => new window.kakao.maps.LatLng(...i))

    const polygon = util.getPolygon(path, index, data.type)

    polygon.setMap(map_ref.current)

    polygon_overlays.current.push(polygon)

    const position = new window.kakao.maps.LatLng(data.lat, data.lng)
    const overlay = new window.kakao.maps.CustomOverlay({
      position,
      content: util.sandanOverlayGenerator(data.name ?? data.api_name, data.type),
    })

    overlay.setMap(map_ref.current)

    custom_overlays.current.push(overlay)

    //지도 초기 level 6으로 수정 => 초기 진입 시 overlay text 미노출
    document.querySelectorAll('.c-overlay').forEach((element) => {
      element.style.display =  'none'
    })

  }

  function onResize() {
    const level = map_ref.current.getLevel()

    document.querySelectorAll('.c-overlay').forEach((element) => {
      element.style.display = level > 4 ? 'none' : 'block'
    })
  }

  // 다각형의 무게 중심 좌표 구하기
  function getCentroid(points: number[][]): number[] {
    let area = 0
    let cx = 0
    let cy = 0

    for (let i = 0; i < points.length; i++) {
      let j = (i + 1) % points.length

      let pt1 = points[i]
      let pt2 = points[j]

      let x1 = pt1[0]
      let x2 = pt2[0]
      let y1 = pt1[1]
      let y2 = pt2[1]

      area += x1 * y2
      area -= y1 * x2

      cx += (x1 + x2) * (x1 * y2 - x2 * y1)
      cy += (y1 + y2) * (x1 * y2 - x2 * y1)
    }

    area /= 2
    area = Math.abs(area)

    cx = cx / (6.0 * area)
    cy = cy / (6.0 * area)

    return [Math.abs(cx), Math.abs(cy)]
  }

  const polygonRemove = () => {
    for (const overlay of polygon_overlays.current) {
      overlay.setMap(null)
    }
  }

  const customOverlayRemove = () => {
    for (const overlay of custom_overlays.current) {
      overlay.setMap(null)
    }
  }

  useEffect(() => {
    mapDraw()

    return () => {
      mapResizeEventRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    polygonDraw()

    return () => {
      polygonRemove()
      customOverlayRemove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area_type])

  useEffect(()=>{
    // maptype에 따라 지도에 추가할 지도타입을 결정
    const changeMaptype = window.kakao.maps.MapTypeId[currentOverlayMapTypeId]
    // 이미 등록된 지도 타입이 있으면 제거
    if (currentTypeId_ref.current) {
      map_ref.current.removeOverlayMapTypeId(currentTypeId_ref.current)
    }
    // maptype에 해당하는 지도타입을 지도에 추가
    map_ref.current.addOverlayMapTypeId(changeMaptype)
    // 지도에 추가된 타입정보를 갱신
    currentTypeId_ref.current = changeMaptype
  },[currentOverlayMapTypeId])

  return (
    <>
      <div id='sandan-map' style={{ width: '100%', height: 500 }} />
      <MapTypeButton full_map/>
    </>
  )
}

export default SandanMap
