import axios, { CancelTokenSource } from 'axios'
import { selector } from 'recoil'
import map_filter_atom from '../atom/map-filter'
import API from '../service/api'
import ApiBaseResponseModel from '../service/model/api-base-response-model'
import { SIGHT } from '../shared/constant'
import {
  ElasticDealKindTextType,
  ElasticTextType,
  ElasticType,
  MapStatusType,
  YNType,
} from '../shared/types'

interface BrokerModel {
  idx: number
  lat: number
  lng: number
  store_name: string
  profile_path: string | null
}

export interface EstateModel {
  address_text: string
  idx: number
  is_certify: boolean
  lat: number
  lng: number
  lot?: Array<LotModel>
  price_text: string
  tags_text: string
  thumbnail_path: null | string
  total_plat_area_text: string
  type: ElasticType
  type_text: ElasticTextType
  marker_price_main: string
  marker_price_sub: string
  marker_price_text: string
  same_address_count: number
  deal_kind_text: ElasticDealKindTextType
  status: MapStatusType
  realdeal_yn: YNType
  price_per_area_yn: YNType
  recommend_map_yn: YNType
  title?: string
  complete_ymd: string
}

type PolygonType = [number, number]

interface LotModel {
  title: string
  polygon: Array<PolygonType>
}

interface InnerLotModel {
  polygon: Array<[number, number]>
}

interface SandanModel {
  idx: number
  id: string
  lat: number
  lng: number
  name: string
  polygon: Array<[number, number]>
  type: string
  api_yn: YNType
  lot: Array<InnerLotModel>
}

export interface MapDataResponseModel extends ApiBaseResponseModel {
  broker: Array<BrokerModel>
  estate: Array<EstateModel>
  sandan: Array<SandanModel>
}

const error_data = {
  estate: [],
  broker: [],
  sandan: [],
}

let cancel_token: CancelTokenSource | null = null

const get_map_data_selector = selector<MapDataResponseModel>({
  key: '@map/get-map-data',
  get: async ({ get }) => {
    const request = get(map_filter_atom)

    if (request.level > SIGHT.CITIES.max) return error_data

    if (cancel_token) cancel_token.cancel()

    cancel_token = axios.CancelToken.source()

    const response = await API.estate.getMapCluster(request, cancel_token.token)

    cancel_token = null

    if (response.data.code !== 200) return error_data

    return response.data
  },
})

export default get_map_data_selector
