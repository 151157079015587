import { useRecoilValue } from "recoil"
import get_bunyang_detail_selector from "../../selector/get-bunyang-detail-selector"
import { getQuery } from "../../shared/function"
import RealEstateInfo from "../detail/real-estate-info"

/* eslint-disable @typescript-eslint/no-unused-vars */
const News = () => {

  const data = useRecoilValue(get_bunyang_detail_selector(getQuery().idx))
  
  return (
    <RealEstateInfo lat={data.detail.lat} lng={data.detail.lng} />
  )
}

export default News